import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';

/**
 * The default list control configuration for collections of `Scenario` objects.
 */
const defaultListControls = {
  matches: {},
  search: '',
  sort: {
    by: 'name',
    order: 'asc',
  },
  pagination: {
    page: 1,
    size: 10,
    pages: 1,
  },
};

/**
 * Select all `Scenario` objects which match the supplied criteria. The resulting
 * collection is sorted.
 * @param {Object[]} scenarios A collection of `Scenario` objects.
 * @param {[string[]]} iteratees (Optional) Array of sort attribute names.
 * @param {[string[]]} orders (Optional) Array of sort order directions.
 * @returns {Object[]} A sorted collection of scenarios.
 */
export const selectScenarios = (scenarios = [], iteratees = ['name'], orders = ['asc']) => {
  return orderBy(scenarios, iteratees, orders);
};

/**
 * Select all `Scenario` objects which contain the supplied `text` value in their name.
 * @param {Object[]} scenarios A collection of `Scenario` objects.
 * @param {string} text The selection criteria.
 * @returns A collection of scenarios.
 */
export const selectScenariosByText = (scenarios = [], text = '') => {
  return filter(scenarios, (s) => {
    return s.name.toLowerCase().includes(text.toLowerCase());
  });
};

/**
 * Select Scenarios with list controls selections applied.
 * @param {Object[]} scenarios A collection of `Scenario` objects.
 * @param {Object} listControls A ListControls object.
 * @returns {Object} An object whose attributes are `data` and `page`.
 * The `data` attribute is a collection of all of the Scenario objects
 * with list controls applied. The `page` attribute is a page of Scenario objects with
 * list controls applied.
 */
export const selectScenariosWithPagination = (scenarios = [], listControls) => {
  const result = {};
  const opts = merge({}, defaultListControls, listControls);
  // 1. filter by attribute matcher
  let items = filter(scenarios, opts.matches);
  // 2. filter by search text
  items = opts.search && opts.search.length > 1 ? selectScenariosByText(items, opts.search) : items;
  // 3. apply sort
  result.data = orderBy(items, [opts.sort.by], [opts.sort.order]);
  // 4. apply pagination
  result.page = chunk(result.data, opts.pagination.size)[opts.pagination.page - 1] || [];

  return result;
};
