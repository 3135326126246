import { TrialListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import EvidenceTrialListLoading from './EvidenceTrialListLoading';
import EvidenceTrialListEmpty from './EvidenceTrialListEmpty';
import EvidenceTrialListContent from './EvidenceTrialListContent';

import { useGetAllTrialsForEvidence } from '../../../../hooks/trials.hooks';

const EvidenceTrialList = ({ evidence, projectId }) => {
  const { data: evidenceTrials, isLoading } = useGetAllTrialsForEvidence(projectId, evidence.id);

  return (
    <div id="trial-edit-evidence-list">
      {isLoading ? (
        <EvidenceTrialListLoading />
      ) : (
        <>
          <EvidenceTrialListEmpty evidence={evidence} trialList={evidenceTrials} />
          <TrialListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <EvidenceTrialListContent
              projectId={projectId}
              evidence={evidence}
              trialList={evidenceTrials}
            />
          </TrialListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default EvidenceTrialList;
