import classNames from 'classnames';

import Button from './Button';
import Icon from './Icon';

/**
 * The `LoadingButton` renders a `Button` that displays a visual indicator
 * when the `isLoading` property is `true`. Useful for form submission, etc.
 * @param {Object} props The component properties.
 * @returns {string} CSS classes
 */
const LoadingButton = ({ children, isLoading, size = 'md', ...props }) => {
  let sizeClasses = '';
  switch (size) {
    case 'lg':
      sizeClasses = 'h-5 w-5';
      break;
    case 'sm':
      sizeClasses = 'h-3 w-3';
      break;
    default:
      sizeClasses = 'h-4 w-4';
  }

  if (isLoading) {
    return (
      <Button size={size} {...props}>
        <div className="flex items-center justify-center">
          <Icon icon="rotate" spin className={classNames(sizeClasses, 'mr-2')} />
          {children}
        </div>
      </Button>
    );
  }

  return (
    <Button size={size} {...props}>
      {children}
    </Button>
  );
};

export default LoadingButton;
