import assignWith from 'lodash/assignWith';
import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import toString from 'lodash/toString';

/**
 * The default list control configuration for collections of `Claim` objects.
 */
const defaultListControls = {
  matches: {},
  search: '',
  sort: {
    by: 'name',
    order: 'asc',
  },
  pagination: {
    page: 1,
    size: 10,
    pages: 1,
  },
};

/**
 * Select all `Claim` objects which match the supplied criteria. The resulting
 * collection is sorted.
 * @param {Object[]} claims A collection of `Claim` objects.
 * @param {[Object]} matches (Optional) Filter matching criteria.
 * @param {[string[]]} iteratees (Optional) Array of sort attribute names.
 * @param {[string[]]} orders (Optional) Array of sort order directions.
 * @returns {Object[]} A sorted collection of claims.
 */
export const selectClaims = (claims = [], matches = {}, iteratees = ['name'], orders = ['asc']) => {
  return orderBy(filter(claims, matches), iteratees, orders);
};

/**
 * Select all `Claim` objects which contain the supplied `text` value in their name or type.
 * @param {Object[]} claims A collection of `Claim` objects.
 * @param {string} text The selection criteria.
 * @returns A collection of claims.
 */
export const selectClaimsByText = (claims = [], text = '') => {
  return filter(claims, (c) => {
    return c.name.toLowerCase().includes(text.toLowerCase()) || c.type.includes(text.toLowerCase());
  });
};

/**
 * Select Claims with list controls selections applied.
 * @param {Object[]} claims A collection of `Claim` objects.
 * @param {Object} listControls A ListControls object.
 * @returns {Object} An object whose attributes are `data` and `page`.
 * The `data` attribute is a collection of all of the Claim objects
 * with list controls applied. The `page` attribute is a page of Claim objects with
 * list controls applied.
 */
export const selectClaimsWithListControls = (claims = [], listControls) => {
  const result = {};
  const opts = merge({}, defaultListControls, listControls);
  // 1. filter by attribute matcher
  let items = filter(claims, opts.matches);
  // 2. filter by search text
  items = opts.search && opts.search.length > 1 ? selectClaimsByText(items, opts.search) : items;
  // 3. apply sort
  result.data = orderBy(items, [opts.sort.by], [opts.sort.order]);
  // 4. apply pagination
  result.page = chunk(result.data, opts.pagination.size)[opts.pagination.page - 1] || [];

  return result;
};

/**
 * Selects all `Claim` objects from the supplied collection which are
 * not part of a merged claim group.
 * @param {Object[]} claims A collection of `Claim` objects.
 * @returns {Object[]} A collection of claims.
 */
export const selectNotMerged = (claims = []) => {
  return filter(claims, (c) => c.mergedClaimId === undefined);
};

/**
 * Select the standalone claims, i.e. those not part of a merged group,
 * from the supplied collection of `Claim` objects.
 * @param {Object[]} claims A collection of `Claim` objects.
 * @returns {Object[]} A collection of claims.
 */
export const selectStandaloneClaims = (claims = []) => {
  return filter(claims, (c) => {
    let isSelected = c.mergedClaimId === undefined;
    isSelected = isSelected && !c.type.includes('merged');
    return isSelected;
  });
};

/**
 * Formats the supplied collection of `Claim` objects for export.
 * @param {Object[]} claims A collection of `Claim` objects.
 * @returns {Object[]} A formatted collection of claims.
 */
export const selectClaimsForExport = (claims = []) => {
  return map(claims, (c) => {
    let claim = omit(c, ['evidence', 'scenarios']);
    claim = assignWith({}, claim, (objValue, srcValue) => toString(srcValue));
    return claim;
  });
};
