import classNames from 'classnames';

import Icon from '../../../common/Icon';

const ScenarioHierarchyItemLoading = ({ className }) => {
  return (
    <div className={classNames('flex items-center', className)}>
      <Icon icon="rotate" spin />
      <div className="ml-4">Loading...</div>
    </div>
  );
};

export default ScenarioHierarchyItemLoading;
