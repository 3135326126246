import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';

import ScenarioIndicationForm from './ScenarioIndicationForm';

import { generateId } from '../../../../../utils/id';
import { indicationSchema } from '../../../../../validations/indication.valudations';
import { useUpdateScenario } from '../../../../../hooks/scenarios.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';

const ScenarioIndicationCreate = ({ scenario, projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateScenario = useUpdateScenario();
  const toastsContext = useToastsContext();

  return (
    <div id="scenario-indication-create" className="h-full overflow-y-auto">
      <h3 className="mb-4 font-bold text-sky-900">CREATE SCENARIO INDICATION</h3>

      <Formik
        initialValues={{
          therapeuticArea: '',
          indication: '',
          indicationOther: '',
        }}
        validationSchema={indicationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (values.indication !== 'Other') values.indicationOther = '';
          updateScenario.mutate(
            {
              projectId,
              scenario: {
                ...scenario,
                indications: [
                  ...scenario.indications,
                  {
                    id: generateId(),
                    ...values,
                  },
                ],
              },
            },
            {
              onSuccess: (scenario) => {
                setSubmitting(false);
                toastsContext.createToast(`Added indication to scenario.`);
                searchParams.delete('mode');
                setSearchParams(searchParams);
              },
              onError: (err) => {
                console.error(`Failed to create scenario indication. Detail:`, err);
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <ScenarioIndicationForm formik={formik} />}
      </Formik>
    </div>
  );
};

export default ScenarioIndicationCreate;
