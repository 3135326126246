import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';

import Icon from '../common/Icon';

import { useAuthState } from '../../hooks/cognito.hooks';
import { useGetProjects } from '../../hooks/projects.hooks';
import { selectProjects } from '../../selectors/projects.selectors';

const SearchResultItem = ({ result }) => {
  const navigate = useNavigate();

  if (result) {
    return (
      <div
        className="flex cursor-pointer flex-col px-2 py-0.5 hover:bg-slate-300"
        onClick={() => {
          navigate(`/projects/${result.id}`);
        }}
      >
        <div className="text-sm">{result.name}</div>
        <div className="text-xs text-slate-400">{result.client}</div>
      </div>
    );
  }

  return null;
};

const SearchResults = ({ results = [], useShowResults }) => {
  const [showResults, setShowResults] = useShowResults;

  if (showResults && results.length > 0) {
    return (
      <>
        <div
          className={classNames(
            { 'absolute top-0 left-0 z-40 h-screen w-screen': showResults },
            { hidden: !showResults },
          )}
          onClick={() => setShowResults(false)}
        ></div>
        <div
          className={classNames(
            {
              'absolute z-50 mt-1 max-h-[216px] w-80 overflow-y-auto rounded-lg bg-white py-2 text-sky-900 shadow-lg shadow-slate-600':
                showResults,
            },
            { hidden: !showResults },
          )}
          onClick={() => setShowResults(false)}
        >
          {results.map((result) => (
            <SearchResultItem key={result.id} result={result} />
          ))}
        </div>
      </>
    );
  }

  return null;
};

const SearchForm = () => {
  const [showResults, setShowResults] = useState(true);

  const [criteria, setCriteria] = useState('');
  const { data: authState } = useAuthState();
  const { data: projects, status } = useGetProjects(authState?.sub, {
    enabled: !!authState?.sub,
  });

  const eligibleProjects = selectProjects(projects, { isArchived: false });

  // filter by search criteia
  let results = [];
  if (criteria.length > 1) {
    results = filter(
      eligibleProjects,
      (project) =>
        project.name.toLowerCase().includes(criteria.toLowerCase()) ||
        project.client.toLowerCase().includes(criteria.toLowerCase()),
    );
  }

  // handler for search form value input
  const onCriteriaChange = (event) => {
    setCriteria(event.target.value);
  };
  // debounce user input; wait for 300ms pause in typing
  // eslint-disable-next-line
  const debouncedCriteriaChange = useMemo(() => debounce(onCriteriaChange, 300), [criteria]);

  // clean up debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedCriteriaChange.cancel();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <form className="relative z-50">
        <input
          type="text"
          placeholder="Search here..."
          onChange={debouncedCriteriaChange}
          onFocus={() => setShowResults(true)}
          className="h-10 w-80 rounded-full border-0 bg-white/50 text-xs text-slate-700 focus:ring-0"
          disabled={status === 'loading'}
        />
        <Icon
          icon="magnifying-glass"
          className="absolute right-3 top-3 inline-block h-4 w-4 text-sky-900/70"
        />
      </form>
      <SearchResults results={results} useShowResults={[showResults, setShowResults]} />
    </div>
  );
};

export default SearchForm;
