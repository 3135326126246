import filter from 'lodash/filter';
import find from 'lodash/find';
import * as _reject from 'lodash/reject';
import unionBy from 'lodash/unionBy';
import union from 'lodash/union';
import without from 'lodash/without';

import { getProject, updateProject } from './projects.api';
import { generateId } from '../utils/id';

/**
 * Fetch the collection of `Claim` objects for a Project.
 * @param {string} projectId A project identifier.
 * @returns {Promise} Returns a promise which resolves to a collection of Claims if successful,
 * an empty collection if not found, otherwise rejects with an error.
 */
export const getClaims = async (projectId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        return resolve(project.claims || []);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Claim` objects for a Project and linked to an Evidence
 * item.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of Claims
 * if successful, otherwise rejects with an error.
 */
export const getClaimsForEvidence = async ({ projectId, evidenceId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const evidence = find(project.evidence, { id: evidenceId });
        if (!evidence) return resolve([]);

        const evidenceClaims = filter(project.claims, (c) => evidence.claims.includes(c.id));
        return resolve(evidenceClaims);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Claim` objects for a Project and linked to a Scenario.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of Claims
 * if successful, otherwise rejects with an error.
 */
export const getClaimsForScenario = async ({ projectId, scenarioId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const scenario = find(project.scenarios, { id: scenarioId });
        if (!scenario) return resolve([]);

        const claims = filter(project.claims, (c) => scenario.claims.includes(c.id));
        return resolve(claims);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch a single `Claim` for the supplied project and claim identifiers.
 * @param {string} projectId A project identifier.
 * @param {string} claimId A claim identifier.
 * @returns {Promise} Returns a Promise which resolves to a Claim if found,
 * otherwise rejects with an error.
 */
export const getClaim = async (projectId, claimId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (claim) {
          return resolve(claim);
        }
        return reject(new Error('Not found.'));
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a `Claim` for a Project.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} variables.claim The Claim to be created.
 * @returns {Promise} Returns a Promise which resolves to the created Claim
 * if successful, otherwise rejects with an error.
 */
export const createClaim = async ({ projectId, claim }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const newClaim = {
          ...claim,
          id: generateId(),
          evidence: [],
          scenarios: [],
          createdAt: new Date().toISOString(),
        };
        const currentClaims = project.claims || [];
        updateProject({
          ...project,
          claims: [...currentClaims, newClaim],
        }).then(() => {
          return resolve(newClaim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Update a `Claim`.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} variables.claim The claim to update.
 * @returns {Promise} Returns a Promise which resolves to the updated Claim
 * if successful, otherwise rejects with an error.
 */
export const updateClaim = async ({ projectId, claim }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const updatedClaim = { ...claim, updatedAt: new Date().toISOString() };
        const otherClaims = _reject(project.claims, { id: claim.id });
        updateProject({
          ...project,
          claims: [...otherClaims, updatedClaim],
        }).then(() => {
          return resolve(updatedClaim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Prioritize a `Claim`. Update the attributes of a Claim to indicate it is a priority.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @returns {Promise} Returns a Promise which resolves to the prioritized Claim
 * if successful, otherwise rejects with an error.
 */
export const prioritizeClaim = async ({ projectId, claimId }) => {
  return new Promise((resolve, reject) => {
    try {
      getClaim(projectId, claimId).then((claim) => {
        updateClaim({
          projectId,
          claim: {
            ...claim,
            isPrioritized: true,
          },
        }).then((claim) => {
          return resolve(claim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Merges the source claim with the target claim.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.sourceId The source claim identifier.
 * @param {string} variables.targetId The target claim identifier.
 * @returns {Promise} Returns a Promise which resolves to the merged Claim
 * if successful, otherwise rejects with an error.
 */
export const mergeClaim = async ({ projectId, sourceId, targetId }) => {
  return new Promise((resolve, reject) => {
    try {
      const sourceClaimPromise = getClaim(projectId, sourceId);
      const targetClaimPromise = getClaim(projectId, targetId);
      // fetch source & target claims
      Promise.all([sourceClaimPromise, targetClaimPromise]).then((claims) => {
        const sourceClaim = claims[0];
        const targetClaim = claims[1];

        if (targetClaim.type.includes('merged')) {
          // update existing merged claim group
          updateClaim({
            projectId,
            claim: {
              ...targetClaim,
              type: [...union(targetClaim.type, sourceClaim.type)],
              isReviewNeeded: true,
              evidence: unionBy(targetClaim.evidence, sourceClaim.evidence, 'id'),
              claims: [...targetClaim.claims, sourceClaim.id],
              scenarios: unionBy(targetClaim.scenarios, sourceClaim.scenarios, 'id'),
            },
          }).then((mergedClaim) => {
            updateClaim({
              projectId,
              claim: {
                ...sourceClaim,
                mergedClaimId: mergedClaim.id,
              },
            }).then(() => {
              return resolve(mergedClaim);
            });
          });
        } else {
          // create new merged claim group
          createClaim({
            projectId,
            claim: {
              type: [...union(sourceClaim.type, targetClaim.type), 'merged'],
              name: targetClaim.name,
              details: '',
              riskScore: 0,
              timeScore: 0,
              costScore: 0,
              overallScore: 0,
              isPrioritized: false,
              isReviewNeeded: true,
              isArchived: false,
              evidence: unionBy(sourceClaim.evidence, targetClaim.evidence, 'id'),
              claims: [sourceClaim.id, targetClaim.id],
              scenarios: unionBy(sourceClaim.scenarios, targetClaim.scenarios, 'id'),
            },
          }).then((mergedClaim) => {
            // update the source & target claims serially to prevent storage overwrites
            updateClaim({
              projectId,
              claim: {
                ...sourceClaim,
                mergedClaimId: mergedClaim.id,
              },
            }).then(() => {
              updateClaim({
                projectId,
                claim: {
                  ...targetClaim,
                  mergedClaimId: mergedClaim.id,
                },
              }).then(() => {
                return resolve(mergedClaim);
              });
            });
          });
        }
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Unmerge a claim from a claim group. If the group consists of 2 claims, both claims are
 * unmerged and the group is deleted. If the group consists of 3 or more claims, the
 * standalone claim is removed from the group.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.mergedId The merged claim group identifier.
 * @param {string} variables.standaloneId The identifier of the claim to be unmerged.
 * @returns {Promise} Returns a Promise which resolves to `null` if successful,
 * otherwise rejects with an error.
 */
export const unmergeClaim = async ({ projectId, mergedId, standaloneId }) => {
  return new Promise((resolve, reject) => {
    try {
      // fetch the merged claim group
      getClaim(projectId, mergedId).then((mergedClaim) => {
        if (mergedClaim.claims.length === 2) {
          // unmerge entire group
          const standalonePromise1 = getClaim(projectId, mergedClaim.claims[0]);
          const standalonePromise2 = getClaim(projectId, mergedClaim.claims[1]);
          Promise.all([standalonePromise1, standalonePromise2]).then((standaloneClaims) => {
            const [standaloneClaim1, standaloneClaim2] = standaloneClaims;
            // update the first standalone claim
            delete standaloneClaim1.mergedClaimId;
            updateClaim({ projectId, claim: { ...standaloneClaim1 } }).then(() => {
              // update the second standalone claim
              delete standaloneClaim2.mergedClaimId;
              updateClaim({ projectId, claim: { ...standaloneClaim2 } }).then(() => {
                // remove the merged claim group from the project
                getProject(projectId).then((project) => {
                  const filteredClaims = project.claims.filter((c) => c.id !== mergedId);
                  updateProject({
                    ...project,
                    claims: filteredClaims,
                  }).then(() => {
                    return resolve();
                  });
                });
              });
            });
          });
        } else {
          // unmerge the standalone from the group
          // fetch the merged and standalone claims
          const mergedPromise = getClaim(projectId, mergedId);
          const standalonePromise = getClaim(projectId, standaloneId);
          Promise.all([mergedPromise, standalonePromise]).then((claims) => {
            const [mergedClaim, standaloneClaim] = claims;
            // update the standalone claim
            delete standaloneClaim.mergedClaimId;
            updateClaim({ projectId, claim: { ...standaloneClaim } }).then(() => {
              // update the merged claim
              const filteredClaims = mergedClaim.claims.filter(
                (claimId) => claimId !== standaloneId,
              );
              updateClaim({
                projectId,
                claim: {
                  ...mergedClaim,
                  isReviewNeeded: true,
                  claims: filteredClaims,
                },
              }).then(() => {
                return resolve();
              });
            });
          });
        }
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a bi-directional link between a `Claim` and an `Evidence` item.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @param {string} variables.evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to the linked Claim if
 * successful, otherwise rejects with an error.
 */
export const linkClaimAndEvidence = async ({ projectId, claimId, evidenceId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (!claim) return reject('Not found.');
        const evidence = find(project.evidence, { id: evidenceId });
        if (!evidence) return reject('Not found.');

        claim.evidence = [...claim.evidence, evidenceId];
        evidence.claims = [...evidence.claims, claimId];

        updateProject({
          ...project,
        }).then(() => {
          return resolve(claim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Removes the bi-directional link between a `Claim` and an `Evidence` item.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @param {string} variables.evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to the Claim if successful,
 * otherwise rejects with an error.
 */
export const unlinkClaimAndEvidence = async ({ projectId, claimId, evidenceId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (!claim) return reject('Not found.');
        const evidence = find(project.evidence, { id: evidenceId });
        if (!evidence) return reject('Not found.');

        claim.evidence = without(claim.evidence, evidenceId);
        evidence.claims = without(evidence.claims, claimId);

        updateProject({
          ...project,
        }).then(() => {
          return resolve(claim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a bi-directional link between a `Claim` and a `Scenario`.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @param {string} variables.scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to the linked Claim if
 * successful, otherwise rejects with an error.
 */
export const linkClaimAndScenario = async ({ projectId, claimId, scenarioId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (!claim) return reject('Claim not found.');
        const scenario = find(project.scenarios, { id: scenarioId });
        if (!scenario) return reject('Scenario Not found.');

        claim.scenarios = [...claim.scenarios, scenarioId];
        scenario.claims = [...scenario.claims, claimId];

        updateProject({
          ...project,
        }).then(() => {
          return resolve(claim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Removes the bi-directional link between a `Claim` and a `Scenario`.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @param {string} variables.scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to the Claim if successful,
 * otherwise rejects with an error.
 */
export const unlinkClaimAndScenario = async ({ projectId, claimId, scenarioId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (!claim) return reject('Claim not found.');
        const scenario = find(project.scenarios, { id: scenarioId });
        if (!scenario) return reject('Scenario not found.');

        claim.scenarios = without(claim.scenarios, scenarioId);
        scenario.claims = without(scenario.claims, claimId);

        updateProject({
          ...project,
        }).then(() => {
          return resolve(claim);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};
