import { useEffect } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import Submenu from '../../common/Submenu';
import ClaimEditStandalone from './ClaimEditStandalone';
import ClaimEditMerged from './ClaimEditMerged';
import ClaimEvidenceList from './evidence/ClaimEvidenceList';
import ClaimScenarioList from './scenarios/ClaimScenarioList';

import { useGetClaim } from '../../../hooks/claims.hooks';

const VIEWS = ['detail', 'evidence', 'scenarios'];

const ClaimEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, claimId } = useParams();
  const { data: claim, isLoading } = useGetClaim({ projectId, claimId });

  // set default view
  useEffect(() => {
    if (!VIEWS.includes(searchParams.get('view'))) {
      navigate('?view=detail', { replace: true });
    }
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  if (!claim) {
    return <Navigate to={`/projects/${projectId}/claims`} />;
  }

  return (
    <div id="claim-edit" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate('..')}
        />
        <h3 className="font-bold">EDIT CLAIM</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Submenu
          items={[
            {
              name: 'DETAIL',
              view: 'detail',
              to: `/projects/${projectId}/claims/${claimId}?view=detail`,
            },
            {
              name: 'EVIDENCE',
              view: 'evidence',
              to: `/projects/${projectId}/claims/${claimId}?view=evidence`,
            },
            {
              name: 'SCENARIOS',
              view: 'scenarios',
              to: `/projects/${projectId}/claims/${claimId}?view=scenarios`,
            },
          ]}
        />

        {searchParams.get('view') === 'detail' && (
          <>
            {claim.type.includes('merged') ? (
              <ClaimEditMerged projectId={projectId} claim={claim} />
            ) : (
              <ClaimEditStandalone projectId={projectId} claim={claim} />
            )}
          </>
        )}

        {searchParams.get('view') === 'evidence' && (
          <ClaimEvidenceList projectId={projectId} claim={claim} />
        )}

        {searchParams.get('view') === 'scenarios' && (
          <ClaimScenarioList projectId={projectId} claim={claim} />
        )}
      </div>
    </div>
  );
};

export default ClaimEdit;
