import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { getAuthState, signIn, signOut } from '../api/cognito.api';
import { QueryKeys } from '../utils/constants';

/**
 * React query mutation hook to authenticate a user.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useSignIn = (options) => {
  const queryClient = useQueryClient();
  return useMutation(signIn, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Accounts, data.sub], data);
      queryClient.resetQueries([QueryKeys.AuthState]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook sign out a user.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useSignOut = (options) => {
  const queryClient = useQueryClient();
  return useMutation(signOut, {
    ...options,
    onSuccess: async (data) => {
      await queryClient.resetQueries();
      queryClient.setQueryData([QueryKeys.AuthState], { isAuthenticated: false });
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query hook to fetch a user's authentication state.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useAuthState = (options) => {
  return useQuery([QueryKeys.AuthState], getAuthState, {
    ...options,
    refetchInterval: 1000 * 60,
  });
};
