import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { usePopperTooltip } from 'react-popper-tooltip';

import Tooltip from '../../../common/Tooltip';

import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';
import { pluralize } from '../../../../utils/pluralize';

dayjs.extend(duration);

const ScenarioTime = ({ projectId, scenarioId }) => {
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'top',
  });
  const { data: trials, isLoading } = useGetAllTrialsForScenario(projectId, scenarioId);

  if (isLoading) return null;

  // Calculate the latest end date of all trials
  const latestEnd = reduce(
    map(trials, 'endAt'),
    (latest, end) => {
      const latestDate = dayjs(latest);
      const endDate = dayjs(end);
      return endDate.isAfter(latestDate) ? endDate.valueOf() : latest;
    },
    0,
  );

  // Calculate the earliest start date of all trials
  const earliestStart = reduce(
    map(trials, 'startAt'),
    (earliest, start) => {
      const earliestDate = dayjs(earliest);
      const startDate = dayjs(start);
      return startDate.isBefore(earliestDate) ? startDate.valueOf() : earliest;
    },
    latestEnd,
  );

  // Calculate the total duration of all trials
  const totalTrialDuration = dayjs.duration(dayjs(latestEnd).diff(dayjs(earliestStart)));
  const years = totalTrialDuration.years();
  const months = totalTrialDuration.months();
  const asDays = totalTrialDuration.asDays();

  return (
    <div className="flex items-center" ref={setTriggerRef}>
      <div>
        {asDays === 0 ? (
          <span>0m</span>
        ) : (
          <>
            {!!years && <span className="mr-1">{years}y</span>}
            {!!months && <span>{months}m</span>}
          </>
        )}
      </div>
      <Tooltip {...tooltipProps}>
        {asDays === 0 ? (
          <span>
            The total estimated duration cannot be calculated at this time. Ensure that all linked
            trials have start and end dates.
          </span>
        ) : (
          <span>
            The total estimated duration is{' '}
            {!!years && `${years} ${pluralize(years, 'year', 'years')}`}{' '}
            {!!months && `${months} ${pluralize(months, 'month', 'months')}`}, calculated from the
            earliest trial start date to the latest trial end date.
          </span>
        )}
      </Tooltip>
    </div>
  );
};

export default ScenarioTime;
