import { useState } from 'react';

import Icon from '../../../common/Icon';
import LetterIcon from '../../../common/LetterIcon';
import ScenarioHierarchyClaim from './ScenarioHierarchyClaim';
import ScenarioHierarchyItemLoading from './ScenarioHierarchyItemLoading';

import { useGetClaimsForScenario } from '../../../../hooks/claims.hooks';
import { selectClaims } from '../../../../selectors/claims.selectors';

const ScenarioHierarchy = ({ projectId, scenario }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState(false);
  const hasClaims = scenario.claims && scenario.claims.length > 0;

  const { data: claims, isLoading: isLoadingClaims } = useGetClaimsForScenario({
    projectId,
    scenarioId: scenario.id,
  });

  const expandAll = () => {
    setIsExpandAll(true);
    setIsExpanded(true);
  };

  const toggle = () => {
    setIsExpandAll(false);
    setIsExpanded(!isExpanded);
  };

  const renderClaims = () => {
    if (isExpanded) {
      if (isLoadingClaims) {
        return <ScenarioHierarchyItemLoading className="ml-20 py-0.5" />;
      } else {
        const selectedClaims = selectClaims(claims, null, ['overallScore'], ['desc']);
        return selectedClaims.map((claim) => (
          <ScenarioHierarchyClaim
            key={claim.id}
            projectId={projectId}
            claim={claim}
            scenario={scenario}
            expanded={isExpandAll}
          />
        ));
      }
    }

    return null;
  };

  return (
    <div className="flex flex-col">
      <div className="group flex items-center py-0.5 hover:bg-sky-900/10">
        <div className="mr-4">
          {hasClaims ? (
            <Icon
              icon={isExpanded ? 'minus' : 'plus'}
              fixedWidth
              title={isExpanded ? 'Click to collapse' : 'Click to expand'}
              onClick={toggle}
              className="cursor-pointer"
            />
          ) : (
            <div className="w-5" />
          )}
        </div>
        <div>
          <LetterIcon letter="s" className="mr-1" title="Scenario" />
          {scenario.name}
        </div>
        {!isExpandAll && (
          <div
            className="ml-8 hidden cursor-pointer text-sm group-hover:block"
            title="Click to expand the full hierarchy"
            onClick={expandAll}
          >
            expand all
          </div>
        )}
      </div>

      {renderClaims()}
    </div>
  );
};

export default ScenarioHierarchy;
