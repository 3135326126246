import { Link, useNavigate } from 'react-router-dom';

import NoContent from '../common/NoContent';
import Placeholder from '../common/Placeholder';
import Button from '../common/Button';
import Dropdown from '../common/Dropdown';
import Icon from '../common/Icon';

import { useAuthState } from '../../hooks/cognito.hooks';
import { useGetProjects } from '../../hooks/projects.hooks';
import { selectProjects } from '../../selectors/projects.selectors';
import { keys } from '../../utils/keys';
import Spinner from '../common/Spinner';

const AllProjectsLoading = () => {
  return (
    <div className="h-full overflow-y-auto">
      {keys(3).map((item) => (
        <div key={item.key} className="flex items-center py-2">
          <Placeholder className="mr-2 w-6" size="xl" />
          <div className="grow">
            <Placeholder size="lg" className="mb-2" />
            <Placeholder />
          </div>
          <Placeholder className="ml-2 w-6" size="xl" />
        </div>
      ))}
    </div>
  );
};

const AllProjectsListItem = ({ project }) => {
  return (
    <div key={project.id} className="flex items-center py-2">
      {/* <FolderIcon className="mr-2 h-6 w-6 fill-sky-200" /> */}
      <Icon icon="folder" className="mr-2 h-6 w-6 text-sky-200" />
      <div className="grow">
        <Link to={`/projects/${project.id}`} className="text-slate-700">
          {project.name}
        </Link>
        <div className="text-xs text-slate-400">{project.client}</div>
      </div>
      <Dropdown
        toggle={<Icon icon="ellipsis-vertical" className="ml-2 h-5 w-5 text-slate-400" />}
        content={[
          {
            type: 'link',
            name: 'Edit',
            title: 'Edit Project',
            to: `/projects/${project.id}/edit`,
            className: 'text-xs',
          },
          {
            type: 'link',
            name: 'Archive',
            title: 'Archive Project',
            to: `?archiveProject=${project.id}`,
            className: 'text-xs',
          },
          {
            type: 'link',
            name: 'Export',
            title: 'Export Project',
            to: `?exportProject=${project.id}`,
            className: 'text-xs',
          },
        ]}
        contentClasses="right-5 -top-1.5 text-sky-900"
      />
    </div>
  );
};

const AllProjectsList = ({ projects = [] }) => {
  if (projects.length === 0) {
    return null;
  }

  return (
    <div className="h-full overflow-y-auto">
      {projects.map((project) => (
        <AllProjectsListItem key={project.id} project={project} />
      ))}
    </div>
  );
};

const AllProjectsListEmpty = ({ projects = [] }) => {
  const navigate = useNavigate();

  if (projects.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="primary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You don't have any Projects yet.</div>
          <div className="mt-2 text-xs">
            Go ahead and create a new one by clicking the button below.
          </div>
          <Button
            variant="secondary"
            className="mt-4 w-32 rounded"
            onClick={() => navigate('/projects/create')}
          >
            CREATE NEW
          </Button>
        </div>
      </NoContent>
    </div>
  );
};

const AllProjects = () => {
  const { data: authState } = useAuthState();
  const {
    data: projects,
    status,
    isFetching,
  } = useGetProjects(authState?.sub, {
    enabled: !!authState?.sub,
  });

  const selectedProjects = selectProjects(projects);

  return (
    <div className="h-full">
      <div className="flex items-baseline">
        <h3 className="text-xl font-bold text-sky-900">Projects</h3>
        <Spinner show={isFetching} className="ml-2 self-center" />
      </div>

      <div className="mt-4 h-[calc(100%_-_44px)] overflow-y-auto">
        {status === 'loading' ? (
          <AllProjectsLoading />
        ) : (
          <>
            <AllProjectsListEmpty projects={selectedProjects} />
            <AllProjectsList projects={selectedProjects} />
          </>
        )}
      </div>
    </div>
  );
};

export default AllProjects;
