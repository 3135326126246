import Loading from '../../../common/Loading';

const ClaimEvidenceListLoading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    </div>
  );
};

export default ClaimEvidenceListLoading;
