import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import numeral from 'numeral';

import Icon from '../../../common/Icon';
import Dropdown from '../../../common/Dropdown';

import { RiskScores } from '../../../../utils/constants';

dayjs.extend(duration);

const ScenarioTrialListItem = ({ scenario, trial, projectId, highlight }) => {
  const [searchParams] = useSearchParams();

  if (!trial) return null;

  let trialDuration;
  if (trial.startAt && trial.endAt) {
    trialDuration = dayjs.duration(dayjs(trial.endAt).diff(dayjs(trial.startAt)));
  }

  return (
    <div
      className={classNames('grid grid-cols-12 items-center px-2 py-3 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-8 truncate">
        <Link to={`/projects/${projectId}/trials/${trial.id}`} className="hover:text-sky-700">
          {trial.name}
        </Link>
      </div>
      <div>{RiskScores[trial.riskScore]}</div>
      <div>{!!trialDuration && trialDuration.format('Y[y] M[m]')}</div>
      <div>{trial.cost && numeral(trial.cost).format('(0[.]00a)').toUpperCase()}</div>
      <div>
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={[
            {
              type: 'link',
              name: 'Unlink Trial',
              title: 'Unlink Scenario and Trial',
              to: `?${searchParams}&unlinkScenarioTrial=${scenario.id}&trial=${trial.id}`,
              className: 'text-xs',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ScenarioTrialListItem;
