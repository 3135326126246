import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createClaim,
  getClaim,
  getClaims,
  getClaimsForEvidence,
  getClaimsForScenario,
  linkClaimAndEvidence,
  linkClaimAndScenario,
  unlinkClaimAndEvidence,
  unlinkClaimAndScenario,
  mergeClaim,
  unmergeClaim,
  updateClaim,
  prioritizeClaim,
} from '../api/claims.api';
import { QueryKeys } from '../utils/constants';
import { useNotify } from './notifications.hooks';

/**
 * React query hook to fetch all `Claims` for a project.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetClaims = ({ projectId }, options) => {
  return useQuery([QueryKeys.Claims, projectId], () => getClaims(projectId), options);
};

/**
 * React query hook to fetch all `Claims` linked to an Evidence item.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.evidenceId An evidence identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetClaimsForEvidence = ({ projectId, evidenceId }, options) => {
  return useQuery(
    [QueryKeys.Claims, projectId, evidenceId],
    () => getClaimsForEvidence({ projectId, evidenceId }),
    options,
  );
};

/**
 * React query hook to fetch all `Claims` linked to a Scenario.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.scenarioId A scenario identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetClaimsForScenario = ({ projectId, scenarioId }, options) => {
  return useQuery(
    [QueryKeys.Claims, projectId, scenarioId],
    () => getClaimsForScenario({ projectId, scenarioId }),
    options,
  );
};

/**
 * React query hook to fetch a single `Claim`.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetClaim = ({ projectId, claimId }, options) => {
  return useQuery(
    [QueryKeys.Claims, projectId, claimId],
    () => getClaim(projectId, claimId),
    options,
  );
};

/**
 * React query mutation hook to create a `Claim`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCreateClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(createClaim, {
    ...options,
    onSuccess: (data) => {
      notify({ text: `Created claim ${data.name}.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to update a `Claim`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUpdateClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(updateClaim, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Updated claim ${data.name}.` });
      queryClient.setQueryData([QueryKeys.Claims, variables.projectId, data.id], data);
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to prioritize a `Claim`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const usePrioritizeClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(prioritizeClaim, {
    ...options,
    onMutate: ({ projectId, claimId }) => {
      const cachedClaims = queryClient.getQueryData([QueryKeys.Claims, projectId]);
      queryClient.setQueryData(
        [QueryKeys.Claims, projectId],
        cachedClaims.map((cachedClaim) => {
          if (cachedClaim.id === claimId) {
            return { ...cachedClaim, isPrioritized: true };
          }
          return cachedClaim;
        }),
      );
    },
    onSuccess: (data, variables) => {
      notify({ text: `Prioritized claim ${data.name}.` });
      queryClient.setQueryData([QueryKeys.Claims, variables.projectId, data.id], data);
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to merge two `Claim` objects.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useMergeClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(mergeClaim, {
    ...options,
    onMutate: ({ projectId, sourceId, targetId }) => {
      // do stuff immediately on mutation invocation
    },
    onSuccess: (data, variables) => {
      notify({ text: `Merged claims.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation split a merged `Claim` from the merge group.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUnmergeClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(unmergeClaim, {
    ...options,
    onMutate: ({ projectId, mergedId, standaloneId }) => {
      // do stuff immediately on mutation invocation
    },
    onSuccess: (data, variables) => {
      notify({ text: `Unmerged claim.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to link a `Claim` with an Evidence item.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useLinkEvidence = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(linkClaimAndEvidence, {
    ...options,
    onMutate: ({ projectId, claimId, evidenceId }) => {
      // do stuff immediately on mutation invocation
    },
    onSuccess: (data, variables) => {
      notify({ text: `Linked evidence with claim.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to unlink a `Claim` and an Evidence item.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUnlinkEvidence = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(unlinkClaimAndEvidence, {
    ...options,
    onMutate: ({ projectId, claimId, evidenceId }) => {
      // do stuff immediately on mutation invocation
    },
    onSuccess: (data, variables) => {
      notify({ text: `Unlinked evidence and claim.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to link a `Claim` and a Scenario.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useLinkScenario = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(linkClaimAndScenario, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Linked claim and scenario.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to unlink a `Claim` and a Scenario.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUnlinkScenario = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(unlinkClaimAndScenario, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Unlinked claim and scenario.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
