import { Droppable } from 'react-beautiful-dnd';

import DraggableClaimCard from '../common/DraggableClaimCard';

import { selectClaims } from '../../../selectors/claims.selectors';

const ColumnMergedClaims = ({ claims = [] }) => {
  const selectedClaims = selectClaims(claims, { isPrioritized: false, type: ['merged'] });

  return (
    <Droppable droppableId="column-merged">
      {(provided) => (
        <div
          className="h-full overflow-y-auto rounded-b-lg py-4 px-2 shadow-lg shadow-slate-500/50"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {selectedClaims.map((claim, index) => (
            <DraggableClaimCard key={claim.id} claim={claim} index={index} variant="purple" />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ColumnMergedClaims;
