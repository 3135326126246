import Icon from '../../../common/Icon';

import { useEvidenceListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ClaimEvidenceListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useEvidenceListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const ClaimEvidenceListHeader = () => {
  return (
    <div className="grid grid-cols-12 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-11">
        <ClaimEvidenceListColumnHeading name="name" title="NAME" />
      </div>
      <div></div>
    </div>
  );
};

export default ClaimEvidenceListHeader;
