import { ClaimListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import EvidenceClaimListLoading from './EvidenceClaimListLoading';
import EvidenceClaimListEmpty from './EvidenceClaimListEmpty';
import EvidenceClaimListContent from './EvidenceClaimListContent';

import { useGetClaimsForEvidence } from '../../../../hooks/claims.hooks';

const EvidenceClaimList = ({ evidence, projectId }) => {
  const { data: evidenceClaims, isLoading } = useGetClaimsForEvidence({
    projectId,
    evidenceId: evidence.id,
  });

  return (
    <div id="evidence-edit-claim-list" className="h-full">
      {isLoading ? (
        <EvidenceClaimListLoading />
      ) : (
        <>
          <EvidenceClaimListEmpty evidence={evidence} claims={evidenceClaims} />
          <ClaimListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <EvidenceClaimListContent
              projectId={projectId}
              evidence={evidence}
              claims={evidenceClaims}
            />
          </ClaimListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default EvidenceClaimList;
