import { IndicationListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import AssetIndicationListEmpty from './AssetIndicationListEmpty';
import AssetIndicationListContent from './AssetIndicationListContent';

const AssetIndicationList = ({ asset, projectId }) => {
  return (
    <div id="scenario-edit-claim-list" className="h-full">
      <AssetIndicationListEmpty asset={asset} indications={asset.indications} />
      <IndicationListControlsContextProvider
        initialState={{ sort: { by: 'therapeuticArea' }, pagination: { size: 5 } }}
      >
        <AssetIndicationListContent
          projectId={projectId}
          asset={asset}
          indications={asset.indications}
        />
      </IndicationListControlsContextProvider>
    </div>
  );
};

export default AssetIndicationList;
