import Loading from '../../common/Loading';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { useGetProject, useUpdateProject } from '../../../hooks/projects.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ProjectArchive = ({ onCancel, onSuccess, projectId }) => {
  const updateProject = useUpdateProject();
  const { data: project, status } = useGetProject(projectId);
  const toastsContext = useToastsContext();

  const archiveProject = () => {
    updateProject.mutate(
      { ...project, isArchived: true },
      {
        onSuccess: (project) => {
          toastsContext.createToast('Archived project successfully.');
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to archive project. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (status === 'loading') {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">ARCHIVE PROJECT</h3>

      <div className="mb-4">
        You are about to archive project <span className="italic">{project.name}</span>.
      </div>

      <div className="mb-4">Archived projects are hidden from lists and searches. Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={updateProject.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={archiveProject}
          disabled={updateProject.isLoading}
          isLoading={updateProject.isLoading}
        >
          ARCHIVE
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default ProjectArchive;
