import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import Tooltip from '../../common/Tooltip';
import { usePopperTooltip } from 'react-popper-tooltip';

const ProjectMenuItem = ({ name, title, to, tooltip }) => {
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'bottom',
  });

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          classNames(
            '-mb-0.5 mr-6 h-10 border-b-2 px-1',
            { 'border-b-sky-200': !isActive },
            {
              'border-b-sky-500 text-sky-500': isActive,
            },
          )
        }
        to={to}
        title={title}
        ref={setTriggerRef}
      >
        {name}
      </NavLink>
      <Tooltip {...tooltipProps}>{tooltip}</Tooltip>
    </>
  );
};

export default ProjectMenuItem;
