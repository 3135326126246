import classNames from 'classnames';

/**
 * Returns CSS classes for the supplied content box variation.
 * @param {string} variant The content box variation.
 * @returns {string} CSS classes.
 */
const getColorClasses = (variant) => {
  switch (variant) {
    case 'primary':
      return 'bg-sky-900';
    case 'secondary':
      return 'bg-sky-400';
    case 'info':
      return 'bg-fuchsia-500';
    case 'danger':
      return 'bg-red-600';
    case 'amber':
      return 'bg-amber-500';
    case 'green':
      return 'bg-green-600';
    case 'purple':
      return 'bg-purple-500';
    default:
      return 'bg-transparent';
  }
};

/**
 * The `ContentBox` component renders a styled block container for child components.
 * @param {Object} props The component properties.
 * @returns JXS
 */
const ContentBox = ({ className, contentClasses, children, variant }) => {
  return (
    <div
      className={classNames(
        'overflow-hidden rounded-lg bg-white/50 shadow-lg shadow-slate-500/20',
        className,
      )}
    >
      <div className="flex h-full">
        {!!variant && (
          <div className={classNames('min-h-full w-2 shrink-0', getColorClasses(variant))}></div>
        )}
        <div className={classNames('h-full min-w-0 grow p-4', contentClasses)}>{children}</div>
      </div>
    </div>
  );
};

export default ContentBox;
