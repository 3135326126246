import { ScenarioListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import ClaimScenarioListLoading from './ClaimScenarioListLoading';
import ClaimScenarioListEmpty from './ClaimScenarioListEmpty';
import ClaimScenarioListContent from './ClaimScenarioListContent';

import { useGetScenariosForClaim } from '../../../../hooks/scenarios.hooks';

const ClaimScenarioList = ({ claim, projectId }) => {
  const { data: scenarios, isLoading } = useGetScenariosForClaim({
    projectId,
    claimId: claim.id,
  });

  return (
    <div id="scenario-edit-claim-list" className="h-full">
      {isLoading ? (
        <ClaimScenarioListLoading />
      ) : (
        <>
          <ClaimScenarioListEmpty claim={claim} scenarios={scenarios} />
          <ScenarioListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <ClaimScenarioListContent projectId={projectId} claim={claim} scenarios={scenarios} />
          </ScenarioListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default ClaimScenarioList;
