import { useSearchParams } from 'react-router-dom';

import Loading from '../../../common/Loading';
import ScenarioTrialPhase from './ScenarioTrialPhase';

import { useGetProject } from '../../../../hooks/projects.hooks';
import { useGetScenario } from '../../../../hooks/scenarios.hooks';

const ScenarioTrialsReport = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('project');
  const scenarioId = searchParams.get('scenario');
  const { data: project, isLoading: isLoadingProject } = useGetProject(projectId);
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });

  if (isLoadingProject || isLoadingScenario) {
    return (
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    );
  }

  return (
    <div className="text-sky-900">
      <div>
        <div className="text-2xl uppercase">SCENARIO TRIALS: {scenario.name}</div>
        <div className="text-sm">Project: {project.name}</div>
      </div>

      <div className="mt-8">
        <div className="mb-4">
          <ScenarioTrialPhase
            className="border-l-2 border-sky-600"
            phases={['1']}
            phaseName="PHASE 1"
            project={project}
            scenario={scenario}
          />
        </div>
        <div className="mb-4">
          <ScenarioTrialPhase
            className="border-l-2 border-sky-600"
            phases={['2', '2a', '2b']}
            phaseName="PHASE 2"
            project={project}
            scenario={scenario}
          />
        </div>
        <div className="mb-4">
          <ScenarioTrialPhase
            className="border-l-2 border-sky-600"
            phases={['3', '3b']}
            phaseName="PHASE 3"
            project={project}
            scenario={scenario}
          />
        </div>
        <div className="mb-4">
          <ScenarioTrialPhase
            className="border-l-2 border-sky-600"
            phases={['4']}
            phaseName="PHASE 4"
            project={project}
            scenario={scenario}
          />
        </div>
      </div>
    </div>
  );
};

export default ScenarioTrialsReport;
