import Modal from '../../../common/Modal';
import ScenarioLinkClaim from './ScenarioLinkClaim';

const ScenarioLinkClaimModal = ({ isHidden = true, onHide, scenarioId }) => {
  const hide = () => {
    !!onHide && onHide();
  };

  if (isHidden) {
    return null;
  }

  return (
    <Modal onClose={hide} size="lg" showClose={false}>
      <div className="flex min-h-[300px] overflow-hidden rounded-2xl bg-white shadow-lg shadow-slate-400">
        <div className="w-3 shrink-0 bg-sky-900"></div>
        <div className="grow">
          <ScenarioLinkClaim onCancel={hide} onSuccess={hide} scenarioId={scenarioId} />
        </div>
      </div>
    </Modal>
  );
};

export default ScenarioLinkClaimModal;
