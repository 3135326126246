import NoContent from '../common/NoContent';
import Placeholder from '../common/Placeholder';
import PieChart from '../common/PieChart';
import Spinner from '../common/Spinner';

import { useAuthState } from '../../hooks/cognito.hooks';
import { useGetProjects } from '../../hooks/projects.hooks';
import { selectProjects, selectProjectsByStatus } from '../../selectors/projects.selectors';

const ProjectStatusChartLoading = () => {
  return (
    <div className="flex h-4/5 items-center justify-center">
      <Placeholder className="h-4/5 w-4/5 rounded-lg" />
    </div>
  );
};

const ProjectStatusChartEmpty = ({ projects = [] }) => {
  if (projects.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="info">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You don't have enough activity yet.</div>
          <div className="mt-2 text-xs">Create a new project to get started.</div>
        </div>
      </NoContent>
    </div>
  );
};

const ProjectStatusChartContent = ({ projects = [] }) => {
  if (projects.length === 0) {
    return null;
  }

  const eligibleProjects = selectProjects(projects, { isArchived: false });
  const notStartedCount = selectProjectsByStatus(eligibleProjects, 'notstarted').length;
  const inProgressCount = selectProjectsByStatus(eligibleProjects, 'inprogress').length;
  const completeCount = selectProjectsByStatus(eligibleProjects, 'complete').length;
  const chartData = {
    labels: ['Not Started', 'In Progress', 'Complete'],
    datasets: [
      {
        label: 'by Status',
        data: [notStartedCount, inProgressCount, completeCount],
        backgroundColor: ['#86a2f1', '#c750ff', '#00a389'],
        borderColor: ['#86a2f1', '#c750ff', '#00a389'],
        borderWidth: 0,
      },
    ],
  };
  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        labels: { boxWidth: 12 },
      },
    },
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-[200px] md:w-[250px] lg:w-[330px]">
        <PieChart data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

const ProjectStatusChart = () => {
  const { data: authState } = useAuthState();
  const {
    data: projects,
    status,
    isFetching,
  } = useGetProjects(authState?.sub, {
    enabled: !!authState?.sub,
  });

  return (
    <div className="h-full">
      <div className="flex items-center">
        <h3 className="text-xl font-bold text-sky-900">Projects by Status</h3>
        <Spinner show={isFetching} className="ml-2" />
      </div>

      <div className="mt-4 h-[calc(100%_-_44px)] overflow-auto">
        {status === 'loading' ? (
          <ProjectStatusChartLoading />
        ) : (
          <>
            <ProjectStatusChartEmpty projects={projects} />
            <ProjectStatusChartContent projects={projects} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectStatusChart;
