import filter from 'lodash/filter';

import Icon from '../../../common/Icon';

import { selectTrials } from '../../../../selectors/trials.selectors';

const ScenarioTrials = ({ trials = [] }) => {
  if (trials.length === 0) return null;

  const trialsPhase1 = selectTrials(trials, { phase: '1' }, ['startAt'], ['asc']);
  const trialsPhase2 = selectTrials(
    filter(trials, (t) => ['2', '2a', '2b'].includes(t.phase)),
    null,
    ['phase', 'startAt'],
    ['asc', 'asc'],
  );
  const trialsPhase3 = selectTrials(
    filter(trials, (t) => ['3', '3b'].includes(t.phase)),
    null,
    ['phase', 'startAt'],
    ['asc', 'asc'],
  );
  const trialsPhase4 = selectTrials(trials, { phase: '4' }, ['startAt'], ['asc']);

  return (
    <div>
      <div className="mb-4 -ml-2 flex items-center text-5xl">
        <span className="fa-layers fa-fw" title="Trials">
          <Icon icon="square" />
          <Icon icon="t" transform="shrink-6" />
        </span>
        <div className="-ml-1.5 text-3xl">rials</div>
      </div>

      <div className="px-2">
        {trialsPhase1.length > 0 && (
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">PHASE 1</div>
            {trialsPhase1.map((trial) => (
              <div key={trial.id} className="my-0.5 flex items-center">
                <span className="fa-layers fa-fw" title="Trial">
                  <Icon icon="square" />
                  <Icon icon="t" transform="shrink-6" />
                </span>
                <div>{trial.name}</div>
              </div>
            ))}
          </div>
        )}

        {trialsPhase2.length > 0 && (
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">PHASE 2</div>
            {trialsPhase2.map((trial) => (
              <div key={trial.id} className="my-0.5 flex items-center">
                <span className="fa-layers fa-fw" title="Trial">
                  <Icon icon="square" />
                  <Icon icon="t" transform="shrink-6" />
                </span>
                <div>{trial.name}</div>
              </div>
            ))}
          </div>
        )}

        {trialsPhase3.length > 0 && (
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">PHASE 3</div>
            {trialsPhase3.map((trial) => (
              <div key={trial.id} className="my-0.5 flex items-center">
                <span className="fa-layers fa-fw" title="Trial">
                  <Icon icon="square" />
                  <Icon icon="t" transform="shrink-6" />
                </span>
                <div>{trial.name}</div>
              </div>
            ))}
          </div>
        )}

        {trialsPhase4.length > 0 && (
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">PHASE 4</div>
            {trialsPhase4.map((trial) => (
              <div key={trial.id} className="my-0.5 flex items-center">
                <span className="fa-layers fa-fw" title="Trial">
                  <Icon icon="square" />
                  <Icon icon="t" transform="shrink-6" />
                </span>
                <div>{trial.name}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScenarioTrials;
