import Icon from '../../../common/Icon';

import { useIndicationListControlsContext } from '../../../../hooks/listcontrols.hooks';

const AssetIndicationListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useIndicationListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const AssetIndicationListHeader = () => {
  return (
    <div className="grid grid-cols-12 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-4">
        <AssetIndicationListColumnHeading name="therapeuticArea" title="THERAPEUTIC AREA" />
      </div>
      <div className="col-span-4">
        <AssetIndicationListColumnHeading name="indication" title="INDICATION" />
      </div>
      <div className="col-span-4">
        <AssetIndicationListColumnHeading name="indicationOther" title="OTHER" />
      </div>
      <div></div>
    </div>
  );
};

export default AssetIndicationListHeader;
