import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { checkTokens } from './api/cognito.api';
import { ToastsContextProvider } from './contexts/toasts.context';
import { router } from './components/routers/Router';

// Create the React Query QueryClient for the application.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

/**
 * The `App` component is the outermost component of the application.
 * @returns JSX
 */
const App = () => {
  // periodically refresh OAuth tokens
  useEffect(() => {
    const tokenInterval = setInterval(checkTokens, 60000);
    return () => clearInterval(tokenInterval);
  }, []);

  return (
    <div className="bg-gradient-to-r from-sky-50 to-sky-200">
      <QueryClientProvider client={queryClient}>
        <ToastsContextProvider>
          <RouterProvider router={router} />
        </ToastsContextProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
