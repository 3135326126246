import axios from 'axios';

import { saveAccount } from './account.api';
import { Configuration } from '../utils/config';
import storage from '../utils/storage';

/**
 * Fetch the information about a user from AWS Cognito.
 * @returns {Promise} A Promise which resolves to the user info object if successful,
 * otherwise rejects with an error.
 */
export const getUserInfo = async () => {
  const config = Configuration.get();
  const { domain } = config.auth.cognito;
  const authState = storage.getJson(storage.Keys.AuthState);

  // get user info
  const userInfoResponse = await axios.request({
    method: 'get',
    url: `https://${domain}/oauth2/userInfo`,
    headers: {
      Authorization: `Bearer ${authState.accessToken}`,
    },
  });

  const { sub, name, email, username } = userInfoResponse.data;
  storage.setJson(storage.Keys.AuthState, {
    ...authState,
    sub,
    name,
    email,
    username,
  });

  const account = { sub, name, email, username };
  await saveAccount({ account });

  return userInfoResponse.data;
};

/**
 * Authenticate a user to AWS Cognito, exchanging a Cognito issued
 * authorization code for OAuth tokens. If successful, stores tokens
 * in local storage.
 * @param {code} string An AWS Cognito authorization code.
 * @returns {Promise} A Promise which resolves to an object containing
 * tokens if successful, otherwise rejects with an error.
 */
export const signIn = async ({ code }) => {
  const config = Configuration.get();
  const { clientId, domain, redirectUrl } = config.auth.cognito;
  // authenticate to the 'token' endpoint
  const tokenResponse = await axios.request({
    method: 'post',
    url: `https://${domain}/oauth2/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      grant_type: 'authorization_code',
      client_id: clientId,
      code,
      redirect_uri: redirectUrl,
    },
    transformRequest: [
      (request = {}) => {
        return Object.entries(request)
          .map((pair) => `${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
          .join('&');
      },
    ],
  });

  const { access_token, expires_in, id_token, refresh_token } = tokenResponse.data;
  const expiresAt = Date.now() + expires_in * 1000;
  storage.setJson(storage.Keys.AuthState, {
    accessToken: access_token,
    idToken: id_token,
    refreshToken: refresh_token,
    expiresIn: expires_in,
    expiresAt,
  });

  return getUserInfo();
};

/**
 * Signs out a user, removing all tokens and authentication state.
 * @returns {Promise} A Promise which resolves to `null` if successful,
 * otherwise rejects with an error.
 */
export const signOut = async () => {
  return new Promise((resolve) => {
    storage.removeItem(storage.Keys.AuthState);
    return resolve();
  });
};

/**
 * Obtain fresh OAuth tokens from AWS Cognito using a refresh token.
 * If successful, stores tokens in local storage.
 * @returns {Promise} A Promise which resolves to `null` if successful,
 * otherwise rejects with an error.
 */
export const refreshTokens = async () => {
  const config = Configuration.get();
  const { clientId, domain } = config.auth.cognito;
  const authState = await getAuthState();
  const response = await axios.request({
    method: 'post',
    url: `https://${domain}/oauth2/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      grant_type: 'refresh_token',
      client_id: clientId,
      refresh_token: authState.refreshToken,
    },
    transformRequest: [
      (request = {}) => {
        return Object.entries(request)
          .map((pair) => `${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
          .join('&');
      },
    ],
  });

  const { access_token, expires_in, id_token } = response.data;
  const expiresAt = Date.now() + expires_in * 1000;
  storage.setJson(storage.Keys.AuthState, {
    ...authState,
    accessToken: access_token,
    idToken: id_token,
    expiresIn: expires_in,
    expiresAt,
  });
};

/**
 * Examines the currently stored tokens and determines if a token refresh
 * is needed. Performs a token refresh if the current access token will expire
 * in less than the `expiresInSeconds` parameter value.
 * @param {number} [expiresInSeconds=300] The number of seconds.
 * @returns {Promise} A Promise which resolves to `null` if successful,
 * otherwise rejects with an error.
 */
export const checkTokens = async (expiresInSeconds = 300) => {
  const { expiresAt = 0, refreshToken } = await getAuthState();
  const refreshAt = expiresAt - expiresInSeconds * 1000;
  const now = Date.now();
  const needsRefresh = now > refreshAt;

  if (needsRefresh) {
    // token expires soon. refresh tokens.
    if (refreshToken) {
      try {
        await refreshTokens();
      } catch (err) {
        // refresh token expired
        await signOut();
      }
    }
  }
};

/**
 * Fetch the current `AuthState` object.
 * @returns {Promise} A Promise which resolves to the AuthState object if successful,
 * otherwise rejects with an error.
 */
export const getAuthState = async () => {
  return new Promise((resolve) => {
    const authState = storage.getJson(storage.Keys.AuthState);
    if (authState) {
      const isAuthenticated = authState.expiresAt && authState.expiresAt > Date.now();
      return resolve({
        ...authState,
        isAuthenticated,
      });
    } else {
      return resolve({ isAuthenticated: false });
    }
  });
};
