import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import TrialForm from '../common/TrialForm';
import Icon from '../../common/Icon';

import { trialSchema } from '../../../validations/trial.validations';
import { useCreateTrial } from '../../../hooks/trials.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const TrialCreate = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const createTrial = useCreateTrial();
  const toastsContext = useToastsContext();

  return (
    <div id="trial-create" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">CREATE NEW TRIAL</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Formik
          initialValues={{
            name: '',
            details: '',
            phase: '',
            indication: '',
            geography: '',
            patientCount: 0,
            adaptiveDesign: false,
            masterProtocol: 'no',
            cost: 0,
            riskScore: 3,
            startAt: '',
            endAt: '',
            arms: [],
          }}
          validationSchema={trialSchema}
          onSubmit={(values, { setSubmitting }) => {
            createTrial.mutate(
              { projectId, trial: values },
              {
                onSuccess: (trial) => {
                  setSubmitting(false);
                  toastsContext.createToast(`Created trial.`);
                  navigate(`/projects/${projectId}/trials`);
                },
                onError: (err) => {
                  console.error(`Failed to create trial. Detail:`, err);
                  // TODO display error notification
                  setSubmitting(false);
                },
              },
            );
          }}
        >
          {(formik) => <TrialForm formik={formik} projectId={projectId} />}
        </Formik>
      </div>
    </div>
  );
};

export default TrialCreate;
