import AssetIndicationListMenu from './AssetIndicationListMenu';
import AssetIndicationListItem from './AssetIndicationListItem';
import AssetIndicationListHeader from './AssetIndicationListHeader';
import AssetIndicationListFooter from './AssetIndicationListFooter';

import { selectIndicationsWithListControls } from '../../../../selectors/indications.selectors';
import { useIndicationListControlsContext } from '../../../../hooks/listcontrols.hooks';

const AssetIndicationListContent = ({ asset, indications = [], projectId }) => {
  const { data: listControls } = useIndicationListControlsContext();

  if (indications.length === 0) {
    return null;
  }

  const { data: allIndications, page } = selectIndicationsWithListControls(
    indications,
    listControls,
  );

  return (
    <>
      <AssetIndicationListMenu asset={asset} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <AssetIndicationListHeader />

        {page.map((indication, index) => (
          <AssetIndicationListItem
            key={indication.id}
            indication={indication}
            asset={asset}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <AssetIndicationListFooter
            totalItemsOnPage={page.length}
            totalItems={allIndications.length}
          />
        </div>
      </div>
    </>
  );
};

export default AssetIndicationListContent;
