import { useEffect, useState } from 'react';

import Icon from '../../../common/Icon';
import LetterIcon from '../../../common/LetterIcon';
import NumberIcon from '../../../common/NumberIcon';
import ScenarioHierarchyEvidence from './ScenarioHierarchyEvidence';
import ScenarioHierarchyItemLoading from './ScenarioHierarchyItemLoading';

import { useGetAllEvidenceForClaim } from '../../../../hooks/evidence.hooks';
import { selectEvidence } from '../../../../selectors/evidence.selectors';

const ScenarioHierarchyClaim = ({ projectId, claim, scenario, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const hasEvidence = claim.evidence && claim.evidence.length > 0;

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const { data: evidence, isLoading: isLoadingEvidence } = useGetAllEvidenceForClaim({
    projectId,
    claimId: claim.id,
  });

  const renderEvidence = () => {
    if (isExpanded) {
      if (isLoadingEvidence) {
        return <ScenarioHierarchyItemLoading className="ml-20 py-0.5" />;
      } else {
        const selectedEvidence = selectEvidence(evidence);
        return selectedEvidence.map((evidence) => (
          <ScenarioHierarchyEvidence
            key={evidence.id}
            projectId={projectId}
            evidence={evidence}
            scenario={scenario}
            expanded={expanded}
          />
        ));
      }
    }

    return null;
  };

  return (
    <div className="ml-9 flex flex-col">
      <div className="flex items-center py-0.5 hover:bg-sky-900/10">
        <div className="mr-4">
          {hasEvidence ? (
            <Icon
              icon={isExpanded ? 'minus' : 'plus'}
              fixedWidth
              title={isExpanded ? 'Click to collapse' : 'Click to expand'}
              onClick={() => setIsExpanded(!isExpanded)}
              className="cursor-pointer"
            />
          ) : (
            <div className="w-5" />
          )}
        </div>
        <LetterIcon letter="c" className="mr-1" title="Claim" />
        <div className="mr-2">{claim.name}</div>
        <NumberIcon number={claim.overallScore} className="mr-1" title="Claim Score" />
        {claim.type.includes('clinical') && (
          <Icon
            icon="stethoscope"
            className="mr-1 inline-block h-3.5 w-3.5 text-slate-600"
            title="Clinical"
          />
        )}
        {claim.type.includes('commercial') && (
          <Icon
            icon="landmark"
            className="mr-1 inline-block h-3.5 w-3.5 text-slate-600"
            title="Commercial"
          />
        )}
      </div>

      {renderEvidence()}
    </div>
  );
};

export default ScenarioHierarchyClaim;
