import classNames from 'classnames';

/**
 * Returns the CSS color classes to style the label.
 * @param {string} variant The label variant.
 * @returns {string} CSS classes
 */
const getColorClasses = (variant) => {
  switch (variant) {
    case 'amber':
      return 'bg-amber-200 text-amber-900';
    case 'purple':
      return 'bg-purple-200 text-purple-900';
    default:
      return 'bg-sky-200 text-sky-900';
  }
};

/**
 * The `Label` component renders a small, color-coded wrapper for text.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Label = ({ children, variant }) => {
  return (
    <div
      className={classNames(
        'mr-0.5 inline rounded-full py-0.5 px-1 text-xs lowercase',
        getColorClasses(variant),
      )}
    >
      {children}
    </div>
  );
};

export default Label;
