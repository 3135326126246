import * as Yup from 'yup';

const apiSchema = Yup.object({
  delayMillis: Yup.number().min(0).max(15000).default(1200),
});

const appSchema = Yup.object({
  toastDismissMillis: Yup.number().min(0).max(30000).default(5000),
});

const cognitoSchema = Yup.object({
  clientId: Yup.string().required('AWS Cognito user pool client ID required'),
  domain: Yup.string().required('AWS Cognito user pool domain required'),
  poolId: Yup.string().required('AWS Cognito user pool ID required'),
  redirectUrl: Yup.string().required('AWS Cognito user pool client callback redirect URL required'),
  region: Yup.string().required('AWS Cognito region required'),
  signoutUrl: Yup.string().required('AWS Cognito user pool client signout URL required'),
});

const authSchema = Yup.object({
  cognito: cognitoSchema,
  sessionExpireMillis: Yup.number().min(900000).max(86400000).default(14400000),
});

export const configSchema = Yup.object({
  api: apiSchema,
  app: appSchema,
  auth: authSchema,
  NODE_ENV: Yup.string().default('development'),
  image_tag_design_center: Yup.string().default('local'),
  version: Yup.string().default('local'),
});
