import { useNavigate, useParams } from 'react-router-dom';

import ProjectEdit from './ProjectEdit';

const ProjectSection = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  return (
    <div
      id="project-section"
      className="mt-4 h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      <ProjectEdit
        projectId={projectId}
        onSuccess={(project) => navigate(`/projects/${project.id}/edit`)}
      />
    </div>
  );
};

export default ProjectSection;
