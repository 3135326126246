import { useParams } from 'react-router-dom';

import Loading from '../../../../common/Loading';
import ButtonBar from '../../../../common/ButtonBar';
import Button from '../../../../common/Button';
import LoadingButton from '../../../../common/LoadingButton';

import { useGetAsset, useUpdateAsset } from '../../../../../hooks/asset.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';

const AssetIndicationDelete = ({ onCancel, onSuccess, indicationId }) => {
  const { projectId } = useParams();
  const toastsContext = useToastsContext();
  const updateAsset = useUpdateAsset();
  const { data: asset, isLoading: isLoadingAsset } = useGetAsset({ projectId });

  const deleteIndication = () => {
    updateAsset.mutate(
      {
        projectId,
        asset: {
          ...asset,
          indications: asset.indications.filter((i) => i.id !== indicationId),
        },
      },
      {
        onSuccess: () => {
          toastsContext.createToast(`Deleted indication from asset.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to delete asset indication. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoadingAsset) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const indication = asset.indications.find((i) => i.id === indicationId);

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">DELETE ASSET INDICATION</h3>

      <div className="mb-4">
        You are about to delete therapeutic area{' '}
        <span className="italic">{indication.therapeuticArea}</span> and indication{' '}
        <span className="italic">
          {!!indication.indicationOther ? indication.indicationOther : indication.indication}
        </span>
        .
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={updateAsset.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={deleteIndication}
          disabled={updateAsset.isLoading}
          isLoading={updateAsset.isLoading}
        >
          DELETE
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default AssetIndicationDelete;
