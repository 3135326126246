import classNames from 'classnames';

/**
 * Returns CSS classes for the specified `variant`.
 * @param {string} variant The color variant.
 * @returns {string} CSS classes
 */
const getColorClasses = (variant) => {
  switch (variant) {
    case 'primary':
      return 'bg-blue-500 text-white';
    case 'danger':
      return 'bg-red-600 text-white';
    case 'success':
      return 'bg-green-600 text-white';
    case 'warning':
      return 'bg-amber-300';
    default:
      return 'bg-slate-300';
  }
};

/**
 * The `Badge` component renders a colored wrapper around a small bit of text, e.g. a status value.
 * @param {Object} props The component properties.
 * @returns JSX
 */
export const Badge = ({ className, variant, children }) => {
  return (
    <span
      className={classNames(
        'rounded px-2 py-1 text-xs font-bold',
        getColorClasses(variant),
        className,
      )}
    >
      {children}
    </span>
  );
};

export default Badge;
