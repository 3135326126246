import { Outlet } from 'react-router-dom';

import { ScenarioListControlsContextProvider } from '../../contexts/listcontrols.context';

const ScenariosSection = () => {
  return (
    <div
      id="scenarios-section"
      className="mt-4 h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      <ScenarioListControlsContextProvider
        initialState={{ sort: { by: 'name' }, pagination: { size: 9 } }}
      >
        <Outlet />
      </ScenarioListControlsContextProvider>
    </div>
  );
};

export default ScenariosSection;
