import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonBar from '../common/ButtonBar';
import Button from '../common/Button';
import LatestActivity from '../dashboard/LatestActivity';
import ProjectList from '../projects/list/ProjectList';

const ProjectListPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Projects | IQVIA Design Center';
  }, []);

  return (
    <div className="h-full py-8 px-16">
      <div className="grid grid-cols-12 items-end gap-6">
        <div className="col-span-4">
          <h2 className="mb-8 text-2xl text-sky-900">PORTFOLIO</h2>
          <ButtonBar>
            <Button
              type="button"
              variant="primary"
              className="w-48 rounded"
              onClick={() => navigate('/projects/create')}
            >
              CREATE NEW
            </Button>
            <Button
              type="button"
              variant="outline-primary"
              className="ml-4 w-48 rounded"
              onClick={() => navigate('?importProject')}
            >
              IMPORT
            </Button>
          </ButtonBar>
        </div>
        <div className="col-span-7 col-end-13">
          <LatestActivity />
        </div>
      </div>

      <div className="mt-6">
        <ProjectList />
      </div>
    </div>
  );
};

export default ProjectListPage;
