import Icon from './Icon';
import classNames from 'classnames';

/**
 * The `NumberIcon` component renders an icon displaying a number.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const NumberIcon = ({ className, number, title }) => {
  return (
    <span className={classNames('fa-layers fa-fw', className)} title={title}>
      <Icon icon="circle" />
      <Icon icon={Number(number).toString()} transform="shrink-6" />
    </span>
  );
};

export default NumberIcon;
