import filter from 'lodash/filter';
import find from 'lodash/find';
import * as _reject from 'lodash/reject';

import { getProject, updateProject } from './projects.api';
import { generateId } from '../utils/id';

/**
 * Fetch all `Evidence` items for the specified project.
 * @param {string} projectId A project identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * Evidence objects if successful, otherwise rejects with an error.
 */
export const getAllEvidence = async (projectId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        return resolve(project.evidence || []);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Evidence` objects for a Project and linked to a Claim.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of Evidence
 * if successful, otherwise rejects with an error.
 */
export const getAllEvidenceForClaim = async ({ projectId, claimId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (!claim) return [];

        const claimEvidence = filter(project.evidence, (e) => claim.evidence.includes(e.id));
        return resolve(claimEvidence);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Evidence` objects for a Project and linked to a Trial.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.trialId A trial identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of Evidence
 * if successful, otherwise rejects with an error.
 */
export const getAllEvidenceForTrial = async ({ projectId, trialId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trial = find(project.trials, { id: trialId });
        if (!trial) return [];

        const trialEvidence = filter(project.evidence, (e) => trial.evidence.includes(e.id));
        return resolve(trialEvidence);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch a single `Evidence` item.
 * @param {string} projectId A project identifier.
 * @param {string} evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to an Evidence item
 * if successful, otherwise rejects with an error.
 */
export const getEvidence = async (projectId, evidenceId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const evidence = find(project.evidence, {
          id: evidenceId,
        });
        if (evidence) {
          return resolve(evidence);
        }
        return reject(new Error('Not found.'));
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Creates an `Evidence` item.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.evidence An Evidence object to be created.
 * @returns {Promise} A Promise which resolves to the created Evidence if successful,
 * otherwise rejects with an error.
 */
export const createEvidence = async ({ projectId, evidence }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const newEvidence = {
          ...evidence,
          id: generateId(),
          claims: [],
          trials: [],
          createdAt: new Date().toISOString(),
        };
        const currentEvidenceList = project.evidence || [];
        updateProject({
          ...project,
          evidence: [...currentEvidenceList, newEvidence],
        }).then(() => {
          return resolve(newEvidence);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Updates an `Evidence` item.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.evidence An Evidence object to be updated.
 * @returns {Promise} A Promise which resolves to the updated Evidence if successful,
 * otherwise rejects with an error.
 */
export const updateEvidence = async ({ projectId, evidence }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const updatedEvidence = {
          ...evidence,
          updatedAt: new Date().toISOString(),
        };
        const otherEvidence = _reject(project.evidence, {
          id: evidence.id,
        });
        updateProject({
          ...project,
          evidence: [...otherEvidence, updatedEvidence],
        }).then(() => {
          return resolve(updatedEvidence);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};
