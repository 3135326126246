import { Form } from 'formik';
import keys from 'lodash/keys';

import InputField from '../../common/InputField';
import TextareaField from '../../common/TextareaField';
import SelectField from '../../common/SelectField';
import LoadingButton from '../../common/LoadingButton';

import { PopulationAgeGroup } from '../../../utils/constants';

const EvidenceForm = ({ formik }) => {
  return (
    <Form className="h-full">
      <div className="grid grid-cols-3 gap-x-8 gap-y-4">
        <div className="col-start-1">
          <InputField
            name="name"
            label="Name"
            type="text"
            maxLength="254"
            disabled={formik.isSubmitting}
            tooltip="Evidentiary Statement Proposed"
          />
        </div>

        <div className="col-start-2">
          <InputField
            name="biomarker"
            label="Biomarker"
            type="text"
            maxLength="254"
            disabled={formik.isSubmitting}
            tooltip="Biomarker Selected, If Applicable, for Evidentiary Statement"
          />
        </div>

        <div className="col-start-3">
          <InputField
            name="sampleSize"
            label="Sample Size"
            type="number"
            min="0"
            disabled={formik.isSubmitting}
            tooltip="Estimated Sample Size Required to Generate Evidence"
          />
        </div>

        <div className="col-start-1 row-span-3">
          <TextareaField name="details" label="Details" rows="8" disabled={formik.isSubmitting} />
        </div>

        <div className="col-start-2">
          <InputField
            name="endpoint"
            label="Endpoint"
            type="text"
            maxLength="254"
            disabled={formik.isSubmitting}
            tooltip="Endpoint of Evidentiary Statement"
          />
        </div>

        <div className="col-start-3">
          <SelectField
            name="populationAgeGroup"
            label="Population Age Group"
            disabled={formik.isSubmitting}
          >
            <option value=""></option>
            {keys(PopulationAgeGroup).map((key, index) => (
              <option key={index} value={key}>
                {PopulationAgeGroup[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="col-start-2">
          <InputField
            name="power"
            label="Power"
            type="number"
            min="0"
            disabled={formik.isSubmitting}
            tooltip="Power Required to Generate Evidence"
          />
        </div>

        <div className="col-start-2">
          <InputField
            name="hazardRatio"
            label="Hazard Ratio"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Hazard Ratio Required to Generate Evidence"
          />
        </div>

        <div className="col-span-3 justify-self-end">
          {formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default EvidenceForm;
