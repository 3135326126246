import { Form } from 'formik';

import Icon from '../../common/Icon';
import InputField from '../../common/InputField';
import CheckboxField from '../../common/CheckboxField';
import TextareaField from '../../common/TextareaField';
import StarField from '../../common/StarField';
import BinaryField from '../../common/BinaryField';
import LoadingButton from '../../common/LoadingButton';
import Button from '../../common/Button';

const ClaimForm = ({ formik, readOnly }) => {
  return (
    <Form className="h-full">
      <div className="grid grid-cols-4 gap-y-4 gap-x-8">
        <div className="col-span-2">
          <InputField
            name="name"
            label="Name"
            type="text"
            disabled={formik.isSubmitting}
            readOnly={readOnly}
            tooltip="Short Name of Claim"
          />
        </div>

        <div>
          <div className="mb-1 font-bold text-slate-700">Type</div>
          <CheckboxField
            name="type"
            values={[
              { label: 'Clinical', value: 'clinical' },
              { label: 'Commercial', value: 'commercial' },
            ]}
            disabled={formik.isSubmitting}
            readOnly={readOnly}
          />
        </div>

        <div className="row-span-3">
          <div>
            <StarField
              name="riskScore"
              label="RISK"
              disabled={formik.isSubmitting}
              readOnly={readOnly}
            />
          </div>
          <div>
            <StarField
              name="timeScore"
              label="TIME"
              disabled={formik.isSubmitting}
              readOnly={readOnly}
            />
          </div>
          <div>
            <StarField
              name="costScore"
              label="COST"
              disabled={formik.isSubmitting}
              readOnly={readOnly}
            />
          </div>
          <div>
            <StarField
              name="overallScore"
              label="OVERALL"
              disabled={formik.isSubmitting}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="col-span-2 row-span-6">
          <TextareaField
            name="details"
            label="Details"
            rows="8"
            disabled={formik.isSubmitting}
            readOnly={readOnly}
          />
        </div>

        <div>
          <InputField
            name="dueAt"
            label="Due Date"
            type="date"
            disabled={formik.isSubmitting}
            readOnly={readOnly}
          />
        </div>

        <div className="col-start-4 flex items-center justify-between">
          <label htmlFor="isPrioritized" className="font-bold text-slate-700">
            Priority
          </label>
          <BinaryField
            name="isPrioritized"
            label="Prioritized"
            disabled={formik.isSubmitting}
            readOnly={readOnly}
            offValue={false}
            off={
              <Button
                type="button"
                variant="outline-green"
                size="xs"
                className="flex w-28 items-center justify-center rounded"
                title="Prioritize"
              >
                <Icon icon="minus" className="mr-1 h-4 w-4" />
                NOT PRIORITY
              </Button>
            }
            onValue={true}
            on={
              <Button
                type="button"
                variant="green"
                size="xs"
                className="flex w-28 items-center justify-center rounded"
                title="Deprioritize"
              >
                <Icon icon="chevron-up" className="mr-1 h-4 w-4" />
                PRIORITY
              </Button>
            }
          />
        </div>

        <div className="col-start-4 flex items-center justify-between">
          <label htmlFor="isReviewNeeded" className="font-bold text-slate-700">
            Review
          </label>
          <BinaryField
            name="isReviewNeeded"
            label="Review"
            disabled={formik.isSubmitting}
            readOnly={readOnly}
            offValue={false}
            off={
              <Button
                type="button"
                variant="outline-red"
                size="xs"
                className="flex w-28 items-center justify-center rounded"
                title="Flag for review"
              >
                <Icon icon="flag-regular" className="mr-1 h-4 w-4" />
                NOT FLAGGED
              </Button>
            }
            onValue={true}
            on={
              <Button
                type="button"
                variant="red"
                size="xs"
                className="flex w-28 items-center justify-center rounded"
                title="Clear flag"
              >
                <Icon icon="flag" className="mr-1 h-4 w-4" />
                FLAGGED
              </Button>
            }
          />
        </div>

        <div className="col-start-4 flex items-center justify-between">
          <label htmlFor="isArchived" className="font-bold text-slate-700">
            Archive
          </label>
          <BinaryField
            name="isArchived"
            label="Review"
            disabled={formik.isSubmitting}
            readOnly={readOnly}
            offValue={false}
            off={
              <Button
                type="button"
                variant="outline-primary"
                size="xs"
                className="flex w-28 items-center justify-center rounded"
                title="Archive"
              >
                <Icon icon="box-open" className="mr-1 h-4 w-4" />
                NOT ARCHIVED
              </Button>
            }
            onValue={true}
            on={
              <Button
                type="button"
                variant="primary"
                size="xs"
                className="flex w-28 items-center justify-center rounded"
                title="Unarchive"
              >
                <Icon icon="box" className="mr-1 h-4 w-4" />
                ARCHIVED
              </Button>
            }
          />
        </div>

        <div className="col-span-4 justify-self-end">
          {!readOnly && formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default ClaimForm;
