import { useSearchParams } from 'react-router-dom';

import AssetIndicationList from './AssetIndicationList';
import AssetIndicationCreate from './create/AssetIndicationCreate';

const AssetIndications = (props) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get('mode') === 'create') {
    return <AssetIndicationCreate {...props} />;
  }

  return <AssetIndicationList {...props} />;
};

export default AssetIndications;
