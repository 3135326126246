import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import ContentBox from '../../common/ContentBox';
import Label from '../../common/Label';
import Dropdown from '../../common/Dropdown';

const EvidenceCard = ({ evidence, className, variant }) => {
  const { projectId } = useParams();
  if (!evidence) {
    return null;
  }

  const getMenuContent = () => {
    const menu = [
      { type: 'header', name: 'ACTIONS', className: 'text-xs' },
      {
        type: 'link',
        name: 'Edit',
        title: 'Edit Evidence',
        to: `/projects/${projectId}/evidence/${evidence.id}`,
        className: 'text-xs',
      },
      {
        type: 'link',
        name: 'Link Claim',
        title: 'Link Claim with Evidence',
        to: `?linkClaim=${evidence.id}`,
        className: 'text-xs',
      },
    ];
    return menu;
  };

  return (
    <div className={classNames('mb-3', className)}>
      <ContentBox variant={variant} contentClasses="py-2 px-2 h-36">
        <div className="flex h-full flex-col overflow-y-clip whitespace-pre-wrap">
          <div className="mb-2 flex h-full justify-between">
            <div className="flex flex-col">
              <div className="font-bold leading-tight text-slate-700">{evidence.name}</div>
              <div className="text-xs text-slate-500">{evidence.details}</div>
              <div className="mt-auto">
                {/* Labels */}
                {evidence.type === 'type1' && <Label variant="amber">Type 1</Label>}
                {evidence.type === 'type2' && <Label>Type 2</Label>}
              </div>
            </div>
            <div className="ml-1">
              <Dropdown
                toggle={
                  <div className="mx-1 flex cursor-pointer items-center justify-center rounded hover:bg-slate-500/20">
                    <Icon
                      icon="ellipsis-vertical"
                      className="h-4 w-4 cursor-pointer text-slate-700"
                    />
                  </div>
                }
                content={getMenuContent()}
                contentClasses="top-5 right-0 text-sky-900"
              />
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default EvidenceCard;
