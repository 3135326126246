import { useQuery } from '@tanstack/react-query';

import {
  getIndications,
  getIndicationsByTherapeuticArea,
  getTherapeuticAreas,
} from '../api/therapeutics.api';
import { QueryKeys } from '../utils/constants';

export const useGetTherapeuticAreas = (options) => {
  return useQuery([QueryKeys.TherapeuticAreas], getTherapeuticAreas, options);
};

export const useGetIndications = (options) => {
  return useQuery([QueryKeys.Indications], getIndications, options);
};

export const useGetIndicationsByTherapeuticArea = ({ therapeuticArea }, options) => {
  return useQuery(
    [QueryKeys.Indications, therapeuticArea],
    () => getIndicationsByTherapeuticArea({ therapeuticArea }),
    options,
  );
};
