import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import reject from 'lodash/reject';

import Loading from '../../../common/Loading';
import SelectField from '../../../common/SelectField';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { linkTrialSchema } from '../../../../validations/scenario.validations';
import { useGetScenario } from '../../../../hooks/scenarios.hooks';
import { useGetTrials, useLinkTrialScenario } from '../../../../hooks/trials.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';
import { selectTrials } from '../../../../selectors/trials.selectors';

const ScenarioLinkTrial = ({ onCancel, onSuccess, scenarioId }) => {
  const toastsContext = useToastsContext();
  const linkTrial = useLinkTrialScenario();
  const { projectId } = useParams();
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });
  const { data: allTrials, isLoading: isLoadingTrials } = useGetTrials(projectId);

  if (isLoadingScenario || isLoadingTrials) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  // filter Trials to only those eligible to be linked to a Scenario
  // reject any Trial already linked to a scenario
  const eligibleTrials = selectTrials(reject(allTrials, (trial) => !!trial.scenario));

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <Formik
        initialValues={{ trialId: '' }}
        validationSchema={linkTrialSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { trialId } = values;
          linkTrial.mutate(
            { projectId, scenarioId, trialId },
            {
              onSuccess: () => {
                toastsContext.createToast(`Linked scenario and trial.`);
                setSubmitting(false);
                onSuccess && onSuccess();
              },
              onError: (err) => {
                console.error(`Failed to link scenario and trial. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <h3 className="mb-6 text-2xl font-bold">LINK TRIAL</h3>

            <div className="mb-4">
              You are about to link a trial to scenario{' '}
              <span className="italic">{scenario.name}</span>.
            </div>

            <div>Select the trial you wish to link:</div>
            <div className="mb-6">
              <SelectField name="trialId" disabled={formik.isSubmitting}>
                <option value="">Select trial...</option>
                {eligibleTrials.map((trial) => (
                  <option key={trial.id} value={trial.id}>
                    {trial.name}
                  </option>
                ))}
              </SelectField>
            </div>

            <ButtonBar className="mt-auto ml-auto">
              <Button
                type="button"
                variant="primary"
                className="w-48 rounded"
                onClick={onCancel}
                disabled={linkTrial.isLoading}
              >
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                variant="secondary"
                className="ml-4 w-48 rounded"
                disabled={linkTrial.isLoading}
                isLoading={linkTrial.isLoading}
              >
                LINK
              </LoadingButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ScenarioLinkTrial;
