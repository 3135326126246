import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import ColumnClaims from './ColumnClaims';
import ColumnMergedClaims from './ColumnMergedClaims';
import ColumnPriorityClaims from './ColumnPriorityClaims';
import ColumnEvidence from './ColumnEvidence';

import { usePrioritizeClaim } from '../../../hooks/claims.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ClaimBoardColumns = ({ claims = [] }) => {
  const navigate = useNavigate();
  const toastsContext = useToastsContext();
  const { projectId } = useParams();
  const prioritizeClaim = usePrioritizeClaim();

  const onDragEnd = async (result) => {
    const { destination, draggableId, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;
    const [claimId, claimType] = draggableId.split('|');

    if (source.droppableId === 'column-evidence') {
      // drag evidence card to any claim column; link
      navigate(`?linkClaim=${draggableId}`);
      return;
    }

    switch (destination.droppableId) {
      case 'column-priority':
        prioritizeClaim.mutate(
          { projectId, claimId },
          {
            onSuccess: () => {
              toastsContext.createToast('Prioritized claim.');
            },
            onError: (err) => {
              console.error(`Failed to prioritize claim. Detail:`, err);
              // TODO display error notification
            },
          },
        );
        break;
      case 'column-merged':
        if (claimType !== 'merged') {
          navigate(`?mergeClaim=${claimId}`);
        }
        break;
      case 'column-evidence':
        navigate(`?linkEvidence=${claimId}`);
        break;
      default:
        break;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="grid h-[calc(100%_-_72px)] grid-cols-4 gap-4">
        <ColumnClaims claims={claims} />
        <ColumnMergedClaims claims={claims} />
        <ColumnPriorityClaims claims={claims} />
        <ColumnEvidence />
      </div>
    </DragDropContext>
  );
};

export default ClaimBoardColumns;
