import classNames from 'classnames';

/**
 * Returns CSS classes for the supplied parameters.
 * @param {string} variant The button variant.
 * @param {boolean} isPressed Indicates if the button is in a pressed state.
 * @returns {string} CSS class names.
 */
const getColorClasses = (variant, isPressed = false) => {
  switch (variant) {
    case 'primary':
      return classNames(
        'border-2 text-white hover:border-sky-700 hover:bg-sky-700 disabled:border-sky-800 disabled:bg-sky-800 disabled:text-slate-200',
        {
          'border-sky-700 bg-sky-700': isPressed,
        },
        { 'border-sky-900 bg-sky-900': !isPressed },
      );
    case 'outline-primary':
      return 'border-2 border-sky-900 bg-white text-sky-900 hover:bg-sky-900 hover:text-white disabled:border-sky-800 disabled:bg-slate-200 disabled:text-sky-800';
    case 'secondary':
      return 'border-2 border-sky-500 bg-sky-500 text-white hover:text-slate-700 hover:border-sky-300 hover:bg-sky-300 disabled:border-sky-400 disabled:bg-sky-400 disabled:text-slate-200';
    case 'amber':
      return 'border-2 border-amber-500 bg-amber-500 text-white hover:text-slate-700 hover:border-amber-300 hover:bg-amber-300 disabled:border-amber-400 disabled:bg-amber-400 disabled:text-slate-200';
    case 'purple':
      return 'border-2 border-purple-500 bg-purple-500 text-white hover:text-slate-700 hover:border-purple-300 hover:bg-purple-300 disabled:border-purple-400 disabled:bg-purple-400 disabled:text-slate-200';
    case 'green':
      return 'border-2 border-green-600 bg-green-600 text-white hover:border-green-400 hover:bg-green-400 disabled:border-green-500 disabled:bg-green-500 disabled:text-slate-200';
    case 'outline-green':
      return 'border-2 border-green-600 text-green-600 hover:text-white hover:bg-green-600 disabled:border-green-500 disabled:bg-green-500 disabled:text-slate-200';
    case 'pink':
      return 'border-2 border-pink-600 bg-pink-600 text-white hover:text-slate-700 hover:border-pink-400 hover:bg-pink-400 disabled:border-pink-500 disabled:bg-pink-500 disabled:text-slate-200';
    case 'red':
      return 'border-2 border-red-600 bg-red-600 text-white hover:border-red-400 hover:bg-red-400 disabled:border-red-500 disabled:bg-red-500 disabled:text-slate-200';
    case 'outline-red':
      return 'border-2 border-red-600 text-red-600 hover:text-white hover:bg-red-600 disabled:border-red-500 disabled:bg-red-500 disabled:text-slate-200';
    case 'transparent': {
      return 'border-2 border-transparent bg-gransparent text-slate-500 hover:text-sky-900 disabled:text-slate-500';
    }
    default:
      return 'border border-white bg-white hover:border-slate-200 hover:bg-slate-200';
  }
};

/**
 * Returns CSS size classes for the supplied parameters.
 * @param {string} size The button size.
 * @returns {string} CSS classes.
 */
const getSizeClasses = (size) => {
  switch (size) {
    case 'lg':
      return 'py-3 text-lg';
    case 'sm':
      return 'py-1 text-sm';
    case 'xs':
      return 'py-1 text-xs';
    default:
      return 'py-2';
  }
};

/**
 * The `Button` component renders a styled button.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Button = ({ children, className, isPressed, variant, size, ...props }) => {
  return (
    <button
      className={classNames(
        'font-bold',
        getSizeClasses(size),
        getColorClasses(variant, isPressed),
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
