import { Form } from 'formik';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';

import InputField from '../../common/InputField';
import TextareaField from '../../common/TextareaField';
import SelectField from '../../common/SelectField';
import BinaryField from '../../common/BinaryField';
import CurrencyField from '../../common/CurrencyField';
import LoadingButton from '../../common/LoadingButton';
import Icon from '../../common/Icon';

import { useGetIndications } from '../../../hooks/therapeutics.hooks';
import { useGetScenarios } from '../../../hooks/scenarios.hooks';
import {
  MasterProtocols,
  RiskScores,
  TrialGeographies,
  TrialPhases,
} from '../../../utils/constants';
import { selectScenarios } from '../../../selectors/scenarios.selectors';

const TrialForm = ({ formik, projectId }) => {
  const { data: indications, isLoading: isLoadingIndications } = useGetIndications();
  const { data: scenarios, isLoading: isLoadingScenarios } = useGetScenarios({ projectId });
  const eligibleScenarios = selectScenarios(scenarios);

  return (
    <Form className="h-full">
      <div className="grid grid-cols-3 gap-x-8 gap-y-4">
        <div className="col-span-2">
          <InputField
            name="name"
            label="Name"
            type="text"
            maxLength="254"
            tabIndex="1"
            autoFocus
            disabled={formik.isSubmitting}
            tooltip="Trial Name (i.e., for Protocol)"
          />
        </div>

        <div>
          <SelectField name="phase" label="Phase" tabIndex="101" disabled={formik.isSubmitting}>
            <option value=""></option>
            {sortBy(keys(TrialPhases)).map((key, index) => (
              <option key={index} value={key}>
                {TrialPhases[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="col-span-2 row-span-3">
          <TextareaField
            name="details"
            label="Details"
            rows="8"
            tabIndex="2"
            disabled={formik.isSubmitting}
          />
        </div>

        <div>
          <InputField
            name="startAt"
            label="Start Date"
            type="date"
            tabIndex="102"
            disabled={formik.isSubmitting}
          />
        </div>

        <div>
          <InputField
            name="endAt"
            label="End Date"
            type="date"
            tabIndex="103"
            min={formik.values['startAt']}
            disabled={!formik.values['startAt'] || formik.isSubmitting}
          />
        </div>

        <div>
          <CurrencyField
            name="cost"
            label="Cost"
            type="number"
            min="0"
            tabIndex="104"
            disabled={formik.isSubmitting}
            tooltip="Estimated Cost"
          />
        </div>

        <div>
          <SelectField
            name="indication"
            label="Indication"
            tabIndex="3"
            disabled={isLoadingIndications || formik.isSubmitting}
          >
            <option value=""></option>
            {indications &&
              indications.map((indication, index) => (
                <option key={index} value={indication}>
                  {indication}
                </option>
              ))}
            <option value="Other">Other</option>
          </SelectField>
        </div>

        <div>
          <div className={classNames({ hidden: formik.values['indication'] !== 'Other' })}>
            <InputField
              name="indicationOther"
              label="Other Indication"
              type="text"
              maxLength="254"
              tabIndex="4"
              disabled={formik.isSubmitting}
              tooltip="Enter an 'other' Indication when a suitable value is not found in the drop-down list."
            />
          </div>
        </div>

        <div>
          <SelectField name="riskScore" label="Risk" tabIndex="105" disabled={formik.isSubmitting}>
            {keys(RiskScores).map((key, index) => (
              <option key={index} value={key}>
                {RiskScores[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div>
          <SelectField
            name="geography"
            label="Geography"
            tabIndex="5"
            disabled={formik.isSubmitting}
          >
            <option value=""></option>
            {keys(TrialGeographies).map((key, index) => (
              <option key={index} value={key}>
                {TrialGeographies[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div>
          <InputField
            name="patientCount"
            label="Patient Count"
            type="number"
            min="0"
            tabIndex="6"
            disabled={formik.isSubmitting}
            tooltip="Total Patient Count for Trial"
          />
        </div>

        <div>
          <SelectField
            name="scenario"
            label="Scenario"
            tabIndex="106"
            disabled={isLoadingScenarios || formik.isSubmitting}
          >
            <option></option>
            {eligibleScenarios.map((scenario) => (
              <option key={scenario.id} value={scenario.id}>
                {scenario.name}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="col-start-1">
          <label htmlFor="adaptiveDesign" className="font-bold text-slate-700">
            Adaptive Design
          </label>
          <div className="mt-1 py-2">
            <BinaryField
              id="adaptiveDesign"
              name="adaptiveDesign"
              disabled={formik.isSubmitting}
              offValue={false}
              off={<Icon icon="square" className="h-5 w-5" />}
              onValue={true}
              on={<Icon icon="square-check" className="h-5 w-5" />}
            />
          </div>
        </div>

        <div>
          <SelectField
            name="masterProtocol"
            label="Master Protocol"
            tabIndex="8"
            disabled={formik.isSubmitting}
          >
            {keys(MasterProtocols).map((key, index) => (
              <option key={index} value={key}>
                {MasterProtocols[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="col-span-3 justify-self-end">
          {formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              tabIndex="1000"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default TrialForm;
