import { useEffect } from 'react';

import ContentBox from '../common/ContentBox';
import ProjectsButtonBar from '../dashboard/ProjectsButtonBar';
import AllProjects from '../dashboard/AllProjects';
import LatestActivity from '../dashboard/LatestActivity';
import Notifications from '../dashboard/Notifications';
import ProjectStatusChart from '../dashboard/ProjectStatusChart';

const DashboardPage = () => {
  useEffect(() => {
    document.title = 'Dashboard | IQVIA Design Center';
  }, []);

  return (
    <div className="h-full py-8 px-16">
      <div className="grid grid-cols-12 items-end gap-6">
        <div className="col-span-4">
          <h2 className="mb-8 text-2xl text-sky-900">DASHBOARD</h2>
          <ProjectsButtonBar />
        </div>
        <div className="col-span-8">
          <LatestActivity />
        </div>
      </div>

      <div className="mt-6 grid h-[calc(100%_-_150px)] grid-flow-col grid-cols-2 grid-rows-5 gap-6">
        <ContentBox className="row-span-5 h-full rounded-2xl bg-white/80" variant="primary">
          <AllProjects />
        </ContentBox>
        <ContentBox className="row-span-3 h-full rounded-2xl bg-white/80" variant="info">
          <ProjectStatusChart />
        </ContentBox>
        <ContentBox className="row-span-2 h-full rounded-2xl bg-white/80" variant="secondary">
          <Notifications />
        </ContentBox>
      </div>
    </div>
  );
};

export default DashboardPage;
