import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';

const defaultListControls = {
  matches: {},
  search: '',
  sort: {
    by: 'name',
    order: 'asc',
  },
  pagination: {
    page: 1,
    size: 10,
    pages: 1,
  },
};

/**
 * Select all `Indication` objects which contain the supplied `text` value in their name.
 * @param {Object[]} indications A collection of `Indication` objects.
 * @param {string} text The selection criteria.
 * @returns A collection of indications.
 */
export const selectIndicationsByText = (indications = [], text = '') => {
  return filter(indications, (i) => {
    return (
      i.indication.toLowerCase().includes(text.toLowerCase()) ||
      i.indicationOther.includes(text.toLowerCase())
    );
  });
};

/**
 * Select Indications with list controls selections applied.
 * @param {Object[]} indications A collection of `Indication` objects.
 * @param {Object} listControls A ListControls object.
 * @returns {Object} An object whose attributes are `data` and `page`.
 * The `data` attribute is a collection of all of the Indication objects
 * with list controls applied. The `page` attribute is a page of Indication objects with
 * list controls applied.
 */
export const selectIndicationsWithListControls = (indications = [], listControls) => {
  const result = {};
  const opts = merge({}, defaultListControls, listControls);
  // 1. filter by attribute matcher
  let items = filter(indications, opts.matches);
  // 2. filter by search text
  items =
    opts.search && opts.search.length > 1 ? selectIndicationsByText(items, opts.search) : items;
  // 3. apply sort
  result.data = orderBy(items, [opts.sort.by], [opts.sort.order]);
  // 4. apply pagination
  result.page = chunk(result.data, opts.pagination.size)[opts.pagination.page - 1] || [];

  return result;
};
