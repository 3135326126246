import { Formik } from 'formik';

import ProjectForm from '../common/ProjectForm';

import { useAuthState } from '../../../hooks/cognito.hooks';
import { useCreateProject } from '../../../hooks/projects.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';
import { projectSchema } from '../../../validations/project.validations';

const ProjectCreate = ({ onCancel, onSuccess }) => {
  const toastsContext = useToastsContext();
  const { data: authState } = useAuthState();
  const createProject = useCreateProject();

  return (
    <div
      id="project-create"
      className="h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      <div className="mb-2 font-bold text-sky-900">CREATE NEW PROJECT</div>

      <Formik
        initialValues={{
          name: '',
          client: '',
          projectLead: '',
          startedAt: '',
          dueAt: '',
          stage: 'notstarted',
          status: 'notstarted',
        }}
        validationSchema={projectSchema}
        onSubmit={(values, { setSubmitting }) => {
          createProject.mutate(
            { accountId: authState.sub, project: values },
            {
              onSuccess: (project) => {
                setSubmitting(false);
                toastsContext.createToast(`Created project ${project.name}.`);
                onSuccess && onSuccess(project);
              },
              onError: (err) => {
                console.error(`Failed to create project. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <ProjectForm formik={formik} onCancel={onCancel} />}
      </Formik>
    </div>
  );
};

export default ProjectCreate;
