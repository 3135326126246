import { useSearchParams } from 'react-router-dom';

import TrialArmList from './list/TrialArmList';
import TrialArmCreate from './create/TrialArmCreate';
import TrialArmEdit from './edit/TrialArmEdit';
import TrialArmDelete from './delete/TrialArmDelete';

const TrialArms = ({ projectId, trial }) => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');

  switch (mode) {
    case 'create':
      return <TrialArmCreate projectId={projectId} trial={trial} />;
    case 'delete':
      return <TrialArmDelete projectId={projectId} trial={trial} />;
    case 'edit':
      return <TrialArmEdit projectId={projectId} trial={trial} />;
    default:
      return <TrialArmList projectId={projectId} trial={trial} />;
  }
};

export default TrialArms;
