import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Button from '../common/Button';
import ContentBox from '../common/ContentBox';
import Placeholder from '../common/Placeholder';

import { useAuthState } from '../../hooks/cognito.hooks';
import { useGetLatestProject } from '../../hooks/projects.hooks';

const LatestActivityLoading = () => {
  return (
    <div className="flex items-center justify-between text-sky-900">
      <div>
        <div className="text-sm font-bold text-sky-700">LATEST OPEN PROJECT</div>
        <Placeholder className="w-36 xl:w-48" size="xl" />
      </div>
      <Placeholder className="w-36 xl:w-48" size="lg" />
      <Placeholder className="w-24 xl:w-32" size="xl" />
    </div>
  );
};

const LatestActivityContent = ({ project }) => {
  if (project) {
    return (
      <div className="flex items-center justify-between text-sky-900">
        <div>
          <div className="text-sm font-bold text-sky-500">LATEST OPEN PROJECT</div>
          <div className="text-xl font-bold">{project.name}</div>
          <div className="mt-4 text-sm">
            Modified on{' '}
            {dayjs(project.updatedAt || project.createdAt).format('MMM D, YYYY [at] h:mma')}
          </div>
        </div>
        <Link
          to={`/projects/${project.id}`}
          className="w-32 rounded border-sky-500 bg-sky-500 py-2 text-center font-bold text-white hover:border-sky-300 hover:bg-sky-300 hover:text-slate-700"
        >
          OPEN
        </Link>
      </div>
    );
  }

  return null;
};

const LatestActivityEmpty = ({ project }) => {
  const navigate = useNavigate();

  if (project) {
    return null;
  }

  return (
    <div className="flex items-center justify-between text-sky-900">
      <div>
        <div className="text-sm font-bold text-sky-500">LATEST OPEN PROJECT</div>
        <div className="text-xl">No projects to display yet.</div>
      </div>
      <Button
        variant="secondary"
        className="w-32 rounded"
        onClick={() => navigate('/projects/create')}
      >
        CREATE
      </Button>
    </div>
  );
};

const LatestActivity = () => {
  const { data: authState } = useAuthState();
  const { data: project, status } = useGetLatestProject(authState?.sub, {
    enabled: !!authState?.sub,
  });

  return (
    <ContentBox className="rounded-2xl bg-white/30 bg-gradient-to-r from-[#F8FEFE] to-[#F8FDFE00] shadow-none">
      {status === 'loading' ? (
        <LatestActivityLoading />
      ) : (
        <>
          <LatestActivityEmpty project={project} />
          <LatestActivityContent project={project} />
        </>
      )}
    </ContentBox>
  );
};

export default LatestActivity;
