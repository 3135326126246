import Icon from '../../../../common/Icon';

import { useArmListControlsContext } from '../../../../../hooks/listcontrols.hooks';

const TrialArmListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useArmListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const TrialArmListHeader = () => {
  return (
    <div className="grid grid-cols-12 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-5">
        <TrialArmListColumnHeading name="name" title="NAME" />
      </div>
      <div className="col-span-2">
        <TrialArmListColumnHeading name="startAt" title="START" />
      </div>
      <div className="col-span-2">
        <TrialArmListColumnHeading name="endAt" title="END" />
      </div>
      <div className="col-span-2">
        <TrialArmListColumnHeading name="sampleSize" title="SAMPLE SIZE" />
      </div>
      <div></div>
    </div>
  );
};

export default TrialArmListHeader;
