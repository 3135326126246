import FileSaver from 'file-saver';

import storage from '../utils/storage';
import { generateId } from '../utils/id';
import { delay } from '../utils/delay';

/**
 * Fetch a collection of `Project` objects belonging to an Account.
 * @param {string} sub An account identifier.
 * @returns {Promise} Returns a collection of Projects if successful,
 * otherwise rejects with an error.
 */
export const getProjects = async (sub) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const accounts = storage.getJson(storage.Keys.Accounts) || [];
        const account = accounts.find((a) => a.sub === sub);
        const projectIds = account?.projects || [];

        const projects = projectIds.map((projectId) => {
          return storage.getJson(`project.${projectId}`);
        });
        return resolve(projects);
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Fetch a single `Project` by identifier.
 * @param {string} id A project identifier.
 * @returns {Promise} Returns a Promise which resolves to the Project
 * if found, otherwise rejects with an error.
 */
export const getProject = async (id) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const project = storage.getJson(`project.${id}`);
        if (project) {
          resolve(project);
        }
        return reject(new Error('Not found.'));
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Create a `Project`.
 * @param {string} accountId The account identifier; the `sub` attribute value.
 * @param {Object} project The project to be created.
 * @returns {Promise} A Promise which resolves to the created Project if successful,
 * otherwise rejects with an error.
 */
export const createProject = async ({ accountId, project }) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      const projectId = generateId();
      try {
        // find the account
        const accounts = storage.getJson(storage.Keys.Accounts) || [];
        const account = accounts.find((a) => a.sub === accountId);
        if (!account) {
          return reject(new Error('Account not found.'));
        }

        const now = new Date().toISOString();

        // create the project
        const createdProject = {
          scenarios: [],
          claims: [],
          evidence: [],
          trials: [],
          ...project,
          id: projectId,
          isArchived: false,
          createdAt: now,
          updatedAt: now,
        };
        storage.setJson(`project.${projectId}`, createdProject);

        // update the account
        const updatedAccount = {
          ...account,
          projects: [...account.projects, projectId],
        };
        storage.setJson(
          storage.Keys.Accounts,
          accounts.map((item) => {
            if (item.id === updatedAccount.id) {
              return updatedAccount;
            }
            return item;
          }),
        );

        return resolve(createdProject);
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Update a `Project`.
 * @param {Object} project The project to be updated.
 * @returns {Promise} Returns a Promise which resolves to the updated Project
 * if successful, otherwise, rejects with an error.
 */
export const updateProject = async (project) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        // find the project
        const projectToUpdate = storage.getJson(`project.${project.id}`);
        if (!projectToUpdate) {
          return reject(new Error('Not found.'));
        }

        // update the project
        const updatedProject = {
          ...projectToUpdate,
          ...project,
          updatedAt: new Date().toISOString(),
        };
        storage.setJson(`project.${updatedProject.id}`, updatedProject);

        return resolve(updatedProject);
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Deletes a `Project`.
 * @param {string} accountId An account identifier.
 * @param {string} projectId A project identifier.
 * @returns {Promise} Returns a Promise which resolves to empty if successful,
 * otherwise rejects with an error.
 */
export const removeProject = async (accountId, projectId) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        // find the account
        const accounts = storage.getJson(storage.Keys.Accounts) || [];
        const account = accounts.find((a) => a.id === accountId);
        if (!account) {
          return reject(new Error('Account not found.'));
        }

        // update the account
        const updatedAccount = {
          ...account,
          projects: account.projects.filter((item) => item !== projectId),
        };
        storage.setJson(
          storage.Keys.Accounts,
          accounts.map((item) => {
            if (item.id === updatedAccount.id) {
              return updatedAccount;
            }
            return item;
          }),
        );

        // delete the project
        storage.removeItem(`project.${projectId}`);

        return resolve();
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Fetches the most recent `Project` accessed by an account.
 * @param {string} accountId An account identifier.
 * @returns {Promise} Returns a Promise which resolves to the Project if found,
 * rejects with an error if not found or not successful.
 */
export const getLatestProject = async (accountId) => {
  return new Promise((resolve, reject) => {
    try {
      const latestProjects = storage.getJson(storage.Keys.ProjectsLatest) || {};
      const projectId = latestProjects[accountId];
      if (projectId) {
        getProject(latestProjects[accountId]).then((project) => {
          return resolve(project);
        });
      } else {
        return reject(new Error('Not found.'));
      }
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Save the most recent `Project` accessed by an account.
 * @param {Object} variables The API request variables.
 * @param {string} variables.accountId An account identifier.
 * @param {string} variables.projectId A project identifier.
 * @returns {Promise} Returns a Promise which resolves to the Project if
 * successful, otherwise rejects with an error.
 */
export const setLatestProject = async ({ accountId, projectId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const latestProjects = storage.getJson(storage.Keys.ProjectsLatest) || {};
        latestProjects[accountId] = project.id;
        storage.setJson(storage.Keys.ProjectsLatest, latestProjects);
        return resolve(project);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Export a project to a JSON file.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @returns {Promise} A Promise which resolves to the export filename
 * if successful, otherwise rejects with an error.
 */
export const exportProject = async ({ projectId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const blob = new Blob([JSON.stringify(project, null, 2)], {
          type: 'text/plain;charset=utf-8',
        });
        const fileName = project.name.replaceAll(' ', '_') + '.json';
        FileSaver.saveAs(blob, fileName);
        return resolve(fileName);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Import a `Project` from JSON. Saves the Project and adds to the account.
 * @param {Object} variables The API request variables.
 * @param {string} variables.accountId An account identifier.
 * @param {Object} variables.project A project object.
 * @returns {Promise} Returns a Promise which resolves to the created Project
 * if successful, otherwise rejects with an error.
 */
export const importProject = async ({ accountId, project }) => {
  return new Promise((resolve, reject) => {
    try {
      createProject({ accountId, project }).then((createdProject) => {
        return resolve(createdProject);
      });
    } catch (err) {
      return reject(err);
    }
  });
};
