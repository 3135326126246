import { Form } from 'formik';

import InputField from '../../common/InputField';
import TextareaField from '../../common/TextareaField';
import LoadingButton from '../../common/LoadingButton';

const AssetForm = ({ formik }) => {
  return (
    <Form className="h-full">
      <div className="grid grid-cols-3 gap-x-8 gap-y-4">
        <div>
          <InputField
            name="compoundName"
            label="Compound Name"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Sponsor Identifier, Generic Name, or Brand Name"
          />
        </div>

        <div>
          <InputField
            name="manufacturer"
            label="Manufacturer"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Sponsor(s) of Compound or Combination"
          />
        </div>

        <div className="row-span-5">
          <TextareaField
            name="notes"
            label="Notes"
            type="text"
            disabled={formik.isSubmitting}
            rows="12"
          />
        </div>

        <div>
          <InputField
            name="developmentType"
            label="Development Type"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Novel, Generic, or Existing Class; Pathway, etc."
          />
        </div>

        <div>
          <InputField
            name="phase"
            label="Phase"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Current Phase of Development of Asset"
          />
        </div>

        <div>
          <InputField
            name="diseaseType"
            label="Disease Type"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Chronic, Acute, Genetic"
          />
        </div>

        <div className="col-span-3 justify-self-end">
          {formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default AssetForm;
