import ClaimEvidenceListMenu from './ClaimEvidenceListMenu';
import ClaimEvidenceListItem from './ClaimEvidenceListItem';
import ClaimEvidenceListHeader from './ClaimEvidenceListHeader';
import ClaimEvidenceListFooter from './ClaimEvidenceListFooter';

import { selectEvidenceWithPagination } from '../../../../selectors/evidence.selectors';
import { useEvidenceListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ClaimEvidenceListContent = ({ claim, evidenceList, projectId }) => {
  const { data: listControls } = useEvidenceListControlsContext();

  if (evidenceList.length === 0) {
    return null;
  }

  const { data: allEvidence, page } = selectEvidenceWithPagination(evidenceList, listControls);

  return (
    <>
      <ClaimEvidenceListMenu claim={claim} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <ClaimEvidenceListHeader />

        {page.map((evidence, index) => (
          <ClaimEvidenceListItem
            key={evidence.id}
            evidence={evidence}
            claim={claim}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <ClaimEvidenceListFooter totalItemsOnPage={page.length} totalItems={allEvidence.length} />
        </div>
      </div>
    </>
  );
};

export default ClaimEvidenceListContent;
