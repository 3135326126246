import classNames from 'classnames';

import Icon from './Icon';

/**
 * The `Spinner` component renders an animated icon. Used to indicate that
 * some activity is in progress, e.g. a form submission.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Spinner = ({ className, show = true }) => {
  if (show) {
    return <Icon icon="rotate" spin className={classNames('h-4 w-4 text-sky-900', className)} />;
  }

  return null;
};

export default Spinner;
