import numeral from 'numeral';
import { usePopperTooltip } from 'react-popper-tooltip';

import Tooltip from '../../../common/Tooltip';

import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';
import {
  selectTrialsAverageRisk,
  selectTrialRiskLabel,
} from '../../../../selectors/trials.selectors';

const ScenarioRisk = ({ projectId, scenarioId }) => {
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'top',
  });
  const { data: trials, isLoading } = useGetAllTrialsForScenario(projectId, scenarioId);

  if (isLoading) return null;

  const averageTrialRisk = selectTrialsAverageRisk(trials);

  return (
    <div className="flex items-center" ref={setTriggerRef}>
      <div>
        <span className="mr-0.5">{numeral(averageTrialRisk).format('0[.]0[0]')}</span>
        <span>/</span>
        <span className="ml-0.5">5</span>
      </div>
      <Tooltip {...tooltipProps}>
        The estimated risk of the scenario is {selectTrialRiskLabel(averageTrialRisk).toLowerCase()}
        , with an average score of {numeral(averageTrialRisk).format('0[.]0[0]')} out of a maximum
        of 5. This is calculated by averaging the risk of all trials in the scenario.
      </Tooltip>
    </div>
  );
};

export default ScenarioRisk;
