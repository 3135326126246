import { useEffect, useState } from 'react';
import filter from 'lodash/filter';

import Icon from '../../../common/Icon';
import LetterIcon from '../../../common/LetterIcon';
import ScenarioHierarchyTrial from './ScenarioHierarchyTrial';
import ScenarioHierarchyItemLoading from './ScenarioHierarchyItemLoading';

import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';
import { selectTrials } from '../../../../selectors/trials.selectors';

const ScenarioHierarchyEvidence = ({ projectId, evidence, scenario, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const trialIds = evidence.trials.filter((trialId) => scenario.trials.includes(trialId));
  const hasTrials = trialIds.length > 0;

  const { data: trials, isLoading: isLoadingTrials } = useGetAllTrialsForScenario(
    projectId,
    scenario.id,
  );

  const renderTrials = () => {
    if (isExpanded) {
      if (isLoadingTrials) {
        return <ScenarioHierarchyItemLoading className="ml-20 py-0.5" />;
      } else {
        const filteredTrials = filter(trials, (t) => evidence.trials.includes(t.id));
        const selectedTrials = selectTrials(
          filteredTrials,
          null,
          ['phase', 'startAt'],
          ['asc', 'asc'],
        );
        return selectedTrials.map((trial) => (
          <ScenarioHierarchyTrial key={trial.id} trial={trial} />
        ));
      }
    }

    return null;
  };

  return (
    <div className="ml-8 flex flex-col">
      <div className="flex items-center py-0.5 hover:bg-sky-900/10">
        <div className="mr-4">
          {hasTrials ? (
            <Icon
              icon={isExpanded ? 'minus' : 'plus'}
              fixedWidth
              title={isExpanded ? 'Click to collapse' : 'Click to expand'}
              onClick={() => setIsExpanded(!isExpanded)}
              className="cursor-pointer"
            />
          ) : (
            <div className="w-5" />
          )}
        </div>
        <div>
          <LetterIcon letter="e" className="mr-1" title="Evidence" />
          {evidence.name}
        </div>
      </div>

      {renderTrials()}
    </div>
  );
};

export default ScenarioHierarchyEvidence;
