import Icon from '../../../common/Icon';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';

import { useScenarioListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ClaimScenarioListFooter = ({ totalItemsOnPage, totalItems }) => {
  const { data: listControls, setPage } = useScenarioListControlsContext();
  let pageStart = 0;
  let pageEnd = 0;
  if (totalItems > 0) {
    pageStart = (listControls.pagination.page - 1) * listControls.pagination.size + 1;
    pageEnd = pageStart + (totalItemsOnPage - 1);
  }

  const totalPages = Math.ceil(totalItems / listControls.pagination.size);
  const isLastPage = listControls.pagination.page === totalPages;
  const pages = [];
  let pageIndex = isLastPage ? listControls.pagination.page - 2 : listControls.pagination.page - 1;
  while (pages.length < Math.min(totalPages, 3)) {
    if (pageIndex > 0 && pageIndex <= totalPages) {
      pages.push(pageIndex);
    }
    pageIndex++;
  }

  return (
    <div className="grid grid-cols-12 gap-1 py-3 px-2 text-sky-700">
      <div className="col-span-8 font-bold">
        Showing {pageStart}-{pageEnd} of {totalItems} linked scenarios
      </div>
      <div className="col-span-4 justify-self-end">
        <ButtonBar>
          <Button
            title="First Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(1)}
          >
            <Icon icon="chevron-left" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
          {pages.map((page) => (
            <Button
              key={page}
              title={`Page ${page}`}
              variant={listControls.pagination.page === page ? 'primary' : 'outline-primary'}
              size="sm"
              className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
              onClick={() => setPage(page)}
              disabled={listControls.pagination.page === page}
            >
              {page}
            </Button>
          ))}
          <Button
            title="Last Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(totalPages)}
          >
            <Icon icon="chevron-right" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
        </ButtonBar>
      </div>
    </div>
  );
};

export default ClaimScenarioListFooter;
