import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import EvidenceForm from '../common/EvidenceForm';
import Icon from '../../common/Icon';

import { evidenceSchema } from '../../../validations/evidence.validations';
import { useCreateEvidence } from '../../../hooks/evidence.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const EvidenceCreate = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const createEvidence = useCreateEvidence();
  const toastsContext = useToastsContext();

  return (
    <div id="evidence-create" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">CREATE NEW EVIDENCE</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Formik
          initialValues={{
            name: '',
            details: '',
            sampleSize: 0,
            biomarker: '',
            populationAgeGroup: '',
            endpoint: '',
            power: 0,
            hazardRatio: '',
          }}
          validationSchema={evidenceSchema}
          onSubmit={(values, { setSubmitting }) => {
            createEvidence.mutate(
              { projectId, evidence: values },
              {
                onSuccess: (evidence) => {
                  toastsContext.createToast(`Created evidence.`);
                  setSubmitting(false);
                  navigate(`/projects/${projectId}/evidence`);
                },
                onError: (err) => {
                  console.error(`Failed to create evidence. Detail:`, err);
                  // TODO display error notification
                  setSubmitting(false);
                },
              },
            );
          }}
        >
          {(formik) => <EvidenceForm formik={formik} />}
        </Formik>
      </div>
    </div>
  );
};

export default EvidenceCreate;
