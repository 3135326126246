import find from 'lodash/find';
import unionBy from 'lodash/unionBy';

import storage from '../utils/storage';
import { delay } from '../utils/delay';

/**
 * Create or update an `Account`.
 * @param {Object} account The account to be saved.
 * @returns {Promise} Returns a Promise which resolves to the saved Account if successful,
 * otherwise rejects with an error.
 */
export const saveAccount = async ({ account }) => {
  return new Promise((resolve) => {
    delay().then(() => {
      const accounts = storage.getJson(storage.Keys.Accounts) || [];
      const existingAccount = find(accounts, { sub: account.sub });
      if (existingAccount) {
        // update
        const updatedAccount = {
          ...existingAccount,
          ...account,
        };
        storage.setJson(storage.Keys.Accounts, unionBy([updatedAccount], accounts, 'sub'));
        return resolve(updatedAccount);
      } else {
        // create
        const createdAccount = {
          ...account,
          projects: [],
        };
        storage.setJson(storage.Keys.Accounts, [...accounts, createdAccount]);
        return resolve(createdAccount);
      }
    });
  });
};

/**
 * Fetch a single `Account` by the `sub` attribute value, i.e. "subject".
 * @param {string} accountId The account identifier, i.e. the `sub` (or subject) value.
 * @returns {Promise} A Promise which resolves to the Account if found,
 * otherwise rejects with an error.
 */
export const getAccount = async (accountId) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      const accounts = storage.getJson(storage.Keys.Accounts) || [];
      const account = accounts.find((a) => a.sub === accountId);
      if (account) {
        return resolve(account);
      }
      return reject(new Error('Not found.'));
    });
  });
};
