import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import includes from 'lodash/includes';
import reject from 'lodash/reject';

import Loading from '../../../common/Loading';
import SelectField from '../../../common/SelectField';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { linkEvidenceSchema } from '../../../../validations/claim.validations';
import { useGetClaim, useLinkEvidence } from '../../../../hooks/claims.hooks';
import { useGetAllEvidence } from '../../../../hooks/evidence.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';
import { selectEvidence } from '../../../../selectors/evidence.selectors';

const ClaimLinkEvidence = ({ onCancel, onSuccess, claimId }) => {
  const toastsContext = useToastsContext();
  const linkEvidence = useLinkEvidence();
  const { projectId } = useParams();
  const { data: claim, isLoading: isLoadingClaim } = useGetClaim({
    projectId,
    claimId,
  });
  const { data: allEvidence, isLoading: isLoadingEvidence } = useGetAllEvidence({ projectId });

  if (isLoadingClaim || isLoadingEvidence) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const evidenceList = selectEvidence(reject(allEvidence, (e) => includes(claim.evidence, e.id)));

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <Formik
        initialValues={{ evidenceId: '' }}
        validationSchema={linkEvidenceSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { evidenceId } = values;
          // mergeClaim mutator
          linkEvidence.mutate(
            { projectId, claimId, evidenceId },
            {
              onSuccess: () => {
                toastsContext.createToast(`Linked evidence with claim.`);
                setSubmitting(false);
                onSuccess && onSuccess();
              },
              onError: (err) => {
                console.error(`Failed to link evidence to claim. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <h3 className="mb-6 text-2xl font-bold">LINK EVIDENCE</h3>

            <div className="mb-4">
              You are about to link evidence to claim <span className="italic">{claim.name}</span>.
            </div>

            <div>Select the evidence you wish to link:</div>
            <div className="mb-6">
              <SelectField name="evidenceId" disabled={formik.isSubmitting}>
                <option value="">Select evidence...</option>
                {evidenceList.map((evidence) => (
                  <option key={evidence.id} value={evidence.id}>
                    {evidence.name}
                  </option>
                ))}
              </SelectField>
            </div>

            <ButtonBar className="mt-auto ml-auto">
              <Button
                type="button"
                variant="primary"
                className="w-48 rounded"
                onClick={onCancel}
                disabled={linkEvidence.isLoading}
              >
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                variant="secondary"
                className="ml-4 w-48 rounded"
                disabled={linkEvidence.isLoading}
                isLoading={linkEvidence.isLoading}
              >
                LINK
              </LoadingButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimLinkEvidence;
