import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// initialize the ChartJS library components for a Pie Chart
ChartJS.register(ArcElement, Tooltip, Legend);

// default chart data
const defaultData = {
  labels: ['A', 'B'],
  datasets: [
    {
      label: 'placeholder',
      data: [8, 4],
      backgroundColor: ['#06b6d480', '#22c55e80'],
      borderColor: ['#06b6d4', '#22c55e'],
      borderWidth: 1,
    },
  ],
};

/**
 * The `PieChart` component renders a chart.js pie chart using the
 * chart data and options supplied in the component properties.
 * @see https://www.chartjs.org/docs/latest/charts/doughnut.html#pie
 * @param {Object} props The component properties.
 * @returns JSX
 */
const PieChart = ({ data, options }) => {
  const pieData = data || defaultData;

  return <Pie data={pieData} options={options} />;
};

export default PieChart;
