import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import NoContent from '../../common/NoContent';
import StatusBadge from '../../common/StatusBadge';
import Placeholder from '../../common/Placeholder';
import Spinner from '../../common/Spinner';
import Dropdown from '../../common/Dropdown';
import ProjectListFilter from './ProjectListFilter';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';

import { useAuthState } from '../../../hooks/cognito.hooks';
import { useGetProjects } from '../../../hooks/projects.hooks';
import {
  selectProjects,
  selectProjectsWithPagination,
} from '../../../selectors/projects.selectors';
import { keys } from '../../../utils/keys';
import { useProjectListControlsContext } from '../../../hooks/listcontrols.hooks';
import { ProjectStatus } from '../../../utils/constants';

const ProjectListLoading = () => {
  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="font-bold text-sky-900">ALL PROJECTS</h3>
      </div>

      <div className="mt-4 rounded-lg bg-white/20">
        <div className="grid grid-cols-20 gap-1 py-3 px-2 text-sm text-sky-700">
          <div className="col-span-6">NAME</div>
          <div className="col-span-5">CLIENT</div>
          <div className="col-span-2">START</div>
          <div className="col-span-2">DUE</div>
          <div className="col-span-2">UPDATED</div>
          <div className="col-span-2">STATUS</div>
          <div className="col-span-1"></div>
        </div>

        {keys(3).map((item, index) => (
          <div
            key={item.key}
            className={classNames('grid grid-cols-20 gap-1 py-3 px-2 text-sm text-slate-900', {
              'bg-white/50': index % 2 === 0,
            })}
          >
            <div className="col-span-6 pr-3">
              <Placeholder />
            </div>
            <div className="col-span-5 pr-3">
              <Placeholder />
            </div>
            <div className="col-span-2 pr-3">
              <Placeholder />
            </div>
            <div className="col-span-2 pr-3">
              <Placeholder />
            </div>
            <div className="col-span-2 pr-3">
              <Placeholder />
            </div>
            <div className="col-span-2 pr-3">
              <Placeholder />
            </div>
            <div className="col-span-1">
              <Placeholder />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const ProjectListEmpty = ({ projects = [] }) => {
  const navigate = useNavigate();

  if (projects.length > 0) {
    return null;
  }

  return (
    <div className="h-full rounded-lg bg-white/20">
      <div className="flex h-full items-center justify-center">
        <NoContent variant="primary">
          <div className="mt-2 flex flex-col items-center text-slate-700">
            <div>You don't have any Projects yet.</div>
            <div className="mt-2 text-xs">
              Go ahead and create a new one by clicking the button below.
            </div>
            <Button
              type="button"
              variant="secondary"
              className="mt-4 w-32 rounded"
              onClick={() => navigate('/projects/create')}
            >
              CREATE NEW
            </Button>
          </div>
        </NoContent>
      </div>
    </div>
  );
};

const ProjectListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useProjectListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const ProjectListHeader = () => {
  return (
    <div className="grid grid-cols-20 gap-1 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-6">
        <ProjectListColumnHeading name="name" title="NAME" />
      </div>
      <div className="col-span-5">
        <ProjectListColumnHeading name="client" title="CLIENT" />
      </div>
      <div className="col-span-2">
        <ProjectListColumnHeading name="startedAt" title="START" />
      </div>
      <div className="col-span-2">
        <ProjectListColumnHeading name="dueAt" title="DUE" />
      </div>
      <div className="col-span-2">
        <ProjectListColumnHeading name="updatedAt" title="UPDATED" />
      </div>
      <div className="col-span-2">
        <ProjectListColumnHeading name="status" title="STATUS" />
      </div>
      <div className="col-span-1"></div>
    </div>
  );
};

const ProjectListFooter = ({ totalItemsOnPage, totalItems }) => {
  const { data: listControls, setPage } = useProjectListControlsContext();
  let pageStart = 0;
  let pageEnd = 0;
  if (totalItems > 0) {
    pageStart = (listControls.pagination.page - 1) * listControls.pagination.size + 1;
    pageEnd = pageStart + (totalItemsOnPage - 1);
  }

  const totalPages = Math.ceil(totalItems / listControls.pagination.size);
  const isLastPage = listControls.pagination.page === totalPages;
  const pages = [];
  let pageIndex = isLastPage ? listControls.pagination.page - 2 : listControls.pagination.page - 1;
  while (pages.length < Math.min(totalPages, 3)) {
    if (pageIndex > 0 && pageIndex <= totalPages) {
      pages.push(pageIndex);
    }
    pageIndex++;
  }

  return (
    <div className="grid grid-cols-20 gap-1 py-3 px-2 text-sky-700">
      <div className="col-span-10 font-bold">
        Showing {pageStart}-{pageEnd} of {totalItems} projects
      </div>
      <div className="col-span-10 justify-self-end">
        <ButtonBar>
          <Button
            title="First Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(1)}
          >
            <Icon icon="chevron-left" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
          {pages.map((page) => (
            <Button
              key={page}
              title={`Page ${page}`}
              variant={listControls.pagination.page === page ? 'primary' : 'outline-primary'}
              size="sm"
              className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
              onClick={() => setPage(page)}
              disabled={listControls.pagination.page === page}
            >
              {page}
            </Button>
          ))}
          <Button
            title="Last Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(totalPages)}
          >
            <Icon icon="chevron-right" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
        </ButtonBar>
      </div>
    </div>
  );
};

const ProjectListItem = ({ project, highlight }) => {
  if (project) {
    return (
      <div
        className={classNames('grid grid-cols-20 gap-1 py-3 px-2 text-slate-900', {
          'bg-white/50': highlight,
        })}
      >
        <div className="col-span-6 truncate">
          <Link to={`/projects/${project.id}`} className="hover:text-sky-700">
            {project.name}
          </Link>
        </div>
        <div className="col-span-5 truncate">{project.client}</div>
        <div className="col-span-2">{dayjs(project.startedAt).format('MMM D, YYYY')}</div>
        <div className="col-span-2">{dayjs(project.dueAt).format('MMM D, YYYY')}</div>
        <div className="col-span-2">
          {project.updatedAt && dayjs(project.updatedAt).format('MMM D, YYYY')}
        </div>
        <div className="col-span-2">
          <StatusBadge status={project.status} className="text-[14px]">
            {ProjectStatus[project.status]}
          </StatusBadge>
        </div>
        <div className="col-span-1">
          <Dropdown
            toggle={
              <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
            }
            content={[
              {
                type: 'link',
                name: 'Edit',
                title: 'Edit Project',
                to: `/projects/${project.id}/edit`,
                className: 'text-xs',
              },
              {
                type: 'link',
                name: 'Archive',
                title: 'Archive Project',
                to: `?archiveProject=${project.id}`,
                className: 'text-xs',
              },
              {
                type: 'link',
                name: 'Export',
                title: 'Export Project',
                to: `?exportProject=${project.id}`,
                className: 'text-xs',
              },
            ]}
            contentClasses="right-5 -top-1.5 text-sky-900"
          />
        </div>
      </div>
    );
  }

  return null;
};

const ProjectListContent = ({ isFetching, projects = [] }) => {
  const { data: listControls } = useProjectListControlsContext();

  if (projects.length === 0) {
    return null;
  }

  let { data: allProjects, page } = selectProjectsWithPagination(projects, listControls);

  return (
    <>
      <div className="flex items-center">
        <h3 className="font-bold text-sky-900">ALL PROJECTS</h3>
        <Spinner show={isFetching} className="ml-2" />
        <ProjectListFilter className="ml-auto" />
      </div>

      <div className="mt-4 flex flex-col rounded-xl bg-white/30">
        <ProjectListHeader />

        {page.map((project, index) => (
          <ProjectListItem key={project.id} project={project} highlight={index % 2 === 0} />
        ))}

        <div className="mt-auto">
          <ProjectListFooter totalItemsOnPage={page.length} totalItems={allProjects.length} />
        </div>
      </div>
    </>
  );
};

const ProjectList = () => {
  const { data: authState } = useAuthState();
  const {
    data: projects,
    status,
    isFetching,
  } = useGetProjects(authState?.sub, {
    enabled: !!authState?.sub,
  });

  const unarchivedProjects = selectProjects(projects);

  return (
    <div className="h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400">
      {status === 'loading' ? (
        <ProjectListLoading />
      ) : (
        <>
          <ProjectListEmpty projects={unarchivedProjects} />
          <ProjectListContent projects={unarchivedProjects} isFetching={isFetching} />
        </>
      )}
    </div>
  );
};

export default ProjectList;
