import { useNavigate, useParams } from 'react-router-dom';

import Modal from '../../common/Modal';
import ClaimMerge from './ClaimMerge';

const ClaimMergeModal = ({ isHidden = true, onHide, claimId }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const hide = () => {
    !!onHide && onHide();
  };

  if (isHidden) {
    return null;
  }

  return (
    <Modal onClose={hide} size="lg" showClose={false}>
      <div className="flex min-h-[300px] overflow-hidden rounded-2xl bg-white shadow-lg shadow-slate-400">
        <div className="w-3 shrink-0 bg-sky-900"></div>
        <div className="grow">
          <ClaimMerge
            onCancel={hide}
            onSuccess={(mergedClaim) => navigate(`/projects/${projectId}/claims/${mergedClaim.id}`)}
            claimId={claimId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ClaimMergeModal;
