import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

/**
 * The `DropdownHeader` component renders a section heading within a dropdown menu.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const DropdownHeader = ({ className, name, ...props }) => {
  return (
    <h6 className={classNames('px-2 py-1 text-sm font-bold text-sky-900', className)} {...props}>
      {name}
    </h6>
  );
};

/**
 * The `DropdownDivider` component renders a section separator within a dropdown menu.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const DropdownDivider = ({ className, ...props }) => {
  return <hr className={classNames('my-1', className)} {...props} />;
};

/**
 * The `DropdownLink` component renders a menu item within a dropdown menu.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const DropdownLink = ({ className, name, ...props }) => {
  return (
    <Link className={classNames('block py-1 px-2 hover:bg-slate-200', className)} {...props}>
      {name}
    </Link>
  );
};

/**
 * The `Dropdown` component renders a dropdown menu with the supplied `content`
 * items upon clicking the `toggle`.
 * @param {Object} perops The component properties.
 * @returns JSX
 */
const Dropdown = ({ content = [], contentClasses, toggle }) => {
  const [showTarget, setShowTarget] = useState(false);

  /**
   * Returns JSX representation of a dropdown content object.
   * @param {Object} content A dropdown content object.
   * @returns JSX
   */
  const formatContent = (content) => {
    switch (content.type) {
      case 'link':
        return <DropdownLink {...content} />;
      case 'divider':
        return <DropdownDivider {...content} />;
      case 'header':
        return <DropdownHeader {...content} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Dropdown backdrop */}
      <div
        className={classNames(
          { 'absolute top-0 left-0 z-40 h-screen w-screen': showTarget },
          { hidden: !showTarget },
        )}
        onClick={() => setShowTarget(false)}
      ></div>
      {/* Dropdown */}
      <div className="relative">
        {/* Dropdown Toggler */}
        <div onClick={() => setShowTarget(!showTarget)}>{toggle}</div>
        {/* Dropdown Target Content */}
        <div
          className={classNames(
            'absolute z-50 m-1 min-w-[160px] rounded-lg bg-white py-2 shadow-lg shadow-slate-600',
            {
              hidden: !showTarget,
            },
            contentClasses,
          )}
          onClick={() => setShowTarget(false)}
        >
          <ul>
            {content.map((item, index) => (
              <li key={index}>{formatContent(item)}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
