import classNames from 'classnames';

/**
 * The `ButtonBar` component renders a container for one or more `Button` children.
 * Ensures buttons are aligned within the bar.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const ButtonBar = ({ className, children }) => {
  return <div className={classNames('flex items-center', className)}>{children}</div>;
};

export default ButtonBar;
