/**
 * The `ProgressRing` component renders a circular SVG image which depicts
 * the percent complete of any value.
 * @param {Object} props The component properties.
 * @returns SVG
 */
const ProgressRing = ({ className, progress = 0 }) => {
  const strokeDashoffset = 100 - progress;

  return (
    <svg viewBox="0 0 36 36" className={className}>
      <g strokeWidth={6}>
        <circle stroke="#0c4a6e50" fill="none" r="15" cx="18" cy="18" />
        <circle
          stroke="#0c4a6e"
          fill="none"
          strokeDasharray="100 100"
          r="15"
          cx="18"
          cy="18"
          style={{
            strokeDashoffset,
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
        />
      </g>
    </svg>
  );
};

export default ProgressRing;
