import { useParams } from 'react-router-dom';

import Loading from '../common/Loading';
import AssetCreate from './create/AssetCreate';
import AssetEdit from './edit/AssetEdit';

import { useGetAsset } from '../../hooks/asset.hooks';

const AssetSection = () => {
  const { projectId } = useParams();
  const { data: asset, status } = useGetAsset({ projectId }, { retry: false });

  if (status === 'loading') {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div
      id="asset-section"
      className="mt-4 h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      {!!asset ? <AssetEdit /> : <AssetCreate />}
    </div>
  );
};

export default AssetSection;
