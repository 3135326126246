import { useParams } from 'react-router-dom';

import Loading from '../../common/Loading';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { useGetClaim, useUnmergeClaim } from '../../../hooks/claims.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ClaimUnmerge = ({ onCancel, onSuccess, mergedId, standaloneId }) => {
  const toastsContext = useToastsContext();
  const unmergeClaim = useUnmergeClaim();
  const { projectId } = useParams();
  const { data: mergedClaim, isLoading: isLoadingMergedClaim } = useGetClaim({
    projectId,
    claimId: mergedId,
  });
  const { data: standaloneClaim, isLoading: isLoadingStandaloneClaim } = useGetClaim({
    projectId,
    claimId: standaloneId,
  });

  const unmerge = () => {
    unmergeClaim.mutate(
      { projectId, mergedId, standaloneId },
      {
        onSuccess: () => {
          toastsContext.createToast('Updated claims successfully.');
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to unmerge claim. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoadingMergedClaim || isLoadingStandaloneClaim) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">UNMERGE CLAIM</h3>

      {mergedClaim.claims.length > 2 ? (
        <>
          <div className="mb-4">
            You are about to unmerge claim <span className="italic">{standaloneClaim.name}</span>{' '}
            from the merged claim group <span className="italic">{mergedClaim.name}</span>.
          </div>

          <div className="mb-4">
            The claim will be restored to its state prior to merging and the merged group will be
            flagged for review. Proceed?
          </div>
        </>
      ) : (
        <>
          <div className="mb-4">
            You are about to dissolve the merged claim group{' '}
            <span className="italic">{mergedClaim.name}</span>.
          </div>

          <div className="mb-4">
            Both claims within the group will be restored to their state prior to merging and the
            merged group will be deleted. Proceed?
          </div>
        </>
      )}

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={unmergeClaim.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={unmerge}
          disabled={unmergeClaim.isLoading}
          isLoading={unmergeClaim.isLoading}
        >
          UNMERGE
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default ClaimUnmerge;
