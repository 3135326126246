import classNames from 'classnames';

import Icon from './Icon';

/**
 * Returns the CSS classes for the component color.
 * @param {string} variant The variant name.
 * @returns {string} CSS classes.
 */
const getColorClasses = (variant) => {
  switch (variant) {
    case 'secondary':
      return 'bg-sky-300/50';
    case 'info':
      return 'bg-fuchsia-500/50';
    default:
      return 'bg-sky-400/50';
  }
};

/**
 * The `NoContent` component returns a standard block to display when there are
 * no user data values to display.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const NoContent = ({ children, variant }) => {
  return (
    <div className="flex flex-col items-center">
      <div className={classNames('rounded-full p-6', getColorClasses(variant))}>
        <Icon icon="file" className="h-14 w-14 text-white/80" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default NoContent;
