import EvidenceClaimListMenu from './EvidenceClaimListMenu';
import EvidenceClaimListItem from './EvidenceClaimListItem';
import EvidenceClaimListHeader from './EvidenceClaimListHeader';
import EvidenceClaimListFooter from './EvidenceClaimListFooter';

import { selectClaimsWithListControls } from '../../../../selectors/claims.selectors';
import { useClaimListControlsContext } from '../../../../hooks/listcontrols.hooks';

const EvidenceClaimListContent = ({ evidence, claims, projectId }) => {
  const { data: listControls } = useClaimListControlsContext();

  if (claims.length === 0) {
    return null;
  }

  const { data: allClaims, page } = selectClaimsWithListControls(claims, listControls);

  return (
    <>
      <EvidenceClaimListMenu evidence={evidence} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <EvidenceClaimListHeader />

        {page.map((claim, index) => (
          <EvidenceClaimListItem
            key={claim.id}
            claim={claim}
            evidence={evidence}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <EvidenceClaimListFooter totalItemsOnPage={page.length} totalItems={allClaims.length} />
        </div>
      </div>
    </>
  );
};

export default EvidenceClaimListContent;
