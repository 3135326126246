import { Form } from 'formik';
import keys from 'lodash/keys';

import InputField from '../../common/InputField';
import SelectField from '../../common/SelectField';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { ProjectStage, ProjectStatus } from '../../../utils/constants';

const ProjectForm = ({ formik, onCancel }) => {
  return (
    <Form>
      <div className="grid grid-cols-6 gap-x-8 gap-y-4 rounded-2xl bg-white p-8">
        <div className="col-span-2">
          <InputField
            name="name"
            label="Name"
            type="text"
            placeholder="e.g. Sigma Formulation"
            disabled={formik.isSubmitting}
            tooltip="IQVIA Project Name (e.g., from CRM)"
          />
        </div>

        <div className="col-span-2">
          <InputField
            name="client"
            label="Client"
            type="text"
            placeholder="e.g. Sigma Pharmaceutical Inc."
            disabled={formik.isSubmitting}
            tooltip="Company Name"
          />
        </div>

        <div>
          <SelectField name="status" label="Status" disabled={formik.isSubmitting}>
            {keys(ProjectStatus).map((key, index) => (
              <option key={index} value={key}>
                {ProjectStatus[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div>
          <SelectField name="stage" label="Stage" disabled={formik.isSubmitting}>
            {keys(ProjectStage).map((key, index) => (
              <option key={index} value={key}>
                {ProjectStage[key]}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="col-span-2">
          <InputField
            name="projectLead"
            label="Project Lead"
            type="text"
            placeholder="e.g. Jordan Miller"
            disabled={formik.isSubmitting}
            tooltip="IQVIA Project Lead"
          />
        </div>

        <div className="col-start-5">
          <InputField name="startedAt" label="Start" type="date" disabled={formik.isSubmitting} />
        </div>

        <div>
          <InputField name="dueAt" label="Due" type="date" disabled={formik.isSubmitting} />
        </div>

        <div className="col-span-6">
          <ButtonBar className="mt-8 justify-end">
            {!!onCancel && (
              <Button
                variant="transparent"
                type="button"
                disabled={formik.isSubmitting}
                onClick={onCancel}
              >
                CANCEL
              </Button>
            )}
            {formik.dirty && (
              <LoadingButton
                variant="secondary"
                type="submit"
                className="ml-12 w-32 rounded px-6"
                disabled={formik.isSubmitting}
                isLoading={formik.isSubmitting}
              >
                SAVE
              </LoadingButton>
            )}
          </ButtonBar>
        </div>
      </div>
    </Form>
  );
};

export default ProjectForm;
