import Icon from '../../../common/Icon';
import NumberIcon from '../../../common/NumberIcon';

import { selectEvidence } from '../../../../selectors/evidence.selectors';

const ScenarioClaim = ({ claim, evidenceList }) => {
  if (!claim) return null;

  const selectedEvidence = selectEvidence(evidenceList);

  return (
    <div className="mb-4 rounded-md bg-sky-900/5 p-4">
      <div className="flex flex-col">
        <div className="flex items-center">
          <span className="fa-layers fa-fw mr-1" title="Claim">
            <Icon icon="square" />
            <Icon icon="c" transform="shrink-6" />
          </span>
          <div className="mr-2 font-bold">{claim.name}</div>
          <NumberIcon number={claim.overallScore} className="mr-1" title="Claim Score" />
          {claim.type.includes('clinical') && (
            <Icon
              icon="stethoscope"
              className="mr-1 inline-block h-3.5 w-3.5 text-slate-600"
              title="Clinical"
            />
          )}
          {claim.type.includes('commercial') && (
            <Icon
              icon="landmark"
              className="mr-1 inline-block h-3.5 w-3.5 text-slate-600"
              title="Commercial"
            />
          )}
        </div>

        {selectedEvidence.map((evidence) => (
          <div
            key={evidence.id}
            className="my-0.5 ml-6 flex items-center rounded-md bg-white/20 p-1"
          >
            <span className="fa-layers fa-fw mr-1" title="Evidence">
              <Icon icon="square" />
              <Icon icon="e" transform="shrink-6" />
            </span>
            <div className="text-sm">{evidence.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScenarioClaim;
