import { useEffect } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Icon from '../common/Icon';
import Loading from '../common/Loading';
import ProjectMenu from '../projects/common/ProjectMenu';

import { ProjectStage, ProjectStatus } from '../../utils/constants';
import { useAuthState } from '../../hooks/cognito.hooks';
import { useGetProject, useSetLatestProject } from '../../hooks/projects.hooks';

const ProjectPage = () => {
  const { projectId } = useParams();
  const { data: project, status } = useGetProject(projectId);
  const { data: authState } = useAuthState();
  const setLatestProject = useSetLatestProject();

  useEffect(() => {
    document.title = `Project ${project?.name} | IQVIA Design Center`;
  }, [project]);

  useEffect(() => {
    setLatestProject.mutate({ accountId: authState.sub, projectId });
    // eslint-disable-next-line
  }, [authState, projectId]);

  if (status === 'loading') {
    return (
      <div className="flex h-1/4 w-screen items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div id="project" className="py-8 px-16">
      <div className="rounded-xl bg-gradient-to-r from-transparent to-white/30 py-0.5">
        {/* Project Heading */}
        <div className="mb-4 flex items-center text-sky-900">
          <Link to="/projects">
            <Icon icon="arrow-left" className="mr-2 h-5 w-5" />
          </Link>
          <h2 className="mr-6 text-2xl">
            PROJECT <span className="font-bold uppercase">{project.name}</span>
          </h2>
        </div>

        {/* Project Summary */}
        <div className="mb-4 flex items-center font-bold text-sky-900">
          <div className="mr-12">
            <div className="text-sm text-sky-900/70">Client</div>
            <div>{project.client}</div>
          </div>
          <div className="mr-12">
            <div className="text-sm text-sky-900/70">Project Lead</div>
            <div>{project.projectLead}</div>
          </div>
          <div className="mr-12">
            <div className="text-sm text-sky-900/70">Status</div>
            <div>{ProjectStatus[project.status]}</div>
          </div>
          <div className="mr-12">
            <div className="text-sm text-sky-900/70">Stage</div>
            <div>{ProjectStage[project.stage]}</div>
          </div>
          <div className="mr-12">
            <div className="text-sm text-sky-900/70">Due Date</div>
            <div>{dayjs(project.dueAt).format('MM-DD-YYYY')}</div>
          </div>
          <div className="mr-12">
            <div className="text-sm text-sky-900/70">Last Updated</div>
            <div>{dayjs(project.updatedAt).format('MM-DD-YYYY')}</div>
          </div>
        </div>
      </div>

      {/* Tabbed Project Sections */}
      <div className="mt-8">
        {/* Project Sub-Menu */}
        <ProjectMenu
          items={[
            {
              name: 'PROJECT',
              to: `/projects/${project.id}/edit`,
              tooltip: 'Manage project details',
            },
            {
              name: 'ASSET',
              to: `/projects/${project.id}/asset`,
              tooltip: 'Asset, indication, and therapeutical areas',
            },
            {
              name: 'SCENARIOS',
              to: `/projects/${project.id}/scenarios`,
              tooltip: 'Manage project scenarios; time, risk, cost',
            },
            {
              name: 'CLAIMS',
              to: `/projects/${project.id}/claims`,
              tooltip: 'Manage clinical and commercial claims',
            },
            {
              name: 'EVIDENCE',
              to: `/projects/${project.id}/evidence`,
              tooltip: 'Manage evidence',
            },
            {
              name: 'TRIALS',
              to: `/projects/${project.id}/trials`,
              tooltip: 'Manage trial designs',
            },
          ]}
        />

        {/* Project Selected Sub-Menu Content */}
        <div className="mt-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
