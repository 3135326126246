import { Outlet } from 'react-router-dom';

import { ClaimListControlsContextProvider } from '../../contexts/listcontrols.context';

const ClaimsSection = () => {
  return (
    <div
      id="claims-section"
      className="mt-4 h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      <ClaimListControlsContextProvider
        initialState={{
          matches: { isArchived: false },
          sort: { by: 'name' },
          pagination: { size: 9 },
        }}
      >
        <Outlet />
      </ClaimListControlsContextProvider>
    </div>
  );
};

export default ClaimsSection;
