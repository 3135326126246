import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../common/Loading';
import NoContent from '../../common/NoContent';
import Button from '../../common/Button';
import EvidenceListMenu from '../list/EvidenceListMenu';
import EvidenceBoardColumns from './EvidenceBoardColumns';

import { useGetClaims } from '../../../hooks/claims.hooks';
import { useGetAllEvidence } from '../../../hooks/evidence.hooks';
import { selectEvidenceWithPagination } from '../../../selectors/evidence.selectors';
import { useEvidenceListControlsContext } from '../../../hooks/listcontrols.hooks';

const EvidenceBoardLoading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    </div>
  );
};

const EvidenceBoardEmpty = ({ evidenceList = [] }) => {
  const navigate = useNavigate();

  if (evidenceList.length > 0) {
    return null;
  }

  return (
    <>
      <EvidenceListMenu isFetching={false} />

      <div className="mt-4 flex h-[calc(100%-24px)] items-center justify-center">
        <NoContent variant="primary">
          <div className="mt-2 flex flex-col items-center text-slate-700">
            <div>You don't have any Evidence yet.</div>
            <div className="mt-2 text-xs">Create a new one by clicking the button below.</div>
            <Button
              size="sm"
              variant="secondary rounded"
              className="mt-4 w-48"
              onClick={() => navigate('../create')}
            >
              CREATE EVIDENCE
            </Button>
          </div>
        </NoContent>
      </div>
    </>
  );
};

const EvidenceBoardHeader = () => {
  return (
    <div className="grid grid-cols-5 gap-4 font-bold text-sky-900">
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Priority Claims</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Evidence</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Linked Evidence</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Non-Evidence</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Evidence Plans</div>
      </div>
    </div>
  );
};

const EvidenceBoardContent = ({ claims = [], evidenceList = [], isFetching }) => {
  if (evidenceList.length === 0) {
    return null;
  }

  return (
    <>
      <EvidenceListMenu isFetching={isFetching} />

      <div className="mt-4 flex h-[calc(100%-24px)] flex-col rounded-lg bg-slate-200/20">
        <EvidenceBoardHeader />
        <EvidenceBoardColumns claims={claims} evidenceList={evidenceList} />
      </div>
    </>
  );
};

const EvidenceBoard = () => {
  const { projectId } = useParams();
  const { data: listControls } = useEvidenceListControlsContext();
  const {
    data: evidenceList,
    isLoading: isLoadingEvidence,
    isFetching,
  } = useGetAllEvidence({ projectId });
  const { data: claims, isLoading: isLoadingClaims } = useGetClaims({ projectId });

  const { data: selectedEvidence } = selectEvidenceWithPagination(evidenceList, listControls);

  return (
    <div id="evidence-board" className="h-full">
      {isLoadingEvidence || isLoadingClaims ? (
        <EvidenceBoardLoading />
      ) : (
        <>
          <EvidenceBoardEmpty evidenceList={selectedEvidence} />
          <EvidenceBoardContent
            claims={claims}
            evidenceList={selectedEvidence}
            isFetching={isFetching}
          />
        </>
      )}
    </div>
  );
};

export default EvidenceBoard;
