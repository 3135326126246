import { useState } from 'react';

import Loading from '../../common/Loading';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { useAuthState } from '../../../hooks/cognito.hooks';
import { useImportProject } from '../../../hooks/projects.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ProjectImport = ({ onCancel, onSuccess }) => {
  const [fileName, setFileName] = useState();
  const [fileContent, setFileContent] = useState();
  const toastsContext = useToastsContext();
  const importProject = useImportProject();
  const { data: authState, isLoading } = useAuthState();

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const onFileChange = (file) => {
    const fileReader = new FileReader();
    const doImport = () => {
      const fileName = file.name;
      const fileContent = JSON.parse(fileReader.result);
      setFileName(fileName);
      setFileContent(fileContent);
    };
    fileReader.onloadend = doImport;
    fileReader.readAsText(file);
  };

  const doImport = () => {
    importProject.mutate(
      { accountId: authState.sub, project: fileContent },
      {
        onSuccess: (project) => {
          toastsContext.createToast(`Imported project ${project.name}.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to import project. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">IMPORT PROJECT</h3>

      {fileName ? (
        <>
          <div className="mb-4">
            You are about to import a project from file <span className="italic">{fileName}</span>.
          </div>

          <div className="mb-4">Proceed?</div>

          <ButtonBar className="mt-auto ml-auto">
            <Button
              type="button"
              variant="primary"
              className="w-48 rounded"
              onClick={onCancel}
              disabled={importProject.isLoading}
            >
              CANCEL
            </Button>
            <LoadingButton
              type="button"
              variant="secondary"
              className="ml-4 w-48 rounded"
              onClick={doImport}
              disabled={!fileContent || importProject.isLoading}
              isLoading={importProject.isLoading}
            >
              IMPORT
            </LoadingButton>
          </ButtonBar>
        </>
      ) : (
        <>
          <div className="mb-4">Select a project file to begin.</div>

          <div className="mb-4">
            <label
              htmlFor="projectFile"
              className="block w-48 cursor-pointer rounded border-2 border-sky-900 bg-sky-900 py-2 text-center font-bold uppercase text-white hover:border-sky-700 hover:bg-sky-700"
            >
              SELECT FILE
            </label>
            <input
              id="projectFile"
              name="projectFile"
              type="file"
              accept=".json"
              className="hidden"
              onChange={(e) => onFileChange(e.target.files[0])}
            />
            <div>{fileName}</div>
          </div>

          <ButtonBar className="mt-auto ml-auto">
            <Button type="button" variant="primary" className="w-48 rounded" onClick={onCancel}>
              CANCEL
            </Button>
          </ButtonBar>
        </>
      )}
    </div>
  );
};

export default ProjectImport;
