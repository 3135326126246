import { Draggable } from 'react-beautiful-dnd';

import ClaimCard from './ClaimCard';

const DraggableClaimCard = ({ claim, index, ...props }) => {
  if (!claim) return null;

  const draggableId = `${claim.id}|${claim.type.includes('merged') ? 'merged' : 'standalone'}`;

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <ClaimCard claim={claim} {...props} />
        </div>
      )}
    </Draggable>
  );
};

export default DraggableClaimCard;
