import { Formik } from 'formik';

import Loading from '../../common/Loading';
import ProjectForm from '../common/ProjectForm';

import { projectSchema } from '../../../validations/project.validations';
import { useGetProject, useUpdateProject } from '../../../hooks/projects.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ProjectEdit = ({ onCancel, onSuccess, projectId }) => {
  const { data: project, status } = useGetProject(projectId);
  const updateProject = useUpdateProject();
  const toastsContext = useToastsContext();

  if (status === 'loading') {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div id="project-edit">
      <Formik
        initialValues={{ ...project }}
        enableReinitialize={true}
        validationSchema={projectSchema}
        onSubmit={(values, { setSubmitting }) => {
          updateProject.mutate(
            { ...project, ...values },
            {
              onSuccess: (project) => {
                setSubmitting(false);
                toastsContext.createToast('Updated project successfully.');
                onSuccess && onSuccess(project);
              },
              onError: (err) => {
                console.error(`Failed to update project. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <ProjectForm formik={formik} onCancel={onCancel} />}
      </Formik>
    </div>
  );
};

export default ProjectEdit;
