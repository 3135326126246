import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import ContentBox from '../../common/ContentBox';
import Dropdown from '../../common/Dropdown';
import { Stars } from '../../common/StarField';
import ScoreRing from './ScoreRing';
import Label from '../../common/Label';

const ClaimCard = ({ claim, className, variant }) => {
  const { projectId } = useParams();
  if (!claim) {
    return null;
  }

  const getMenuContent = () => {
    const menu = [
      { type: 'header', name: 'ACTIONS', className: 'text-xs' },
      {
        type: 'link',
        name: 'Edit',
        title: 'Edit Claim',
        to: `/projects/${projectId}/claims/${claim.id}`,
        className: 'text-xs',
      },
    ];

    if (!claim.type.includes('merged')) {
      menu.push({
        type: 'link',
        name: 'Merge',
        title: 'Merge Claim',
        to: `?mergeClaim=${claim.id}`,
        className: 'text-xs',
      });
    }

    menu.push({
      type: 'link',
      name: 'Link Evidence',
      title: 'Link Evidence with Claim',
      to: `?linkEvidence=${claim.id}`,
      className: 'text-xs',
    });

    return menu;
  };

  return (
    <div className={classNames('mb-3', className)}>
      <ContentBox
        variant={variant}
        contentClasses="py-2 px-2 h-36"
        className={classNames({ 'bg-amber-200/50': claim.isReviewNeeded })}
      >
        <div className="flex h-full flex-col">
          <div className="mb-2 flex h-full justify-between">
            <div className="flex flex-col">
              <div className="font-bold leading-tight text-slate-700">{claim.name}</div>
              <div className="mt-auto">
                {/* Labels */}
                {claim.type.includes('merged') && <Label variant="purple">Merged</Label>}
                {claim.type.includes('commercial') && <Label variant="amber">Commercial</Label>}
                {claim.type.includes('clinical') && <Label>Clinical</Label>}
              </div>
            </div>
            <div className="ml-1">
              <Dropdown
                toggle={
                  <div className="mx-1 flex cursor-pointer items-center justify-center rounded hover:bg-slate-500/20">
                    <Icon
                      icon="ellipsis-vertical"
                      className="h-4 w-4 cursor-pointer text-slate-700"
                    />
                  </div>
                }
                content={getMenuContent()}
                contentClasses="top-5 right-0 text-sky-900"
              />
            </div>
          </div>
          <div className="mt-auto mr-1 flex items-center">
            <div className="mr-4">
              <div className="text-xs font-bold text-slate-500">SCORE</div>
              <Stars count={5} value={claim.overallScore} />
            </div>
            {claim.isReviewNeeded && (
              <Icon icon="flag" className="h-4 w-4 self-end text-red-500" title="Review Needed" />
            )}
            {claim.isArchived && (
              <Icon icon="box" className="h-4 w-4 self-end text-slate-600" title="Archived" />
            )}
            <div className="ml-auto flex items-center">
              <div className="mr-2 flex flex-col items-center">
                <div className="text-xs font-bold text-slate-500">RISK</div>
                <div>
                  <ScoreRing score={claim.riskScore} />
                </div>
              </div>
              <div className="mr-2 flex flex-col items-center">
                <div className="text-xs font-bold text-slate-500">COST</div>
                <div>
                  <ScoreRing score={claim.costScore} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-xs font-bold text-slate-500">TIME</div>
                <div>
                  <ScoreRing score={claim.timeScore} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default ClaimCard;
