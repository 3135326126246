import { useEffect } from 'react';

import Icon from './Icon';

import { Configuration } from '../../utils/config';

/**
 * The `Toast` component renders a single user notification on screen.
 * The toast is auto-dismissed, i.e. removed from the DOM, after a
 * number of milliseconds (see app configuration).
 * @see The {@link Toasts} component for more information.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Toast = ({ id, message, onDismiss }) => {
  useEffect(() => {
    const config = Configuration.get();
    const timeoutId = setTimeout(() => {
      onDismiss(id);
    }, config.app.toastDismissMillis);
    return () => clearTimeout(timeoutId);
  }, [id, onDismiss]);

  return (
    <div className="mb-4 flex w-[350px] items-start justify-between rounded-lg border border-sky-900 bg-white p-4 text-sm text-sky-900 shadow-lg shadow-slate-600 last:mb-0">
      <div>{message}</div>
      <button type="button" onClick={() => onDismiss(id)}>
        <Icon icon="xmark" className="ml-1 h-5 w-5 shrink-0" />
      </button>
    </div>
  );
};

export default Toast;
