import ScenarioIndicationListMenu from './ScenarioIndicationListMenu';
import ScenarioIndicationListItem from './ScenarioIndicationListItem';
import ScenarioIndicationListHeader from './ScenarioIndicationListHeader';
import ScenarioIndicationListFooter from './ScenarioIndicationListFooter';

import { selectIndicationsWithListControls } from '../../../../selectors/indications.selectors';
import { useIndicationListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ScenarioIndicationListContent = ({ scenario, indications = [], projectId }) => {
  const { data: listControls } = useIndicationListControlsContext();

  if (indications.length === 0) {
    return null;
  }

  const { data: allIndications, page } = selectIndicationsWithListControls(
    indications,
    listControls,
  );

  return (
    <>
      <ScenarioIndicationListMenu scenario={scenario} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <ScenarioIndicationListHeader />

        {page.map((indication, index) => (
          <ScenarioIndicationListItem
            key={indication.id}
            indication={indication}
            scenario={scenario}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <ScenarioIndicationListFooter
            totalItemsOnPage={page.length}
            totalItems={allIndications.length}
          />
        </div>
      </div>
    </>
  );
};

export default ScenarioIndicationListContent;
