import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createProject,
  exportProject,
  getLatestProject,
  getProject,
  getProjects,
  importProject,
  setLatestProject,
  updateProject,
  removeProject,
} from '../api/projects.api';
import { QueryKeys } from '../utils/constants';
import { useNotify } from './notifications.hooks';

/**
 * React query hook to fetch all `Projects` for an account.
 * @param {string} accountId An account identifier.
 * @param {string} projectId A project identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetProjects = (accountId, options) => {
  return useQuery([QueryKeys.Projects, accountId], () => getProjects(accountId), options);
};

/**
 * React query hook to fetch a single `Project`.
 * @param {string} id A project identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetProject = (id, options) => {
  return useQuery([QueryKeys.Projects, id], () => getProject(id), options);
};

/**
 * React query hook to fetch the latest `Project` accessed by a user.
 * @param {string} accountId An account identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetLatestProject = (accountId, options) => {
  return useQuery([QueryKeys.ProjectLatest, accountId], () => getLatestProject(accountId), {
    ...options,
    retry: false,
  });
};

/**
 * React query mutation hook to set the latest `Project` accessed by a user.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useSetLatestProject = (options) => {
  const queryClient = useQueryClient();
  return useMutation(setLatestProject, {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.ProjectLatest]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to create a `Project`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCreateProject = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(createProject, {
    ...options,
    onSuccess: (data) => {
      notify({ text: `Created project ${data.name}.` });
      queryClient.invalidateQueries([QueryKeys.Projects]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to update a `Project`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUpdateProject = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(updateProject, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Projects, data.id], data);
      notify({ text: `Updated project ${data.name}.` });
      queryClient.invalidateQueries([QueryKeys.Projects]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to remove a `Project`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useRemoveProject = (options) => {
  const queryClient = useQueryClient();
  return useMutation(removeProject, {
    ...options,
    onSuccess: (data, vars) => {
      queryClient.removeQueries([QueryKeys.Projects, vars]);
      queryClient.invalidateQueries([QueryKeys.Projects]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to export a `Project` to a file.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useExportProject = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(exportProject, {
    ...options,
    onSuccess: (data) => {
      notify({ text: `Exported project file ${data}.` });
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to import a `Project` from a file.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useImportProject = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(importProject, {
    ...options,
    onSuccess: (data) => {
      notify({ text: `Imported project ${data.name}.` });
      queryClient.invalidateQueries([QueryKeys.Projects]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
