import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import reject from 'lodash/reject';

import Loading from '../../common/Loading';
import SelectField from '../../common/SelectField';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { selectClaims, selectNotMerged } from '../../../selectors/claims.selectors';
import { mergeClaimSchema } from '../../../validations/claim.validations';
import { useGetClaim, useGetClaims, useMergeClaim } from '../../../hooks/claims.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ClaimMerge = ({ onCancel, onSuccess, claimId }) => {
  const toastsContext = useToastsContext();
  const mergeClaim = useMergeClaim();
  const { projectId } = useParams();
  const { data: sourceClaim, isLoading: isLoadingSourceClaim } = useGetClaim({
    projectId,
    claimId,
  });
  const { data: claims, isLoading: isLoadingClaims } = useGetClaims({ projectId });
  let targetClaims = selectClaims(selectNotMerged(claims), { isArchived: false });
  targetClaims = reject(targetClaims, { id: claimId });

  if (isLoadingSourceClaim || isLoadingClaims) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <Formik
        initialValues={{ targetId: '' }}
        validationSchema={mergeClaimSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { targetId } = values;
          // mergeClaim mutator
          mergeClaim.mutate(
            { projectId, sourceId: claimId, targetId },
            {
              onSuccess: (mergedClaim) => {
                toastsContext.createToast(`Merged claims.`);
                setSubmitting(false);
                onSuccess && onSuccess(mergedClaim);
              },
              onError: (err) => {
                console.error(`Failed to merge claims. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <h3 className="mb-6 text-2xl font-bold">MERGE CLAIMS</h3>

            <div className="mb-4">
              You are about to merge claim <span className="italic">{sourceClaim.name}</span> with
              another claim.
            </div>

            <div>Select the claim with which you wish to merge:</div>
            <div className="mb-6">
              <SelectField name="targetId" disabled={formik.isSubmitting}>
                <option value="">Select a claim...</option>
                {targetClaims.map((claim) => (
                  <option key={claim.id} value={claim.id}>
                    {claim.name}
                  </option>
                ))}
              </SelectField>
            </div>

            <ButtonBar className="mt-auto ml-auto">
              <Button
                type="button"
                variant="primary"
                className="w-48 rounded"
                onClick={onCancel}
                disabled={mergeClaim.isLoading}
              >
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                variant="secondary"
                className="ml-4 w-48 rounded"
                disabled={mergeClaim.isLoading}
                isLoading={mergeClaim.isLoading}
              >
                MERGE
              </LoadingButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimMerge;
