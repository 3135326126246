import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Configuration } from './utils/config';
import { checkTokens } from './api/cognito.api';

// render at most once
let hasRendered = false;
/**
 * Render the React application.
 */
const renderApplication = () => {
  if (!hasRendered) {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);
  }
  hasRendered = true;
};

/**
 * Perform application startup activities.
 */
const startApplication = async () => {
  try {
    await Configuration.init();
    await checkTokens();
    renderApplication();
  } catch (err) {
    console.error('A fatal exception occurred starting the application.', err);
  }
};

// Start the React application.
startApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
