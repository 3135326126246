import Badge from './Badge';

/**
 * Returns the `Badge` variant value for the supplied status.
 * @param {string} status The status value.
 * @returns {string} The badge variant value.
 */
const getVariant = (status) => {
  switch (status) {
    case 'notstarted':
      return 'default';
    case 'inprogress':
      return 'primary';
    case 'complete':
      return 'success';
    default:
      return 'default';
  }
};

/**
 * The `StatusBadge` component extends `Badge`. Renders a Badge
 * whose `variant` is determined by the status value.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const StatusBadge = ({ status, ...props }) => {
  return <Badge variant={getVariant(status)} {...props} />;
};

export default StatusBadge;
