import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import ScenarioForm from '../common/ScenarioForm';
import Icon from '../../common/Icon';

import { scenarioSchema } from '../../../validations/scenario.validations';
import { useCreateScenario } from '../../../hooks/scenarios.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ScenarioCreate = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const createScenario = useCreateScenario();
  const toastsContext = useToastsContext();

  return (
    <div id="scenario-create" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">CREATE NEW SCENARIO</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Formik
          initialValues={{
            name: '',
            type: '',
            owner: '',
            regulatoryDesignation: '',
            indications: [],
            status: '',
            rangesMode: '',
            targetRegions: '',
            patientSegment: '',
          }}
          validationSchema={scenarioSchema}
          onSubmit={(values, { setSubmitting }) => {
            createScenario.mutate(
              { projectId, scenario: values },
              {
                onSuccess: (scenario) => {
                  setSubmitting(false);
                  toastsContext.createToast(`Created project scenario.`);
                  navigate(`/projects/${projectId}/scenarios`);
                },
                onError: (err) => {
                  console.error(`Failed to create project scenario. Detail:`, err);
                  // TODO display error notification
                  setSubmitting(false);
                },
              },
            );
          }}
        >
          {(formik) => <ScenarioForm formik={formik} />}
        </Formik>
      </div>
    </div>
  );
};

export default ScenarioCreate;
