import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from '../../../common/Dropdown';
import Icon from '../../../common/Icon';

const ScenarioClaimListItem = ({ scenario, claim, projectId, highlight }) => {
  const [searchParams] = useSearchParams();

  if (!claim) return null;

  return (
    <div
      className={classNames('grid grid-cols-12 items-center px-2 py-3 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-10 truncate">
        <Link to={`/projects/${projectId}/claims/${claim.id}`} className="hover:text-sky-700">
          {claim.name}
        </Link>
      </div>
      <div className="col-span-1">
        {claim.type.includes('clinical') && (
          <Icon
            icon="stethoscope"
            className="mr-1 inline-block h-4 w-4 text-slate-600"
            title="Clinical"
          />
        )}
        {claim.type.includes('commercial') && (
          <Icon
            icon="landmark"
            className="mr-1 inline-block h-4 w-4 text-slate-600"
            title="Commercial"
          />
        )}
        {claim.type.includes('merged') && (
          <Icon
            icon="code-merge"
            className="mr-1 inline-block h-4 w-4 text-slate-600"
            title="Merged"
          />
        )}
      </div>
      <div>
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={[
            {
              type: 'link',
              name: 'Unlink Claim',
              title: 'Unlink Scenario and Claim',
              to: `?${searchParams}&unlinkScenarioClaim=${scenario.id}&claim=${claim.id}`,
              className: 'text-xs',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ScenarioClaimListItem;
