import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import Icon from '../../common/Icon';
import ClaimForm from '../common/ClaimForm';

import { claimSchema } from '../../../validations/claim.validations';
import { useCreateClaim } from '../../../hooks/claims.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ClaimCreate = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const createClaim = useCreateClaim();
  const toastsContext = useToastsContext();

  return (
    <div id="claim-create" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">CREATE NEW CLAIM</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Formik
          initialValues={{
            name: '',
            type: [],
            dueAt: '',
            details: '',
            riskScore: 0,
            timeScore: 0,
            costScore: 0,
            overallScore: 0,
            isPrioritized: false,
            isReviewNeeded: false,
            isArchived: false,
          }}
          validationSchema={claimSchema}
          onSubmit={(values, { setSubmitting }) => {
            createClaim.mutate(
              { projectId, claim: values },
              {
                onSuccess: (claim) => {
                  setSubmitting(false);
                  toastsContext.createToast(`Created project claim.`);
                  navigate(`/projects/${projectId}/claims`);
                },
                onError: (err) => {
                  console.error(`Failed to create project claim. Detail:`, err);
                  // TODO display error notification
                  setSubmitting(false);
                },
              },
            );
          }}
        >
          {(formik) => <ClaimForm formik={formik} />}
        </Formik>
      </div>
    </div>
  );
};

export default ClaimCreate;
