import { Formik } from 'formik';

import EvidenceForm from '../common/EvidenceForm';

import { evidenceSchema } from '../../../validations/evidence.validations';
import { useUpdateEvidence } from '../../../hooks/evidence.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const EvidenceDetail = ({ evidence, projectId }) => {
  const updateEvidence = useUpdateEvidence();
  const toastsContext = useToastsContext();

  return (
    <Formik
      initialValues={{ ...evidence }}
      validationSchema={evidenceSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        updateEvidence.mutate(
          { projectId, evidence: { ...evidence, ...values } },
          {
            onSuccess: (evidence) => {
              toastsContext.createToast(`Updated evidence.`);
              setSubmitting(false);
            },
            onError: (err) => {
              console.error(`Failed to update evidence. Detail:`, err);
              // TODO display error notification
              setSubmitting(false);
            },
          },
        );
      }}
    >
      {(formik) => <EvidenceForm formik={formik} />}
    </Formik>
  );
};

export default EvidenceDetail;
