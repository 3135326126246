import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Icon from '../common/Icon';

import { Configuration } from '../../utils/config';
import { useSignIn } from '../../hooks/cognito.hooks';

const SignIn = () => {
  const config = Configuration.get();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const signIn = useSignIn();
  // IdP authorization code
  const code = searchParams.get('code');

  useEffect(() => {
    document.title = 'Sign In | IQVIA Design Center';
  }, []);

  useEffect(() => {
    if (code) {
      signIn.mutate(
        { code },
        {
          onSuccess: (data) => {
            navigate('/dashboard');
          },
          onError: (err) => {
            console.error('Sign in failed.', err);
            // TODO display error notification to user
          },
        },
      );
    } else {
      const { clientId, domain, redirectUrl } = config.auth.cognito;
      const cognitoUrl = `https://${domain}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`;
      window.location.replace(cognitoUrl);
    }
    // eslint-disable-next-line
  }, [code]);

  return (
    <div className="flex items-center justify-center">
      <Icon icon="arrow-right-to-bracket" fade className="mr-2 h-6 w-6 text-sky-900" />
      <span className="text-2xl font-bold text-sky-900">SIGNING IN...</span>
    </div>
  );
};

export default SignIn;
