import classNames from 'classnames';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import NoContent from '../../common/NoContent';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import Dropdown from '../../common/Dropdown';

import { useGetScenarios } from '../../../hooks/scenarios.hooks';
import {
  selectScenarios,
  selectScenariosWithPagination,
} from '../../../selectors/scenarios.selectors';
import { useScenarioListControlsContext } from '../../../hooks/listcontrols.hooks';

const ScenarioListLoading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    </div>
  );
};

const ScenarioListEmpty = ({ scenarios = [] }) => {
  const navigate = useNavigate();

  if (scenarios.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="primary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You don't have any Scenarios yet.</div>
          <div className="mt-2 text-xs">Create a new one by clicking the button below.</div>
          <Button
            variant="secondary"
            className="mt-4 w-48 rounded"
            onClick={() => navigate('create')}
          >
            CREATE SCENARIO
          </Button>
        </div>
      </NoContent>
    </div>
  );
};

const ScenarioListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useScenarioListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const ScenarioListHeader = () => {
  return (
    <div className="grid grid-cols-12 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-3">
        <ScenarioListColumnHeading name="name" title="NAME" />
      </div>
      <div className="col-span-3">
        <ScenarioListColumnHeading name="type" title="TYPE" />
      </div>
      <div className="col-span-2">
        <ScenarioListColumnHeading name="owner" title="OWNER" />
      </div>
      <div className="col-span-1">
        <ScenarioListColumnHeading name="status" title="STATUS" />
      </div>
      <div className="col-span-1">
        <ScenarioListColumnHeading name="claims.length" title="CLAIMS" />
      </div>
      <div className="col-span-1">
        <ScenarioListColumnHeading name="trials.length" title="TRIALS" />
      </div>
      <div className="col-span-1"></div>
    </div>
  );
};

const ScenarioListFooter = ({ totalItemsOnPage, totalItems }) => {
  const { data: listControls, setPage } = useScenarioListControlsContext();
  let pageStart = 0;
  let pageEnd = 0;
  if (totalItems > 0) {
    pageStart = (listControls.pagination.page - 1) * listControls.pagination.size + 1;
    pageEnd = pageStart + (totalItemsOnPage - 1);
  }

  const totalPages = Math.ceil(totalItems / listControls.pagination.size);
  const isLastPage = listControls.pagination.page === totalPages;
  const pages = [];
  let pageIndex = isLastPage ? listControls.pagination.page - 2 : listControls.pagination.page - 1;
  while (pages.length < Math.min(totalPages, 3)) {
    if (pageIndex > 0 && pageIndex <= totalPages) {
      pages.push(pageIndex);
    }
    pageIndex++;
  }

  return (
    <div className="grid grid-cols-12 gap-1 py-3 px-2 text-sky-700">
      <div className="col-span-8 font-bold">
        Showing {pageStart}-{pageEnd} of {totalItems} scenarios
      </div>
      <div className="col-span-4 justify-self-end">
        <ButtonBar>
          <Button
            title="First Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(1)}
          >
            <Icon icon="chevron-left" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
          {pages.map((page) => (
            <Button
              key={page}
              title={`Page ${page}`}
              variant={listControls.pagination.page === page ? 'primary' : 'outline-primary'}
              size="sm"
              className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
              onClick={() => setPage(page)}
              disabled={listControls.pagination.page === page}
            >
              {page}
            </Button>
          ))}
          <Button
            title="Last Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(totalPages)}
          >
            <Icon icon="chevron-right" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
        </ButtonBar>
      </div>
    </div>
  );
};

const ScenarioListItem = ({ scenario, highlight }) => {
  const { projectId } = useParams();

  if (!scenario) {
    return null;
  }

  return (
    <div
      className={classNames('grid grid-cols-12 py-3 px-2 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-3 truncate">
        <Link to={`${scenario.id}`} className="hover:text-sky-700">
          {scenario.name}
        </Link>
      </div>
      <div className="col-span-3">{scenario.type}</div>
      <div className="col-span-2">{scenario.owner}</div>
      <div className="col-span-1">{scenario.status}</div>
      <div className="col-span-1">{scenario.claims.length}</div>
      <div className="col-span-1">{scenario.trials.length}</div>
      <div className="col-span-1">
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={[
            {
              type: 'link',
              name: 'Edit',
              title: 'Edit Scenario',
              to: `${scenario.id}`,
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'Link Claim',
              title: 'Link Claim with Scenario',
              to: `?linkScenarioClaim=${scenario.id}`,
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'Link Trial',
              title: 'Link Trial with Scenario',
              to: `?linkScenarioTrial=${scenario.id}`,
              className: 'text-xs',
            },
            {
              type: 'divider',
            },
            {
              type: 'link',
              name: 'Summary Report',
              title: 'View the Scenario Summary Report',
              to: `/reports/scenario-summary?project=${projectId}&scenario=${scenario.id}`,
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'SCET Report',
              title: 'View the Scenario SCET Report',
              to: `/reports/scenario-scet?project=${projectId}&scenario=${scenario.id}`,
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'Hierarchy Report',
              title: 'View the Scenario Hierarchy Report',
              to: `/reports/scenario-hierarchy?project=${projectId}&scenario=${scenario.id}`,
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'Trials Report',
              title: 'View the Scenario Trials Report',
              to: `/reports/scenario-trials?project=${projectId}&scenario=${scenario.id}`,
              className: 'text-xs',
            },
          ]}
        />
      </div>
    </div>
  );
};

const ScenarioListContent = ({ isFetching, scenarios = [] }) => {
  const navigate = useNavigate();
  const { data: listControls } = useScenarioListControlsContext();

  if (scenarios.length === 0) {
    return null;
  }

  let { data: allScenarios, page } = selectScenariosWithPagination(scenarios, listControls);

  return (
    <>
      <div className="flex items-center">
        <h3 className="font-bold text-sky-900">ALL SCENARIOS</h3>
        <Spinner show={isFetching} className="ml-2" />
        <Button
          variant="primary"
          size="sm"
          className="ml-auto w-48 rounded"
          onClick={() => navigate('create')}
        >
          CREATE SCENARIO
        </Button>
      </div>

      <div className="mt-4 flex flex-col rounded-xl bg-white/30">
        <ScenarioListHeader />

        {page.map((scenario, index) => (
          <ScenarioListItem key={scenario.id} scenario={scenario} highlight={index % 2 === 0} />
        ))}

        <div className="mt-auto">
          <ScenarioListFooter totalItemsOnPage={page.length} totalItems={allScenarios.length} />
        </div>
      </div>
    </>
  );
};

const ScenarioList = () => {
  const { projectId } = useParams();
  const { data: scenarios, status, isFetching } = useGetScenarios({ projectId });

  const selectedScenarios = selectScenarios(scenarios);

  return (
    <div id="scenario-list" className="h-full">
      {status === 'loading' ? (
        <ScenarioListLoading />
      ) : (
        <>
          <ScenarioListEmpty scenarios={selectedScenarios} />
          <ScenarioListContent scenarios={selectedScenarios} isFetching={isFetching} />
        </>
      )}
    </div>
  );
};

export default ScenarioList;
