import React, { useMemo, useReducer } from 'react';
import merge from 'lodash/merge';

export const Actions = {
  SET_MATCHES: 'SET_MATCHES',
  SET_PAGE: 'SET_PAGE',
  SET_SEARCH: 'SET_SEARCH',
  SET_SORT: 'SET_SORT',
};

// Create a reducer to manage list control state
/** The list controls reducer default state. */
const defaultState = {
  matches: {},
  pagination: {
    page: 1,
    size: 10,
  },
  search: '',
  sort: {
    by: '',
    order: 'asc',
  },
};
/** The list controls reducer function. */
const reducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_MATCHES: {
      return {
        ...state,
        matches: action.payload.matches,
      };
    }
    case Actions.SET_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
      };
    }
    case Actions.SET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
      };
    case Actions.SET_SORT:
      return {
        ...state,
        sort: {
          ...state.sort,
          order:
            action.payload.by === state.sort.by
              ? state.sort.order === 'asc'
                ? 'desc'
                : 'asc'
              : 'asc',
          by: action.payload.by,
        },
      };
    default:
      return state;
  }
};

/** The React Context for `Project` list controls. */
export const ProjectListControlsContext = React.createContext();

/** The React Context Provider for `Project` list controls. */
export const ProjectListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <ProjectListControlsContext.Provider value={value} {...props} />;
};

/** The React Context for `Scenario` list controls. */
export const ScenarioListControlsContext = React.createContext();

/** The React Context Provider for `Scenario` list controls. */
export const ScenarioListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <ScenarioListControlsContext.Provider value={value} {...props} />;
};

/** The React Context for `Claim` list controls. */
export const ClaimListControlsContext = React.createContext();

/** The React Context Provider for `Claim` list controls. */
export const ClaimListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <ClaimListControlsContext.Provider value={value} {...props} />;
};

/** The React Context for `Evidence` list controls. */
export const EvidenceListControlsContext = React.createContext();

/** The React Context Provider for `Evidence` list controls. */
export const EvidenceListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <EvidenceListControlsContext.Provider value={value} {...props} />;
};

/** The React Context for `Indication` list controls. */
export const IndicationListControlsContext = React.createContext();

/** The React Context Provider for `Indication` list controls. */
export const IndicationListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <IndicationListControlsContext.Provider value={value} {...props} />;
};

/** The React Context for `Trial` list controls. */
export const TrialListControlsContext = React.createContext();

/** The React Context Provider for `Trial` list controls. */
export const TrialListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <TrialListControlsContext.Provider value={value} {...props} />;
};

/** The React Context for [Trial] `Arm` list controls. */
export const ArmListControlsContext = React.createContext();

/** The React Context Provider for [Trial] `Arm` list controls. */
export const ArmListControlsContextProvider = ({ initialState = {}, ...props }) => {
  const startingState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(reducer, startingState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <ArmListControlsContext.Provider value={value} {...props} />;
};
