import { useNavigate, useParams } from 'react-router-dom';

import Modal from '../../common/Modal';
import TrialCopy from './TrialCopy';

const TrialCopyModal = ({ isHidden = true, onHide, trialId }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const hide = () => {
    !!onHide && onHide();
  };

  if (isHidden) {
    return null;
  }

  return (
    <Modal onClose={hide} size="lg" showClose={false}>
      <div className="flex min-h-[300px] overflow-hidden rounded-2xl bg-white shadow-lg shadow-slate-400">
        <div className="w-3 shrink-0 bg-sky-900"></div>
        <div className="grow">
          <TrialCopy
            onCancel={hide}
            onSuccess={(trialCopy) => navigate(`/projects/${projectId}/trials/${trialCopy.id}`)}
            projectId={projectId}
            trialId={trialId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TrialCopyModal;
