import { useParams } from 'react-router-dom';

import TrialListLoading from './TrialListLoading';
import TrialListEmpty from './TrialListEmpty';
import TrialListContent from './TrialListContent';

import { useGetTrials } from '../../../hooks/trials.hooks';

const TrialList = () => {
  const { projectId } = useParams();
  const { data: trials, isLoading, isFetching } = useGetTrials(projectId);

  return (
    <div id="trial-list" className="h-full">
      {isLoading ? (
        <TrialListLoading />
      ) : (
        <>
          <TrialListEmpty trials={trials} />
          <TrialListContent trials={trials} isFetching={isFetching} />
        </>
      )}
    </div>
  );
};

export default TrialList;
