import { useState } from 'react';
import { Formik } from 'formik';

import ClaimCardThumbnail from '../common/ClaimCardThumbnail';
import Loading from '../../common/Loading';
import ClaimForm from '../common/ClaimForm';

import { claimSchema } from '../../../validations/claim.validations';
import { useGetClaim, useUpdateClaim } from '../../../hooks/claims.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ClaimFormMerged = ({ claim, projectId }) => {
  const toastsContext = useToastsContext();
  const updateClaim = useUpdateClaim();
  const [selectedClaimId, setSelectedClaimId] = useState(claim.id);
  const { data: selectedClaim, isLoading } = useGetClaim({ projectId, claimId: selectedClaimId });

  return (
    <div className="grid grid-cols-6 gap-y-4 gap-x-8">
      <div className="h-[330px] overflow-y-auto rounded-lg bg-slate-100">
        <div className="flex flex-col items-center p-3">
          <ClaimCardThumbnail
            claimId={claim.id}
            variant="purple"
            selected={selectedClaimId === claim.id}
            onClick={() => setSelectedClaimId(claim.id)}
          />
          <div className="my-2 h-4 w-4/5 border-b-2 border-b-sky-900"></div>
          {claim.claims.map((claimId) => (
            <ClaimCardThumbnail
              key={claimId}
              claimId={claimId}
              mergedId={claim.id}
              variant="secondary"
              selected={selectedClaimId === claimId}
              onClick={() => setSelectedClaimId(claimId)}
            />
          ))}
        </div>
      </div>

      <div className="col-span-5">
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loading>
              <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
            </Loading>
          </div>
        ) : (
          <>
            {selectedClaimId === claim.id ? (
              <Formik
                initialValues={{ ...selectedClaim }}
                validationSchema={claimSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  updateClaim.mutate(
                    { projectId, claim: { ...selectedClaim, ...values } },
                    {
                      onSuccess: (claim) => {
                        toastsContext.createToast(`Updated project claim.`);
                        setSubmitting(false);
                      },
                      onError: (err) => {
                        console.error(`Failed to update project claim. Detail:`, err);
                        // TODO display error notification
                        setSubmitting(false);
                      },
                    },
                  );
                }}
              >
                {(formik) => <ClaimForm formik={formik} />}
              </Formik>
            ) : (
              <Formik initialValues={{ ...selectedClaim }} enableReinitialize={true}>
                {(formik) => <ClaimForm formik={formik} readOnly />}
              </Formik>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClaimFormMerged;
