import numeral from 'numeral';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { usePopperTooltip } from 'react-popper-tooltip';

import Tooltip from '../../../common/Tooltip';

import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';

const ScenarioCost = ({ projectId, scenarioId }) => {
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'top',
  });
  const { data: trials, isLoading } = useGetAllTrialsForScenario(projectId, scenarioId);

  if (isLoading) return null;

  const totalTrialCost = reduce(map(trials, 'cost'), (total, cost) => total + cost, 0);

  return (
    <div className="flex items-center" ref={setTriggerRef}>
      <div>{numeral(totalTrialCost).format('(0[.]00a)').toUpperCase()}</div>
      <Tooltip {...tooltipProps}>
        The total estimated cost is {numeral(totalTrialCost).format('($0[.]00a)').toUpperCase()}.
      </Tooltip>
    </div>
  );
};

export default ScenarioCost;
