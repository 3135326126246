import { useNavigate } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import ColumnClaims from './ColumnClaims';
import ColumnEvidence from './ColumnEvidence';
import ColumnNonEvidence from './ColumnNonEvidence';
import ColumnEvidenceLinked from './ColumnEvidenceLinked';
import ColumnEvidencePlans from './ColumnEvidencePlans';

const EvidenceBoardColumns = ({ claims = [], evidenceList = [] }) => {
  const navigate = useNavigate();
  const onDragEnd = async (result) => {
    const { destination, draggableId, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    const [claimId] = draggableId.split('|');

    switch (destination.droppableId) {
      case 'column-claims':
        navigate(`?linkClaim=${draggableId}`);
        break;
      case 'column-evidence':
        navigate(`?linkEvidence=${claimId}`);
        break;
      case 'column-evidence-linked':
        navigate(`?linkEvidence=${claimId}`);
        break;
      default:
        break;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="grid h-[calc(100%_-_72px)] grid-cols-5 gap-4">
        <ColumnClaims claims={claims} />
        <ColumnEvidence evidenceList={evidenceList} />
        <ColumnEvidenceLinked claims={claims} evidenceList={evidenceList} />
        <ColumnNonEvidence />
        <ColumnEvidencePlans />
      </div>
    </DragDropContext>
  );
};

export default EvidenceBoardColumns;
