import { Form } from 'formik';

import InputField from '../../common/InputField';
import TextareaField from '../../common/TextareaField';
import LoadingButton from '../../common/LoadingButton';

const ScenarioForm = ({ formik }) => {
  return (
    <Form>
      <div className="grid grid-cols-3 gap-y-4 gap-x-8">
        <div>
          <InputField
            name="name"
            label="Name"
            type="text"
            maxLength="254"
            disabled={formik.isSubmitting}
            tooltip="Short Name"
          />
        </div>

        <div>
          <InputField
            name="regulatoryDesignation"
            label="Regulatory Designation"
            type="text"
            maxLength="50"
            disabled={formik.isSubmitting}
            tooltip="Regulatory Pathway (e.g., 'Accelerated')"
          />
        </div>

        <div className="row-span-5">
          <TextareaField
            name="targetRegions"
            label="Target Regions"
            type="text"
            maxLength="1000"
            disabled={formik.isSubmitting}
            rows="12"
          />
        </div>

        <div className="col-start-1">
          <InputField
            name="type"
            label="Type"
            type="text"
            maxLength="254"
            disabled={formik.isSubmitting}
            tooltip="Sponsor Objective (e.g., 'First to Market')"
          />
        </div>

        <div>
          <InputField
            name="patientSegment"
            label="Patient Segment"
            type="text"
            maxLength="50"
            disabled={formik.isSubmitting}
            tooltip="Patient Segment Description (i.e., from Target Product Profile)"
          />
        </div>

        <div className="col-start-1">
          <InputField
            name="owner"
            label="Owner"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Client or Client Contact"
          />
        </div>

        <div>
          <InputField
            name="rangesMode"
            label="Ranges Mode"
            type="text"
            maxLength="50"
            disabled={formik.isSubmitting}
            tooltip="Ranges Mode"
          />
        </div>

        <div className="col-start-1">
          <InputField
            name="status"
            label="Status"
            type="text"
            maxLength="50"
            disabled={formik.isSubmitting}
            tooltip="Active, Inactive, Accepted"
          />
        </div>

        <div className="col-span-4 justify-self-end">
          {formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default ScenarioForm;
