import { Form } from 'formik';
import classNames from 'classnames';

import Loading from '../../../../common/Loading';
import InputField from '../../../../common/InputField';
import SelectField from '../../../../common/SelectField';
import LoadingButton from '../../../../common/LoadingButton';

import {
  useGetIndicationsByTherapeuticArea,
  useGetTherapeuticAreas,
} from '../../../../../hooks/therapeutics.hooks';

const AssetIndicationForm = ({ formik }) => {
  const { data: therapeuticAreas, isLoading: isLoadingTherapeuticAreas } = useGetTherapeuticAreas();
  const { data: indications, isLoading: isLoadingIndications } = useGetIndicationsByTherapeuticArea(
    {
      therapeuticArea: formik.values['therapeuticArea'],
    },
    {
      enabled: !!formik.values['therapeuticArea'],
    },
  );

  if (isLoadingTherapeuticAreas) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <Form className="h-full">
      <div className="grid grid-cols-3 gap-x-8 gap-y-4">
        <div>
          <SelectField
            name="therapeuticArea"
            label="Therapeutic Area"
            disabled={formik.isSubmitting}
          >
            <option value=""></option>
            {therapeuticAreas.map((ta, index) => (
              <option key={index} value={ta}>
                {ta}
              </option>
            ))}
          </SelectField>
        </div>

        <div>
          <SelectField
            name="indication"
            label="Indication"
            disabled={
              isLoadingIndications || !formik.values['therapeuticArea'] || formik.isSubmitting
            }
          >
            <option value=""></option>
            {indications &&
              indications.map((indication, index) => (
                <option key={index} value={indication}>
                  {indication}
                </option>
              ))}
            <option value="Other">Other</option>
          </SelectField>
        </div>

        <div className={classNames({ hidden: formik.values['indication'] !== 'Other' })}>
          <InputField
            name="indicationOther"
            label="Other Indication"
            type="text"
            disabled={formik.isSubmitting}
            tooltip="Enter an 'other' Indication when a suitable value is not found in the drop-down list."
          />
        </div>

        <div className="col-span-3 justify-self-end">
          {formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default AssetIndicationForm;
