import { Form } from 'formik';

import InputField from '../../../../common/InputField';
import LoadingButton from '../../../../common/LoadingButton';

const TrialArmForm = ({ formik }) => {
  return (
    <Form className="h-full">
      <div className="grid grid-cols-3 gap-x-8 gap-y-4">
        <div className="col-span-3">
          <InputField
            name="name"
            label="Name"
            type="text"
            maxLength="254"
            tabIndex="1"
            disabled={formik.isSubmitting}
            tooltip="Arm Name"
          />
        </div>

        <div>
          <InputField
            name="startAt"
            label="Start Date"
            type="date"
            tabIndex="2"
            disabled={formik.isSubmitting}
          />
        </div>

        <div>
          <InputField
            name="endAt"
            label="End Date"
            type="date"
            tabIndex="3"
            min={formik.values['startAt']}
            disabled={!formik.values['startAt'] || formik.isSubmitting}
          />
        </div>

        <div>
          <InputField
            name="sampleSize"
            label="Sample Size"
            type="number"
            min="0"
            tabIndex="4"
            disabled={formik.isSubmitting}
            tooltip="Total Patient Count for Arm"
          />
        </div>

        <div className="col-span-3 justify-self-end">
          {formik.dirty && (
            <LoadingButton
              type="submit"
              variant="secondary"
              className="mt-6 w-32 rounded"
              tabIndex="1000"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              SAVE
            </LoadingButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default TrialArmForm;
