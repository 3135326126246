import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

/**
 * The default filter matches configuration for collections of `Project` objects.
 */
const defaultMatches = {
  isArchived: false,
};

/**
 * The default list control configuration for collections of `Project` objects.
 */
const defaultListControls = {
  matches: {
    isArchived: false,
  },
  search: '',
  sort: {
    by: '',
    order: 'asc',
  },
  pagination: {
    page: 1,
    size: 10,
    pages: 1,
  },
};

/**
 * Select all `Project` objects which match the supplied criteria. The resulting
 * collection is sorted.
 * @param {Object[]} projects A collection of `Project` objects.
 * @param {[Object]} matches (Optional) Filter matching criteria.
 * @param {[string[]]} iteratees (Optional) Array of sort attribute names.
 * @param {[string[]]} orders (Optional) Array of sort order directions.
 * @returns {Object[]} A sorted collection of projects.
 */
export const selectProjects = (
  projects = [],
  matches = {},
  sortBy = ['name'],
  sortOrder = ['asc'],
) => {
  const filterMatches = { ...defaultMatches, ...matches };
  return orderBy(filter(projects, filterMatches), sortBy, sortOrder);
};

/**
 * Select Projects with list controls selections applied.
 * @param {Object[]} projects A collection of `Project` objects.
 * @param {Object} listControls A ListControls object.
 * @returns {Object} An object whose attributes are `data` and `page`.
 * The `data` attribute is a collection of all of the Project objects
 * with list controls applied. The `page` attribute is a page of Project objects with
 * list controls applied.
 */
export const selectProjectsWithPagination = (projects = [], options) => {
  const result = {};
  const opts = { ...defaultListControls, ...options };
  // 1. filter by attribute matcher
  let items = filter(projects, opts.matches);
  // 2. filter by search text
  items = opts.search && opts.search.length > 1 ? selectProjectsByText(items, opts.search) : items;
  // 3. apply sort
  result.data = orderBy(items, (i) => [get(i, opts.sort.by, 0)], [opts.sort.order]);
  // 4. apply pagination
  result.page = chunk(result.data, opts.pagination.size)[opts.pagination.page - 1] || [];

  return result;
};

/**
 * Select all `Project` objects which match the supplied `status` value.
 * @param {Object[]} projects A collection of `Project` objects.
 * @param {string} status A status value.
 * @returns A collection of projects.
 */
export const selectProjectsByStatus = (projects = [], status) => {
  return filter(projects, { status });
};

/**
 * Select all `Project` objects which contain the supplied `text` value in their name or client.
 * @param {Object[]} projects A collection of `Project` objects.
 * @param {string} text The selection criteria.
 * @returns A collection of projects.
 */
export const selectProjectsByText = (projects = [], text = '') => {
  return filter(projects, (p) => {
    return (
      p.name.toLowerCase().includes(text.toLowerCase()) ||
      p.client.toLowerCase().includes(text.toLowerCase())
    );
  });
};
