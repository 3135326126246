import { useField } from 'formik';
import classNames from 'classnames';

/**
 * The `BinaryField` component renders a custom Formik field for boolean input values.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const BinaryField = ({ className, on, onValue, off, offValue, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const { value, touched, error } = meta;
  const { setValue } = helpers;
  const showError = touched && error;

  const setFieldValue = (value) => {
    if (props.readOnly || props.disabled) return;
    setValue(value);
  };

  return (
    <>
      {value === onValue ? (
        <div
          onClick={() => setFieldValue(offValue)}
          className={classNames('cursor-pointer', className)}
        >
          {on}
        </div>
      ) : (
        <div
          onClick={() => setFieldValue(onValue)}
          className={classNames('cursor-pointer', className)}
        >
          {off}
        </div>
      )}
      {showError ? <div className="mt-1 text-sm text-red-600">{meta.error}</div> : null}
    </>
  );
};

export default BinaryField;
