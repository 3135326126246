import LetterIcon from '../../../common/LetterIcon';

const ScenarioHierarchyTrial = ({ trial }) => {
  return (
    <div className="ml-8 flex flex-col">
      <div className="flex items-center py-0.5 hover:bg-sky-900/10">
        <div className="ml-9">
          <LetterIcon letter="t" className="mr-1" title="Trial" />
          {trial.name}
        </div>
      </div>
    </div>
  );
};

export default ScenarioHierarchyTrial;
