import { NavLink, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

/**
 * The `SubmenuItem` component renders a single item within a `Submenu`.
 * Provides a single navigation link.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const SubmenuItem = ({ name, view, ...props }) => {
  const [searchParams] = useSearchParams();
  const isActiveView = searchParams.get('view') === view;

  return (
    <NavLink
      {...props}
      className={classNames(
        '-mb-0.5 mr-6 h-8 border-b-2 px-1',
        { 'border-b-sky-200': !isActiveView },
        { 'border-b-sky-500 text-sky-500': isActiveView },
      )}
    >
      {name}
    </NavLink>
  );
};

/**
 * The `Submenu` component renders a horizontal navigation menu. Useful for
 * user navigation within a section of the application.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Submenu = ({ items = [] }) => {
  return (
    <div className="mb-4 flex items-center border-b-2 border-b-sky-200 text-sm font-bold text-sky-900">
      {items.map((item, index) => (
        <SubmenuItem key={index} {...item} />
      ))}
    </div>
  );
};

export default Submenu;
