import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';

import AssetIndicationForm from './AssetIndicationForm';

import { generateId } from '../../../../../utils/id';
import { indicationSchema } from '../../../../../validations/indication.valudations';
import { useUpdateAsset } from '../../../../../hooks/asset.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';

const AssetIndicationCreate = ({ asset, projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateAsset = useUpdateAsset();
  const toastsContext = useToastsContext();

  return (
    <div id="asset-indication-create" className="h-full overflow-y-auto">
      <h3 className="mb-4 font-bold text-sky-900">CREATE ASSET INDICATION</h3>

      <Formik
        initialValues={{
          therapeuticArea: '',
          indication: '',
          indicationOther: '',
        }}
        validationSchema={indicationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (values.indication !== 'Other') values.indicationOther = '';
          updateAsset.mutate(
            {
              projectId,
              asset: {
                ...asset,
                indications: [
                  ...asset.indications,
                  {
                    id: generateId(),
                    ...values,
                  },
                ],
              },
            },
            {
              onSuccess: (asset) => {
                setSubmitting(false);
                toastsContext.createToast(`Added indication to asset.`);
                searchParams.delete('mode');
                setSearchParams(searchParams);
              },
              onError: (err) => {
                console.error(`Failed to create asset indication. Detail:`, err);
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <AssetIndicationForm formik={formik} />}
      </Formik>
    </div>
  );
};

export default AssetIndicationCreate;
