import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import Loading from '../common/Loading';
import { useAuthState } from '../../hooks/cognito.hooks';
import ButtonBar from '../common/ButtonBar';
import Button from '../common/Button';

const LandingPage = () => {
  const navigate = useNavigate();
  const { data: authState, status } = useAuthState();
  const isAuthenticated = status === 'success' && authState?.isAuthenticated;

  useEffect(() => {
    document.title = 'Welcome | IQVIA Design Center';
  }, []);

  if (status === 'loading') {
    return (
      <div className="flex h-1/4 items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="h-full py-8 px-16">
      <div className="mt-16 mb-8 text-4xl font-bold text-sky-900">
        Welcome to IQVIA Design Center
      </div>
      <div className="mb-8 max-w-2xl text-xl text-sky-900/70">
        Capture assets, claims, and evidence, design trials, and explore clinical development
        scenarios in one place.
      </div>
      <ButtonBar>
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          title="Sign In"
          onClick={() => navigate('/auth/signin')}
        >
          SIGN IN
        </Button>
        <Button
          type="button"
          variant="outline-primary"
          className="ml-4 w-48 rounded"
          title="Sign Up"
          onClick={() => navigate('/auth/signin')}
        >
          SIGN UP
        </Button>
      </ButtonBar>
    </div>
  );
};

export default LandingPage;
