import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuthState } from './cognito.hooks';
import {
  createNotification,
  getNotification,
  getNotifications,
  updateNotification,
  removeNotification,
  removeAllNotifications,
} from '../api/notifications.api';
import { QueryKeys } from '../utils/constants';

/**
 * React query hook to fetch all `Notifications` for an account.
 * @param {string} accountId An account identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetNotifications = (accountId, options) => {
  return useQuery([QueryKeys.Notifications], () => getNotifications(accountId), options);
};

/**
 * React query hook to fetch a single `Notification`.
 * @param {string} id A notification identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetNotification = (id, options) => {
  return useQuery([QueryKeys.Notifications, id], () => getNotification(id), options);
};

/**
 * React query mutation hook to create a `Notification`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCreateNotification = (options) => {
  const queryClient = useQueryClient();
  return useMutation(createNotification, {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to update a `Notification`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUpdateNotification = (options) => {
  const queryClient = useQueryClient();
  return useMutation(updateNotification, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Notifications, data.id], data);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to remove (delete) a `Notification`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useRemoveNotification = (options) => {
  const queryClient = useQueryClient();
  return useMutation(removeNotification, {
    ...options,
    onSuccess: (data, vars) => {
      queryClient.removeQueries([QueryKeys.Notifications, vars]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to remove (delete) all `Notification` objects.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useRemoveAllNotifications = (options) => {
  const queryClient = useQueryClient();
  return useMutation(removeAllNotifications, {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * A hook to fetch the `notify` utility. Simplifies the creation of notifications
 * within other hooks.
 * @returns The `notify` utility function.
 */
export const useNotify = () => {
  const { data: authState } = useAuthState();
  const notify = async ({ text }) => {
    return createNotification({
      to: [authState.sub],
      from: authState.sub,
      text,
    });
  };

  return notify;
};
