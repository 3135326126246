import dayjs from 'dayjs';

import NoContent from '../common/NoContent';
import Placeholder from '../common/Placeholder';
import Dropdown from '../common/Dropdown';
import Spinner from '../common/Spinner';
import Icon from '../common/Icon';

import {
  useGetNotifications,
  useRemoveNotification,
  useRemoveAllNotifications,
} from '../../hooks/notifications.hooks';
import { useAuthState } from '../../hooks/cognito.hooks';
import { useGetAccount } from '../../hooks/account.hooks';
import { selectNotifications } from '../../selectors/notifications.selectors';
import { keys } from '../../utils/keys';

const NotificationsLoading = () => {
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      {keys(3).map((item) => (
        <div key={item.key} className="mb-4 flex items-center">
          <Icon icon="bell" className="mr-4 h-6 w-6 text-amber-400" />
          <div className="grow">
            <Placeholder className="mb-1" size="lg" />
            <Placeholder />
          </div>
        </div>
      ))}
    </div>
  );
};

const NotificationListEmpty = ({ notifications = [] }) => {
  if (notifications.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="secondary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You're all caught up!</div>
        </div>
      </NoContent>
    </div>
  );
};

const NotificationListItem = ({ notification }) => {
  const { data: account, status } = useGetAccount(notification.from);
  const removeNotification = useRemoveNotification();

  const markRead = () => {
    removeNotification.mutate(notification.id);
  };

  return (
    <div className="mb-4 flex min-w-0 items-center text-slate-700">
      <Icon icon="bell" className="mr-4 h-6 w-6 text-amber-400" />
      <div className="min-w-0">
        <div className="truncate">{notification.text}</div>
        <div className="truncate text-sm text-slate-500">
          by{' '}
          {status === 'loading' ? <Placeholder className="inline-block w-16" /> : `${account.name}`}{' '}
          on {dayjs(notification.createdAt).format('MMM D, YYYY [at] h:mma')}
        </div>
      </div>
      <div className="ml-auto">
        <Dropdown
          toggle={<Icon icon="ellipsis-vertical" className="ml-auto h-5 w-5 text-slate-400" />}
          content={[
            {
              type: 'link',
              title: 'Mark Read',
              onClick: markRead,
              name: 'Mark Read',
              className: 'text-xs',
            },
          ]}
          contentClasses="right-5 -top-1.5"
        />
      </div>
    </div>
  );
};

const NotificationList = ({ notifications = [] }) => {
  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {notifications.map((notification) => (
        <NotificationListItem key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

const Notifications = () => {
  const { data: authState } = useAuthState();
  const {
    data: notifications,
    status,
    isFetching,
  } = useGetNotifications(authState?.sub, {
    enabled: !!authState?.sub,
  });
  const removeAllNotifications = useRemoveAllNotifications();

  const selectedNotifications = selectNotifications(notifications);

  return (
    <div className="h-full">
      <h3 className="flex items-start text-sky-900">
        <div className="text-xl font-bold">Activity</div>
        {!!selectedNotifications && selectedNotifications.length > 0 && (
          <div className="ml-1 inline-block rounded-full bg-sky-400 px-1 text-xs font-bold text-white">
            {selectedNotifications.length > 99 ? '99+' : selectedNotifications.length}
          </div>
        )}
        <Spinner
          show={isFetching || removeAllNotifications.isLoading}
          className="ml-2 self-center"
        />
        <button
          type="button"
          className="ml-auto self-center font-bold"
          onClick={() => removeAllNotifications.mutate(authState?.sub)}
        >
          MARK ALL READ
        </button>
      </h3>

      <div className="mt-4 h-[calc(100%_-_44px)] overflow-y-auto">
        {status === 'loading' ? (
          <NotificationsLoading />
        ) : (
          <>
            <NotificationListEmpty notifications={selectedNotifications} />
            <NotificationList notifications={selectedNotifications} />
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
