import { EvidenceListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import ClaimEvidenceListLoading from './ClaimEvidenceListLoading';
import ClaimEvidenceListEmpty from './ClaimEvidenceListEmpty';
import ClaimEvidenceListContent from './ClaimEvidenceListContent';

import { useGetAllEvidenceForClaim } from '../../../../hooks/evidence.hooks';

const ClaimEvidenceList = ({ claim, projectId }) => {
  const { data: claimEvidence, isLoading } = useGetAllEvidenceForClaim({
    projectId,
    claimId: claim.id,
  });

  return (
    <div id="claim-edit-evidence-list" className="h-full">
      {isLoading ? (
        <ClaimEvidenceListLoading />
      ) : (
        <>
          <ClaimEvidenceListEmpty claim={claim} evidenceList={claimEvidence} />
          <EvidenceListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <ClaimEvidenceListContent
              projectId={projectId}
              claim={claim}
              evidenceList={claimEvidence}
            />
          </EvidenceListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default ClaimEvidenceList;
