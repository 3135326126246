import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LatestActivity from '../../dashboard/LatestActivity';
import Button from '../../common/Button';
import ProjectCreate from './ProjectCreate';

const ProjectCreatePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Create Project | IQVIA Design Center';
  }, []);

  return (
    <div className="h-full py-8 px-16">
      <div className="grid grid-cols-12 items-end gap-6">
        <div className="col-span-4">
          <h2 className="mb-8 text-2xl text-sky-900">PORTFOLIO</h2>
          <Button
            type="button"
            variant="primary"
            className="mr-4 w-48 rounded"
            onClick={() => navigate('/projects')}
          >
            <div className="flex items-center justify-center">ALL PROJECTS</div>
          </Button>
        </div>
        <div className="col-span-7 col-end-13">
          <LatestActivity />
        </div>
      </div>

      <div className="mt-6 h-[calc(100%_-_150px)]">
        <ProjectCreate
          onCancel={() => navigate(-1)}
          onSuccess={(project) => navigate(`/projects/${project.id}`)}
        />
      </div>
    </div>
  );
};

export default ProjectCreatePage;
