import { useSearchParams } from 'react-router-dom';

import Button from '../../../common/Button';
import ScenarioTrialCost from './ScenarioTrialCost';
import ScenarioTrialTime from './ScenarioTrialTime';
import ScenarioTrialRisk from './ScenarioTrialRisk';

const ScenarioTrialListMenu = ({ scenario, trials }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex items-center">
      <div className="font-bold uppercase text-sky-900">SCENARIO TRIALS</div>
      <div className="ml-auto mr-10 flex items-center rounded border-2 border-sky-900 text-sm">
        <div className="bg-sky-900 py-1 px-4 font-bold text-white">SUMMARY</div>
        <div className="h-full border-r border-r-sky-900 px-4 py-1 font-bold text-sky-900">
          <ScenarioTrialRisk trials={trials} />
        </div>
        <div className="border-r border-r-sky-900 px-4 py-1 font-bold text-sky-900">
          <ScenarioTrialTime trials={trials} />
        </div>
        <div className="px-4 font-bold text-sky-900">
          <ScenarioTrialCost trials={trials} />
        </div>
      </div>
      <Button
        size="sm"
        variant="primary"
        className="w-48 rounded"
        onClick={() => {
          searchParams.append('linkScenarioTrial', scenario.id);
          setSearchParams(searchParams);
        }}
      >
        LINK TRIAL
      </Button>
    </div>
  );
};

export default ScenarioTrialListMenu;
