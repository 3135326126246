import classNames from 'classnames';

/**
 * Returns CSS classes for the placeholder element size.
 * @param {string} size The size value.
 * @returns {string} CSS classes.
 */
const getSizeClasses = (size) => {
  switch (size) {
    case 'xl':
      return 'h-8';
    case 'lg':
      return 'h-4';
    case 'sm':
      return 'h-2';
    default:
      return 'h-3';
  }
};

/**
 * The `Placeholder` component renders an animated element which appears as a solid block.
 * Typically used as a placeholder for a single piece of data while that data is loading.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Placeholder = ({ className, size }) => {
  return (
    <div
      className={classNames(getSizeClasses(size), 'animate-pulse rounded bg-sky-900/20', className)}
    ></div>
  );
};

export default Placeholder;
