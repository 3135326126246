import { useSearchParams } from 'react-router-dom';

import AssetIndicationDeleteModal from '../asset/edit/indications/delete/AssetIndicationDeleteModal';
import ClaimLinkEvidenceModal from '../claims/edit/evidence/ClaimLinkEvidenceModal';
import ClaimUnlinkEvidenceModal from '../claims/edit/evidence/ClaimUnlinkEvidenceModal';
import ClaimLinkScenarioModal from '../claims/edit/scenarios/ClaimLinkScenarioModal';
import ClaimMergeModal from '../claims/merge/ClaimMergeModal';
import ClaimUnmergeModal from '../claims/merge/ClaimUnmergeModal';
import EvidenceLinkClaimModal from '../evidence/edit/claims/EvidenceLinkClaimModal';
import EvidenceLinkTrialModal from '../evidence/edit/trials/EvidenceLinkTrialModal';
import ProjectArchiveModal from '../projects/archive/ProjectArchiveModal';
import ProjectExportModal from '../projects/export/ProjectExportModal';
import ProjectImportModal from '../projects/import/ProjectImportModal';
import ScenarioIndicationDeleteModal from '../scenarios/edit/indications/delete/ScenarioIndicationDeleteModal';
import ScenarioLinkClaimModal from '../scenarios/edit/claims/ScenarioLinkClaimModal';
import ScenarioUnlinkClaimModal from '../scenarios/edit/claims/ScenarioUnlinkClaimModal';
import ScenarioLinkTrialModal from '../scenarios/edit/trials/ScenarioLinkTrialModal';
import ScenarioUnlinkTrialModal from '../scenarios/edit/trials/ScenarioUnlinkTrialModal';
import TrialCopyModal from '../trials/copy/TrialCopyModal';
import TrialExportModal from '../trials/export/TrialExportModal';
import TrialLinkEvidenceModal from '../trials/edit/evidence/TrialLinkEvidenceModal';
import TrialUnlinkEvidenceModal from '../trials/edit/evidence/TrialUnlinkEvidenceModal';

/**
 * The `WithModals` component renders a specific `Modal` component when
 * detecting the trigger for that modal. Modals are triggered by the presence
 * of specific values in the `searchParams`. This component manages the
 * application modals and their display behavior.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const WithModals = ({ children }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <AssetIndicationDeleteModal
        isHidden={!searchParams.has('deleteAssetIndication')}
        onHide={() => {
          searchParams.delete('deleteAssetIndication');
          setSearchParams(searchParams);
        }}
        indicationId={searchParams.get('deleteAssetIndication')}
      />
      <ClaimLinkEvidenceModal
        isHidden={!searchParams.has('linkEvidence')}
        onHide={() => {
          searchParams.delete('linkEvidence');
          setSearchParams(searchParams);
        }}
        claimId={searchParams.get('linkEvidence')}
      />
      <ClaimUnlinkEvidenceModal
        isHidden={!searchParams.has('unlinkClaimEvidence')}
        onHide={() => {
          searchParams.delete('unlinkClaimEvidence');
          searchParams.delete('evidence');
          setSearchParams(searchParams);
        }}
        claimId={searchParams.get('unlinkClaimEvidence')}
        evidenceId={searchParams.get('evidence')}
      />
      <ClaimLinkScenarioModal
        isHidden={!searchParams.has('linkClaimScenario')}
        onHide={() => {
          searchParams.delete('linkClaimScenario');
          setSearchParams(searchParams);
        }}
        claimId={searchParams.get('linkClaimScenario')}
      />
      <ClaimMergeModal
        isHidden={!searchParams.has('mergeClaim')}
        onHide={() => {
          searchParams.delete('mergeClaim');
          setSearchParams(searchParams);
        }}
        claimId={searchParams.get('mergeClaim')}
      />
      <ClaimUnmergeModal
        isHidden={!searchParams.has('unmergeClaim')}
        onHide={() => {
          searchParams.delete('unmergeClaim');
          searchParams.delete('standaloneId');
          setSearchParams(searchParams);
        }}
        mergedId={searchParams.get('unmergeClaim')}
        standaloneId={searchParams.get('standaloneId')}
      />
      <EvidenceLinkClaimModal
        isHidden={!searchParams.has('linkClaim')}
        onHide={() => {
          searchParams.delete('linkClaim');
          setSearchParams(searchParams);
        }}
        evidenceId={searchParams.get('linkClaim')}
      />
      <EvidenceLinkTrialModal
        isHidden={!searchParams.has('linkEvidenceTrial')}
        onHide={() => {
          searchParams.delete('linkEvidenceTrial');
          setSearchParams(searchParams);
        }}
        evidenceId={searchParams.get('linkEvidenceTrial')}
      />
      <ProjectArchiveModal
        isHidden={!searchParams.has('archiveProject')}
        onHide={() => {
          searchParams.delete('archiveProject');
          setSearchParams(searchParams);
        }}
        projectId={searchParams.get('archiveProject')}
      />
      <ProjectExportModal
        isHidden={!searchParams.has('exportProject')}
        onHide={() => {
          searchParams.delete('exportProject');
          setSearchParams(searchParams);
        }}
        projectId={searchParams.get('exportProject')}
      />
      <ProjectImportModal
        isHidden={!searchParams.has('importProject')}
        onHide={() => {
          searchParams.delete('importProject');
          setSearchParams(searchParams);
        }}
      />
      <ScenarioIndicationDeleteModal
        isHidden={!searchParams.has('deleteScenarioIndication')}
        onHide={() => {
          searchParams.delete('deleteScenarioIndication');
          setSearchParams(searchParams);
        }}
        indicationId={searchParams.get('deleteScenarioIndication')}
      />
      <ScenarioLinkClaimModal
        isHidden={!searchParams.has('linkScenarioClaim')}
        onHide={() => {
          searchParams.delete('linkScenarioClaim');
          setSearchParams(searchParams);
        }}
        scenarioId={searchParams.get('linkScenarioClaim')}
      />
      <ScenarioUnlinkClaimModal
        isHidden={!searchParams.has('unlinkScenarioClaim')}
        onHide={() => {
          searchParams.delete('unlinkScenarioClaim');
          searchParams.delete('claim');
          setSearchParams(searchParams);
        }}
        scenarioId={searchParams.get('unlinkScenarioClaim')}
        claimId={searchParams.get('claim')}
      />
      <ScenarioLinkTrialModal
        isHidden={!searchParams.has('linkScenarioTrial')}
        onHide={() => {
          searchParams.delete('linkScenarioTrial');
          setSearchParams(searchParams);
        }}
        scenarioId={searchParams.get('linkScenarioTrial')}
      />
      <ScenarioUnlinkTrialModal
        isHidden={!searchParams.has('unlinkScenarioTrial')}
        onHide={() => {
          searchParams.delete('unlinkScenarioTrial');
          searchParams.delete('trial');
          setSearchParams(searchParams);
        }}
        scenarioId={searchParams.get('unlinkScenarioTrial')}
        trialId={searchParams.get('trial')}
      />
      <TrialCopyModal
        isHidden={!searchParams.has('copyTrial')}
        onHide={() => {
          searchParams.delete('copyTrial');
          setSearchParams(searchParams);
        }}
        trialId={searchParams.get('copyTrial')}
      />
      <TrialExportModal
        isHidden={!searchParams.has('exportTrial')}
        onHide={() => {
          searchParams.delete('exportTrial');
          setSearchParams(searchParams);
        }}
        trialId={searchParams.get('exportTrial')}
      />
      <TrialLinkEvidenceModal
        isHidden={!searchParams.has('linkTrialEvidence')}
        onHide={() => {
          searchParams.delete('linkTrialEvidence');
          setSearchParams(searchParams);
        }}
        trialId={searchParams.get('linkTrialEvidence')}
      />
      <TrialUnlinkEvidenceModal
        isHidden={!searchParams.has('unlinkTrialEvidence')}
        onHide={() => {
          searchParams.delete('unlinkTrialEvidence');
          searchParams.delete('evidence');
          setSearchParams(searchParams);
        }}
        trialId={searchParams.get('unlinkTrialEvidence')}
        evidenceId={searchParams.get('evidence')}
      />
      {children}
    </>
  );
};

export default WithModals;
