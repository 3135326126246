import * as Yup from 'yup';

export const trialArmSchema = Yup.object({
  name: Yup.string().max(254, 'Must be 254 characters or less').required('Required'),
  sampleSize: Yup.number().min(0),
  startAt: Yup.date(),
  endAt: Yup.date(),
});

export const trialSchema = Yup.object({
  name: Yup.string().max(254, 'Must be 254 characters or less').required('Required'),
  details: Yup.string().max(1000, 'Must be 1000 characters or less'),
  phase: Yup.string(),
  indication: Yup.string(),
  indicationOther: Yup.string(),
  geography: Yup.string(),
  patientCount: Yup.string(),
  adaptiveDesign: Yup.boolean().required('Required'),
  masterProtocol: Yup.string()
    .oneOf(['no', 'basket', 'platform', 'umbrella'], 'Select a value from the list')
    .required('Required'),
  cost: Yup.number().min(0, 'Must be 0 or greater'),
  riskScore: Yup.number().min(0).max(5),
  startAt: Yup.date(),
  endAt: Yup.date(),
  scenario: Yup.string(),
  arms: Yup.array(trialArmSchema),
});

export const linkEvidenceSchema = Yup.object({
  evidenceId: Yup.string().required('Required'),
});

export const linkScenarioSchema = Yup.object({
  scenarioId: Yup.string().required('Required'),
});
