import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import ContentBox from '../../common/ContentBox';

import { useGetClaim } from '../../../hooks/claims.hooks';

const ClaimCardThumbnail = ({ claimId, mergedId, onClick, variant, selected }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const { data: claim, isLoading } = useGetClaim({ projectId, claimId });

  if (isLoading) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      className={classNames('group relative mt-2 w-full cursor-pointer text-xs first:mt-0', {
        'rounded-lg ring-2 ring-sky-400': selected,
      })}
    >
      <ContentBox variant={variant} contentClasses="px-1 py-2 min-h-[60px]">
        {claim.name}
      </ContentBox>
      {mergedId && (
        <Icon
          icon="minus"
          className="absolute right-1 top-1 z-10 hidden h-4 w-4 rounded-sm bg-slate-500 text-white group-hover:block"
          title="Remove from group"
          onClick={() =>
            navigate(`?${searchParams}&unmergeClaim=${mergedId}&standaloneId=${claimId}`)
          }
        />
      )}
    </div>
  );
};

export default ClaimCardThumbnail;
