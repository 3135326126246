import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import includes from 'lodash/includes';
import reject from 'lodash/reject';

import Loading from '../../../common/Loading';
import SelectField from '../../../common/SelectField';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { linkTrialSchema } from '../../../../validations/evidence.validations';
import { useGetEvidence } from '../../../../hooks/evidence.hooks';
import { useLinkTrialEvidence, useGetTrials } from '../../../../hooks/trials.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';
import { selectTrials } from '../../../../selectors/trials.selectors';

const EvidenceLinkTrial = ({ onCancel, onSuccess, evidenceId }) => {
  const toastsContext = useToastsContext();
  const linkEvidence = useLinkTrialEvidence();
  const { projectId } = useParams();
  const { data: evidence, isLoading: isLoadingEvidence } = useGetEvidence({
    projectId,
    evidenceId,
  });
  const { data: allTrials, isLoading: isLoadingTrials } = useGetTrials(projectId);

  if (isLoadingEvidence || isLoadingTrials) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const eligibleTrials = selectTrials(reject(allTrials, (t) => includes(evidence.trials, t.id)));

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <Formik
        initialValues={{ trialId: '' }}
        validationSchema={linkTrialSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { trialId } = values;
          // mergeClaim mutator
          linkEvidence.mutate(
            { projectId, evidenceId, trialId },
            {
              onSuccess: () => {
                toastsContext.createToast(`Linked trial and evidence.`);
                setSubmitting(false);
                onSuccess && onSuccess();
              },
              onError: (err) => {
                console.error(`Failed to link trial and evidence. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <h3 className="mb-6 text-2xl font-bold">LINK TRIAL</h3>

            <div className="mb-4">
              You are about to link a trial to evidence{' '}
              <span className="italic">{evidence.name}</span>.
            </div>

            <div>Select the trial you wish to link:</div>
            <div className="mb-6">
              <SelectField name="trialId" disabled={formik.isSubmitting}>
                <option value="">Select trial...</option>
                {eligibleTrials.map((trial) => (
                  <option key={trial.id} value={trial.id}>
                    {trial.name}
                  </option>
                ))}
              </SelectField>
            </div>

            <ButtonBar className="mt-auto ml-auto">
              <Button
                type="button"
                variant="primary"
                className="w-48 rounded"
                onClick={onCancel}
                disabled={linkEvidence.isLoading}
              >
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                variant="secondary"
                className="ml-4 w-48 rounded"
                disabled={linkEvidence.isLoading}
                isLoading={linkEvidence.isLoading}
              >
                LINK
              </LoadingButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EvidenceLinkTrial;
