import filter from 'lodash/filter';
import find from 'lodash/find';
import * as _reject from 'lodash/reject';

import { getProject, updateProject } from './projects.api';
import { generateId } from '../utils/id';

/**
 * Fetch a collection of `Scenario` objects for a Project.
 * @param {string} projectId A project identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * `Scenario` objects if successful, otherwise rejects with an error.
 */
export const getScenarios = async (projectId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        return resolve(project.scenarios || []);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Scenario` objects which are linked to a Claim.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * `Scenario` objects if successful, otherwise rejects with an error.
 */
export const getScenariosForClaim = async ({ projectId, claimId }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const claim = find(project.claims, { id: claimId });
        if (!claim) return resolve([]);

        const scenarios = filter(project.scenarios, (s) => claim.scenarios.includes(s.id));
        return resolve(scenarios);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch a single `Scenario`.
 * @param {string} projectId A project identifier.
 * @param {string} scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to the `Scenario`
 * if found, otherwise rejects with an error.
 */
export const getScenario = async (projectId, scenarioId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId)
        .then((project) => {
          const scenario = find(project.scenarios, { id: scenarioId });
          if (scenario) {
            return resolve(scenario);
          }
          return reject(new Error('Not found.'));
        })
        .catch((err) => {
          return reject(err);
        });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a `Scenario`.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} variables.scenario The scenario to create.
 * @returns {Promise} Returns a Promise which resolves to the created
 * `Scenario` if successful, otherwise rejects with an error.
 */
export const createScenario = async ({ projectId, scenario }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const newScenario = {
          ...scenario,
          id: generateId(),
          claims: [],
          trials: [],
          createdAt: new Date().toISOString(),
        };
        const currentScenarios = project.scenarios || [];
        updateProject({
          ...project,
          scenarios: [...currentScenarios, newScenario],
        }).then(() => {
          return resolve(newScenario);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Update a `Scenario`.
 * @param {Object} variables The API request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} variables.scenario The scenario to be updated.
 * @returns {Promise} Returns a Promise which resolves to the updated
 * `Scenario` if successful, otherwise rejects with an error.
 */
export const updateScenario = async ({ projectId, scenario }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const updatedScenario = { updatedAt: new Date().toISOString(), ...scenario };
        const currentScenarios = _reject(project.scenarios, { id: scenario.id });
        updateProject({
          ...project,
          scenarios: [...currentScenarios, updatedScenario],
        }).then(() => {
          return resolve(updatedScenario);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};
