import { useParams } from 'react-router-dom';

import Loading from '../../../common/Loading';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { useGetScenario } from '../../../../hooks/scenarios.hooks';
import { useGetTrial, useUnlinkTrialScenario } from '../../../../hooks/trials.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';

const ScenarioUnlinkTrial = ({ onCancel, onSuccess, scenarioId, trialId }) => {
  const { projectId } = useParams();
  const toastsContext = useToastsContext();
  const unlinkTrial = useUnlinkTrialScenario();
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });
  const { data: trial, isLoading: isLoadingTrial } = useGetTrial(projectId, trialId);

  const doUnlink = () => {
    unlinkTrial.mutate(
      {
        projectId,
        trialId,
        scenarioId,
      },
      {
        onSuccess: () => {
          toastsContext.createToast(`Unlinked scenario and trial.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to unlink scenario and trial. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoadingScenario || isLoadingTrial) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">UNLINK SCENARIO AND TRIAL</h3>

      <div className="mb-4">
        You are about to unlink scenario <span className="italic">{scenario.name}</span> and trial{' '}
        <span className="italic">{trial.name}</span>.
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={unlinkTrial.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={doUnlink}
          disabled={unlinkTrial.isLoading}
          isLoading={unlinkTrial.isLoading}
        >
          UNLINK
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default ScenarioUnlinkTrial;
