import * as Yup from 'yup';

export const evidenceSchema = Yup.object({
  name: Yup.string().max(254, 'Must be 254 characters or less').required('Required'),
  details: Yup.string().max(1000, 'Must be 1000 characters or less'),
  sampleSize: Yup.number().min(0, 'Must be 0 or greater'),
  biomarker: Yup.string().max(254, 'Must be 254 characters or less'),
  populationAgeGroup: Yup.string(),
  endpoint: Yup.string().max(254, 'Must be 254 characters or less'),
  power: Yup.number().min(0, 'Must be 0 or greater'),
  hazardRatio: Yup.string().matches(/^[0-9]+:[0-9]+$/, 'Must be formatted N:N, e.g. 1:10'),
});

export const linkClaimSchema = Yup.object({
  claimId: Yup.string().required('Required'),
});

export const linkTrialSchema = Yup.object({
  trialId: Yup.string().required('Required'),
});
