import { useSearchParams } from 'react-router-dom';

import Button from '../../../common/Button';

const ClaimScenarioListMenu = ({ claim }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex items-center">
      <div className="font-bold uppercase text-sky-900">CLAIM SCENARIOS</div>
      <Button
        size="sm"
        variant="primary"
        className="ml-auto w-48 rounded"
        onClick={() => {
          searchParams.append('linkClaimScenario', claim.id);
          setSearchParams(searchParams);
        }}
      >
        LINK SCENARIO
      </Button>
    </div>
  );
};

export default ClaimScenarioListMenu;
