import { useToastsContext } from '../../hooks/toasts.hooks';
import Toast from './Toast';

/**
 * The `Toasts` component is a container for a collection of `Toast` items.
 * Each individual `Toast` is rendered within this component. Position this
 * component within the DOM where all `Toast` notifications should be rendered.
 * @see {@link Toast}
 * @returns JSX
 */
const Toasts = () => {
  const toastContext = useToastsContext();

  return (
    <div id="toasts" className="absolute bottom-0 left-0 z-50 p-4">
      {toastContext.toasts.map((toast) => (
        <Toast key={toast.id} onDismiss={toastContext.removeToast} {...toast} />
      ))}
    </div>
  );
};

export default Toasts;
