import { Draggable } from 'react-beautiful-dnd';

import EvidenceCard from './EvidenceCard';

const DraggableEvidenceCard = ({ evidence, index, ...props }) => {
  if (!evidence) return null;

  return (
    <Draggable draggableId={evidence.id} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <EvidenceCard evidence={evidence} {...props} />
        </div>
      )}
    </Draggable>
  );
};

export default DraggableEvidenceCard;
