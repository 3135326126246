import { IndicationListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import ScenarioIndicationListEmpty from './ScenarioIndicationListEmpty';
import ScenarioIndicationListContent from './ScenarioIndicationListContent';

const ScenarioIndicationList = ({ scenario, projectId }) => {
  return (
    <div id="scenario-edit-claim-list" className="h-full">
      <ScenarioIndicationListEmpty scenario={scenario} indications={scenario.indications} />
      <IndicationListControlsContextProvider
        initialState={{ sort: { by: 'therapeuticArea' }, pagination: { size: 5 } }}
      >
        <ScenarioIndicationListContent
          projectId={projectId}
          scenario={scenario}
          indications={scenario.indications}
        />
      </IndicationListControlsContextProvider>
    </div>
  );
};

export default ScenarioIndicationList;
