import Icon from '../../../common/Icon';

import { useTrialListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ScenarioTrialListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useTrialListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const ScenarioTrialListHeader = () => {
  return (
    <div className="grid grid-cols-12 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-8">
        <ScenarioTrialListColumnHeading name="name" title="NAME" />
      </div>
      <div>
        <ScenarioTrialListColumnHeading name="riskScore" title="RISK" />
      </div>
      <div>
        <ScenarioTrialListColumnHeading name="time" title="TIME" />
      </div>
      <div>
        <ScenarioTrialListColumnHeading name="cost" title="COST" />
      </div>
      <div></div>
    </div>
  );
};

export default ScenarioTrialListHeader;
