import classNames from 'classnames';

import Icon from './Icon';

/**
 * Returns the CSS classes for the modal size.
 * @param {string} size The size option.
 * @returns {string} CSS classes
 */
const getModalSize = (size) => {
  switch (size) {
    case 'xl':
      return 'w-[1100px]';
    case 'lg':
      return 'w-[800px]';
    case 'sm':
      return 'w-[300px]';
    default:
      return 'w-[500px]';
  }
};

/**
 * The `Modal` component renders a standard modal block. The modal content is
 * supplied as `children`.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Modal = ({ children, onClose, showClose = true, size }) => {
  const close = () => {
    onClose && onClose();
  };

  return (
    <div className="absolute top-0 left-0 z-[1000] flex h-screen w-screen items-center justify-center bg-slate-300/50 backdrop-blur-sm">
      <div className={classNames('relative z-[1001] m-4', getModalSize(size))}>
        {showClose && (
          <button
            type="button"
            className="absolute -top-2 -right-2 rounded-full border border-slate-200 bg-white p-2"
            onClick={close}
          >
            <Icon icon="xmark" className="h-6 w-6" />
          </button>
        )}

        {children}
      </div>
    </div>
  );
};

export default Modal;
