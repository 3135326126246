import { useField } from 'formik';

/**
 * The `CheckboxField` component is a custom Formik input field for collecting form
 * values as checkboxes.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const CheckboxField = ({ values = [], ...props }) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;

  if (values.length === 0) {
    return null;
  }

  return (
    <>
      {values.map(({ label, value }, index) => (
        <div className="flex items-center" key={index}>
          <input
            type="checkbox"
            {...field}
            {...props}
            checked={field.value.includes(value)}
            className="mr-2 rounded focus:ring-0"
            value={value}
            disabled={props.disabled || props.readOnly}
          />
          <label htmlFor={props.id || props.name}>{label}</label>
        </div>
      ))}
      {showError ? <div className="mt-1 text-sm text-red-600">{meta.error}</div> : null}
    </>
  );
};

export default CheckboxField;
