import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createEvidence,
  getEvidence,
  getAllEvidence,
  getAllEvidenceForClaim,
  getAllEvidenceForTrial,
  updateEvidence,
} from '../api/evidence.api';
import { linkClaimAndEvidence, unlinkClaimAndEvidence } from '../api/claims.api';
import { QueryKeys } from '../utils/constants';
import { useNotify } from './notifications.hooks';

/**
 * React query hook to fetch all `Evidence` items for a project.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAllEvidence = ({ projectId }, options) => {
  return useQuery([QueryKeys.Evidence, projectId], () => getAllEvidence(projectId), options);
};

/**
 * React query hook to fetch all `Evidence` items linked to a Claim.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.claimId A claim identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAllEvidenceForClaim = ({ projectId, claimId }, options) => {
  return useQuery(
    [QueryKeys.Evidence, projectId, claimId],
    () => getAllEvidenceForClaim({ projectId, claimId }),
    options,
  );
};

/**
 * React query hook to fetch all `Evidence` items linked to a Trial.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.trialId A trial identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAllEvidenceForTrial = ({ projectId, trialId }, options) => {
  return useQuery(
    [QueryKeys.Evidence, projectId, trialId],
    () => getAllEvidenceForTrial({ projectId, trialId }),
    options,
  );
};

/**
 * React query hook to fetch a single `Evidence` item.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {string} variables.evidenceId A evidence identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetEvidence = ({ projectId, evidenceId }, options) => {
  return useQuery(
    [QueryKeys.Evidence, projectId, evidenceId],
    () => getEvidence(projectId, evidenceId),
    options,
  );
};

/**
 * React query mutation hook to create an `Evidence` item.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCreateEvidence = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(createEvidence, {
    ...options,
    onSuccess: (data) => {
      notify({ text: `Created evidence ${data.name}.` });
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to update an `Evidence` item.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUpdateEvidence = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(updateEvidence, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Updated evidence ${data.name}.` });
      queryClient.setQueryData([QueryKeys.Evidence, variables.projectId, data.id]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to link an `Evidence` item with a Claim.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useLinkClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(linkClaimAndEvidence, {
    ...options,
    onMutate: ({ projectId, claimId, evidenceId }) => {
      // do stuff immediately on mutation invocation
    },
    onSuccess: (data, variables) => {
      notify({ text: `Linked claim with evidence.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to unlink an `Evidence` item and a Claim.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUnlinkClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(unlinkClaimAndEvidence, {
    ...options,
    onMutate: ({ projectId, claimId, evidenceId }) => {
      // do stuff immediately on mutation invocation
    },
    onSuccess: (data, variables) => {
      notify({ text: `Unlinked claim and evidence.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
