import filter from 'lodash/filter';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

import { therapeutics } from '../data/therapeutics.data';

/**
 * Fetch all `TherapeuticArea` objects.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * Therapeutic Areas if successful, otherwise rejects with an error.
 */
export const getTherapeuticAreas = async () => {
  return new Promise((resolve, reject) => {
    try {
      return resolve(uniq(map(therapeutics, 'therapeuticArea')).sort());
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch all `Indication` objects.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * Indications if successful, otherwise rejects with an error.
 */
export const getIndications = async () => {
  return new Promise((resolve, reject) => {
    try {
      return resolve(uniq(map(therapeutics, 'indication')).sort());
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch all `Indication` objects for a Therapeutic Area.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * Indications if successful, otherwise rejects with an error.
 */
export const getIndicationsByTherapeuticArea = async ({ therapeuticArea }) => {
  return new Promise((resolve, reject) => {
    try {
      if (therapeuticArea) {
        return resolve(uniq(map(filter(therapeutics, { therapeuticArea }), 'indication')).sort());
      } else {
        return resolve(getIndications());
      }
    } catch (err) {
      return reject(err);
    }
  });
};
