import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createScenario,
  getScenario,
  getScenarios,
  getScenariosForClaim,
  updateScenario,
} from '../api/scenarios.api';
import { linkClaimAndScenario, unlinkClaimAndScenario } from '../api/claims.api';
import { QueryKeys } from '../utils/constants';
import { useNotify } from './notifications.hooks';

export const useGetScenarios = ({ projectId }, options) => {
  return useQuery([QueryKeys.Scenarios, projectId], () => getScenarios(projectId), options);
};

export const useGetScenariosForClaim = ({ projectId, claimId }, options) => {
  return useQuery(
    [QueryKeys.Scenarios, projectId, claimId],
    () => getScenariosForClaim({ projectId, claimId }),
    options,
  );
};

export const useGetScenario = ({ projectId, scenarioId }, options) => {
  return useQuery(
    [QueryKeys.Scenarios, projectId, scenarioId],
    () => getScenario(projectId, scenarioId),
    options,
  );
};

export const useCreateScenario = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(createScenario, {
    ...options,
    onSuccess: (data) => {
      notify({ text: `Created scenario ${data.name}.` });
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

export const useUpdateScenario = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(updateScenario, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Updated scenario ${data.name}.` });
      queryClient.setQueryData([QueryKeys.Scenarios, variables.projectId, data.id]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

export const useLinkClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(linkClaimAndScenario, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Linked scenario and claim.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

export const useUnlinkClaim = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(unlinkClaimAndScenario, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Unlinked scenario and claim.` });
      queryClient.invalidateQueries([QueryKeys.Claims]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
