import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  copyTrial,
  createTrial,
  exportTrial,
  getAllTrialsForEvidence,
  getAllTrialsForScenario,
  getTrial,
  getTrials,
  linkTrialAndEvidence,
  linkTrialAndScenario,
  unlinkTrialAndEvidence,
  unlinkTrialAndScenario,
  updateTrial,
} from '../api/trials.api';
import { useNotify } from './notifications.hooks';
import { QueryKeys } from '../utils/constants';

/**
 * React query hook to fetch all `Trials` for a project.
 * @param {string} projectId A project identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetTrials = (projectId, options) => {
  return useQuery([QueryKeys.Trials, projectId], () => getTrials(projectId), options);
};

/**
 * React query hook to fetch all `Trials` for a project and linked to an
 * Evidence item.
 * @param {string} projectId A project identifier.
 * @param {string} evidenceId An evidence identifier.
 * @param {Object} options Use query options.
 * @returns A React Query `useQuery` hook.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAllTrialsForEvidence = (projectId, evidenceId, options) => {
  return useQuery(
    [QueryKeys.Trials, projectId, evidenceId],
    () => getAllTrialsForEvidence(projectId, evidenceId),
    options,
  );
};

/**
 * React query hook to fetch all `Trials` for a project and linked to a
 * Scenario.
 * @param {string} projectId A project identifier.
 * @param {string} scenarioId A scenario identifier.
 * @param {Object} options Use query options.
 * @returns A React Query `useQuery` hook.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAllTrialsForScenario = (projectId, scenarioId, options) => {
  return useQuery(
    [QueryKeys.Trials, projectId, scenarioId],
    () => getAllTrialsForScenario(projectId, scenarioId),
    options,
  );
};

/**
 * React query hook to fetch a single `Trial`.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetTrial = (projectId, trialId, options) => {
  return useQuery(
    [QueryKeys.Trials, projectId, trialId],
    () => getTrial(projectId, trialId),
    options,
  );
};

/**
 * React query mutation hook to create a `Trial`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCreateTrial = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trial }) => createTrial(projectId, trial),
    ...options,
    onSuccess: (trial) => {
      notify({ text: `Created trial ${trial.name}.` });
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to update a `Trial`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUpdateTrial = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trial }) => updateTrial(projectId, trial),
    ...options,
    onSuccess: (trial, variables) => {
      notify({ text: `Updated trial ${trial.name}.` });
      queryClient.setQueryData([QueryKeys.Trials, variables.projectId, trial.id], trial);
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to link a `Trial` and an `Evidence`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useLinkTrialEvidence = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trialId, evidenceId }) =>
      linkTrialAndEvidence(projectId, trialId, evidenceId),
    ...options,
    onSuccess: (trial) => {
      notify({ text: `Linked trial and evidence.` });
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to unlink a `Trial` and an `Evidence`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUnlinkTrialEvidence = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trialId, evidenceId }) =>
      unlinkTrialAndEvidence(projectId, trialId, evidenceId),
    ...options,
    onSuccess: (trial) => {
      notify({ text: `Unlinked trial and evidence.` });
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to link a `Trial` and a `Scenario`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useLinkTrialScenario = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trialId, scenarioId }) =>
      linkTrialAndScenario(projectId, trialId, scenarioId),
    ...options,
    onSuccess: (trial) => {
      notify({ text: `Linked trial and scenario.` });
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to unlink a `Trial` and a `Scenario`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUnlinkTrialScenario = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trialId, scenarioId }) =>
      unlinkTrialAndScenario(projectId, trialId, scenarioId),
    ...options,
    onSuccess: (trial) => {
      notify({ text: `Unlinked trial and scenario.` });
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to copy a `Trial`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCopyTrial = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trialId }) => copyTrial(projectId, trialId),
    ...options,
    onSuccess: (trial) => {
      notify({ text: `Copied trial ${trial.name}.` });
      queryClient.invalidateQueries([QueryKeys.Trials]);
      queryClient.invalidateQueries([QueryKeys.Scenarios]);
      queryClient.invalidateQueries([QueryKeys.Evidence]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(trial);
    },
  });
};

/**
 * React query mutation hook to export a `Trial`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useExportTrial = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, trialId }) => exportTrial(projectId, trialId),
    ...options,
    onSuccess: (data) => {
      notify({ text: `Exported trial file ${data}.` });
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
