import { useNavigate } from 'react-router-dom';

import ButtonBar from '../common/ButtonBar';
import Button from '../common/Button';

const ProjectsButtonBar = () => {
  const navigate = useNavigate();

  return (
    <ButtonBar>
      <Button
        type="button"
        variant="primary"
        className="mt-auto w-48 rounded"
        onClick={() => navigate('/projects/create')}
      >
        CREATE NEW
      </Button>
    </ButtonBar>
  );
};

export default ProjectsButtonBar;
