import { useEffect } from 'react';

import Icon from '../common/Icon';

import { Configuration } from '../../utils/config';
import { useSignOut } from '../../hooks/cognito.hooks';

const CognitoSignOut = () => {
  const signOut = useSignOut();

  useEffect(() => {
    document.title = 'Sign Out | IQVIA Design Center';
  }, []);

  useEffect(() => {
    signOut.mutate(null, {
      onSuccess: () => {
        const config = Configuration.get();
        const { clientId, domain, signoutUrl } = config.auth.cognito;
        const cognitoUrl = `https://${domain}/logout?client_id=${clientId}&logout_uri=${signoutUrl}`;
        window.location.replace(cognitoUrl);
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex items-center justify-center">
      <Icon icon="arrow-right-from-bracket" fade className="mr-2 h-6 w-6 text-sky-900" />
      <span className="text-2xl font-bold text-sky-900">SIGNING OUT...</span>
    </div>
  );
};

export default CognitoSignOut;
