import { Formik } from 'formik';

import AssetForm from '../common/AssetForm';

import { assetSchema } from '../../../validations/asset.validations';
import { useUpdateAsset } from '../../../hooks/asset.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const AssetEditForm = ({ projectId, asset }) => {
  const toastsContext = useToastsContext();
  const updateAsset = useUpdateAsset();

  return (
    <Formik
      initialValues={{ ...asset }}
      enableReinitialize={true}
      validationSchema={assetSchema}
      onSubmit={(values, { setSubmitting }) => {
        updateAsset.mutate(
          { projectId, asset: values },
          {
            onSuccess: (asset) => {
              setSubmitting(false);
              toastsContext.createToast(`Updated asset information.`);
            },
            onError: (err) => {
              console.error(`Failed to update project asset. Detail:`, err);
              setSubmitting(false);
            },
          },
        );
      }}
    >
      {(formik) => <AssetForm formik={formik} />}
    </Formik>
  );
};

export default AssetEditForm;
