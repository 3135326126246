import classNames from 'classnames';

import { useAuthState } from '../../hooks/cognito.hooks';

// Color option hash object.
const colorOptions = {
  blue: 'bg-blue-500 text-white',
  green: 'bg-lime-600 text-white',
  orange: 'bg-orange-500 text-white',
  purple: 'bg-violet-600 text-white',
  red: 'bg-red-600 text-white',
  slate: 'bg-slate-500 text-white',
  yellow: 'bg-yellow-400 text-white',
};

/**
 * Get the color CSS classes for a user's name.
 * @param {string} name A user name.
 * @returns {string} Color classnames.
 */
const getColorClasses = (name) => {
  const colors = Object.keys(colorOptions);
  const color = colorOptions[colors[name.length % colors.length]];
  return color;
};

/**
 * The `Avatar` component renders a icon customized for a user.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Avatar = ({ className }) => {
  const { data: authState, isLoading } = useAuthState();

  if (isLoading) return null;

  const name = authState.name || authState.username || '?';
  const colorClasses = getColorClasses(name);

  return (
    <div
      className={classNames(
        'flex h-10 w-10 items-center justify-center rounded-full text-xl font-bold uppercase',
        colorClasses,
        className,
      )}
      title={name}
    >
      <span className="font-display uppercase">{name.charAt(0)}</span>
    </div>
  );
};

export default Avatar;
