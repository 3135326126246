import ClaimScenarioListMenu from './ClaimScenarioListMenu';
import ClaimScenarioListItem from './ClaimScenarioListItem';
import ClaimScenarioListHeader from './ClaimScenarioListHeader';
import ClaimScenarioListFooter from './ClaimScenarioListFooter';

import { selectScenariosWithPagination } from '../../../../selectors/scenarios.selectors';
import { useScenarioListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ClaimScenarioListContent = ({ claim, scenarios, projectId }) => {
  const { data: listControls } = useScenarioListControlsContext();

  if (scenarios.length === 0) {
    return null;
  }

  const { data: allClaims, page } = selectScenariosWithPagination(scenarios, listControls);

  return (
    <>
      <ClaimScenarioListMenu claim={claim} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <ClaimScenarioListHeader />

        {page.map((scenario, index) => (
          <ClaimScenarioListItem
            key={scenario.id}
            claim={claim}
            scenario={scenario}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <ClaimScenarioListFooter totalItemsOnPage={page.length} totalItems={allClaims.length} />
        </div>
      </div>
    </>
  );
};

export default ClaimScenarioListContent;
