import { Outlet } from 'react-router-dom';

import WithModals from '../common/WithModals';
import { ProjectListControlsContextProvider } from '../../contexts/listcontrols.context';

const ProjectsPage = () => {
  return (
    <WithModals>
      <ProjectListControlsContextProvider initialState={{ sort: { by: 'name' } }}>
        <Outlet />
      </ProjectListControlsContextProvider>
    </WithModals>
  );
};

export default ProjectsPage;
