import Loading from '../../common/Loading';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { useGetProject, useExportProject } from '../../../hooks/projects.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ProjectExport = ({ onCancel, onSuccess, projectId }) => {
  const exportProject = useExportProject();
  const { data: project, status } = useGetProject(projectId);
  const toastsContext = useToastsContext();

  const onExport = () => {
    exportProject.mutate(
      { projectId: project.id },
      {
        onSuccess: (fileName) => {
          toastsContext.createToast(`Exported project file: ${fileName}`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to export project. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (status === 'loading') {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">EXPORT PROJECT</h3>

      <div className="mb-4">
        You are about to export project <span className="italic">{project.name}</span>.
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={exportProject.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={onExport}
          disabled={exportProject.isLoading}
          isLoading={exportProject.isLoading}
        >
          EXPORT
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default ProjectExport;
