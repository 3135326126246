import { useParams } from 'react-router-dom';

import Loading from '../../../common/Loading';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { useGetTrial, useUnlinkTrialEvidence } from '../../../../hooks/trials.hooks';
import { useGetEvidence } from '../../../../hooks/evidence.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';

const TrialUnlinkEvidence = ({ onCancel, onSuccess, trialId, evidenceId }) => {
  const { projectId } = useParams();
  const toastsContext = useToastsContext();
  const unlinkEvidence = useUnlinkTrialEvidence();
  const { data: trial, isLoading: isLoadingTrial } = useGetTrial(projectId, trialId);
  const { data: evidence, isLoading: isLoadingEvidence } = useGetEvidence({
    projectId,
    evidenceId,
  });

  const unlinkTrialEvidence = () => {
    unlinkEvidence.mutate(
      {
        projectId,
        trialId,
        evidenceId,
      },
      {
        onSuccess: () => {
          toastsContext.createToast(`Unlinked trial and evidence.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to unlink trial and evidence. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoadingTrial || isLoadingEvidence) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">UNLINK TRIAL AND EVIDENCE</h3>

      <div className="mb-4">
        You are about to unlink trial <span className="italic">{trial.name}</span> and evidence{' '}
        <span className="italic">{evidence.name}</span>.
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={unlinkEvidence.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={unlinkTrialEvidence}
          disabled={unlinkEvidence.isLoading}
          isLoading={unlinkEvidence.isLoading}
        >
          UNLINK
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default TrialUnlinkEvidence;
