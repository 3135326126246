import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faCircle,
  faFlag as faFlagRegular,
  faSquareCheck,
  faSquare,
  faStar as faStarRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faArrowDown,
  faArrowLeft,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowUp,
  faBox,
  faBoxOpen,
  faC,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCodeMerge,
  faDollarSign,
  faE,
  faEllipsisVertical,
  faFile,
  faFlag as faFlagSolid,
  faFolder,
  faFolderTree,
  faLandmark,
  faList,
  faMagnifyingGlass,
  faMinus,
  faMoneyBill,
  faNotdef,
  faPlus,
  faRotate,
  faS,
  faScaleBalanced,
  faStar as faStarSolid,
  faStethoscope,
  faStopwatch,
  faSwatchbook,
  faT,
  faTableColumns,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

const getIconByName = (name) => {
  switch (name) {
    case '0':
      return fa0;
    case '1':
      return fa1;
    case '2':
      return fa2;
    case '3':
      return fa3;
    case '4':
      return fa4;
    case '5':
      return fa5;
    case 'arrow-down':
      return faArrowDown;
    case 'arrow-left':
      return faArrowLeft;
    case 'arrow-right-from-bracket':
      return faArrowRightFromBracket;
    case 'arrow-right-to-bracket':
      return faArrowRightToBracket;
    case 'arrow-up':
      return faArrowUp;
    case 'bell':
      return faBell;
    case 'box':
      return faBox;
    case 'box-open':
      return faBoxOpen;
    case 'c':
      return faC;
    case 'chevron-left':
      return faChevronLeft;
    case 'chevron-right':
      return faChevronRight;
    case 'chevron-up':
      return faChevronUp;
    case 'circle':
      return faCircle;
    case 'circle-info':
      return faCircleInfo;
    case 'code-merge':
      return faCodeMerge;
    case 'dollar-sign':
      return faDollarSign;
    case 'e':
      return faE;
    case 'ellipsis-vertical':
      return faEllipsisVertical;
    case 'file':
      return faFile;
    case 'flag':
      return faFlagSolid;
    case 'flag-regular':
      return faFlagRegular;
    case 'folder':
      return faFolder;
    case 'folder-tree':
      return faFolderTree;
    case 'landmark':
      return faLandmark;
    case 'list':
      return faList;
    case 'magnifying-glass':
      return faMagnifyingGlass;
    case 'minus':
      return faMinus;
    case 'money-bill':
      return faMoneyBill;
    case 'rotate':
      return faRotate;
    case 'plus':
      return faPlus;
    case 's':
      return faS;
    case 'scale-balanced':
      return faScaleBalanced;
    case 'square':
      return faSquare;
    case 'square-check':
      return faSquareCheck;
    case 'star':
      return faStarSolid;
    case 'star-regular':
      return faStarRegular;
    case 'stethoscope':
      return faStethoscope;
    case 'stopwatch':
      return faStopwatch;
    case 'swatchbook':
      return faSwatchbook;
    case 't':
      return faT;
    case 'table-columns':
      return faTableColumns;
    case 'triangle-exclamation':
      return faTriangleExclamation;
    case 'xmark':
      return faXmark;
    default:
      return faNotdef;
  }
};
/**
 * The `Icon` component renders an icon from the Font Awesome library.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Icon = ({ icon, ...props }) => {
  return <FontAwesomeIcon icon={getIconByName(icon)} {...props} />;
};

export default Icon;
