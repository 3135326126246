import { useParams } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';

import DraggableEvidenceCard from '../../evidence/common/DraggableEvidenceCard';

import { useGetAllEvidence } from '../../../hooks/evidence.hooks';
import { selectEvidence } from '../../../selectors/evidence.selectors';

const ColumnEvidence = () => {
  const { projectId } = useParams();
  const { data: evidenceList } = useGetAllEvidence({ projectId });
  const selectedEvidence = selectEvidence(evidenceList);

  return (
    <Droppable droppableId="column-evidence">
      {(provided) => (
        <div
          className="h-full overflow-y-auto rounded-b-lg px-2 py-4 shadow-lg shadow-slate-500/50"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {selectedEvidence.map((evidence, index) => (
            <DraggableEvidenceCard
              key={evidence.id}
              index={index}
              evidence={evidence}
              variant="amber"
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ColumnEvidence;
