import { useNavigate, useParams } from 'react-router-dom';

import TrialListFilter from './TrialListFilter';
import Spinner from '../../common/Spinner';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';

const TrialListMenu = ({ isFetching }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  return (
    <div className="flex items-center">
      <h3 className="font-bold text-sky-900">ALL TRIALS</h3>
      <Spinner show={isFetching} className="ml-2" />
      <TrialListFilter className="ml-auto" />
      <ButtonBar className="ml-4">
        <Button
          size="sm"
          variant="primary"
          title="Create new trial"
          className="w-32 rounded"
          onClick={() => navigate(`/projects/${projectId}/trials/create`)}
        >
          CREATE TRIAL
        </Button>
      </ButtonBar>
    </div>
  );
};

export default TrialListMenu;
