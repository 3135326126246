import { ClaimListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import ScenarioClaimListLoading from './ScenarioClaimListLoading';
import ScenarioClaimListEmpty from './ScenarioClaimListEmpty';
import ScenarioClaimListContent from './ScenarioClaimListContent';

import { useGetClaimsForScenario } from '../../../../hooks/claims.hooks';

const ScenarioClaimList = ({ scenario, projectId }) => {
  const { data: claims, isLoading } = useGetClaimsForScenario({
    projectId,
    scenarioId: scenario.id,
  });

  return (
    <div id="scenario-edit-claim-list" className="h-full">
      {isLoading ? (
        <ScenarioClaimListLoading />
      ) : (
        <>
          <ScenarioClaimListEmpty scenario={scenario} claims={claims} />
          <ClaimListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <ScenarioClaimListContent projectId={projectId} scenario={scenario} claims={claims} />
          </ClaimListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default ScenarioClaimList;
