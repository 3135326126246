import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import ClaimListFilter from './ClaimListFilter';
import Spinner from '../../common/Spinner';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';

import { useClaimListControlsContext } from '../../../hooks/listcontrols.hooks';

const ClaimListMenu = ({ isFetching }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data: listControls, setMatches } = useClaimListControlsContext();

  const toggleFilterByType = (type = '') => {
    const { matches } = listControls;
    if (!matches.type) {
      setMatches({
        ...matches,
        type: [type],
      });
    } else {
      if (matches.type.includes(type)) {
        setMatches({
          ...matches,
          type: matches.type.filter((t) => t !== type),
        });
      } else {
        setMatches({
          ...matches,
          type: [...matches.type, type],
        });
      }
    }
  };

  const toggleFilterArchived = () => {
    const { matches = {} } = listControls;
    setMatches({
      ...matches,
      isArchived: !matches.isArchived,
    });
  };

  return (
    <div className="flex items-center">
      <h3 className="font-bold text-sky-900">ALL CLAIMS</h3>
      <Spinner show={isFetching} className="ml-2" />
      <ClaimListFilter className="ml-auto" />
      <ButtonBar className="ml-4">
        <Button
          size="sm"
          variant="primary"
          title="Show/hide archived"
          className="flex h-[30px] w-8 items-center justify-center rounded-l border-r border-r-white/30"
          isPressed={listControls.matches.isArchived}
          onClick={() => toggleFilterArchived()}
        >
          <Icon icon="box" fixedWidth className="inline-block h-4 w-4" />
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="Show/hide clinical"
          className="flex h-[30px] w-8 items-center justify-center border-r border-r-white/30"
          isPressed={listControls.matches.type && listControls.matches.type.includes('clinical')}
          onClick={() => toggleFilterByType('clinical')}
        >
          <Icon icon="stethoscope" fixedWidth className="inline-block h-4 w-4" />
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="Show/hide commercial"
          className="mr-2 flex h-[30px] w-8 items-center justify-center rounded-r"
          isPressed={listControls.matches.type && listControls.matches.type.includes('commercial')}
          onClick={() => toggleFilterByType('commercial')}
        >
          <Icon icon="landmark" fixedWidth className="inline-block h-4 w-4" />
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="View list"
          className="flex w-16 items-center justify-center rounded-l border-r border-r-white/30"
          isPressed={!location.pathname.includes('/board')}
          onClick={() => navigate(`/projects/${projectId}/claims`)}
        >
          <Icon icon="list" fixedWidth className="mr-0.5 inline-block h-3.5 w-3.5" />
          LIST
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="View board"
          className="mr-2 flex w-20 items-center justify-center rounded-r"
          isPressed={location.pathname.includes('/board')}
          onClick={() => navigate(`/projects/${projectId}/claims/board`)}
        >
          <Icon
            icon="table-columns"
            fixedWidth
            className="mr-0.5 inline-block h-3.5 w-3.5 rounded"
          />
          BOARD
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="Create new claim"
          className="w-32 rounded"
          onClick={() => navigate(`/projects/${projectId}/claims/create`)}
        >
          CREATE CLAIM
        </Button>
      </ButtonBar>
    </div>
  );
};

export default ClaimListMenu;
