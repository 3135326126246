import { Droppable } from 'react-beautiful-dnd';

import DraggableClaimCard from '../common/DraggableClaimCard';

import { selectClaims, selectStandaloneClaims } from '../../../selectors/claims.selectors';

const ColumnClaims = ({ claims = [] }) => {
  const selectedClaims = selectClaims(selectStandaloneClaims(claims), { isPrioritized: false });

  return (
    <Droppable droppableId="column-claims">
      {(provided) => (
        <div
          className="h-full overflow-y-auto rounded-b-lg px-2 py-4 shadow-lg shadow-slate-500/50"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {selectedClaims.map((claim, index) => (
            <DraggableClaimCard key={claim.id} claim={claim} index={index} variant="secondary" />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ColumnClaims;
