import { useField } from 'formik';
import classNames from 'classnames';

import Icon from './Icon';

/**
 * The `Star` component renders a single star icon. The properties dictate
 * if the star is solid or outline.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Star = ({ isSolid, onClick }) => {
  const isClickable = !!onClick;

  return isSolid ? (
    <Icon
      icon="star"
      className={classNames('h-4 w-4 text-yellow-500', { 'cursor-pointer': isClickable })}
      onClick={onClick}
    />
  ) : (
    <Icon
      icon="star-regular"
      className={classNames('h-4 w-4 text-yellow-500', { 'cursor-pointer': isClickable })}
      onClick={onClick}
    />
  );
};

/**
 * The `Stars` component renders a series of `Star` components in a horizontal
 * bar. The properties control how many stars to depict and how many are
 * solid vs. outline. Handles `onClick` action to set the value when used as
 * a form field.
 * @param {Object} props The component properties.
 * @returns JSX
 */
export const Stars = ({ count = 0, value = 0, setValue }) => {
  const isClickable = !!setValue;

  const stars = [];
  for (let i = 0; i < count; i++) {
    const starValue = i + 1;
    const isCurrentValue = value === starValue;
    const onClick = isClickable ? () => setValue(isCurrentValue ? 0 : starValue) : undefined;
    stars.push(<Star key={i} isSolid={value > i} onClick={onClick} />);
  }

  return <div className="flex items-center">{stars}</div>;
};

/**
 * The `StarField` renders a custom Formik field for collecting numeric values
 * represented as a series of stars. Useful for collecting and displaying
 * user-assigned scores for items.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const StarField = ({ label, labelClassName, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const { value, touched, error } = meta;
  const { setValue } = helpers;
  const showError = touched && error;

  const setFieldValue = (value) => {
    if (props.readOnly || props.disabled) return;
    setValue(value);
  };

  return (
    <div className="flex h-10 items-center justify-between">
      <div
        htmlFor={props.id || props.name}
        className={classNames('mb-1 font-bold text-slate-700', labelClassName)}
      >
        {label}
      </div>
      <Stars count={5} value={value} setValue={setFieldValue} />
      {showError ? <div className="mt-1 text-sm text-red-600">{meta.error}</div> : null}
    </div>
  );
};

export default StarField;
