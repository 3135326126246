import { useField } from 'formik';
import { useState } from 'react';
import classNames from 'classnames';
import numeral from 'numeral';

import Icon from './Icon';
import Tooltip from './Tooltip';
import { usePopperTooltip } from 'react-popper-tooltip';

/**
 * The `CurrencyField` component is a custom Formik input field for collecting form
 * values from an `input` field of type number displaying those values formatted as
 * currency.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const CurrencyField = ({ label, labelClassName = 'text-slate-700', tooltip, ...props }) => {
  const formatCurrency = (currency = 0) => {
    return numeral(currency).format('(0,0)');
  };

  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);
  const [formattedCurrency, setFormattedCurrency] = useState(formatCurrency(meta.value));
  const showError = meta.touched && meta.error;
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'top',
  });

  const handleChange = (e) => {
    !!e && e.preventDefault();
    const costValue = numeral(e.target.value).value() || 0;
    helpers.setTouched(true);
    helpers.setValue(costValue);
    setFormattedCurrency(formatCurrency(e.target.value));
  };

  return (
    <>
      <label
        htmlFor={props.id || props.name}
        className={classNames('flex items-center', labelClassName)}
      >
        <span className="font-bold">{label}</span>
        {!!tooltip && (
          <>
            <div ref={setTriggerRef}>
              <Icon
                icon="circle-info"
                className="ml-0.5 mb-1 inline-block h-3.5 w-3.5 cursor-help text-sky-500"
              />
            </div>
            <Tooltip {...tooltipProps}>{tooltip}</Tooltip>
          </>
        )}
      </label>
      <input
        className={classNames(
          'mt-1 block w-full rounded-md focus:ring focus:ring-opacity-50 disabled:bg-slate-100',
          { 'border-slate-300 text-slate-700 focus:border-sky-300 focus:ring-sky-200': !showError },
          {
            'border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-200': showError,
          },
        )}
        {...props}
        type="text"
        value={formattedCurrency}
        onChange={handleChange}
      />
      {showError ? <div className="mt-1 text-sm text-red-600">{meta.error}</div> : null}
    </>
  );
};

export default CurrencyField;
