import { ArmListControlsContextProvider } from '../../../../../contexts/listcontrols.context';
import TrialArmListEmpty from './TrialArmListEmpty';
import TrialArmListContent from './TrialArmListContent';

const TrialArmList = ({ trial, projectId }) => {
  return (
    <div id="trial-edit-arm-list">
      <TrialArmListEmpty arms={trial.arms} />
      <ArmListControlsContextProvider
        initialState={{ sort: { by: 'startAt' }, pagination: { size: 5 } }}
      >
        <TrialArmListContent projectId={projectId} trial={trial} arms={trial.arms} />
      </ArmListControlsContextProvider>
    </div>
  );
};

export default TrialArmList;
