import { TrialListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import ScenarioTrialListLoading from './ScenarioTrialListLoading';
import ScenarioTrialListEmpty from './ScenarioTrialListEmpty';
import ScenarioTrialListContent from './ScenarioTrialListContent';

import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';

const ScenarioTrialList = ({ scenario, projectId }) => {
  const { data: trials, isLoading } = useGetAllTrialsForScenario(projectId, scenario.id);

  return (
    <div id="scenario-edit-trial-list">
      {isLoading ? (
        <ScenarioTrialListLoading />
      ) : (
        <>
          <ScenarioTrialListEmpty scenario={scenario} trials={trials} />
          <TrialListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <ScenarioTrialListContent projectId={projectId} scenario={scenario} trials={trials} />
          </TrialListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default ScenarioTrialList;
