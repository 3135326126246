import orderBy from 'lodash/orderBy';

/**
 * Select all `Notification` objects which match the supplied criteria. The resulting
 * collection is sorted.
 * @param {Object[]} claims A collection of `Notification` objects.
 * @param {[string[]]} iteratees (Optional) Array of sort attribute names.
 * @param {[string[]]} orders (Optional) Array of sort order directions.
 * @returns {Object[]} A sorted collection of notifications.
 */
export const selectNotifications = (
  notifications = [],
  iteratees = ['createdAt'],
  orders = ['desc'],
) => {
  return orderBy(notifications, iteratees, orders);
};
