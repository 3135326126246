import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';

import TrialArmForm from '../common/TrialArmForm';
import Icon from '../../../../common/Icon';

import { trialArmSchema } from '../../../../../validations/trial.validations';
import { useUpdateTrial } from '../../../../../hooks/trials.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';
import { generateId } from '../../../../../utils/id';

const TrialArmCreate = ({ projectId, trial }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const updateTrial = useUpdateTrial();
  const toastsContext = useToastsContext();

  return (
    <div id="trial-arm-create">
      <div className="mb-8 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">CREATE NEW TRIAL ARM</h3>
      </div>

      <Formik
        initialValues={{
          name: '',
          startAt: '',
          endAt: '',
          sampleSize: 0,
        }}
        validationSchema={trialArmSchema}
        onSubmit={(values, { setSubmitting }) => {
          const armToCreate = {
            id: generateId(),
            ...values,
          };
          updateTrial.mutate(
            {
              projectId,
              trial: {
                ...trial,
                arms: [...trial.arms, armToCreate],
              },
            },
            {
              onSuccess: (trial) => {
                setSubmitting(false);
                toastsContext.createToast(`Created trial arm.`);
                searchParams.set('mode', 'list');
                setSearchParams(searchParams);
              },
              onError: (err) => {
                console.error(`Failed to create trial arm. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <TrialArmForm formik={formik} />}
      </Formik>
    </div>
  );
};

export default TrialArmCreate;
