import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import Submenu from '../../common/Submenu';
import TrialDetail from './TrialDetail';
import TrialArms from './arms/TrialArms';
import TrialEvidenceList from './evidence/TrialEvidenceList';

import { useGetTrial } from '../../../hooks/trials.hooks';

const TrialEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, trialId } = useParams();
  const { data: trial, isLoading } = useGetTrial(projectId, trialId);

  const getViewComponent = () => {
    const view = searchParams.get('view');

    switch (view) {
      case 'arms':
        return <TrialArms projectId={projectId} trial={trial} />;
      case 'evidence':
        return <TrialEvidenceList projectId={projectId} trial={trial} />;
      default:
        return <TrialDetail projectId={projectId} trial={trial} />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  if (!trial) {
    return <Navigate to={`/projects/${projectId}/trials`} />;
  }

  return (
    <div id="trial-edit" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate('..')}
        />
        <h3 className="font-bold">EDIT TRIAL</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Submenu
          items={[
            {
              name: 'DETAIL',
              view: 'detail',
              to: `/projects/${projectId}/trials/${trial.id}?view=detail`,
            },
            {
              name: 'ARMS',
              view: 'arms',
              to: `/projects/${projectId}/trials/${trial.id}?view=arms`,
            },
            {
              name: 'EVIDENCE',
              view: 'evidence',
              to: `/projects/${projectId}/trials/${trial.id}?view=evidence`,
            },
          ]}
        />

        {getViewComponent()}
      </div>
    </div>
  );
};

export default TrialEdit;
