import * as Yup from 'yup';

export const claimSchema = Yup.object({
  name: Yup.string().max(100, 'Must be 100 characters or less').required('Required'),
  type: Yup.array().of(Yup.string()).min(1, 'Required'),
  dueAt: Yup.date(),
  details: Yup.string().max(1000, 'Must be 1000 characters or less'),
  riskScore: Yup.number().min(0).max(5),
  timeScore: Yup.number().min(0).max(5),
  costScore: Yup.number().min(0).max(5),
  overallScore: Yup.number().min(0).max(5),
  isPrioritized: Yup.boolean().default(false),
});

export const mergeClaimSchema = Yup.object({
  targetId: Yup.string().required('Required'),
});

export const linkEvidenceSchema = Yup.object({
  evidenceId: Yup.string().required('Required'),
});

export const linkScenarioSchema = Yup.object({
  scenarioId: Yup.string().required('Required'),
});
