import { useParams } from 'react-router-dom';

import Loading from '../../../../common/Loading';
import ButtonBar from '../../../../common/ButtonBar';
import Button from '../../../../common/Button';
import LoadingButton from '../../../../common/LoadingButton';

import { useGetScenario, useUpdateScenario } from '../../../../../hooks/scenarios.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';

const ScenarioIndicationDelete = ({ onCancel, onSuccess, indicationId }) => {
  const { projectId, scenarioId } = useParams();
  const toastsContext = useToastsContext();
  const updateScenario = useUpdateScenario();
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });

  const deleteIndication = () => {
    updateScenario.mutate(
      {
        projectId,
        scenario: {
          ...scenario,
          indications: scenario.indications.filter((i) => i.id !== indicationId),
        },
      },
      {
        onSuccess: () => {
          toastsContext.createToast(`Deleted indication from scenario.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to delete scenario indication. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoadingScenario) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const indication = scenario.indications.find((i) => i.id === indicationId);

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">DELETE SCENARIO INDICATION</h3>

      <div className="mb-4">
        You are about to delete therapeutic area{' '}
        <span className="italic">{indication.therapeuticArea}</span> and indication{' '}
        <span className="italic">
          {!!indication.indicationOther ? indication.indicationOther : indication.indication}
        </span>
        .
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={updateScenario.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={deleteIndication}
          disabled={updateScenario.isLoading}
          isLoading={updateScenario.isLoading}
        >
          DELETE
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default ScenarioIndicationDelete;
