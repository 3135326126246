import { Formik } from 'formik';

import TrialForm from '../common/TrialForm';

import { useUpdateTrial } from '../../../hooks/trials.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';
import { trialSchema } from '../../../validations/trial.validations';

const TrialDetail = ({ projectId, trial }) => {
  const updateTrial = useUpdateTrial();
  const toastsContext = useToastsContext();

  return (
    <Formik
      initialValues={{ ...trial }}
      validationSchema={trialSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        updateTrial.mutate(
          { projectId, trial: { ...trial, ...values } },
          {
            onSuccess: (trial) => {
              toastsContext.createToast(`Updated trial.`);
              setSubmitting(false);
            },
            onError: (err) => {
              console.error(`Failed to update trial. Detail:`, err);
              // TODO display error notification
              setSubmitting(false);
            },
          },
        );
      }}
    >
      {(formik) => <TrialForm formik={formik} projectId={projectId} />}
    </Formik>
  );
};

export default TrialDetail;
