import Icon from '../../common/Icon';

import { useTrialListControlsContext } from '../../../hooks/listcontrols.hooks';

const TrialListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useTrialListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const TrialListHeader = () => {
  return (
    <div className="grid grid-cols-20 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-7">
        <TrialListColumnHeading name="name" title="NAME" />
      </div>
      <div className="col-span-6">
        <TrialListColumnHeading name="indication" title="INDICATION" />
      </div>
      <div className="col-span-2">
        <TrialListColumnHeading name="phase" title="PHASE" />
      </div>
      <div className="col-span-2">
        <TrialListColumnHeading name="evidence.length" title="EVIDENCE" />
      </div>
      <div className="col-span-2">
        <TrialListColumnHeading name="scenario" title="SCENARIO" />
      </div>
      <div></div>
    </div>
  );
};

export default TrialListHeader;
