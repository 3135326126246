import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createAsset, getAsset, updateAsset } from '../api/asset.api';
import { QueryKeys } from '../utils/constants';
import { useNotify } from './notifications.hooks';

/**
 * React query hook to fetch a single `Asset`.
 * @param {Object} variables The request variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAsset = ({ projectId }, options) => {
  return useQuery([QueryKeys.Asset, projectId], () => getAsset(projectId), options);
};

/**
 * React query mutation hook to create an `Asset`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useCreateAsset = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(createAsset, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Created asset ${data.compoundName}.` });
      queryClient.invalidateQueries([QueryKeys.Asset, variables.projectId]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};

/**
 * React query mutation hook to update an `Asset`.
 * @param {Object} options Use mutation options.
 * @returns {Object} A mutation result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation}
 */
export const useUpdateAsset = (options) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation(updateAsset, {
    ...options,
    onSuccess: (data, variables) => {
      notify({ text: `Updated asset ${data.compoundName}.` });
      queryClient.setQueryData([QueryKeys.Asset, variables.projectId]);
      queryClient.invalidateQueries([QueryKeys.Asset, variables.projectId]);
      queryClient.invalidateQueries([QueryKeys.Notifications]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
