import ProgressRing from '../../common/ProgressRing';

const ScoreRing = ({ maxScore = 5, score = 0 }) => {
  const progress = (score / maxScore) * 100;
  return (
    <div title={`Score: ${score}`}>
      <ProgressRing progress={progress} className="h-4 w-4" />
    </div>
  );
};

export default ScoreRing;
