import * as Yup from 'yup';

export const projectSchema = Yup.object({
  name: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
  client: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
  projectLead: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
  startedAt: Yup.date().required('Required'),
  dueAt: Yup.date().required('Required'),
  stage: Yup.string().required('Required'),
  status: Yup.string()
    .oneOf(
      ['notstarted', 'inprogress', 'complete'],
      'Must be "Not Started", "In Progess", or "Complete"',
    )
    .required('Required'),
});
