import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import forEach from 'lodash/forEach';
import * as _reject from 'lodash/reject';
import union from 'lodash/union';
import without from 'lodash/without';
import * as sheets from 'xlsx';

import { getProject, updateProject } from './projects.api';
import { generateId } from '../utils/id';
import { selectTrialsForExport } from '../selectors/trials.selectors';
import { selectEvidenceForExport } from '../selectors/evidence.selectors';
import { selectClaimsForExport } from '../selectors/claims.selectors';

/**
 * Fetch the collection of `Trial` objects for a Project.
 * @param {string} projectId A project identifier.
 * @returns {Promise} Returns a promise which resolves to a collection of Trials if successful,
 * an empty collection if not found, otherwise rejects with an error.
 */
export const getTrials = async (projectId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        return resolve(project.trials || []);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Trials` objects for a Project and linked to an Evidence item.
 * @param {string} projectId A project identifier.
 * @param {string} evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of Trials
 * if successful, otherwise rejects with an error.
 */
export const getAllTrialsForEvidence = async (projectId, evidenceId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const evidence = find(project.evidence, { id: evidenceId });
        if (!evidence) return [];

        const evidenceTrials = filter(project.trials, (t) => evidence.trials.includes(t.id));
        return resolve(evidenceTrials);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch the collection of `Trials` objects for a Project and linked to a Scenario.
 * @param {string} projectId A project identifier.
 * @param {string} scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of Trials
 * if successful, otherwise rejects with an error.
 */
export const getAllTrialsForScenario = async (projectId, scenarioId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const scenario = find(project.scenarios, { id: scenarioId });
        if (!scenario) return [];

        const scenarioTrials = filter(project.trials, (t) => scenario.trials.includes(t.id));
        return resolve(scenarioTrials);
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Fetch a single `Trial` for the supplied project and trial identifiers.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @returns {Promise} Returns a Promise which resolves to a Trial if found,
 * otherwise rejects with an error.
 */
export const getTrial = async (projectId, trialId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trial = find(project.trials, { id: trialId });
        if (trial) {
          return resolve(trial);
        }
        return reject(new Error('Not found.'));
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a `Trial`.
 * @param {string} projectId A project identifier.
 * @param {Object} trial The Trial to be created.
 * @returns {Promise} Returns a Promise which resolves to the created Trial
 * if successful, otherwise rejects with an error.
 */
export const createTrial = async (projectId, trial) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const newTrial = {
          ...trial,
          id: generateId(),
          arms: [],
          evidence: [],
          createdAt: new Date().toISOString(),
        };
        const currentTrials = project.trials || [];
        updateProject({
          ...project,
          trials: [...currentTrials, newTrial],
        }).then((updatedProject) => {
          if (trial.scenario) {
            linkTrialAndScenario(updatedProject.id, newTrial.id, trial.scenario).then(
              (linkedTrial) => {
                return resolve(linkedTrial);
              },
            );
          } else {
            return resolve(newTrial);
          }
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Update a `Trial`.
 * @param {string} projectId A project identifier.
 * @param {Object} trial The trial to update.
 * @returns {Promise} Returns a Promise which resolves to the updated Trial
 * if successful, otherwise rejects with an error.
 */
export const updateTrial = async (projectId, trial) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const currentTrial = find(project.trials, { id: trial.id });
        const updatedTrial = { ...trial, updatedAt: new Date().toISOString() };
        const otherTrials = _reject(project.trials, { id: trial.id });
        updateProject({
          ...project,
          trials: [...otherTrials, updatedTrial],
        }).then((updatedProject) => {
          // update trial-scenario link if it has changed
          if (trial.scenario !== currentTrial.scenario) {
            // if the trial was previously linked to a scenario...
            if (currentTrial.scenario) {
              // unlink the current scenario
              unlinkTrialAndScenario(projectId, trial.id, currentTrial.scenario).then(
                (unlinkedTrial) => {
                  // if updated trial has a scenario; link that new scenario
                  if (trial.scenario) {
                    linkTrialAndScenario(projectId, trial.id, trial.scenario).then(
                      (linkedTrial) => {
                        return resolve(linkedTrial);
                      },
                    );
                  } else {
                    return resolve(unlinkedTrial);
                  }
                },
              );
            } else if (trial.scenario) {
              // if the trial was NOT previously linked to a scenario, but is now...
              linkTrialAndScenario(projectId, trial.id, trial.scenario).then((linkedTrial) => {
                return resolve(linkedTrial);
              });
            }
          }

          // if the trial-scenario link has not changed...
          return resolve(updatedTrial);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a bi-directional link between a `Trial` and an `Evidence` item.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @param {string} evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to the Trial if
 * successful, otherwise rejects with an error.
 */
export const linkTrialAndEvidence = async (projectId, trialId, evidenceId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trial = find(project.trials, { id: trialId });
        if (!trial) return reject('Not found.');
        const evidence = find(project.evidence, { id: evidenceId });
        if (!evidence) return reject('Not found.');

        trial.evidence = [...trial.evidence, evidenceId];
        evidence.trials = [...evidence.trials, trialId];

        updateProject({
          ...project,
        }).then(() => {
          return resolve(trial);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Removes the bi-directional link between a `Trial` and an `Evidence` item.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @param {string} evidenceId An evidence identifier.
 * @returns {Promise} Returns a Promise which resolves to the Trial if successful,
 * otherwise rejects with an error.
 */
export const unlinkTrialAndEvidence = async (projectId, trialId, evidenceId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trial = find(project.trials, { id: trialId });
        if (!trial) return reject('Not found.');
        const evidence = find(project.evidence, { id: evidenceId });
        if (!evidence) return reject('Not found.');

        trial.evidence = without(trial.evidence, evidenceId);
        evidence.trials = without(evidence.trials, trialId);

        updateProject({
          ...project,
        }).then(() => {
          return resolve(trial);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Create a bi-directional link between a `Trial` and a `Scenario`.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @param {string} scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to the Trial if
 * successful, otherwise rejects with an error.
 */
export const linkTrialAndScenario = async (projectId, trialId, scenarioId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trial = find(project.trials, { id: trialId });
        if (!trial) return reject('Trial not found.');
        const scenario = find(project.scenarios, { id: scenarioId });
        if (!scenario) return reject('Scenario not found.');

        trial.scenario = scenarioId;
        scenario.trials = [...scenario.trials, trialId];

        updateProject({
          ...project,
        }).then(() => {
          return resolve(trial);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Removes the bi-directional link between a `Trial` and a `Scenario`.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @param {string} scenarioId A scenario identifier.
 * @returns {Promise} Returns a Promise which resolves to the Trial if successful,
 * otherwise rejects with an error.
 */
export const unlinkTrialAndScenario = async (projectId, trialId, scenarioId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trial = find(project.trials, { id: trialId });
        if (!trial) return reject('Trial not found.');
        const scenario = find(project.scenarios, { id: scenarioId });
        if (!scenario) return reject('Scenario not found.');

        trial.scenario = undefined;
        scenario.trials = without(scenario.trials, trialId);

        updateProject({
          ...project,
        }).then(() => {
          return resolve(trial);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Copy a `Trial`.
 * @param {string} projectId A project identifier.
 * @param {Object} trialId The Trial to be copied.
 * @returns {Promise} Returns a Promise which resolves to the copied Trial
 * if successful, otherwise rejects with an error.
 */
export const copyTrial = async (projectId, trialId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const trialToCopy = find(project.trials, { id: trialId });
        if (!trialToCopy) return reject('Trial not found.');

        const now = new Date().toISOString();
        const trialCopy = {
          ...trialToCopy,
          id: generateId(),
          createdAt: now,
          updatedAt: now,
        };

        if (trialCopy.scenario) {
          // link the copied trial to the scenario linked to the source trial
          const scenario = find(project.scenarios, { id: trialCopy.scenario });
          if (!scenario) return reject('Scenario not found.');
          scenario.trials = [...scenario.trials, trialCopy.id];
        }

        if (trialCopy.evidence.length > 0) {
          // link the copied trial to all evidence linked to the source trial
          forEach(trialCopy.evidence, (evidenceId) => {
            const evidence = find(project.evidence, { id: evidenceId });
            if (!evidence) return reject('Evidence not found.');
            evidence.trials = [...evidence.trials, trialCopy.id];
          });
        }

        updateProject({
          ...project,
          trials: [...project.trials, trialCopy],
        }).then(() => {
          return resolve(trialCopy);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Export a `Trial`. Export includes all linked Evidence and the union
 * of all Claims linked to the Evidence.
 * @param {string} projectId A project identifier.
 * @param {string} trialId A trial identifier.
 * @returns {Promise} A Promise which resolves to the export filename
 * if successful, otherwise rejects with an error.
 */
export const exportTrial = async (projectId, trialId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        if (!project) return reject('Project not found');
        // get the trial
        const trial = find(project.trials, { id: trialId });
        if (!trial) return reject('Trial not found');
        // get all linked evidence
        const evidenceList = filter(project.evidence, (e) => trial.evidence.includes(e.id));
        // get all linked claims
        const claimIds = union(flatMap(evidenceList, 'claims'));
        const claims = filter(project.claims, (c) => claimIds.includes(c.id));

        // create workbook
        const workbook = sheets.utils.book_new();
        const trialSheet = sheets.utils.json_to_sheet(selectTrialsForExport([trial]), {
          header: ['id', 'name'],
        });
        sheets.utils.book_append_sheet(workbook, trialSheet, 'Trial');
        const armsSheet = sheets.utils.json_to_sheet(trial.arms, {
          header: ['id', 'name'],
        });
        sheets.utils.book_append_sheet(workbook, armsSheet, 'Arms');
        const evidenceSheet = sheets.utils.json_to_sheet(selectEvidenceForExport(evidenceList), {
          header: ['id', 'name'],
        });
        sheets.utils.book_append_sheet(workbook, evidenceSheet, 'Evidence');
        const claimsSheet = sheets.utils.json_to_sheet(selectClaimsForExport(claims), {
          header: ['id', 'name'],
        });
        sheets.utils.book_append_sheet(workbook, claimsSheet, 'Claims');

        // export workbook as ODS file
        const fileName = trial.name.replaceAll(' ', '_') + '.ods';
        sheets.writeFile(workbook, fileName, { bookType: 'ods' });
        return resolve(fileName);
      });
    } catch (err) {
      return reject(err);
    }
  });
};
