import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import NoContent from '../../common/NoContent';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import Dropdown from '../../common/Dropdown';
import EvidenceListMenu from './EvidenceListMenu';

import { useEvidenceListControlsContext } from '../../../hooks/listcontrols.hooks';
import { useGetAllEvidence } from '../../../hooks/evidence.hooks';
import {
  selectEvidence,
  selectEvidenceWithPagination,
} from '../../../selectors/evidence.selectors';

const EvidenceListLoading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    </div>
  );
};

const EvidenceListEmpty = ({ evidenceList = [] }) => {
  const navigate = useNavigate();

  if (evidenceList.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="primary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You don't have any Evidence yet.</div>
          <div className="mt-2 text-xs">Create a new one by clicking the button below.</div>
          <Button
            variant="secondary"
            className="mt-4 w-48 rounded"
            onClick={() => navigate('create')}
          >
            CREATE EVIDENCE
          </Button>
        </div>
      </NoContent>
    </div>
  );
};

const EvidenceListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useEvidenceListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const EvidenceListHeader = () => {
  return (
    <div className="grid grid-cols-12 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-9">
        <EvidenceListColumnHeading name="name" title="NAME" />
      </div>
      <div className="col-span-1">
        <EvidenceListColumnHeading name="claims.length" title="CLAIMS" />
      </div>
      <div className="col-span-1">
        <EvidenceListColumnHeading name="trials.length" title="TRIALS" />
      </div>
      <div className="col-span-1"></div>
    </div>
  );
};

const EvidenceListFooter = ({ totalItemsOnPage, totalItems }) => {
  const { data: listControls, setPage } = useEvidenceListControlsContext();
  let pageStart = 0;
  let pageEnd = 0;
  if (totalItems > 0) {
    pageStart = (listControls.pagination.page - 1) * listControls.pagination.size + 1;
    pageEnd = pageStart + (totalItemsOnPage - 1);
  }

  const totalPages = Math.ceil(totalItems / listControls.pagination.size);
  const isLastPage = listControls.pagination.page === totalPages;
  const pages = [];
  let pageIndex = isLastPage ? listControls.pagination.page - 2 : listControls.pagination.page - 1;
  while (pages.length < Math.min(totalPages, 3)) {
    if (pageIndex > 0 && pageIndex <= totalPages) {
      pages.push(pageIndex);
    }
    pageIndex++;
  }

  return (
    <div className="grid grid-cols-12 gap-1 py-3 px-2 text-sky-700">
      <div className="col-span-6 font-bold">
        Showing {pageStart}-{pageEnd} of {totalItems} evidence
      </div>
      <div className="col-span-6 justify-self-end">
        <ButtonBar>
          <Button
            title="First Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(1)}
          >
            <Icon icon="chevron-left" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
          {pages.map((page) => (
            <Button
              key={page}
              title={`Page ${page}`}
              variant={listControls.pagination.page === page ? 'primary' : 'outline-primary'}
              size="sm"
              className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
              onClick={() => setPage(page)}
              disabled={listControls.pagination.page === page}
            >
              {page}
            </Button>
          ))}
          <Button
            title="Last Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(totalPages)}
          >
            <Icon icon="chevron-right" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
        </ButtonBar>
      </div>
    </div>
  );
};

const EvidenceListItem = ({ evidence, highlight }) => {
  if (!evidence) {
    return null;
  }

  return (
    <div
      className={classNames('grid grid-cols-12 items-center py-3 px-2 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-9 truncate">
        <Link to={`${evidence.id}`} className="hover:text-sky-700">
          {evidence.name}
        </Link>
      </div>
      <div className="col-span-1">{evidence.claims.length}</div>
      <div className="col-span-1">{evidence.trials.length}</div>
      <div className="col-span-1">
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={[
            {
              type: 'link',
              name: 'Edit',
              title: 'Edit Evidence',
              to: `${evidence.id}`,
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'Link Claim',
              title: 'Link Claim with Evidence',
              to: `?linkClaim=${evidence.id}`,
              className: 'text-xs',
            },
          ]}
        />
      </div>
    </div>
  );
};

const EvidenceListContent = ({ evidenceList = [], isFetching }) => {
  const { data: listControls } = useEvidenceListControlsContext();

  if (evidenceList.length === 0) {
    return null;
  }

  const { data: allEvidence, page } = selectEvidenceWithPagination(evidenceList, listControls);

  return (
    <>
      <EvidenceListMenu isFetching={isFetching} />

      <div className="mt-4 flex flex-col rounded-xl bg-white/30">
        <EvidenceListHeader />

        {page.map((evidence, index) => (
          <EvidenceListItem key={evidence.id} evidence={evidence} highlight={index % 2 === 0} />
        ))}

        <div className="mt-auto">
          <EvidenceListFooter totalItemsOnPage={page.length} totalItems={allEvidence.length} />
        </div>
      </div>
    </>
  );
};

const EvidenceList = () => {
  const { projectId } = useParams();
  const { data: evidenceList, status, isFetching } = useGetAllEvidence({ projectId });

  const selectedEvidence = selectEvidence(evidenceList);

  return (
    <div id="evidence-list" className="h-full">
      {status === 'loading' ? (
        <EvidenceListLoading />
      ) : (
        <>
          <EvidenceListEmpty evidenceList={selectedEvidence} />
          <EvidenceListContent evidenceList={selectedEvidence} isFetching={isFetching} />
        </>
      )}
    </div>
  );
};

export default EvidenceList;
