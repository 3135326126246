import { useNavigate } from 'react-router';

import NoContent from '../../common/NoContent';
import Button from '../../common/Button';

const TrialListEmpty = ({ trials = [] }) => {
  const navigate = useNavigate();
  if (trials.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="primary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You don't have any Trials yet.</div>
          <div className="mt-2 text-xs">Create a new one by clicking the button below.</div>
          <Button
            variant="secondary"
            className="mt-4 w-48 rounded"
            onClick={() => navigate('create')}
          >
            CREATE TRIAL
          </Button>
        </div>
      </NoContent>
    </div>
  );
};

export default TrialListEmpty;
