import { useQuery } from '@tanstack/react-query';

import { getAccount } from '../api/account.api';
import { QueryKeys } from '../utils/constants';

/**
 * React query hook to fetch a single `Account`.
 * @param {string} sub An account sub, i.e. subject.
 * @param {Object} options Use query options.
 * @returns {Object} A use query result object.
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery}
 */
export const useGetAccount = (sub, options) => {
  return useQuery([QueryKeys.Accounts, sub], () => getAccount(sub), options);
};
