import { useEffect } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import Submenu from '../../common/Submenu';
import EvidenceDetail from './EvidenceDetail';
import EvidenceClaimList from './claims/EvidenceClaimList';
import EvidenceTrialList from './trials/EvidenceTrialList';

import { useGetEvidence } from '../../../hooks/evidence.hooks';

const VIEWS = ['detail', 'claims', 'trials'];

const EvidenceEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, evidenceId } = useParams();
  const { data: evidence, status } = useGetEvidence({
    projectId,
    evidenceId,
  });
  const view = VIEWS.includes(searchParams.get('view')) ? searchParams.get('view') : 'detail';

  // set default view
  useEffect(() => {
    if (!VIEWS.includes(searchParams.get('view'))) {
      navigate('?view=detail', { replace: true });
    }
    // eslint-disable-next-line
  }, [searchParams]);

  if (status === 'loading') {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  if (!evidence) {
    return <Navigate to={`/projects/${projectId}/evidence`} />;
  }

  return (
    <div id="evidence-edit" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate('..')}
        />
        <h3 className="font-bold">EDIT EVIDENCE</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Submenu
          items={[
            {
              name: 'DETAIL',
              view: 'detail',
              to: `/projects/${projectId}/evidence/${evidence.id}?view=detail`,
            },
            {
              name: 'CLAIMS',
              view: 'claims',
              to: `/projects/${projectId}/evidence/${evidence.id}?view=claims`,
            },
            {
              name: 'TRIALS',
              view: 'trials',
              to: `/projects/${projectId}/evidence/${evidence.id}?view=trials`,
            },
          ]}
        />
        {view === 'detail' && <EvidenceDetail evidence={evidence} projectId={projectId} />}
        {view === 'claims' && <EvidenceClaimList evidence={evidence} projectId={projectId} />}
        {view === 'trials' && <EvidenceTrialList evidence={evidence} projectId={projectId} />}
      </div>
    </div>
  );
};

export default EvidenceEdit;
