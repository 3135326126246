import dayjs from 'dayjs';
import assignWith from 'lodash/assignWith';
import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import toString from 'lodash/toString';

/**
 * The default list control configuration for collections of `Trial` objects.
 */
const defaultListControls = {
  matches: {},
  search: '',
  sort: {
    by: 'name',
    order: 'asc',
  },
  pagination: {
    page: 1,
    size: 10,
    pages: 1,
  },
};

/**
 * Select `Trial` objects with matching criteria and applied ordering.
 * @param {Object[]} trials A collection of `Trial` objects.
 * @param {Object} matches Matcher criteria.
 * @param {string[]} sortBy Sort attributes.
 * @param {string[]} sortOrder Sort directions.
 * @returns {Object[]} A collection of Trials.
 */
export const selectTrials = (trials = [], matches = {}, sortBy = ['name'], sortOrder = ['asc']) => {
  return orderBy(filter(trials, matches), sortBy, sortOrder);
};

/**
 * Select all `Trial` objects which contain the supplied `text` value in their name or type.
 * @param {Object[]} trials A collection of `Trial` objects.
 * @param {string} text The selection criteria.
 * @returns A collection of trials.
 */
export const selectTrialsByText = (trials = [], text = '') => {
  return filter(trials, (t) => {
    return t.name.toLowerCase().includes(text.toLowerCase());
  });
};

/**
 * Select `Trial` objects with list controls selections applied.
 * @param {Object[]} trials A collection of `Trial` objects.
 * @param {Object} listControls A ListControls object.
 * @returns {Object} An object whose attributes are `data` and `page`.
 * The `data` attribute is a collection of all of the Trial objects
 * with list controls applied. The `page` attribute is a page of Trial objects with
 * list controls applied.
 */
export const selectTrialsWithListControls = (trials = [], listControls) => {
  const result = {};
  const opts = merge({}, defaultListControls, listControls);
  // 1. filter by attribute matcher
  let items = filter(trials, opts.matches);
  // 2. filter by search text
  items = opts.search && opts.search.length > 1 ? selectTrialsByText(items, opts.search) : items;
  // 3. apply sort
  result.data = orderBy(items, [opts.sort.by], [opts.sort.order]);
  // 4. apply pagination
  result.page = chunk(result.data, opts.pagination.size)[opts.pagination.page - 1] || [];

  return result;
};

/**
 * Formats the supplied collection of `Trial` objects for export.
 * @param {Object[]} trials A collection of `Trial` objects.
 * @returns {Object[]} A formatted collection of trials.
 */
export const selectTrialsForExport = (trials = []) => {
  return map(trials, (t) => {
    let trial = omit(t, ['arms', 'scenario']);
    trial = assignWith({}, trial, (objValue, srcValue) => toString(srcValue));
    return trial;
  });
};

/**
 * Calculates the average risk score of all supplied `Trial` objects.
 * @param {Object[]} trials  A collection of `Trial` objects.
 * @returns {number} The average risk score of the supplied trials.
 */
export const selectTrialsAverageRisk = (trials = []) => {
  // calculate the total risk score of all trials
  const totalTrialRisk = reduce(map(trials, 'riskScore'), (total, risk) => total + Number(risk), 0);
  // calculate the average risk score
  return totalTrialRisk / trials.length;
};

/**
 * Returns the risk score text label for the supplied risk score.
 * @param {number} riskScore A risk score.
 * @returns {string} A risk score label.
 */
export const selectTrialRiskLabel = (riskScore = 0) => {
  if (riskScore < 1.5) return 'Low';
  if (riskScore < 2.5) return 'Limited';
  if (riskScore < 3.5) return 'Moderate';
  if (riskScore < 4.5) return 'Elevated';
  return 'High';
};

/**
 *
 * @param {Object[]} trials A collection of `Trial` objects.
 * @returns {}
 */
export const selectTrialsLatestEndDate = (trials = []) => {
  return reduce(
    map(trials, 'endAt'),
    (latest, end) => {
      const latestDate = dayjs(latest);
      const endDate = dayjs(end);
      return endDate.isAfter(latestDate) ? endDate.valueOf() : latest;
    },
    0,
  );
};

// Calculate the earliest start date of all trials
export const selectTrialsEarliestStartDate = (trials = [], initialValue) => {
  return reduce(
    map(trials, 'startAt'),
    (earliest, start) => {
      const earliestDate = dayjs(earliest);
      const startDate = dayjs(start);
      return startDate.isBefore(earliestDate) ? startDate.valueOf() : earliest;
    },
    initialValue,
  );
};
