import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from '../../../common/Dropdown';
import Icon from '../../../common/Icon';

const ScenarioIndicationListItem = ({ scenario, indication, projectId, highlight }) => {
  const [searchParams] = useSearchParams();

  if (!indication) return null;

  return (
    <div
      className={classNames('grid grid-cols-12 items-center px-2 py-3 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-4">{indication.therapeuticArea}</div>
      <div className="col-span-4">{indication.indication}</div>
      <div className="col-span-3">{indication.indicationOther}</div>
      <div>
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={[
            {
              type: 'link',
              name: 'Delete',
              title: 'Delete Indication',
              to: `?${searchParams}&deleteScenarioIndication=${indication.id}`,
              className: 'text-xs',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ScenarioIndicationListItem;
