import { useSearchParams } from 'react-router-dom';

import Button from '../../../common/Button';

const TrialEvidenceListMenu = ({ trial }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex items-center">
      <div className="font-bold uppercase text-sky-900">TRIAL EVIDENCE</div>
      <Button
        size="sm"
        variant="primary"
        className="ml-auto w-48 rounded"
        onClick={() => {
          searchParams.append('linkTrialEvidence', trial.id);
          setSearchParams(searchParams);
        }}
      >
        LINK EVIDENCE
      </Button>
    </div>
  );
};

export default TrialEvidenceListMenu;
