export const QueryKeys = {
  Accounts: 'accounts',
  Asset: 'asset',
  AuthState: 'authState',
  Claims: 'claims',
  Evidence: 'evidence',
  Indications: 'indications',
  Notifications: 'notifications',
  Projects: 'projects',
  ProjectLatest: 'projectLatest',
  Scenarios: 'scenarios',
  TherapeuticAreas: 'therapeuticAreas',
  Trials: 'trials',
};

export const StorageKeys = {
  Accounts: 'accounts',
  AuthState: 'auth.state',
  Notifications: 'notifications',
  ProjectsLatest: 'projects.latest',
};

export const ProjectStatus = {
  notstarted: 'Not Started',
  inprogress: 'In Progress',
  complete: 'Complete',
};

export const ProjectStage = {
  notstarted: 'Not Started',
  research: 'Research',
  claims: 'Claims',
  evidence: 'Evidence',
  trialdesign: 'Trial Design',
  review: 'Review',
  complete: 'Complete',
};

export const ClaimTypes = {
  clinical: 'Clinical',
  commercial: 'Commercial',
};

export const PopulationAgeGroup = {
  preterm: 'Preterm infant born at <37 weeks gestation',
  neonate: 'Neonates - Newborn to 1 month',
  infant: 'Infants - 1 month to 2 years',
  child: 'Children - 2 years to 12 years',
  adolescent1: 'Adolescents - 12 years to 16 years',
  adolescent2: 'Adolescents - 16 years and over',
  adult: 'Adults - 17 years and over',
  geriatric: 'Geriatrics - 75 years and over',
};

export const TrialPhases = {
  1: '1',
  2: '2',
  '2a': '2a',
  '2b': '2b',
  3: '3',
  '3b': '3b',
  4: '4',
};

export const TrialGeographies = {
  US_Canada: 'US/Canada',
  Europe: 'Europe',
  China: 'China',
  Korea: 'Korea',
  Japan: 'Japan',
  Australia: 'Australia',
  Asia: 'Asia',
  Western_Europe: 'Western Europe',
  Eastern_Europe: 'Eastern Europe',
  Central_South_America: 'Central/South America',
  Middle_East_Africa: 'Middle East/Africa',
};

export const RiskScores = {
  1: '1 - Low',
  2: '2 - Limited',
  3: '3 - Moderate',
  4: '4 - Elevated',
  5: '5 - High',
};

export const MasterProtocols = {
  no: 'No',
  basket: 'Basket',
  umbrella: 'Umbrella',
  platform: 'Platform',
};
