import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import includes from 'lodash/includes';
import reject from 'lodash/reject';

import Loading from '../../../common/Loading';
import SelectField from '../../../common/SelectField';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { linkClaimSchema } from '../../../../validations/scenario.validations';
import { useGetScenario, useLinkClaim } from '../../../../hooks/scenarios.hooks';
import { useGetClaims } from '../../../../hooks/claims.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';
import { selectClaims } from '../../../../selectors/claims.selectors';

const ScenarioLinkClaim = ({ onCancel, onSuccess, scenarioId }) => {
  const toastsContext = useToastsContext();
  const linkClaim = useLinkClaim();
  const { projectId } = useParams();
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });
  const { data: allClaims, isLoading: isLoadingClaims } = useGetClaims({ projectId });

  if (isLoadingScenario || isLoadingClaims) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const eligibleClaims = selectClaims(
    reject(allClaims, (claim) => {
      let isRejected = claim.isArchived;
      isRejected = isRejected || !!claim.mergedClaimId;
      isRejected = isRejected || includes(claim.scenarios, scenario.id);
      return isRejected;
    }),
  );

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <Formik
        initialValues={{ claimId: '' }}
        validationSchema={linkClaimSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { claimId } = values;
          linkClaim.mutate(
            { projectId, claimId, scenarioId },
            {
              onSuccess: () => {
                toastsContext.createToast(`Linked scenario and claim.`);
                setSubmitting(false);
                onSuccess && onSuccess();
              },
              onError: (err) => {
                console.error(`Failed to link scenario and claim. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <h3 className="mb-6 text-2xl font-bold">LINK CLAIM</h3>

            <div className="mb-4">
              You are about to link a claim to scenario{' '}
              <span className="italic">{scenario.name}</span>.
            </div>

            <div>Select the claim you wish to link:</div>
            <div className="mb-6">
              <SelectField name="claimId" disabled={formik.isSubmitting}>
                <option value="">Select claim...</option>
                {eligibleClaims.map((claim) => (
                  <option key={claim.id} value={claim.id}>
                    {claim.name}
                  </option>
                ))}
              </SelectField>
            </div>

            <ButtonBar className="mt-auto ml-auto">
              <Button
                type="button"
                variant="primary"
                className="w-48 rounded"
                onClick={onCancel}
                disabled={linkClaim.isLoading}
              >
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                variant="secondary"
                className="ml-4 w-48 rounded"
                disabled={linkClaim.isLoading}
                isLoading={linkClaim.isLoading}
              >
                LINK
              </LoadingButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ScenarioLinkClaim;
