/**
 * Therapeutic area and indication combination data.
 */
export const therapeutics = [
  {
    indication: 'ABCC6 deficiency',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Abdominal aortic aneurysm',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Abnormal male sexual function',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Achondroplasia disorder',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Acne',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Acrochordon',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Acromegaly',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Actinic keratosis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Acute congestive heart failure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Acute coronary syndrome',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Acute heart failure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Acute hepatic failure',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Acute hepatitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Acute lymphocytic leukemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Acute myeloid leukemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Acute poliomyelitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Acute pulm disord with SIRS',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Acute radiation disease',
    therapeuticArea: 'Transplantation',
  },
  {
    indication: 'Acute renal impairment',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Acute schizophrenic episode',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Acute stress disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Adenoviral conjunctivitis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'ADHD',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Adrenal insufficiency',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Adult resp distress syndrome',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'African trypanosomiasis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Age related macular degenerati',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Age-related cognitive decline',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'AIDS',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Alcohol dependence',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Allergic rhinitis',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Allergy',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Alopecia',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Alpha-1-antitrypsin deficiency',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'alpha-Mannosidosis',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Alport syndrome',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: "Alzheimer's disease",
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Amenorrhea',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Amyloidosis',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Amyotrophic lateral sclerosis',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Anal fissure',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Anaphylaxis disorder',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Anastomotic failure of flap',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Anemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Anemia in neoplastic disease',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Angelman syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Angina',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Angioedema',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Angiofibroma',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Ankylosing spondylitis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Anorexia nervosa',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Anthrax',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Anxiety disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Aortic aneurysm',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Aortic thromboembolism',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Aplastic anemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Appendicitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Arginase deficiency',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Arrhythmia',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Arterial thrombosis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Arthritis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Arthritis of spine',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Aspergillosis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Asthma',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Atherosclerosis of artery',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Atopic conjunctivitis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Atopic dermatitis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Atopic rhinitis',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Atrial fibrillation',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Atrophic vulvovaginitis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Autistic disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Autoimmune disease',
    therapeuticArea: 'Immunology',
  },
  {
    indication: 'Autoimmune thyroiditis',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Autoimmune vasculitis',
    therapeuticArea: 'Immunology',
  },
  {
    indication: 'Autosomal dominant optic atrop',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Bacterial conjunctivitis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Bacterial infectious disease',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Bacterial overgrowth syndrome',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Bacterial pneumonia',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Bacterial vaginosis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Basal cell carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: "Behcet's syndrome",
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: "Bell's palsy",
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Benign prostatic hyperplasia',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Bifurcation lesion coronary ar',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Biliary anastomotic leak',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Binge eating disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Bipolar disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Bipolar I disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Bipolar II disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Bladder cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Bladder outlet obstruction',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Blepharitis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Blocked central line',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Blood coagulation disorder',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Blood disease',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Bone necrosis',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Bone sarcoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Botulism',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Bowel dysfunction',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Bronchiectasis',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Bronchitis',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Bronchopulm dysplasia newborn',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Bulimia nervosa',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Bullous pemphigoid',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Calciphylaxis',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Candidal vulvovaginitis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Candidiasis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Candidiasis of vagina',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Carcinoma of biliary tract',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Cardiac sarcoidosis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Cardiac surgery',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Cardiomyopathy',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Cardiovascular Disease',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Carpal tunnel syndrome',
    therapeuticArea: 'Neurology',
  },
  {
    indication: "Castleman's superficial pseudo",
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Cataract',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Celiac disease',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Central venous access device',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Cerebral arterial aneurysm',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cerebral artery occlusion',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Cerebral degeneration',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cerebral hemorrhage',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cerebral infarction',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cerebrospinal fluid leak',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cerebrovascular accident',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cerebrovascular disease',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Cervical cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Cervical neoplasia',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Charcot-Marie-Tooth disease',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Chemo-induced nausea',
    therapeuticArea: 'Transplantation',
  },
  {
    indication: 'Chikungunya virus',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Cholangiocarcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Cholecystitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Cholelithiasis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Cholera',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Cholesterol level',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Choroideremia',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Chronic cough',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Chronic hand eczema',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Chronic heart disease',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Chronic heart failure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Chronic interstitial cystitis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Chronic lymphocytic leukemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Chronic myeloid leukemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Chronic pain',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Chronic pancreatitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Chronic renal anemia',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Chronic renal failure syndrome',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Chronic renal impairment',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Chronic schizophrenia',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Chronic venous insufficiency',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'CINCA',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'CIPN chemo-induced neuropathy',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Cirrhosis of liver',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Closed wound of head',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Clostridium difficile colitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Coccidioidomycosis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Colonic polyp',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Com molybdoflavoprotein enzyme',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Community acquired pneumonia',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Conduct disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Congenital adrenal hyperplasia',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Congenital hyperinsulinism',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Congenital ichthyosis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Congenital skeletal dysplasia',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Congestive heart failure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Constipation',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Constriction of bronchioles',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Contact lens related disorder',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Contrast agent nephropathy',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Contusion of brain',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'COPD',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Corneal degeneration',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Corneal dystrophy',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Corneal neovascularization',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Coronary arteriosclerosis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Coronary artery finding',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Coronary artery stenosis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Cough',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'COVID-19',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Cranial nerve palsies ocular',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: "Crohn's disease",
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Cryopyrin assoc periodic synd',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Cyclical vomiting syndrome',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Cystic fibrosis',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Cystitis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Cytomegalovirus infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Darier disease',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Decompensated cardiac failure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Deep venous thrombosis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Defect of articular cartilage',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Defibrination syndrome',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Degenerative disc disease',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Degenerative disease of CNS',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Delay in puberty',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Dementia',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Dengue',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Dental plaque',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Depressive disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Dermatitis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Dermatomyositis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Dermatophytosis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Developmental disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Diabetes mellitus',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Diabetes mellitus type 1',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Diabetes mellitus type 2',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Diabetic foot ulcer',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Diabetic gastroparesis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Diabetic macular edema',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Diabetic neuropathic pain',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Diabetic neuropathy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Diabetic peripheral neuropathy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Diabetic polyneuropathy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Diabetic renal disease',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Diabetic retinopathy',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Diabetic retinopathy nonprolif',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Diabetic retinopathy prolifera',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Diaphragmatic hernia',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Diarrhea',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Diffuse large B-cell lymphoma',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Diphtheria',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Disease caused by parasite',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Disease due to Rhinovirus',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Disease due to Rotavirus',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Disease due to West Nile virus',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Disease of liver',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Disorder bilirubin metabolism',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Disorder of carotid artery',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Disorder of cornea',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Disorder of dialysis catheter',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Disorder of immune function',
    therapeuticArea: 'Immunology',
  },
  {
    indication: 'Disorder of lactation',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Disorder of nervous system',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Disorder of ovary',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Disorder of pregnancy',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Disorder of refraction',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Disorder of stature',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Dissection of aorta',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Distal radius fracture',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Diverticulitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Down Syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Dravet Syndrome',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Drug abuse',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Drug dependence',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Drug-induced constipation',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Drug-induced nausea/vomiting',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Dry eye',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Duchenne muscular dystrophy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Duodenal ulcer disease',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: "Dupuytren's contracture",
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Dwarfism',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Dysfunctional uterine bleeding',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Dyslipidemia',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Dysphagia',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'E. coli infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Ebola virus disease',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Emphysema',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'End stage renal disease',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Endometrial carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Endometrial hyperplasia',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Endometriosis (clinical)',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'ENPP1 deficiency',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Enthesopathy',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Epidermolysis bullosa',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Epilepsy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Erosive esophagitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Esophagitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Essential hypertension',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Essential thrombocythemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Essential tremor',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Excessive sexual drive',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Ex-smoker',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Eyelid finding',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: "Fabry's disease",
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Familial adenomatous polyposis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Familial amyloid polyneuropath',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Fatigue',
    therapeuticArea: 'Transplantation',
  },
  {
    indication: 'Female infertility',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Female pelvic inflammation',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Female sexual dysfunction',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Femoropopliteal vein',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Fibrillation',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Fibromyalgia',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Fibrosis of lung',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Finding of lactation',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Finding of nasal deformity',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Finding of wound healing',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Fit and well',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Focal glomerular sclerosis',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Follicular carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Follicular neoplasm',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Fracture of acetabulum',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Fracture of bone',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Fracture of lacrimal bone',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Fracture of neck of femur',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Fragile X syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Frequent heavy periods',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: "Friedreich's ataxia",
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Fungal respiratory infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Gastric ulcer',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Gastroenteritis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Gastroesophageal reflux disea',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Gastrointestinal cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Gastrointestinal stromal tumor',
    therapeuticArea: 'Oncology',
  },
  {
    indication: "Gaucher's disease",
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Generalised chronic body pains',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Generalized anxiety disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Genital herpes simplex',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Genital warts',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Genitourinary cancers',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Genuine stress incontinence',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Geographical atrophy',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'GERD without esophagitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'GI infections',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'GI tumors',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Giant cell arteritis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Giant cell tumor of bone',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Glaucoma',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Glioblastoma multiforme',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Glioma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Glycogen storage disease',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'GM 2 gangliosidosis',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Goiter',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Gonadal dysgenesis',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Gonadoblastoma',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Gonadotroph adenoma',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Gout',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Graft versus host disease',
    therapeuticArea: 'Transplantation',
  },
  {
    indication: 'Graves ophthalmopathy',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Growing Pains',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Growth hormone deficiency',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Gynecomastia',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'H Pylori',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Hb SS disease',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Head and neck cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Headache disorder',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Heart disease',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Heart failure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Helminth infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Hematologic malignancy',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Hemochromatosis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Hemolytic anemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Hemolytic uremic syndrome',
    therapeuticArea: 'Transplantation',
  },
  {
    indication: 'Hemophilia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Hemorrhagic shock',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Hemorrhoids',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Hepatic congestion',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Hepatic failure',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Hepatic fibrosis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Hepatocarcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Hepatocellular carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Hereditary factor XIII deficie',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Hereditary periodic fever',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Herpes simplex',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Hidradenitis suppurativa',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'HIV infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: "Hodgkin's disease",
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Homocystinuria',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'HPV infection',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: "Huntington's chorea",
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Hydatidiform mole, benign',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Hydrocephalus',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Hyperargininemia',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Hypercalcemia',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hypercholesterolemia',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Hypercortisolism',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hyperglycemia',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hyperkalemia',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Hyperlipidemia',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Hyperparathyroidism',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Hyperphosphatemia',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Hyperplasia of prostate',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Hypertensive disorder',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Hypertriglyceridemia',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Hypertrophic scarring of skin',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Hypoalbuminemia',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Hypoglycemia',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hypogonadism',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hyponatraemia',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hypoparathyroidism',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Hypophosphatasia',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Hypoxic-ischaemic brain injury',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'IgA nephropathy',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Ileal pouchitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Imaging result abnormal',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Immune thrombocytopenic purpur',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Impaired cognition',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Incontinence of feces',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Indigestion',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Infection assoc with catheter',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Infectious disease',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Infectious disease of abdomen',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Inflammatory bowel disease',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Inflammatory disease of liver',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Influenza',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Influenza A (H1N1)',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Influenza due to H5N1',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Inguinal hernia',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Injury anterior cruciate ligam',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Injury of ocular adnexa',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Insomnia',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Intermittent claudication',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Intestinal obstruction',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Intra-abdominal hernia',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Intracranial tumor',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Invasive aspergillosis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'In-vitro fertilization',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Irritable colon',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Ischemic stroke',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Isolated cervical dystonia',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Itching',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Japanese encephalitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Juvenile idiopathic arthritis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Kabuki syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Keratoconjunctivitis sicca',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Kidney disease',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Kidney stone',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Lactic acidosis',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Lassa fever',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: "Leber's congenital amaurosis",
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Leishmaniasis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Lennox-Gastaut syndrome',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Leprosy',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Lesion of lumbar spine',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Leukemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'LHON',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Lipid metabolism disorders',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Lipodystrophy',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Low blood pressure',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Lupus erythematosus',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Lupus nephritis',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Lyme disease',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Lymphoproliferative disorder',
    therapeuticArea: 'Immunology',
  },
  {
    indication: 'Macular degeneration',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Macular retinal edema',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Major depressive disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Malaria',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Male erectile disorder',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Male infertility',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Malignant lymphoma (clinical)',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Malignant melanoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant neoplasm of brain',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant neoplasm of liver',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant neoplasm of skin',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of breast',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of colon',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of esophagus',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of kidney',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of lung',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of nasopharynx',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of oral cavity',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of oropharynx',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of ovary',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of pancreas',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of prostate',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of rectum',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of stomach',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malignant tumor of testis',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Malnutrition (calorie)',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Mania',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Mantle cell lymphoma of spleen',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'McCune-Albright syndrome',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Measles',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Membranous nephropathy',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: "Ménière's disease",
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Meningitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Meningococcal infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Meningococcal meningitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Menopausal flushing',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Menopausal syndrome',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Mental retardation',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Mesothelioma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Metabolic disease',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Metastasis to bone marrow',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Metastasis to brain',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Metastatic cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Methylmalonic acidemia',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Migraine',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Minimal cognitive impairment',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'MNGIE',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Mood disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'MRSA infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Mucopolysaccharidosis',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Multidrug resistant tuberculos',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Multi-infarct dementia',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Multiple myeloma',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Multiple sclerosis',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Multiple system atrophy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Mumps',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Muscle weakness',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Myasthenia gravis',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Mycosis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Myelodysplastic syndrome',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Myelofibrosis',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Myeloid leukemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Myocardial infarction',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Myocarditis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Myopia',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Myositis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Narcolepsy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Nasal polyp',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Necrotizing enterocolitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Neisseria gonorrhoeae',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Neonatal encephalopathy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neonatal hyperbilirubinemia',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Neoplasm and/or hamartoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Neoplasm of biliary tract',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Neoplasm skin adnexal differen',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Nephritis',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Nephroblastoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Neuralgia',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neuro orthostatic hypotension',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Neuroendocrine tumor',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Neurofibroma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Neurofibromatosis syndrome',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Neurogenic bladder',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neuromyelitis optica',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neuron ceroid-lipofuscinosis',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neuropathic pain',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neuropathy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Neutropenic disorder',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Nicotine dependence',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Niemann-Pick disease, type C',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Nipah virus',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Nocturia',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Nocturnal enuresis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Nonalcoholic steatohepatitis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Non-Hodgkin lymphoma',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Non-small cell lung cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Nosocomial infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Nosocomial pneumonia',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Not applicable',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Obesity',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Obsessive-compulsive disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Ocular hypertension',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Ocular surface finding',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Onychomycosis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Open wound of eyeball',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Opioid dependency',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Oppositional defiant disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Ornithine carbamoyltransferase',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Oropharyngeal mucositis',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Ossification posterior longitu',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Osteoarthritis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Osteogenesis imperfecta',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Osteopenia',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Osteoporosis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Otitis media',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Overactive bladder',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Oxygen uptake',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Pain',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Pain from metastases',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Palliative care physician',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Pancreatic exocrine insuffici',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Pancreatic insufficiency',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Pancreatitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Panic disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Parainfluenza disorder',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Parenteral nutrient',
    therapeuticArea: 'Other',
  },
  {
    indication: "Parkinson's disease",
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Paroxysmal nocturnl hemogluria',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Pemphigus vulgaris disorder',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Penetrating wound of eye',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Peptic ulcer',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Perennial allergic rhinitis',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Periodontal disease',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Periodontitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Peripheral arterial disease',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Peripheral degeneration retina',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Peripheral ischaemia',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Peripheral T-cell lymphoma',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Peripheral vascular disease',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Peritoneal carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Personality disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Pertussis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Pharyngitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Phenylketonuria',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'PHPV',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Physical stress',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'PIK3CA related overgrowth spec',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Pityriasis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Plague',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Pneumococcal infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Pneumonia',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Pneumothorax',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Poisoning',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Pollinosis',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Polycystic kidney disease',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Polycythemia vera',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Polymyositis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Pompe disease',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Poor muscle tone',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Post poliomyelitis syndrome',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Postgastric surgery syndrome',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Postherpetic neuralgia',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Postoperative ileus',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Postoperative pain',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Postpartum depressive disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Postpartum hemorrhage',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Posttraumatic stress disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Precocious puberty',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Pre-eclampsia',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Premenstrual tension syndrome',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Presbyopia',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Pre-term labor',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Primary biliary cholangitis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Primary immunodeficiency',
    therapeuticArea: 'Immunology',
  },
  {
    indication: 'Primary sclerosing cholangitis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Prog multifocal leukoencepha',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Proliferative vitreoretinopath',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Prostatitis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Prosthetic joint infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Proteinuria',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Prurigo nodularis disorder',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Pseudoxanthoma elasticum',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Psoriasis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Psoriasis of nail',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Psoriasis with arthropathy',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Psoriatic arthritis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Psychotic disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Pulmonary arterial hyper',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Pulmonary embolism',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Pulmonary hypertension',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Quadriplegia',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Rabies',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: "Raynaud's disease",
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Recurrent sinusitis',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Reflux esophagitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Refractory partial epilepsy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Renal cell carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Renal failure on dialysis',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Renal failure syndrome',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Renal impairment',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Renal transplant rejection',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Respiratory tract congestion',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Respiratory tract infection',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Restless legs syndrome',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Retinal artery occlusion',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Retinal vascular occlusion',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Retinitis pigmentosa',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Retinoblastoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Retinopathy',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Retinoschisis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: "Rett's disorder",
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Rhegmatogenous retinal detachm',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Rheumatoid arthritis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Rhytide of glabellar skin',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Rosacea',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Rotator cuff tear',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'RSV',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Rubella',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Sarcoidosis',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Sarcoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Sarcoma of soft tissue',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Schizoaffective disorder',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Schizophrenia',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Sciatica',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Seasonal allergic rhinitis',
    therapeuticArea: 'Allergy',
  },
  {
    indication: 'Sebaceous adenocarcinoma eyeli',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Seborrheic dermatitis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Seborrheic keratosis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Secondary amyloidosis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Seizure disorder',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Sensorineural hearing loss',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Sepsis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Sexual desire disorder',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Shock',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: 'Short bowel syndrome',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'SIADH',
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Sickle cell disease',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Simple varicose veins',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Sinusitis',
    therapeuticArea: 'Allergy',
  },
  {
    indication: "Sjögren's syndrome",
    therapeuticArea: 'Immunology',
  },
  {
    indication: 'Skin infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Skin ulcer',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Sleep apnea',
    therapeuticArea: 'Respiratory',
  },
  {
    indication: 'Sleep disorder',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Small cell carcinoma of lung',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Smallpox',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Social phobia',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Solid tumor configuration',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Spasmodic torticollis',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Spasticity',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Specialised gonadal neoplasm',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Spinal cord disease',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Spinal cord injury',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Spinal meningeal adhesions',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Spinal muscular atrophy',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Spondylolisthesis',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Squamous cell carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Staphylococcal infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Steatohepatitis',
    therapeuticArea: 'Hepatology',
  },
  {
    indication: 'Stomatitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Strabismus',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Streptococcal infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Sweating',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Syringoma of eyelid',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Systemic infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Systemic inflammatory response',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Systemic mastocytosis',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Systemic mastocytosis',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Systemic sclerosis',
    therapeuticArea: 'Rheumatology',
  },
  {
    indication: 'Tendonitis',
    therapeuticArea: 'Orthopedics',
  },
  {
    indication: 'Tetanus',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Thalassemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Threatened abortion',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Thrombocytopenic disorder',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Thromboembolism of vein',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Thrombophlebitis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Thrombosis',
    therapeuticArea: 'Cardiovascular',
  },
  {
    indication: 'Thyroid cancer',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Thyroid carcinoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Tic disorder',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Tick-borne encephalitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Tinea pedis',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Tonsillitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Toothache',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Toxic effect of chemical',
    therapeuticArea: 'Other',
  },
  {
    indication: 'Traction and rhegmatogenous re',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Transplanted organ rejection',
    therapeuticArea: 'Transplantation',
  },
  {
    indication: 'Traumatic brain injury',
    therapeuticArea: 'Neurology',
  },
  {
    indication: 'Traumatic injury',
    therapeuticArea: 'Acute Care',
  },
  {
    indication: "Traveler's diarrhea",
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Tuberculosis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Tuberous sclerosis syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Tumor finding',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Turner syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Viral hepatitis B',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Typhoid fever',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Ulcer',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Ulcerative colitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Ulcerative proctitis',
    therapeuticArea: 'Gastrointestinal',
  },
  {
    indication: 'Unable to direct attention',
    therapeuticArea: 'Psychiatry',
  },
  {
    indication: 'Urea cycle disorder',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Ureteric stone',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Urgent desire to urinate',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Urinary incontinence',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Urinary tract infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Urolithiasis',
    therapeuticArea: 'Nephrology',
  },
  {
    indication: 'Urticaria',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Uterine leiomyoma',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Uveal melanoma',
    therapeuticArea: 'Oncology',
  },
  {
    indication: 'Uveitis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Vaginitis',
    therapeuticArea: "Women's Health / Sexual Health",
  },
  {
    indication: 'Varicella-zoster infection',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Vascular malformation',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Vasoconstriction',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Venous ulcer of leg',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Verruca vulgaris',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'Viral conjunctivitis',
    therapeuticArea: 'Ophthalmology',
  },
  {
    indication: 'Viral disease',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Viral hepatitis',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Viral hepatitis C',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Viral hepatitis D',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Viral hepatitis E',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'Viral hepatitis A',
    therapeuticArea: 'Infectious Disease',
  },
  {
    indication: 'von Willebrand disorder',
    therapeuticArea: 'Hematology',
  },
  {
    indication: 'Waldenstrom macroglobulinemia',
    therapeuticArea: 'Hematology',
  },
  {
    indication: "Wilson's disease",
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Wolfram syndrome',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: "Wolman's disease",
    therapeuticArea: 'Endocrinology',
  },
  {
    indication: 'Wrinkled skin',
    therapeuticArea: 'Dermatology',
  },
  {
    indication: 'XLHED',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'X-Linked Myotubular Myopathy',
    therapeuticArea: 'Medical Genetics',
  },
  {
    indication: 'Zika virus disease disorder',
    therapeuticArea: 'Infectious Disease',
  },
];
