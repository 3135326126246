import numeral from 'numeral';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { usePopperTooltip } from 'react-popper-tooltip';

import Icon from '../../../common/Icon';
import Tooltip from '../../../common/Tooltip';

const ScenarioTrialCost = ({ trials = [] }) => {
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'top',
  });
  const totalTrialCost = reduce(map(trials, 'cost'), (total, cost) => total + cost, 0);

  return (
    <div className="flex cursor-help items-center" ref={setTriggerRef}>
      <Icon icon="money-bill" className="mr-0.5" />
      <div>{numeral(totalTrialCost).format('(0[.]00a)').toUpperCase()}</div>
      <Tooltip {...tooltipProps}>
        The total estimated cost of all trials is{' '}
        {numeral(totalTrialCost).format('(0[.]00a)').toUpperCase()}.
      </Tooltip>
    </div>
  );
};

export default ScenarioTrialCost;
