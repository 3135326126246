import EvidenceTrialListMenu from './EvidenceTrialListMenu';
import EvidenceTrialListItem from './EvidenceTrialListItem';
import EvidenceTrialListFooter from './EvidenceTrialListFooter';
import EvidenceTrialListHeader from './EvidenceTrialListHeader';

import { selectTrialsWithListControls } from '../../../../selectors/trials.selectors';
import { useTrialListControlsContext } from '../../../../hooks/listcontrols.hooks';

const EvidenceTrialListContent = ({ evidence, trialList, projectId }) => {
  const { data: listControls } = useTrialListControlsContext();

  if (trialList.length === 0) {
    return null;
  }

  const { data: allTrials, page } = selectTrialsWithListControls(trialList, listControls);

  return (
    <>
      <EvidenceTrialListMenu evidence={evidence} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <EvidenceTrialListHeader />

        {page.map((trial, index) => (
          <EvidenceTrialListItem
            key={trial.id}
            evidence={evidence}
            trial={trial}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <EvidenceTrialListFooter totalItemsOnPage={page.length} totalItems={allTrials.length} />
        </div>
      </div>
    </>
  );
};

export default EvidenceTrialListContent;
