import * as Yup from 'yup';

import { indicationSchema } from './indication.valudations';

export const assetSchema = Yup.object({
  compoundName: Yup.string().max(100, 'Must be 100 characters or less').required('Required'),
  developmentType: Yup.string(),
  phase: Yup.string(),
  manufacturer: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
  indications: Yup.array(indicationSchema),
  diseaseType: Yup.string(),
  notes: Yup.string().max(1000, 'Must be 1000 characters or less'),
});
