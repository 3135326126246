import classNames from 'classnames';

/**
 * The `Tooltip` component renders text when an an item is hovered by the mouse
 * pointer. Useful for form field hover help.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Tooltip = ({ children, className, getTooltipProps, setTooltipRef, visible = false }) => {
  if (!visible) return null;

  return (
    <div
      ref={setTooltipRef}
      {...getTooltipProps()}
      className={classNames(
        'z-[9999] flex max-w-[200px] rounded bg-slate-700 py-0.5 px-2 text-sm font-normal text-white shadow shadow-slate-500',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Tooltip;
