import ScenarioClaimListMenu from './ScenarioClaimListMenu';
import ScenarioClaimListItem from './ScenarioClaimListItem';
import ScenarioClaimListHeader from './ScenarioClaimListHeader';
import ScenarioClaimListFooter from './ScenarioClaimListFooter';

import { selectClaimsWithListControls } from '../../../../selectors/claims.selectors';
import { useClaimListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ScenarioClaimListContent = ({ scenario, claims, projectId }) => {
  const { data: listControls } = useClaimListControlsContext();

  if (claims.length === 0) {
    return null;
  }

  const { data: allClaims, page } = selectClaimsWithListControls(claims, listControls);

  return (
    <>
      <ScenarioClaimListMenu scenario={scenario} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <ScenarioClaimListHeader />

        {page.map((claim, index) => (
          <ScenarioClaimListItem
            key={claim.id}
            claim={claim}
            scenario={scenario}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <ScenarioClaimListFooter totalItemsOnPage={page.length} totalItems={allClaims.length} />
        </div>
      </div>
    </>
  );
};

export default ScenarioClaimListContent;
