import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../common/Loading';
import NoContent from '../../common/NoContent';
import Button from '../../common/Button';
import ClaimListMenu from '../list/ClaimListMenu';
import ClaimBoardColumns from './ClaimBoardColumns';

import { useGetClaims } from '../../../hooks/claims.hooks';
import { selectClaimsWithListControls } from '../../../selectors/claims.selectors';
import { useClaimListControlsContext } from '../../../hooks/listcontrols.hooks';

const ClaimBoardLoading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    </div>
  );
};

const ClaimBoardEmpty = ({ claims = [] }) => {
  const navigate = useNavigate();

  if (claims.length > 0) {
    return null;
  }

  return (
    <>
      <ClaimListMenu isFetching={false} />

      <div className="mt-4 flex h-[calc(100%-24px)] items-center justify-center">
        <NoContent variant="primary">
          <div className="mt-2 flex flex-col items-center text-slate-700">
            <div>You don't have any Claims yet.</div>
            <div className="mt-2 text-xs">Create a new one by clicking the button below.</div>
            <Button
              variant="secondary"
              className="mt-4 w-48 rounded"
              onClick={() => navigate('../create')}
            >
              CREATE CLAIM
            </Button>
          </div>
        </NoContent>
      </div>
    </>
  );
};

const ClaimBoardHeader = () => {
  return (
    <div className="grid grid-cols-4 gap-4 font-bold text-sky-900">
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Claims</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Merged Claims</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Priority Claims</div>
      </div>
      <div className="py-3 px-2 shadow-lg shadow-slate-500/50">
        <div>Evidence</div>
      </div>
    </div>
  );
};

const ClaimBoardContent = ({ claims = [], isFetching }) => {
  if (claims.length === 0) {
    return null;
  }

  return (
    <>
      <ClaimListMenu isFetching={isFetching} />

      <div className="mt-4 flex h-[calc(100%-24px)] flex-col rounded-lg bg-slate-200/20">
        {/* Claim Board Columns */}
        <ClaimBoardHeader />
        <ClaimBoardColumns claims={claims} />
      </div>
    </>
  );
};

const ClaimBoard = () => {
  const { projectId } = useParams();
  const { data: listControls } = useClaimListControlsContext();
  const { data: claims, status, isFetching } = useGetClaims({ projectId });

  const { data: selectedClaims } = selectClaimsWithListControls(claims, listControls);

  return (
    <div id="claims-list" className="h-full">
      {status === 'loading' ? (
        <ClaimBoardLoading />
      ) : (
        <>
          <ClaimBoardEmpty claims={selectedClaims} />
          <ClaimBoardContent claims={selectedClaims} isFetching={isFetching} />
        </>
      )}
    </div>
  );
};

export default ClaimBoard;
