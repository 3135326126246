import TrialEvidenceListMenu from './TrialEvidenceListMenu';
import TrialEvidenceListItem from './TrialEvidenceListItem';
import TrialEvidenceListFooter from './TrialEvidenceListFooter';
import TrialEvidenceListHeader from './TrialEvidenceListHeader';

import { selectEvidenceWithPagination } from '../../../../selectors/evidence.selectors';
import { useEvidenceListControlsContext } from '../../../../hooks/listcontrols.hooks';

const TrialEvidenceListContent = ({ trial, evidenceList, projectId }) => {
  const { data: listControls } = useEvidenceListControlsContext();

  if (evidenceList.length === 0) {
    return null;
  }

  const { data: allEvidence, page } = selectEvidenceWithPagination(evidenceList, listControls);

  return (
    <>
      <TrialEvidenceListMenu trial={trial} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <TrialEvidenceListHeader />

        {page.map((evidence, index) => (
          <TrialEvidenceListItem
            key={evidence.id}
            evidence={evidence}
            trial={trial}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <TrialEvidenceListFooter totalItemsOnPage={page.length} totalItems={allEvidence.length} />
        </div>
      </div>
    </>
  );
};

export default TrialEvidenceListContent;
