import TrialListMenu from './TrialListMenu';
import TrialListHeader from './TrialListHeader';
import TrialListItem from './TrialListItem';
import TrialListFooter from './TrialListFooter';

import { useTrialListControlsContext } from '../../../hooks/listcontrols.hooks';
import { selectTrialsWithListControls } from '../../../selectors/trials.selectors';

const TrialListContent = ({ trials = [], isFetching }) => {
  const { data: listControls } = useTrialListControlsContext();

  if (trials.length === 0) {
    return null;
  }

  let { data: allTrials, page } = selectTrialsWithListControls(trials, listControls);

  return (
    <>
      <TrialListMenu isFetching={isFetching} />

      <div className="mt-4 flex flex-col rounded-xl bg-white/30">
        <TrialListHeader />

        {page.map((trial, index) => (
          <TrialListItem key={trial.id} trial={trial} highlight={index % 2 === 0} />
        ))}

        <div className="mt-auto">
          <TrialListFooter totalItemsOnPage={page.length} totalItems={allTrials.length} />
        </div>
      </div>
    </>
  );
};

export default TrialListContent;
