import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import EvidenceListFilter from './EvidenceListFilter';
import Spinner from '../../common/Spinner';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';

const EvidenceListMenu = ({ isFetching }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();

  return (
    <div className="flex items-center">
      <h3 className="font-bold text-sky-900">ALL EVIDENCE</h3>
      <Spinner show={isFetching} className="ml-2" />
      <EvidenceListFilter className="ml-auto" />
      <ButtonBar className="ml-4">
        <Button
          size="sm"
          variant="primary"
          title="View list"
          className="flex w-16 items-center justify-center rounded-l border-r border-r-white/30"
          isPressed={!location.pathname.includes('/board')}
          onClick={() => navigate(`/projects/${projectId}/evidence`)}
        >
          <Icon icon="list" fixedWidth className="mr-0.5 inline-block h-3.5 w-3.5" />
          LIST
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="View board"
          className="mr-2 flex w-20 items-center justify-center rounded-r"
          isPressed={location.pathname.includes('/board')}
          onClick={() => navigate(`/projects/${projectId}/evidence/board`)}
        >
          <Icon
            icon="table-columns"
            fixedWidth
            className="mr-0.5 inline-block h-3.5 w-3.5 rounded"
          />
          BOARD
        </Button>
        <Button
          size="sm"
          variant="primary"
          title="Create new claim"
          className="w-48 rounded"
          onClick={() => navigate(`/projects/${projectId}/evidence/create`)}
        >
          CREATE EVIDENCE
        </Button>
      </ButtonBar>
    </div>
  );
};

export default EvidenceListMenu;
