import ProjectMenuItem from './ProjectMenuItem';

const ProjectMenu = ({ items = [] }) => {
  return (
    <div className="flex items-center border-b-2 border-b-sky-200 text-xl font-bold text-sky-900">
      {items.map((item, index) => (
        <ProjectMenuItem key={index} {...item} />
      ))}
    </div>
  );
};

export default ProjectMenu;
