import Loading from '../../common/Loading';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import LoadingButton from '../../common/LoadingButton';

import { useGetTrial, useExportTrial } from '../../../hooks/trials.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const TrialExport = ({ onCancel, onSuccess, projectId, trialId }) => {
  const exportTrial = useExportTrial();
  const { data: trial, isLoading } = useGetTrial(projectId, trialId);
  const toastsContext = useToastsContext();

  const doExportTrial = () => {
    exportTrial.mutate(
      { projectId, trialId },
      {
        onSuccess: (fileName) => {
          toastsContext.createToast(`Exported trial file: ${fileName}.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to export trial. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">EXPORT TRIAL</h3>

      <div className="mb-4">
        You are about to export trial <span className="italic">{trial.name}</span>.
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={exportTrial.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={doExportTrial}
          disabled={exportTrial.isLoading}
          isLoading={exportTrial.isLoading}
        >
          EXPORT
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default TrialExport;
