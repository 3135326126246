import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from '../../common/Dropdown';
import Badge from '../../common/Badge';
import Icon from '../../common/Icon';

const TrialListItem = ({ trial, highlight }) => {
  if (!trial) {
    return null;
  }

  const getMenuContent = () => {
    const menu = [
      {
        type: 'link',
        name: 'Edit',
        title: 'Edit Trial',
        to: `${trial.id}`,
        className: 'text-xs',
      },
      {
        type: 'link',
        name: 'Link Evidence',
        title: 'Link Evidence with Trial',
        to: `?linkTrialEvidence=${trial.id}`,
        className: 'text-xs',
      },
      {
        type: 'link',
        name: 'Copy',
        title: 'Copy Trial',
        to: `?copyTrial=${trial.id}`,
        className: 'text-xs',
      },
      {
        type: 'link',
        name: 'Export',
        title: 'Export Trial',
        to: `?exportTrial=${trial.id}`,
        className: 'text-xs',
      },
    ];

    return menu;
  };

  return (
    <div
      className={classNames('grid grid-cols-20 items-center py-3 px-2 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-7 truncate">
        <Link to={`${trial.id}`} className="hover:text-sky-700">
          {trial.name}
        </Link>
      </div>
      <div className="col-span-6">{trial.indication}</div>
      <div className="col-span-2">{trial.phase}</div>
      <div className="col-span-2">{trial.evidence.length}</div>
      <div className="col-span-2">{trial.scenario && <Badge variant="success">Linked</Badge>}</div>
      <div>
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={getMenuContent()}
        />
      </div>
    </div>
  );
};

export default TrialListItem;
