import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Dropdown from '../../../../common/Dropdown';
import Icon from '../../../../common/Icon';

const TrialArmListItem = ({ trial, arm, projectId, highlight }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!arm) return null;

  return (
    <div
      className={classNames('grid grid-cols-12 items-center px-2 py-3 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-5 truncate">
        <Link
          to={`/projects/${projectId}/trials/${trial.id}?view=arms&mode=edit&arm=${arm.id}`}
          className="hover:text-sky-700"
        >
          {arm.name}
        </Link>
      </div>
      <div className="col-span-2">{dayjs(arm.startAt).format('MMM D, YYYY')}</div>
      <div className="col-span-2">{dayjs(arm.endAt).format('MMM D, YYYY')}</div>
      <div className="col-span-2">{arm.sampleSize}</div>
      <div>
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={[
            {
              type: 'link',
              name: 'Edit',
              title: 'Edit Arm',
              onClick: (e) => {
                !!e && e.preventDefault();
                searchParams.set('mode', 'edit');
                searchParams.set('arm', arm.id);
                setSearchParams(searchParams);
              },
              className: 'text-xs',
            },
            {
              type: 'link',
              name: 'Delete',
              title: 'Delete Arm',
              onClick: (e) => {
                !!e && e.preventDefault();
                searchParams.set('mode', 'delete');
                searchParams.set('arm', arm.id);
                setSearchParams(searchParams);
              },
              className: 'text-xs',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TrialArmListItem;
