import * as Yup from 'yup';

export const scenarioSchema = Yup.object({
  name: Yup.string().max(254, 'Must be 254 characters or less').required('Required'),
  type: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
  owner: Yup.string().max(50, 'Must be 50 characters or less'),
  regulatoryDesignation: Yup.string().max(50, 'Must be 50 characters or less'),
  status: Yup.string().max(50, 'Must be 50 characters or less'),
  rangesMode: Yup.string().max(50, 'Must be 50 characters or less'),
  targetRegions: Yup.string().max(1000, 'Must be 1000 characters or less'),
  patientSegment: Yup.string().max(50, 'Must be 50 characters or less'),
});

export const linkClaimSchema = Yup.object({
  claimId: Yup.string().required('Required'),
});

export const linkTrialSchema = Yup.object({
  trialId: Yup.string().required('Required'),
});
