import { useSearchParams } from 'react-router-dom';

import NoContent from '../../../common/NoContent';
import Button from '../../../common/Button';

const EvidenceClaimListEmpty = ({ evidence, claims = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (claims.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="primary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You haven't linked any Claims yet.</div>
          <div className="mt-2 text-xs">Link one by clicking the button below.</div>
          <Button
            variant="secondary"
            className="mt-4 w-48 rounded"
            onClick={() => {
              searchParams.append('linkClaim', evidence.id);
              setSearchParams(searchParams);
            }}
          >
            LINK CLAIM
          </Button>
        </div>
      </NoContent>
    </div>
  );
};

export default EvidenceClaimListEmpty;
