import { Formik } from 'formik';

import ScenarioForm from '../common/ScenarioForm';

import { scenarioSchema } from '../../../validations/scenario.validations';
import { useUpdateScenario } from '../../../hooks/scenarios.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const ScenarioEditForm = ({ projectId, scenario }) => {
  const toastsContext = useToastsContext();
  const updateScenario = useUpdateScenario();

  return (
    <Formik
      initialValues={{ ...scenario }}
      enableReinitialize={true}
      validationSchema={scenarioSchema}
      onSubmit={(values, { setSubmitting }) => {
        updateScenario.mutate(
          { projectId, scenario: { ...scenario, ...values } },
          {
            onSuccess: (scenario) => {
              setSubmitting(false);
              toastsContext.createToast(`Updated project scenario.`);
            },
            onError: (err) => {
              console.error(`Failed to update project scenario. Detail:`, err);
              // TODO display error notification
              setSubmitting(false);
            },
          },
        );
      }}
    >
      {(formik) => <ScenarioForm formik={formik} />}
    </Formik>
  );
};

export default ScenarioEditForm;
