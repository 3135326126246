import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Loading from '../../common/Loading';
import Submenu from '../../common/Submenu';
import AssetEditForm from './AssetEditForm';
import AssetIndications from './indications/AssetIndications';

import { useGetAsset } from '../../../hooks/asset.hooks';

const VIEWS = ['detail', 'indications'];

const AssetEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const { data: asset, isLoading } = useGetAsset({ projectId });

  // set default view
  useEffect(() => {
    if (!VIEWS.includes(searchParams.get('view'))) {
      navigate('?view=detail', { replace: true });
    }
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div id="asset-edit" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <h3 className="font-bold">EDIT ASSET</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Submenu
          items={[
            {
              name: 'DETAIL',
              view: 'detail',
              to: `/projects/${projectId}/asset?view=detail`,
            },
            {
              name: 'INDICATIONS',
              view: 'indications',
              to: `/projects/${projectId}/asset?view=indications`,
            },
          ]}
        />

        {searchParams.get('view') === 'detail' && (
          <AssetEditForm projectId={projectId} asset={asset} />
        )}

        {searchParams.get('view') === 'indications' && (
          <AssetIndications projectId={projectId} asset={asset} />
        )}
      </div>
    </div>
  );
};

export default AssetEdit;
