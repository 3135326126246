/**
 * Returns the singular form of the item name when the count = 1; otherwise
 * returns the plural form.
 * @param {number} count The number of items.
 * @param {string} singular The singular form of the item name.
 * @param {string} plural The plural form of the item name.
 * @returns Either the singular or plural form of the item name.
 */
export const pluralize = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};
