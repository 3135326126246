import { useSearchParams } from 'react-router-dom';

import ScenarioIndicationList from './ScenarioIndicationList';
import ScenarioIndicationCreate from './create/ScenarioIndicationCreate';

const ScenarioIndications = (props) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get('mode') === 'create') {
    return <ScenarioIndicationCreate {...props} />;
  }

  return <ScenarioIndicationList {...props} />;
};

export default ScenarioIndications;
