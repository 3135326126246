import { Droppable } from 'react-beautiful-dnd';

import DraggableClaimCard from '../common/DraggableClaimCard';

import { selectClaims, selectNotMerged } from '../../../selectors/claims.selectors';

const ColumnPriorityClaims = ({ claims = [] }) => {
  const priorityClaims = selectClaims(selectNotMerged(claims), { isPrioritized: true });

  return (
    <Droppable droppableId="column-priority">
      {(provided) => (
        <div
          className="h-full overflow-y-auto rounded-b-lg py-4 px-2 shadow-lg shadow-slate-500/50"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {priorityClaims.map((claim, index) => (
            <DraggableClaimCard key={claim.id} claim={claim} index={index} variant="green" />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ColumnPriorityClaims;
