import { useSearchParams } from 'react-router-dom';

import Button from '../../../../common/Button';

const TrialArmListMenu = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex items-center">
      <div className="font-bold uppercase text-sky-900">TRIAL ARMS</div>
      <Button
        size="sm"
        variant="primary"
        className="ml-auto w-48 rounded"
        onClick={() => {
          searchParams.set('mode', 'create');
          setSearchParams(searchParams);
        }}
      >
        CREATE ARM
      </Button>
    </div>
  );
};

export default TrialArmListMenu;
