import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import Submenu from '../../common/Submenu';
import ScenarioEditForm from './ScenarioEditForm';
import ScenarioIndications from './indications/ScenarioIndications';
import ScenarioClaimList from './claims/ScenarioClaimList';
import ScenarioTrialList from './trials/ScenarioTrialList';

import { useGetScenario } from '../../../hooks/scenarios.hooks';

const ScenarioEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, scenarioId } = useParams();
  const { data: scenario, isLoading } = useGetScenario({ projectId, scenarioId });

  const getViewComponent = () => {
    const view = searchParams.get('view');

    switch (view) {
      case 'indications':
        return <ScenarioIndications projectId={projectId} scenario={scenario} />;
      case 'claims':
        return <ScenarioClaimList projectId={projectId} scenario={scenario} />;
      case 'trials':
        return <ScenarioTrialList projectId={projectId} scenario={scenario} />;
      default:
        return <ScenarioEditForm projectId={projectId} scenario={scenario} />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  if (!scenario) {
    return <Navigate to={`/projects/${projectId}/scenarios`} />;
  }

  return (
    <div id="scenario-edit" className="h-full overflow-y-auto">
      <div className="mb-2 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">EDIT SCENARIO</h3>
      </div>

      <div className="rounded-2xl bg-white p-8">
        <Submenu
          items={[
            {
              name: 'DETAIL',
              view: 'detail',
              to: `/projects/${projectId}/scenarios/${scenarioId}?view=detail`,
            },
            {
              name: 'INDICATIONS',
              view: 'indications',
              to: `/projects/${projectId}/scenarios/${scenarioId}?view=indications`,
            },
            {
              name: 'CLAIMS',
              view: 'claims',
              to: `/projects/${projectId}/scenarios/${scenarioId}?view=claims`,
            },
            {
              name: 'TRIALS',
              view: 'trials',
              to: `/projects/${projectId}/scenarios/${scenarioId}?view=trials`,
            },
          ]}
        />

        {getViewComponent()}
      </div>
    </div>
  );
};

export default ScenarioEdit;
