import storage from '../utils/storage';
import { generateId } from '../utils/id';
import { delay } from '../utils/delay';

/**
 * Fetch a collection of `Notification` objects sent to an account.
 * @param {string} accountId An account identifier.
 * @returns {Promise} Returns a Promise which resolves to a collection of
 * `Notification` objects if successful, otherwise rejects with an error.
 */
export const getNotifications = async (accountId) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const notifications = storage.getJson('notifications') || [];
        const accountNotifications = notifications.filter((n) => n.to.includes(accountId));
        return resolve(accountNotifications);
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Fetch a single `Notification` by identifier.
 * @param {string} id A notification identifier.
 * @returns {Promise} Returns a Promise which resolves to a `Notification`
 * if successful, otherwise rejects with an error.
 */
export const getNotification = async (id) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const notifications = storage.getJson('notifications') || [];
        const notification = notifications.find((n) => n.id === id);
        if (notification) {
          resolve(notification);
        }
        return reject(new Error('Not found.'));
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Create a `Notification`.
 * @param {Object} notification The notification to be created.
 * @returns {Promise} Returns a Promise which resolves to the created
 * Notification if successful, otherwise rejects with an error.
 */
export const createNotification = async (notification) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const notifications = storage.getJson('notifications') || [];
        const createdNotification = {
          ...notification,
          id: generateId(),
          createdAt: new Date().toISOString(),
        };
        storage.setJson('notifications', [...notifications, createdNotification]);
        return resolve(createdNotification);
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Update a `Notification`.
 * @param {Object} notification The notification to be updated.
 * @returns {Promise} Returns a Promise which resolves to the updated
 * Notification if successful, otherwise rejects with an error.
 */
export const updateNotification = async (notification) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const notifications = storage.getJson('notifications') || [];
        const notificationToUpdate = notifications.find((n) => n.id === notification.id);
        if (!notificationToUpdate) {
          return reject(new Error('Not found.'));
        }

        const updatedNotification = {
          ...notificationToUpdate,
          ...notification,
          updatedAt: new Date().toISOString(),
        };

        storage.setJson(
          'notifications',
          notifications.map((t) => {
            if (t.id === updatedNotification.id) {
              return updatedNotification;
            }
            return t;
          }),
        );
        return resolve(updatedNotification);
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Remove a `Notification`.
 * @param {string} id A notification identifier.
 * @returns {Promise} Returns a Promise which resolves empty if
 * successful, otherwise rejects with an error.
 */
export const removeNotification = async (id) => {
  return new Promise((resolve, reject) => {
    delay(750).then(() => {
      try {
        const notifications = storage.getJson('notifications') || [];
        storage.setJson(
          'notifications',
          notifications.filter((n) => n.id !== id),
        );
        return resolve();
      } catch (err) {
        return reject(err);
      }
    });
  });
};

/**
 * Removes all `Notification` items for an account.
 * @param {string} accountId An account identifier.
 * @returns {Promise} A Promise which resolves to the collection of removed
 * Notification items if successful, otherwise rejects with an error.
 */
export const removeAllNotifications = async (accountId) => {
  return new Promise((resolve, reject) => {
    delay().then(() => {
      try {
        const notifications = storage.getJson('notifications') || [];
        const accountNotifications = notifications.filter((n) => n.to.includes(accountId));
        const otherNotifications = notifications.filter((n) => !n.to.includes(accountId));
        storage.setJson('notifications', otherNotifications);
        return resolve(accountNotifications);
      } catch (err) {
        return reject(err);
      }
    });
  });
};
