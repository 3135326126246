import { Formik } from 'formik';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import find from 'lodash/find';
import unionBy from 'lodash/unionBy';

import Icon from '../../../../common/Icon';
import TrialArmForm from '../common/TrialArmForm';

import { useUpdateTrial } from '../../../../../hooks/trials.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';
import { trialArmSchema } from '../../../../../validations/trial.validations';

const TrialArmEdit = ({ projectId, trial }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const updateTrial = useUpdateTrial();
  const toastsContext = useToastsContext();

  const arm = find(trial.arms, { id: searchParams.get('arm') });

  if (!arm) {
    return <Navigate to={`/projects/${projectId}/trials/${trial.id}?view=arms`} />;
  }

  return (
    <div id="trial-arm-edit">
      <div className="mb-8 flex items-center text-sky-900">
        <Icon
          icon="arrow-left"
          className="mr-1 h-4 w-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h3 className="font-bold">EDIT TRIAL ARM</h3>
      </div>

      <Formik
        initialValues={{ ...arm }}
        validationSchema={trialArmSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          const trialToUpdate = {
            ...trial,
            arms: unionBy([{ ...values }], trial.arms, 'id'),
          };
          updateTrial.mutate(
            { projectId, trial: trialToUpdate },
            {
              onSuccess: (trial) => {
                toastsContext.createToast(`Updated trial arm.`);
                searchParams.delete('arm');
                searchParams.delete('mode');
                setSearchParams(searchParams);
                setSubmitting(false);
              },
              onError: (err) => {
                console.error(`Failed to update trial arm. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <TrialArmForm formik={formik} />}
      </Formik>
    </div>
  );
};

export default TrialArmEdit;
