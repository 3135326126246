import { Outlet } from 'react-router-dom';

const AuthPage = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="h-3/4 w-[500px]">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthPage;
