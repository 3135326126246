import axios from 'axios';
import merge from 'lodash/merge';

import { configSchema } from '../validations/config.validations';

const DEFAULT_CONFIG = {
  NODE_ENV: 'development',
  api: {
    delayMillis: 1200,
  },
  app: {
    toastDismissMillis: 5000,
  },
  auth: {
    cognito: {
      clientId: 'mqkk8i9ur7eoilt6dtjss6fkr',
      domain: 'ai-design-center.auth.us-east-1.amazoncognito.com',
      poolId: 'us-east-1_2ufhtakRN',
      redirectUrl: 'http://localhost:3000/auth/signin',
      region: 'us-east-1',
      signoutUrl: 'http://localhost:3000',
    },
    sessionExpireMillis: 14400000,
  },
};

class Configuration {
  #config;

  constructor(config) {
    if (!Configuration.instance) {
      this.#config = config;
      Configuration.instance = this;
    }

    return Configuration.instance;
  }

  #load = async () => {
    try {
      const response = await axios.get('/config/app.json');
      // console.log(`raw config::${response.data}`);
      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      // console.log(`Unable to load remote config. ${err}`);
      return {};
    }
  };

  init = async () => {
    try {
      const remoteConfig = await this.#load();
      // console.log(`remote config::${JSON.stringify(remoteConfig)}`);

      const mergedConfig = merge({}, this.#config, remoteConfig);
      this.#config = await configSchema.validate(mergedConfig, {
        abortEarly: false,
        stripUnknown: true,
      });
      // console.log(`config::${JSON.stringify(this.#config, null, 2)}`);
    } catch (err) {
      console.error(`Unable to initialize config. ${err}`);
      if (err.name === 'ValidationError') {
        err.errors.forEach((msg) => console.error(msg));
      }
    }
  };

  get = () => {
    return this.#config;
  };
}

const instance = new Configuration(DEFAULT_CONFIG);

export { instance as Configuration };
