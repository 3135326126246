import numeral from 'numeral';
import { usePopperTooltip } from 'react-popper-tooltip';

import Icon from '../../../common/Icon';
import Tooltip from '../../../common/Tooltip';

import {
  selectTrialsAverageRisk,
  selectTrialRiskLabel,
} from '../../../../selectors/trials.selectors';

const ScenarioTrialRisk = ({ trials = [] }) => {
  const { setTriggerRef, ...tooltipProps } = usePopperTooltip({
    delayShow: 500,
    placement: 'top',
  });

  const averageTrialRisk = selectTrialsAverageRisk(trials);

  return (
    <div className="flex cursor-help items-center" ref={setTriggerRef}>
      <Icon icon="scale-balanced" className="mr-1" />
      <div>{numeral(averageTrialRisk).format('0[.]0[0]')}</div>
      <Tooltip {...tooltipProps}>
        The estimated risk of all trials is {selectTrialRiskLabel(averageTrialRisk).toLowerCase()},
        with an average score of {numeral(averageTrialRisk).format('0[.]0[0]')} out of a maximum of
        5.
      </Tooltip>
    </div>
  );
};

export default ScenarioTrialRisk;
