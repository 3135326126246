import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../common/Icon';
import SearchForm from './SearchForm';
import Dropdown from '../common/Dropdown';
import Avatar from '../common/Avatar';

import { useAuthState } from '../../hooks/cognito.hooks';

const PrimaryNavItem = ({ children, show = true, ...props }) => {
  if (show) {
    return (
      <NavLink
        className={({ isActive }) =>
          classNames('flex items-center px-6 font-bold text-sky-900 hover:text-sky-700', {
            'text-sky-500 hover:text-sky-500': isActive,
          })
        }
        {...props}
      >
        {children}
      </NavLink>
    );
  }

  return null;
};

const Header = () => {
  const { data: authState, isSuccess } = useAuthState();
  const isAuthenticated = isSuccess && authState?.isAuthenticated;

  return (
    <div
      id="header"
      className="flex h-16 items-center px-16 text-sky-900 shadow-lg shadow-slate-500/20"
    >
      {/* Header App Icon */}
      <div className="mr-10">
        <Link to="/" title="Design Center">
          <img src="/logo.svg" alt="logo" />
          <span className="sr-only">Design Center</span>
        </Link>
      </div>

      {/* Header Left Nav Menu */}
      <nav className="mr-auto flex h-full items-center">
        <PrimaryNavItem to="/dashboard" title="Dashboard" show={isAuthenticated}>
          <Icon icon="swatchbook" className="mr-2 inline-block h-5 w-5" />
          Dashboard
        </PrimaryNavItem>
        <PrimaryNavItem to="/projects" title="Projects" show={isAuthenticated}>
          <Icon icon="folder-tree" className="mr-2 inline-block h-5 w-5" />
          Portfolio
        </PrimaryNavItem>
      </nav>

      {/* Header Search Form */}
      {isAuthenticated && <SearchForm />}

      {/* Header Right Nav Menu */}
      <nav className="ml-6 flex items-center">
        {isAuthenticated && (
          <Dropdown
            toggle={<Avatar className="cursor-pointer" />}
            content={[
              { type: 'header', name: 'Account' },
              {
                type: 'link',
                title: 'Sign Out',
                to: '/auth/signout',
                name: 'Sign Out',
                className: 'text-sm',
              },
            ]}
            contentClasses="right-0"
          />
        )}
      </nav>
    </div>
  );
};

export default Header;
