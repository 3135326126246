import dayjs from 'dayjs';
import numeral from 'numeral';
import union from 'lodash/union';
import flatMap from 'lodash/flatMap';

import Icon from '../../../common/Icon';

import { useGetAllEvidenceForTrial } from '../../../../hooks/evidence.hooks';
import { TrialGeographies } from '../../../../utils/constants';
import { selectTrialRiskLabel } from '../../../../selectors/trials.selectors';
import { pluralize } from '../../../../utils/pluralize';

const ScenarioTrial = ({ project, scenario, trial }) => {
  const { data: evidenceList, isLoading } = useGetAllEvidenceForTrial({
    projectId: project.id,
    trialId: trial.id,
  });

  if (isLoading) return <Icon icon="rotate" spin className="my-4 block" />;

  const evidenceClaimIds = union(flatMap(evidenceList, 'claims'));
  const scenarioClaimIds = evidenceClaimIds.filter((cid) => scenario.claims.includes(cid));

  const getEvidenceStatement = (evidenceCount, claimCount) => {
    if (evidenceCount) {
      return (
        <span>
          The study collects {evidenceCount} {pluralize(evidenceCount, 'piece', 'pieces')} of
          evidence to support {claimCount} {pluralize(claimCount, 'claim', 'claims')}.
        </span>
      );
    }

    return null;
  };

  return (
    <div className="mb-3">
      <div className="text-lg font-bold text-sky-600">{trial.name}</div>
      <div>
        A phase {trial.phase} study targeting {trial.indication} in{' '}
        {TrialGeographies[trial.geography]} with {trial.patientCount} patients.{' '}
        {getEvidenceStatement(evidenceList.length, scenarioClaimIds.length)} The study commences{' '}
        {dayjs(trial.startAt).format('MMMM D, YYYY')} and concludes{' '}
        {dayjs(trial.endAt).format('MMMM D, YYYY')} having{' '}
        <span className="lowercase">{selectTrialRiskLabel(trial.riskScore)}</span> risk and a cost
        of {numeral(trial.cost).format('(0[.]00a)').toUpperCase()}.
      </div>
    </div>
  );
};

export default ScenarioTrial;
