import ScenarioTrialListMenu from './ScenarioTrialListMenu';
import ScenarioTrialListItem from './ScenarioTrialListItem';
import ScenarioTrialListHeader from './ScenarioTrialListHeader';
import ScenarioTrialListFooter from './ScenarioTrialListFooter';

import { selectTrialsWithListControls } from '../../../../selectors/trials.selectors';
import { useTrialListControlsContext } from '../../../../hooks/listcontrols.hooks';

const ScenarioTrialListContent = ({ scenario, trials, projectId }) => {
  const { data: listControls } = useTrialListControlsContext();

  if (trials.length === 0) {
    return null;
  }

  const { data: allTrials, page } = selectTrialsWithListControls(trials, listControls);

  return (
    <>
      <ScenarioTrialListMenu scenario={scenario} trials={trials} />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <ScenarioTrialListHeader />

        {page.map((trial, index) => (
          <ScenarioTrialListItem
            key={trial.id}
            trial={trial}
            scenario={scenario}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <ScenarioTrialListFooter totalItemsOnPage={page.length} totalItems={allTrials.length} />
        </div>
      </div>
    </>
  );
};

export default ScenarioTrialListContent;
