import { Droppable } from 'react-beautiful-dnd';
import filter from 'lodash/filter';

import DraggableEvidenceCard from '../../evidence/common/DraggableEvidenceCard';

import { selectEvidence } from '../../../selectors/evidence.selectors';

const ColumnEvidenceLinked = ({ claims = [], evidenceList = [] }) => {
  let selectedEvidence = selectEvidence(evidenceList);
  selectedEvidence = filter(selectedEvidence, (e) => e.claims.length > 0);

  return (
    <Droppable droppableId="column-evidence-linked">
      {(provided) => (
        <div
          className="h-full overflow-y-auto rounded-b-lg py-4 px-2 shadow-lg shadow-slate-500/50"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {selectedEvidence.map((evidence, index) => (
            <DraggableEvidenceCard
              key={evidence.id}
              index={index}
              evidence={evidence}
              variant="info"
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ColumnEvidenceLinked;
