import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import Loading from '../../common/Loading';
import NoContent from '../../common/NoContent';
import { Stars } from '../../common/StarField';
import Badge from '../../common/Badge';
import ButtonBar from '../../common/ButtonBar';
import Button from '../../common/Button';
import ClaimListMenu from './ClaimListMenu';
import Dropdown from '../../common/Dropdown';

import { useGetClaims } from '../../../hooks/claims.hooks';
import {
  selectClaims,
  selectClaimsWithListControls,
  selectNotMerged,
} from '../../../selectors/claims.selectors';
import { useClaimListControlsContext } from '../../../hooks/listcontrols.hooks';

const ClaimListLoading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    </div>
  );
};

const ClaimListEmpty = ({ claims = [] }) => {
  const navigate = useNavigate();
  if (claims.length > 0) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NoContent variant="primary">
        <div className="mt-2 flex flex-col items-center text-slate-700">
          <div>You don't have any Claims yet.</div>
          <div className="mt-2 text-xs">Create a new one by clicking the button below.</div>
          <Button
            variant="secondary"
            className="mt-4 w-48 rounded"
            onClick={() => navigate('create')}
          >
            CREATE CLAIM
          </Button>
        </div>
      </NoContent>
    </div>
  );
};

const ClaimListColumnHeading = ({ name, title }) => {
  const { data: listControls, setSort } = useClaimListControlsContext();

  let icon;
  if (listControls.sort.by === name) {
    if (listControls.sort.order === 'asc') {
      icon = <Icon icon="arrow-up" className="ml-1 inline-block h-3.5 w-3.5" />;
    } else {
      icon = <Icon icon="arrow-down" className="ml-1 inline-block h-3.5 w-3.5 stroke-2" />;
    }
  }

  return (
    <div className="flex cursor-pointer items-center" onClick={() => setSort(name)}>
      <div>{title}</div>
      {icon}
    </div>
  );
};

const ClaimListHeader = () => {
  return (
    <div className="grid grid-cols-20 py-3 px-2 text-sm text-sky-700">
      <div className="col-span-8">
        <ClaimListColumnHeading name="name" title="NAME" />
      </div>
      <div className="col-span-1">
        <ClaimListColumnHeading name="type" title="TYPE" />
      </div>
      <div className="col-span-2">
        <ClaimListColumnHeading name="overallScore" title="SCORE" />
      </div>
      <div className="col-span-2">
        <ClaimListColumnHeading name="isPrioritized" title="PRIORITIZED" />
      </div>
      <div className="col-span-2">
        <ClaimListColumnHeading name="isReviewNeeded" title="REVIEW" />
      </div>
      <div className="col-span-2">
        <ClaimListColumnHeading name="scenarios.length" title="SCENARIOS" />
      </div>
      <div className="col-span-2">
        <ClaimListColumnHeading name="evidence.length" title="EVIDENCE" />
      </div>
      <div></div>
    </div>
  );
};

const ClaimListFooter = ({ totalItemsOnPage, totalItems }) => {
  const { data: listControls, setPage } = useClaimListControlsContext();
  let pageStart = 0;
  let pageEnd = 0;
  if (totalItems > 0) {
    pageStart = (listControls.pagination.page - 1) * listControls.pagination.size + 1;
    pageEnd = pageStart + (totalItemsOnPage - 1);
  }

  const totalPages = Math.ceil(totalItems / listControls.pagination.size);
  const isLastPage = listControls.pagination.page === totalPages;
  const pages = [];
  let pageIndex = isLastPage ? listControls.pagination.page - 2 : listControls.pagination.page - 1;
  while (pages.length < Math.min(totalPages, 3)) {
    if (pageIndex > 0 && pageIndex <= totalPages) {
      pages.push(pageIndex);
    }
    pageIndex++;
  }

  return (
    <div className="grid grid-cols-12 gap-1 py-3 px-2 text-sky-700">
      <div className="col-span-8 font-bold">
        Showing {pageStart}-{pageEnd} of {totalItems} claims
      </div>
      <div className="col-span-4 justify-self-end">
        <ButtonBar>
          <Button
            title="First Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(1)}
          >
            <Icon icon="chevron-left" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
          {pages.map((page) => (
            <Button
              key={page}
              title={`Page ${page}`}
              variant={listControls.pagination.page === page ? 'primary' : 'outline-primary'}
              size="sm"
              className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
              onClick={() => setPage(page)}
              disabled={listControls.pagination.page === page}
            >
              {page}
            </Button>
          ))}
          <Button
            title="Last Page"
            variant="outline-primary"
            size="sm"
            className="mx-1.5 w-8 rounded border-[1px] bg-transparent text-sm"
            onClick={() => setPage(totalPages)}
          >
            <Icon icon="chevron-right" className="mb-0.5 inline-block h-3 w-3" />
          </Button>
        </ButtonBar>
      </div>
    </div>
  );
};

const ClaimListItem = ({ claim, highlight }) => {
  if (!claim) {
    return null;
  }

  const getMenuContent = () => {
    const menu = [
      {
        type: 'link',
        name: 'Edit',
        title: 'Edit Claim',
        to: `${claim.id}`,
        className: 'text-xs',
      },
    ];

    if (!claim.type.includes('merged') && !claim.isArchived) {
      menu.push({
        type: 'link',
        name: 'Merge',
        title: 'Merge Claim',
        to: `?mergeClaim=${claim.id}`,
        className: 'text-xs',
      });
    }

    menu.push({
      type: 'link',
      name: 'Link Evidence',
      title: 'Link Evidence with Claim',
      to: `?linkEvidence=${claim.id}`,
      className: 'text-xs',
    });

    return menu;
  };

  return (
    <div
      className={classNames('grid grid-cols-20 items-center py-3 px-2 text-slate-900', {
        'bg-white/50': highlight,
      })}
    >
      <div className="col-span-8 truncate">
        <Link to={`${claim.id}`} className="hover:text-sky-700">
          {claim.name}
        </Link>
      </div>
      <div className="col-span-1">
        {claim.type.includes('clinical') && (
          <Icon
            icon="stethoscope"
            className="mr-1 inline-block h-4 w-4 text-slate-600"
            title="Clinical"
          />
        )}
        {claim.type.includes('commercial') && (
          <Icon
            icon="landmark"
            className="mr-1 inline-block h-4 w-4 text-slate-600"
            title="Commercial"
          />
        )}
        {claim.type.includes('merged') && (
          <Icon
            icon="code-merge"
            className="mr-1 inline-block h-4 w-4 text-slate-600"
            title="Merged"
          />
        )}
      </div>
      <div className="col-span-2">
        <Stars count={5} value={claim.overallScore} />
      </div>
      <div className="col-span-2">
        {claim.isPrioritized && <Badge variant="success">PRIORITY</Badge>}
      </div>
      <div className="col-span-2">
        {claim.isReviewNeeded && <Badge variant="danger">FLAGGED</Badge>}
      </div>
      <div className="col-span-2">{claim.scenarios.length}</div>
      <div className="col-span-2">{claim.evidence.length}</div>
      <div>
        <Dropdown
          contentClasses="right-5 -top-1.5 text-sky-900"
          toggle={
            <Icon icon="ellipsis-vertical" className="ml-auto block h-4 w-4 text-slate-700" />
          }
          content={getMenuContent()}
        />
      </div>
    </div>
  );
};

const ClaimListContent = ({ claims = [], isFetching }) => {
  const { data: listControls } = useClaimListControlsContext();

  if (claims.length === 0) {
    return null;
  }

  let { data: allClaims, page } = selectClaimsWithListControls(claims, listControls);

  return (
    <>
      <ClaimListMenu isFetching={isFetching} />

      <div className="mt-4 flex flex-col rounded-xl bg-white/30">
        <ClaimListHeader />

        {page.map((claim, index) => (
          <ClaimListItem key={claim.id} claim={claim} highlight={index % 2 === 0} />
        ))}

        <div className="mt-auto">
          <ClaimListFooter totalItemsOnPage={page.length} totalItems={allClaims.length} />
        </div>
      </div>
    </>
  );
};

const ClaimList = () => {
  const { projectId } = useParams();
  const { data: claims, status, isFetching } = useGetClaims({ projectId });

  const selectedClaims = selectNotMerged(selectClaims(claims));

  return (
    <div id="claims-list" className="h-full">
      {status === 'loading' ? (
        <ClaimListLoading />
      ) : (
        <>
          <ClaimListEmpty claims={selectedClaims} />
          <ClaimListContent claims={selectedClaims} isFetching={isFetching} />
        </>
      )}
    </div>
  );
};

export default ClaimList;
