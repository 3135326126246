import { Link, useRouteError } from 'react-router-dom';

import Icon from '../common/Icon';
import ContentBox from '../common/ContentBox';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className="flex h-screen flex-col items-center justify-center text-slate-700"
    >
      <ContentBox className="w-[500px]" variant="danger">
        <h1 className="text-4xl font-bold text-sky-700">OH NO!</h1>
        <div className="mt-2">This is a bit embarrasing. We seem to have had a problem.</div>
        <div className="my-2 rounded border border-amber-400 bg-amber-100 p-2">
          <Icon icon="triangle-exclamation" className="mr-2 inline-block h-5 w-5 text-amber-400" />
          {error.statusText || error.message}
        </div>
        <Link to="/" className="mt-2 ml-auto text-sky-900 hover:text-sky-700">
          GO BACK
        </Link>
      </ContentBox>
    </div>
  );
};

export default ErrorPage;
