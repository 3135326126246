import classNames from 'classnames';

import Icon from './Icon';

/**
 * Returns CSS classes to style the loading component color.
 * @param {string} variant The loading indicator color variant.
 * @returns {string} CSS classes
 */
const getColorClasses = (variant) => {
  switch (variant) {
    case 'secondary':
      return 'bg-sky-300/50';
    case 'info':
      return 'bg-fuchsia-500/50';
    default:
      return 'bg-sky-400/50';
  }
};

/**
 * The `Loading` component renders a standard loading indicator.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const Loading = ({ children, variant }) => {
  return (
    <div className="flex flex-col items-center">
      <div className={classNames('rounded-full p-6', getColorClasses(variant))}>
        <Icon icon="rotate" spin className="h-14 w-14 text-white/80" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Loading;
