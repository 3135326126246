import { createBrowserRouter, Navigate } from 'react-router-dom';

import Protected from './Protected';
import StandardLayout from '../layouts/StandardLayout';
import ErrorPage from '../pages/ErrorPage';
import LandingPage from '../pages/LandingPage';
import AuthPage from '../pages/AuthPage';
import CognitoSignOut from '../auth/CognitoSignOut';
import CognitoSignIn from '../auth/CognitoSignIn';
import WithModals from '../common/WithModals';
import DashboardPage from '../pages/DashboardPage';
import ProjectsPage from '../pages/ProjectsPage';
import ProjectListPage from '../pages/ProjectListPage';
import ProjectCreatePage from '../projects/create/ProjectCreatePage';
import ProjectSection from '../projects/edit/ProjectSection';
import ProjectPage from '../pages/ProjectPage';
import AssetSection from '../asset/AssetSection';
import ScenariosSection from '../scenarios/ScenariosSection';
import ScenarioList from '../scenarios/list/ScenarioList';
import ScenarioCreate from '../scenarios/create/ScenarioCreate';
import ScenarioEdit from '../scenarios/edit/ScenarioEdit';
import ClaimsSection from '../claims/ClaimsSection';
import ClaimList from '../claims/list/ClaimList';
import ClaimBoard from '../claims/board/ClaimBoard';
import ClaimCreate from '../claims/create/ClaimCreate';
import ClaimEdit from '../claims/edit/ClaimEdit';
import EvidenceSection from '../evidence/EvidenceSection';
import EvidenceList from '../evidence/list/EvidenceList';
import EvidenceBoard from '../evidence/board/EvidenceBoard';
import EvidenceCreate from '../evidence/create/EvidenceCreate';
import EvidenceEdit from '../evidence/edit/EvidenceEdit';
import TrialsSection from '../trials/TrialsSection';
import TrialList from '../trials/list/TrialList';
import TrialCreate from '../trials/create/TrialCreate';
import TrialEdit from '../trials/edit/TrialEdit';
import ReportsPage from '../pages/ReportsPage';
import ScenarioSummaryReport from '../reports/scenario/summary/ScenarioSummaryReport';
import ScenarioScetReport from '../reports/scenario/scet/ScenarioScetReport';
import ScenarioHierarchyReport from '../reports/scenario/hierarchy/ScenarioHierarchyReport';
import ScenarioTrialsReport from '../reports/scenario/trials/ScenarioTrialsReport';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <StandardLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: '/auth',
        element: <AuthPage />,
        children: [
          {
            path: 'signin',
            element: <CognitoSignIn />,
          },
          {
            path: 'signout',
            element: <CognitoSignOut />,
          },
        ],
      },
      {
        path: '/dashboard',
        element: (
          <Protected>
            <WithModals>
              <DashboardPage />
            </WithModals>
          </Protected>
        ),
      },
      {
        path: '/projects',
        element: (
          <Protected>
            <ProjectsPage />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <ProjectListPage />,
          },
          {
            path: 'create',
            element: <ProjectCreatePage />,
          },
          {
            path: ':projectId',
            element: <ProjectPage />,
            children: [
              {
                index: true,
                element: <Navigate to="asset" replace={true} />,
              },
              {
                path: 'edit',
                element: <ProjectSection />,
              },
              {
                path: 'asset',
                element: <AssetSection />,
              },
              {
                path: 'scenarios',
                element: <ScenariosSection />,
                children: [
                  {
                    index: true,
                    element: <ScenarioList />,
                  },
                  {
                    path: 'create',
                    element: <ScenarioCreate />,
                  },
                  {
                    path: ':scenarioId',
                    element: <ScenarioEdit />,
                  },
                ],
              },
              {
                path: 'claims',
                element: <ClaimsSection />,
                children: [
                  {
                    index: true,
                    element: <ClaimList />,
                  },
                  {
                    path: 'board',
                    element: <ClaimBoard />,
                  },
                  {
                    path: 'create',
                    element: <ClaimCreate />,
                  },
                  {
                    path: ':claimId',
                    element: <ClaimEdit />,
                  },
                ],
              },
              {
                path: 'evidence',
                element: <EvidenceSection />,
                children: [
                  {
                    index: true,
                    element: <EvidenceList />,
                  },
                  {
                    path: 'board',
                    element: <EvidenceBoard />,
                  },
                  {
                    path: 'create',
                    element: <EvidenceCreate />,
                  },
                  {
                    path: ':evidenceId',
                    element: <EvidenceEdit />,
                  },
                ],
              },
              {
                path: 'trials',
                element: <TrialsSection />,
                children: [
                  {
                    index: true,
                    element: <TrialList />,
                  },
                  {
                    path: 'create',
                    element: <TrialCreate />,
                  },
                  {
                    path: ':trialId',
                    element: <TrialEdit />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'reports',
        element: (
          <Protected>
            <ReportsPage />
          </Protected>
        ),
        children: [
          {
            path: 'scenario-summary',
            element: <ScenarioSummaryReport />,
          },
          {
            path: 'scenario-scet',
            element: <ScenarioScetReport />,
          },
          {
            path: 'scenario-hierarchy',
            element: <ScenarioHierarchyReport />,
          },
          {
            path: 'scenario-trials',
            element: <ScenarioTrialsReport />,
          },
        ],
      },
    ],
  },
]);
