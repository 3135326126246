import { useSearchParams } from 'react-router-dom';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import union from 'lodash/union';

import Loading from '../../../common/Loading';
import Icon from '../../../common/Icon';
import NumberIcon from '../../../common/NumberIcon';
import ScenarioRisk from '../common/ScenarioRisk';
import ScenarioTime from '../common/ScenarioTime';
import ScenarioCost from '../common/ScenarioCost';

import { useGetProject } from '../../../../hooks/projects.hooks';
import { useGetScenario } from '../../../../hooks/scenarios.hooks';
import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';
import { selectClaims } from '../../../../selectors/claims.selectors';
import { selectEvidence } from '../../../../selectors/evidence.selectors';
import { selectTrials } from '../../../../selectors/trials.selectors';

const ScenarioScetReport = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('project');
  const scenarioId = searchParams.get('scenario');

  // fetch project
  const { data: project, isLoading: isLoadingProject } = useGetProject(projectId);
  // fetch scenario
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });
  // fetch trials for scenario
  const { data: trials, isLoading: isLoadingTrials } = useGetAllTrialsForScenario(
    projectId,
    scenarioId,
  );
  const selectedTrials = selectTrials(trials, null, ['startAt'], ['asc']);

  if (isLoadingProject || isLoadingScenario || isLoadingTrials) {
    return (
      <Loading>
        <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
      </Loading>
    );
  }

  // fetch evidence set for trials
  const evidenceIds = union(flatMap(trials, 'evidence'));
  const selectedEvidence = selectEvidence(
    filter(project.evidence, (evidence) => evidenceIds.includes(evidence.id)),
  );
  // fetch claims set for evidence [set]
  const claimIds = union(flatMap(selectedEvidence, 'claims'));
  const selectedClaims = selectClaims(
    filter(project.claims, (claim) => claimIds.includes(claim.id)),
    null,
    ['overallScore'],
    ['desc'],
  );

  return (
    <div className="text-sky-900">
      <div>
        <div className="text-2xl uppercase">SCENARIO SCET: {scenario.name}</div>
        <div className="text-sm">Project: {project.name}</div>
      </div>

      <div className="mt-8">
        <div className="grid grid-cols-3">
          <div className="flex flex-col items-center">
            <div className="text-xl">RISK</div>
            <div className="cursor-help text-3xl">
              <ScenarioRisk projectId={projectId} scenarioId={scenarioId} />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-xl">TIME</div>
            <div className="cursor-help text-3xl">
              <ScenarioTime projectId={projectId} scenarioId={scenarioId} />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-xl">COST</div>
            <div className="cursor-help text-3xl">
              <ScenarioCost projectId={projectId} scenarioId={scenarioId} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 text-lg text-white">
        <div className="grid grid-cols-6">
          <div className="flex flex-col items-center justify-center rounded-tl-2xl bg-gradient-to-b from-sky-600 to-sky-900 py-4">
            <div className="text-6xl">S</div>
            <div>Scenario</div>
          </div>
          <div className="col-span-5 flex items-center rounded-tr-2xl bg-gradient-to-b from-sky-600 to-sky-900 py-4">
            {scenario.name}
          </div>

          <div className="flex flex-col items-center justify-center bg-gradient-to-b from-cyan-500 to-cyan-800 py-4">
            <div className="text-6xl">C</div>
            <div>Claims</div>
          </div>
          <div className="col-span-5 flex items-center bg-gradient-to-b from-cyan-500 to-cyan-800 py-4">
            <ul className="list-none">
              {selectedClaims.map((claim) => (
                <li key={claim.id} className="flex items-center">
                  <div className="mr-2">{claim.name}</div>
                  <NumberIcon number={claim.overallScore} className="mr-1" title="Claim Score" />
                  {claim.type.includes('clinical') && (
                    <Icon
                      icon="stethoscope"
                      className="mr-1 inline-block h-3.5 w-3.5"
                      title="Clinical"
                    />
                  )}
                  {claim.type.includes('commercial') && (
                    <Icon
                      icon="landmark"
                      className="mr-1 inline-block h-3.5 w-3.5"
                      title="Commercial"
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col items-center justify-center bg-gradient-to-b from-violet-800 to-violet-900 py-4">
            <div className="text-6xl">E</div>
            <div>Evidence</div>
          </div>
          <div className="col-span-5 flex items-center bg-gradient-to-b from-violet-800 to-violet-900 py-4">
            <ul className="list-none">
              {selectedEvidence.map((evidence) => (
                <li key={evidence.id}>{evidence.name}</li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col items-center justify-center rounded-bl-2xl bg-gradient-to-b from-purple-600 to-purple-900 py-4">
            <div className="text-6xl">T</div>
            <div>Trials</div>
          </div>
          <div className="col-span-5 flex items-center rounded-br-2xl bg-gradient-to-b from-purple-600 to-purple-900 py-4">
            <ul className="list-none">
              {selectedTrials.map((trial) => (
                <li key={trial.id}>{trial.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioScetReport;
