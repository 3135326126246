import Icon from '../../../common/Icon';

const ScenarioSummary = ({ scenario }) => {
  if (!scenario) return null;

  return (
    <div>
      <div className="mb-4 -ml-2 flex items-center text-5xl">
        <span className="fa-layers fa-fw" title="Scenario">
          <Icon icon="square" />
          <Icon icon="s" transform="shrink-6" />
        </span>
        <div className="-ml-1.5 text-3xl">cenario</div>
      </div>
      <div className="px-2">{scenario.name}</div>
    </div>
  );
};

export default ScenarioSummary;
