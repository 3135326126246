import { Outlet } from 'react-router-dom';

import { EvidenceListControlsContextProvider } from '../../contexts/listcontrols.context';

const EvidenceSection = () => {
  return (
    <div
      id="evidence-section"
      className="mt-4 h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      <EvidenceListControlsContextProvider
        initialState={{ sort: { by: 'name' }, pagination: { size: 9 } }}
      >
        <Outlet />
      </EvidenceListControlsContextProvider>
    </div>
  );
};

export default EvidenceSection;
