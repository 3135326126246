import TrialArmListMenu from './TrialArmListMenu';
import TrialArmListItem from './TrialArmListItem';
import TrialArmListFooter from './TrialArmListFooter';
import TrialArmListHeader from './TrialArmListHeader';

import { selectArmsWithListControls } from '../../../../../selectors/arms.selectors';
import { useArmListControlsContext } from '../../../../../hooks/listcontrols.hooks';

const TrialArmListContent = ({ trial, arms, projectId }) => {
  const { data: listControls } = useArmListControlsContext();

  if (arms.length === 0) {
    return null;
  }

  const { data: allArms, page } = selectArmsWithListControls(arms, listControls);

  return (
    <>
      <TrialArmListMenu />

      <div className="mt-4 flex h-[calc(100%-44px)] flex-col overflow-y-auto rounded-xl bg-sky-100/30">
        <TrialArmListHeader />

        {page.map((arm, index) => (
          <TrialArmListItem
            key={arm.id}
            arm={arm}
            trial={trial}
            projectId={projectId}
            highlight={index % 2 === 0}
          />
        ))}

        <div className="mt-auto">
          <TrialArmListFooter totalItemsOnPage={page.length} totalItems={allArms.length} />
        </div>
      </div>
    </>
  );
};

export default TrialArmListContent;
