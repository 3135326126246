import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';

/**
 * The default list control configuration for collections of [Trial] `Arm` objects.
 */
const defaultListControls = {
  matches: {},
  search: '',
  sort: {
    by: 'startAt',
    order: 'asc',
  },
  pagination: {
    page: 1,
    size: 10,
    pages: 1,
  },
};

/**
 * Select [Trial] `Arm` objects with matching criteria and applied ordering.
 * @param {Object[]} arms A collection of `Arm` objects.
 * @param {Object} matches Matcher criteria.
 * @param {string[]} sortBy Sort attributes.
 * @param {string[]} sortOrder Sort directions.
 * @returns {Object[]} A collection of Arms.
 */
export const selectArms = (arms = [], matches = {}, sortBy = ['startat'], sortOrder = ['asc']) => {
  return orderBy(filter(arms, matches), sortBy, sortOrder);
};

export const selectArmsByText = (arms = [], text = '') => {
  return filter(arms, (a) => {
    return a.name.toLowerCase().includes(text.toLowerCase());
  });
};

/**
 * Select [Trial] `Arm` objects with list controls selections applied.
 * @param {Object[]} arms A collection of `Arm` objects.
 * @param {Object} listControls A ListControls object.
 * @returns {Object} An object whose attributes are `data` and `page`.
 * The `data` attribute is a collection of all of the Arm objects
 * with list controls applied. The `page` attribute is a page of Arm objects with
 * list controls applied.
 */
export const selectArmsWithListControls = (arms = [], listControls) => {
  const result = {};
  const opts = merge({}, defaultListControls, listControls);
  // 1. filter by attribute matcher
  let items = filter(arms, opts.matches);
  // 2. filter by search text
  items = opts.search && opts.search.length > 1 ? selectArmsByText(items, opts.search) : items;
  // 3. apply sort
  result.data = orderBy(items, [opts.sort.by], [opts.sort.order]);
  // 4. apply pagination
  result.page = chunk(result.data, opts.pagination.size)[opts.pagination.page - 1] || [];

  return result;
};
