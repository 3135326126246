import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import Loading from '../common/Loading';

import { useAuthState } from '../../hooks/cognito.hooks';

const Protected = ({ children }) => {
  const { data: authState, isLoading } = useAuthState();

  /**
   * Initialize the Pendo analytics integration with visitor metadata.
   * @see https://support.pendo.io/hc/en-us/articles/360046272771#h_01GESNQ1BTNYMK9FAFTN46QCEE
   */
  useEffect(() => {
    if (authState && authState.sub) {
      // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
      // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
      // Call this function in your authentication promise handler or callback when your visitor and account id values are available
      // Please use Strings, Numbers, or Bools for value types.
      window.pendo.initialize({
        visitor: {
          id: authState.sub, // Required if user is logged in, default creates anonymous ID
          email: authState.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: authState.name, // Recommended if using Pendo Feedback
          // role:                     // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    }
  }, [authState]);

  if (isLoading) {
    return (
      <div className="flex h-1/4 w-screen items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  if (authState.isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/auth/signin" replace={true} />;
  }
};

export default Protected;
