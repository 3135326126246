import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import Loading from '../../common/Loading';

import { claimSchema } from '../../../validations/claim.validations';
import { useGetClaim, useUpdateClaim } from '../../../hooks/claims.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';
import ClaimForm from '../common/ClaimForm';

const ClaimFormStandalone = () => {
  const { projectId, claimId } = useParams();
  const { data: claim, isLoading } = useGetClaim({ projectId, claimId });
  const updateClaim = useUpdateClaim();
  const toastsContext = useToastsContext();

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{ ...claim }}
      validationSchema={claimSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        updateClaim.mutate(
          { projectId, claim: { ...claim, ...values } },
          {
            onSuccess: (claim) => {
              setSubmitting(false);
              toastsContext.createToast(`Updated project claim.`);
            },
            onError: (err) => {
              console.error(`Failed to update project claim. Detail:`, err);
              // TODO display error notification
              setSubmitting(false);
            },
          },
        );
      }}
    >
      {(formik) => <ClaimForm formik={formik} />}
    </Formik>
  );
};

export default ClaimFormStandalone;
