import Icon from '../../common/Icon';

import { useClaimListControlsContext } from '../../../hooks/listcontrols.hooks';

const ClaimListFilter = ({ className }) => {
  const { data: listControls, setSearch } = useClaimListControlsContext();

  return (
    <div className={className}>
      <form className="relative">
        <input
          type="text"
          name="filter"
          placeholder="Filter list..."
          autoComplete="off"
          value={listControls.search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-60 rounded-full border-0 bg-white/50 text-xs text-slate-700 focus:ring-0"
        />
        <Icon
          icon="magnifying-glass"
          className="absolute right-3 top-2 inline-block h-4 w-4 text-sky-900"
        />
      </form>
    </div>
  );
};

export default ClaimListFilter;
