import classNames from 'classnames';
import filter from 'lodash/filter';

import Icon from '../../../common/Icon';
import ScenarioTrial from './ScenarioTrial';

import { useGetAllTrialsForScenario } from '../../../../hooks/trials.hooks';
import { selectTrials } from '../../../../selectors/trials.selectors';

const ScenarioTrialPhase = ({ className, phases = [], phaseName, project, scenario }) => {
  const { data: trials, isLoading } = useGetAllTrialsForScenario(project.id, scenario.id);
  const filteredTrials = filter(trials, (t) => phases.includes(t.phase));
  const selectedTrials = selectTrials(filteredTrials, null, ['phase', 'startAt'], ['asc', 'asc']);

  if (isLoading) return <Icon icon="rotate" spin />;

  if (selectedTrials.length === 0) return null;

  return (
    <div className={classNames('px-2 py-1', className)}>
      <div className="mb-1 text-xl font-bold uppercase">{phaseName}</div>
      {selectedTrials.map((trial) => (
        <ScenarioTrial key={trial.id} trial={trial} project={project} scenario={scenario} />
      ))}
    </div>
  );
};

export default ScenarioTrialPhase;
