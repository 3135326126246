import { useContext } from 'react';

import {
  Actions,
  ArmListControlsContext,
  ClaimListControlsContext,
  EvidenceListControlsContext,
  IndicationListControlsContext,
  ProjectListControlsContext,
  ScenarioListControlsContext,
  TrialListControlsContext,
} from '../contexts/listcontrols.context';

const listControlsActions = (dispatch) => {
  /**
   * Action function to filter a list using the contents of the `matches` object.
   * @param {object} matches The matches iteratee shorthand.
   * @see https://lodash.com/docs#filter
   */
  const setMatches = (matches = {}) => {
    dispatch({
      type: Actions.SET_MATCHES,
      payload: {
        matches,
      },
    });
  };

  /**
   * Action function to modify the list control search text.
   * @param {string} search The list search filter text.
   */
  const setSearch = (search = '') => {
    dispatch({
      type: Actions.SET_SEARCH,
      payload: {
        search,
      },
    });
  };

  /**
   * Action function to modify the list control sort attribute.
   * @param {string} by The list attribute to sort by.
   */
  const setSort = (by = '') => {
    dispatch({
      type: Actions.SET_SORT,
      payload: {
        by,
      },
    });
  };

  /**
   * Action function to modify the list control page number.
   * @param {number} page  The list page number.
   */
  const setPage = (page = 1) => {
    dispatch({
      type: Actions.SET_PAGE,
      payload: {
        page,
      },
    });
  };

  return {
    setMatches,
    setSearch,
    setSort,
    setPage,
  };
};

/**
 * A React hook which provides access to the `ProjectListControlsContext` value.
 */
export const useProjectListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(ProjectListControlsContext);
  if (!context) {
    throw new Error(
      'useProjectListControlsContext hook must be used inside a ProjectListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};

/**
 * A React hook which provides access to the `ScenarioListControlsContext` value.
 */
export const useScenarioListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(ScenarioListControlsContext);
  if (!context) {
    throw new Error(
      'useScenarioListControlsContext hook must be used inside a ScenarioListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};

/**
 * A React hook which provides access to the `ClaimListControlsContext` value.
 */
export const useClaimListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(ClaimListControlsContext);
  if (!context) {
    throw new Error(
      'useClaimListControlsContext hook must be used inside a ClaimListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};

/**
 * A React hook which provides access to the `EvidenceListControlsContext` value.
 */
export const useEvidenceListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(EvidenceListControlsContext);
  if (!context) {
    throw new Error(
      'useEvidenceListControlsContext hook must be used inside an EvidenceListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};

/**
 * A React hook which provides access to the `IndicationListControlsContext` value.
 */
export const useIndicationListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(IndicationListControlsContext);
  if (!context) {
    throw new Error(
      'useIndicationListControlsContext hook must be used inside an IndicationListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};

/**
 * A React hook which provides access to the `TrialListControlsContext` value.
 */
export const useTrialListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(TrialListControlsContext);
  if (!context) {
    throw new Error(
      'useTrialListControlsContext hook must be used inside an TrialListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};

/**
 * A React hook which provides access to the `ArmListControlsContext` value.
 */
export const useArmListControlsContext = () => {
  // get the value from the React Context
  const context = useContext(ArmListControlsContext);
  if (!context) {
    throw new Error(
      'useArmListControlsContext hook must be used inside an ArmListControlsContextProvider',
    );
  }

  const { state, dispatch } = context;

  const actions = listControlsActions(dispatch);

  return {
    data: state,
    setMatches: actions.setMatches,
    setSearch: actions.setSearch,
    setSort: actions.setSort,
    setPage: actions.setPage,
  };
};
