import { useParams } from 'react-router-dom';

import Loading from '../../../common/Loading';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { useGetScenario, useUnlinkClaim } from '../../../../hooks/scenarios.hooks';
import { useGetClaim } from '../../../../hooks/claims.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';

const ScenarioUnlinkClaim = ({ onCancel, onSuccess, scenarioId, claimId }) => {
  const { projectId } = useParams();
  const toastsContext = useToastsContext();
  const unlinkClaim = useUnlinkClaim();
  const { data: scenario, isLoading: isLoadingScenario } = useGetScenario({
    projectId,
    scenarioId,
  });
  const { data: claim, isLoading: isLoadingClaim } = useGetClaim({
    projectId,
    claimId,
  });

  const doUnlink = () => {
    unlinkClaim.mutate(
      {
        projectId,
        claimId,
        scenarioId,
      },
      {
        onSuccess: () => {
          toastsContext.createToast(`Unlinked scenario and claim.`);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error(`Failed to unlink scenario and claim. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  if (isLoadingScenario || isLoadingClaim) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <h3 className="mb-6 text-2xl font-bold">UNLINK SCENARIO AND CLAIM</h3>

      <div className="mb-4">
        You are about to unlink scenario <span className="italic">{scenario.name}</span> and claim{' '}
        <span className="italic">{claim.name}</span>.
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={unlinkClaim.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={doUnlink}
          disabled={unlinkClaim.isLoading}
          isLoading={unlinkClaim.isLoading}
        >
          UNLINK
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default ScenarioUnlinkClaim;
