import { useField } from 'formik';
import classNames from 'classnames';

/**
 * The `TextareaField` component is a custom Formik input field for collecting form
 * values as `textarea` fields.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const TextareaField = ({ label, labelClassName, ...props }) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;

  return (
    <>
      <label
        htmlFor={props.id || props.name}
        className={classNames('font-bold text-slate-700', labelClassName)}
      >
        {label}
      </label>
      <textarea
        className={classNames(
          'mt-1 block w-full rounded-md focus:ring focus:ring-opacity-50 disabled:bg-slate-100',
          { 'border-slate-300 text-slate-700 focus:border-sky-300 focus:ring-sky-200': !showError },
          {
            'border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-200': showError,
          },
        )}
        {...field}
        {...props}
      />
      {showError ? <div className="mt-1 text-sm text-red-600">{meta.error}</div> : null}
    </>
  );
};

export default TextareaField;
