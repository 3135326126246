import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import AssetForm from '../common/AssetForm';

import { assetSchema } from '../../../validations/asset.validations';
import { useCreateAsset } from '../../../hooks/asset.hooks';
import { useToastsContext } from '../../../hooks/toasts.hooks';

const AssetCreate = () => {
  const { projectId } = useParams();
  const createAsset = useCreateAsset();
  const toastsContext = useToastsContext();

  return (
    <div id="asset-create" className="h-full overflow-y-auto">
      <Formik
        initialValues={{
          compoundName: '',
          developmentType: '',
          phase: '',
          manufacturer: '',
          indications: [],
          diseaseType: '',
          notes: '',
        }}
        validationSchema={assetSchema}
        onSubmit={(values, { setSubmitting }) => {
          createAsset.mutate(
            { projectId, asset: values },
            {
              onSuccess: (asset) => {
                setSubmitting(false);
                toastsContext.createToast(`Added asset information.`);
              },
              onError: (err) => {
                console.error(`Failed to create project asset. Detail:`, err);
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => <AssetForm formik={formik} />}
      </Formik>
    </div>
  );
};

export default AssetCreate;
