import { getProject, updateProject } from './projects.api';
import { generateId } from '../utils/id';

/**
 * Fetch an `Asset` for a project.
 * @param {string} projectId A project identifier.
 * @returns {Promise} A Promise which resolves to an Object if found,
 * otherwise rejects with an error.
 */
export const getAsset = async (projectId) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        if (project.asset) {
          return resolve(project.asset);
        }
        return reject(new Error('Not found.'));
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Creates an `Asset` for a project.
 * @param {Object} variables The API variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} variables.asset An Asset object.
 * @returns {Promise} A Promise which resolves to the created Asset if successful,
 * otherwise rejects with an error.
 */
export const createAsset = async ({ projectId, asset }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const newAsset = { ...asset, id: generateId(), createdAt: new Date().toISOString() };
        updateProject({
          ...project,
          asset: newAsset,
        }).then(() => {
          return resolve(newAsset);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * Updates an `Asset` for a project.
 * @param {Object} variables The API variables.
 * @param {string} variables.projectId A project identifier.
 * @param {Object} variables.asset An Asset object.
 * @returns {Promise} A Promise which resolves to the updated Asset if successful,
 * otherwise rejects with an error.
 */
export const updateAsset = async ({ projectId, asset }) => {
  return new Promise((resolve, reject) => {
    try {
      getProject(projectId).then((project) => {
        const updatedAsset = { ...project.asset, ...asset, updatedAt: new Date().toISOString() };
        updateProject({
          ...project,
          asset: updatedAsset,
        }).then(() => {
          return resolve(updatedAsset);
        });
      });
    } catch (err) {
      return reject(err);
    }
  });
};
