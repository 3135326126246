import filter from 'lodash/filter';

import Icon from '../../../common/Icon';
import ScenarioClaim from './ScenarioClaim';

import { selectClaims } from '../../../../selectors/claims.selectors';

const ScenarioClaims = ({ project, claims = [] }) => {
  if (claims.length === 0) return null;

  const selectedClaims = selectClaims(claims, null, ['overallScore'], ['desc']);

  return (
    <div>
      <div className="mb-4 -ml-2 flex items-center text-5xl">
        <span className="fa-layers fa-fw" title="Claims">
          <Icon icon="square" />
          <Icon icon="c" transform="shrink-6" />
        </span>
        <div className="-ml-1.5 text-3xl">laims</div>
      </div>

      <div className="px-2">
        {selectedClaims.map((claim) => (
          <ScenarioClaim
            key={claim.id}
            claim={claim}
            evidenceList={filter(project.evidence, (e) => claim.evidence.includes(e.id))}
          />
        ))}
      </div>
    </div>
  );
};

export default ScenarioClaims;
