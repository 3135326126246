import Icon from './Icon';
import classNames from 'classnames';

/**
 * The `LetterIcon` component renders an icon displaying a letter of the alphabet.
 * @param {Object} props The component properties.
 * @returns JSX
 */
const LetterIcon = ({ className, letter, title }) => {
  return (
    <span className={classNames('fa-layers fa-fw', className)} title={title}>
      <Icon icon="square" />
      <Icon icon={letter} transform="shrink-6" />
    </span>
  );
};

export default LetterIcon;
