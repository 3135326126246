import { Navigate, useSearchParams } from 'react-router-dom';
import find from 'lodash/find';
import reject from 'lodash/reject';

import ButtonBar from '../../../../common/ButtonBar';
import Button from '../../../../common/Button';
import LoadingButton from '../../../../common/LoadingButton';

import { useUpdateTrial } from '../../../../../hooks/trials.hooks';
import { useToastsContext } from '../../../../../hooks/toasts.hooks';

const TrialArmDelete = ({ projectId, trial }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateTrial = useUpdateTrial();
  const toastsContext = useToastsContext();
  const armId = searchParams.get('arm');

  const onCancel = () => {
    searchParams.delete('arm');
    searchParams.delete('mode');
    setSearchParams(searchParams);
  };

  const deleteArm = () => {
    const trialToUpdate = {
      ...trial,
      arms: reject(trial.arms, { id: armId }),
    };
    updateTrial.mutate(
      { projectId, trial: trialToUpdate },
      {
        onSuccess: (trial) => {
          toastsContext.createToast(`Deleted trial arm.`);
          searchParams.delete('arm');
          searchParams.delete('mode');
          setSearchParams(searchParams);
        },
        onError: (err) => {
          console.error(`Failed to delete trial arm. Detail:`, err);
          // TODO display error notification
        },
      },
    );
  };

  const arm = find(trial.arms, { id: armId });

  if (!arm) {
    return <Navigate to={`/projects/${projectId}/trials/${trial.id}?view=arms`} />;
  }

  return (
    <div className="flex h-full flex-col text-sky-900">
      <h3 className="mb-6 font-bold">DELETE TRIAL ARM</h3>

      <div className="mb-4">
        You are about to delete trial arm <span className="italic">{arm.name}</span>.
      </div>

      <div className="mb-4">Proceed?</div>

      <ButtonBar className="mt-auto ml-auto">
        <Button
          type="button"
          variant="primary"
          className="w-48 rounded"
          onClick={onCancel}
          disabled={updateTrial.isLoading}
        >
          CANCEL
        </Button>
        <LoadingButton
          type="button"
          variant="secondary"
          className="ml-4 w-48 rounded"
          onClick={deleteArm}
          disabled={updateTrial.isLoading}
          isLoading={updateTrial.isLoading}
        >
          DELETE
        </LoadingButton>
      </ButtonBar>
    </div>
  );
};

export default TrialArmDelete;
