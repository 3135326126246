import { Outlet } from 'react-router-dom';

import { TrialListControlsContextProvider } from '../../contexts/listcontrols.context';

const TrialsSection = () => {
  return (
    <div
      id="trials-section"
      className="mt-4 h-full rounded-2xl bg-white/10 p-6 shadow-lg shadow-slate-400"
    >
      <TrialListControlsContextProvider
        initialState={{
          sort: { by: 'name' },
          pagination: { size: 9 },
        }}
      >
        <Outlet />
      </TrialListControlsContextProvider>
    </div>
  );
};

export default TrialsSection;
