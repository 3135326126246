import { EvidenceListControlsContextProvider } from '../../../../contexts/listcontrols.context';
import TrialEvidenceListLoading from './TrialEvidenceListLoading';
import TrialEvidenceListEmpty from './TrialEvidenceListEmpty';
import TrialEvidenceListContent from './TrialEvidenceListContent';

import { useGetAllEvidenceForTrial } from '../../../../hooks/evidence.hooks';

const TrialEvidenceList = ({ trial, projectId }) => {
  const { data: trialEvidence, isLoading } = useGetAllEvidenceForTrial({
    projectId,
    trialId: trial.id,
  });

  return (
    <div id="trial-edit-evidence-list">
      {isLoading ? (
        <TrialEvidenceListLoading />
      ) : (
        <>
          <TrialEvidenceListEmpty trial={trial} evidenceList={trialEvidence} />
          <EvidenceListControlsContextProvider
            initialState={{ sort: { by: 'name' }, pagination: { size: 5 } }}
          >
            <TrialEvidenceListContent
              projectId={projectId}
              trial={trial}
              evidenceList={trialEvidence}
            />
          </EvidenceListControlsContextProvider>
        </>
      )}
    </div>
  );
};

export default TrialEvidenceList;
