import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import includes from 'lodash/includes';
import reject from 'lodash/reject';

import Loading from '../../../common/Loading';
import SelectField from '../../../common/SelectField';
import ButtonBar from '../../../common/ButtonBar';
import Button from '../../../common/Button';
import LoadingButton from '../../../common/LoadingButton';

import { linkScenarioSchema } from '../../../../validations/claim.validations';
import { useGetScenarios } from '../../../../hooks/scenarios.hooks';
import { useGetClaim, useLinkScenario } from '../../../../hooks/claims.hooks';
import { useToastsContext } from '../../../../hooks/toasts.hooks';
import { selectScenarios } from '../../../../selectors/scenarios.selectors';

const ClaimLinkScenario = ({ onCancel, onSuccess, claimId }) => {
  const toastsContext = useToastsContext();
  const linkScenario = useLinkScenario();
  const { projectId } = useParams();
  const { data: claim, isLoading: isLoadingClaim } = useGetClaim({
    projectId,
    claimId,
  });
  const { data: allScenarios, isLoading: isLoadingScenarios } = useGetScenarios({ projectId });

  if (isLoadingClaim || isLoadingScenarios) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading>
          <div className="mt-4 animate-pulse font-bold text-sky-900">Loading...</div>
        </Loading>
      </div>
    );
  }

  const eligibleScenarios = selectScenarios(
    reject(allScenarios, (scenario) => includes(scenario.claims, claim.id)),
  );

  return (
    <div className="flex h-full flex-col p-8 text-sky-900">
      <Formik
        initialValues={{ scenarioId: '' }}
        validationSchema={linkScenarioSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { scenarioId } = values;
          linkScenario.mutate(
            { projectId, claimId, scenarioId },
            {
              onSuccess: () => {
                toastsContext.createToast(`Linked scenario and claim.`);
                setSubmitting(false);
                onSuccess && onSuccess();
              },
              onError: (err) => {
                console.error(`Failed to link scenario and claim. Detail:`, err);
                // TODO display error notification
                setSubmitting(false);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <h3 className="mb-6 text-2xl font-bold">LINK SCENARIO</h3>

            <div className="mb-4">
              You are about to link a scenario to claim <span className="italic">{claim.name}</span>
              .
            </div>

            <div>Select the scenario you wish to link:</div>
            <div className="mb-6">
              <SelectField name="scenarioId" disabled={formik.isSubmitting}>
                <option value="">Select scenario...</option>
                {eligibleScenarios.map((scenario) => (
                  <option key={scenario.id} value={scenario.id}>
                    {scenario.name}
                  </option>
                ))}
              </SelectField>
            </div>

            <ButtonBar className="mt-auto ml-auto">
              <Button
                type="button"
                variant="primary"
                className="w-48 rounded"
                onClick={onCancel}
                disabled={linkScenario.isLoading}
              >
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                variant="secondary"
                className="ml-4 w-48 rounded"
                disabled={linkScenario.isLoading}
                isLoading={linkScenario.isLoading}
              >
                LINK
              </LoadingButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimLinkScenario;
