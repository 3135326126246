import { useNavigate, useParams } from 'react-router-dom';

import Modal from '../../common/Modal';
import ClaimUnmerge from './ClaimUnmerge';

const ClaimUnmergeModal = ({ isHidden = true, onHide, mergedId, standaloneId }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const hide = () => {
    !!onHide && onHide();
  };

  if (isHidden) {
    return null;
  }

  return (
    <Modal onClose={hide} size="lg" showClose={false}>
      <div className="flex min-h-[300px] overflow-hidden rounded-2xl bg-white shadow-lg shadow-slate-400">
        <div className="w-3 shrink-0 bg-sky-900"></div>
        <div className="grow">
          <ClaimUnmerge
            onCancel={hide}
            onSuccess={() => navigate(`/projects/${projectId}/claims`)}
            mergedId={mergedId}
            standaloneId={standaloneId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ClaimUnmergeModal;
